import * as React from 'react';
import RouteIcon from '@mui/icons-material/Route';
import PaymentsIcon from '@mui/icons-material/Payments';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Badge from '@mui/material/Badge';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { useState } from 'react';
import { getMenuIndex } from '../other/ConfigBasic';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationHelper from './NavigationHelper';
import EditedSvg from '../other/EditedSvg';
import AddIcon from '@mui/icons-material/Add';
import theme from '../../themes/theme';
import InvoiceReceipt from '../other/InvoiceReceipt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function BottomNavigationComponent(props) {
    const { badge, badgePendingLogbooks, showHint } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    const editAgain = NavigationHelper();
    const [value, setValue] = useState(getMenuIndex(location.pathname, editAgain, true));

    const getMenuItems = location.pathname.includes('/admin')
        ? [
              {
                  name: t('administration'),
                  icon: (
                      <Badge badgeContent={badgePendingLogbooks} color='error'>
                          <DashboardIcon color='secondary' />
                      </Badge>
                  ),
                  onClick: () => navigate('/admin'),
              },
              {
                  name: t('costs'),
                  icon: (
                      <PaymentsIcon
                          color='secondary'
                          sx={{
                              '&:active': {
                                  backgroundColor: '#b9f6ca',
                              },
                          }}
                      />
                  ),
                  onClick: () => navigate('/admin/costs'),
              },
              {
                  name: '',
                  icon: (
                      <Box
                          onClick={() => navigate('admin/logbooksCreate')}
                          sx={{
                              backgroundColor: '#EAFFF0',
                              height: '46px',
                              width: '46px',
                              borderRadius: '16px',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'relative',
                              cursor: 'pointer',
                              '&:hover': {
                                  backgroundColor: '#EEEEEE',
                              },
                          }}
                      >
                          <AddIcon color={theme.palette.secondary.main} />
                      </Box>
                  ),
              },
          ]
        : [
              {
                  name: t('status'),
                  icon: showHint?.status ? (
                      <Badge badgeContent={<PriorityHighIcon />} color='error'>
                          <DirectionsCarIcon color='secondary' />
                      </Badge>
                  ) : (
                      <DirectionsCarIcon color='secondary' />
                  ),
                  onClick: () => navigate('/status'),
              },
              {
                  name: t('open'),
                  icon: (
                      <Badge badgeContent={badge} color='error'>
                          <RouteIcon color='secondary' />
                      </Badge>
                  ),
                  onClick: () => navigate('/open'),
              },
              {
                  name: t('edited'),
                  icon: <EditedSvg />,
                  onClick: () => navigate('/edited'),
              },
              {
                  name: t('receipts'),
                  icon: <InvoiceReceipt size={24} fill={'#00360F'} />,
                  onClick: () => navigate('/costsOverview'),
              },
          ];

    useEffect(() => {
        setValue(getMenuIndex(location.pathname, editAgain, true));
    }, [location.pathname, editAgain]);

    return (
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
        >
            {getMenuItems.map((item) => (
                <BottomNavigationAction key={item.name} sx={{ marginLeft: item.name === '' ? 'auto' : '' }} label={item.name} icon={item.icon} onClick={item.onClick} />
            ))}
        </BottomNavigation>
    );
}

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';

import { LogBooksRolesExtended, LogBooksTeamRole } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';



export default function AllAccessMenu(props) {
    const { selectedRole, setSelectedRole, updateRole, disabled, extended, team, usersAccess, setUsersAccess, id, setDisable } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [role, setRole] = useState();

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        for (let lg in usersAccess) {
            if (usersAccess[lg].length > 0) {
                usersAccess[lg].forEach((element) => {
                    if (element.allowed.length === 1 && element.holder === id) {
                        setRole(t('viewer'));
                    }
                    if (element.allowed.length === 2 && element.holder === id) {
                        setRole(t('editor'));
                    }
                    if (element.allowed.length >= 3 && element.holder === id) {
                        setRole(t('admin'));
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, id, usersAccess])

    useEffect(() => {
        if (selectedRole !== null && selectedRole !== t('chooseRole') && !disabled) {
            setRole(selectedRole);
        }
    }, [selectedRole, t, usersAccess, disabled]);



    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        if (setDisable) {
            setDisable(false)
        }
        if (updateRole && selectedRole !== undefined && !id) {
            updateRole(getRoleId(option));
            if (usersAccess && setUsersAccess) {
                const updatedUsersAccess = Object.keys(usersAccess)?.reduce((acc, key) => {
                    const updatedAccess = usersAccess[key].map((access) => {
                        if (access.allowed.length < 4 && access.variant === 'Person') {
                            switch (getRoleId(option)) {
                                case t('admin'):
                                    return { ...access, allowed: ['view', 'edit', 'grant'] };
                                case t('editor'):
                                    return { ...access, allowed: ['view', 'edit'] };
                                case t('viewer'):
                                    return { ...access, allowed: ['view'] };
                                default:
                                    return { ...access, allowed: [] };
                            }
                        }
                        return access;
                    });
                    acc[key] = updatedAccess;
                    return acc;
                }, {});
                setUsersAccess(updatedUsersAccess);
            }

        } else {
            if (team) {
                updateRole(getRoleId(option));
            } else {
                updateRole(getRoleId('-'));
            }
            setSelectedRole(t('chooseRole'))
            setRole(option);
            const updatedUsersAccess = Object.keys(usersAccess).reduce((acc, key) => {
                const updatedAccess = usersAccess[key].map((access) => {
                    if (access.holder === id && access.allowed.length < 4 && access.variant === 'Person') {
                        switch (option) {
                            case t('admin'):
                                return { ...access, allowed: ['view', 'edit', 'grant'] };
                            case t('editor'):
                                return { ...access, allowed: ['view', 'edit'] };
                            case t('viewer'):
                                return { ...access, allowed: ['view'] };
                            default:
                                return { ...access, allowed: [] };
                        }
                    }
                    return access;
                });
                acc[key] = updatedAccess;
                return acc;
            }, {});

            setUsersAccess(updatedUsersAccess);
        }
        handleClose();
    };

    const getRoleId = (selectedRole) => {
        switch (selectedRole) {
            case t('viewer'):
            case t('editor'):
            case t('admin'):
            case t('noAccess'):
                return selectedRole;
            default:
                return null;
        }
    };

    const getMenuValue = (roleId) => {
        switch (roleId) {
            case t('viewer'):
            case t('editor'):
            case t('noAccess'):
            case t('admin'):
                return roleId;
            default:
                return t('chooseRole');
        }
    };

    return (
        <div>
            <Button
                sx={{ fontSize: '14px', width: '100%' }}
                variant='outlined'
                color='primary'
                id='role-button'
                aria-controls={open ? 'role-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleButtonClick}
                endIcon={<KeyboardArrowDownIcon />}
                disabled={disabled}
            >
                {getMenuValue(role)}
            </Button>
            <Menu
                id='role-menu'
                sx={{ fontSize: '14px' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'role-button',
                    role: 'listbox',
                }}
            >
                {extended
                    ? LogBooksRolesExtended().map((option) => (
                        <MenuItem key={option} value={option} onClick={() => handleMenuItemClick(option)} sx={{ fontSize: '14px' }}>
                            {option}
                        </MenuItem>
                    ))
                    : LogBooksTeamRole().map((option) => (
                        <MenuItem key={option} value={option} onClick={() => handleMenuItemClick(option)} sx={{ fontSize: '14px' }}>
                            {option}
                        </MenuItem>
                    ))}
            </Menu>
        </div>
    );
}

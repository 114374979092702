import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import Link from '@mui/material/Link';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import startIcon from '../other/tour-start-icon.svg';
import endIcon from '../other/tour-end-icon.svg';
import { useTranslation } from 'react-i18next';
import './MapCard.css';

export default function MapCard({ event, address }) {
    const { t } = useTranslation();
    const zoomLevel = 17;

    const getSrc = () => {
        if (address?.latLon === null) {
            return '/v2/imgs/no_address_' + event.type + '.svg';
        } else {
            return `https://www.google.com/maps/search/?api=1&query=${address?.latLon[0]},${address?.latLon[1]}`;
        }
    };

    const getIcon = () => {
        const endIconLabel = t('ziel');
        const icon = L.icon({
            iconUrl: event.label === endIconLabel ? endIcon : startIcon,
            iconSize: [40, 46.67],
        });
        return icon;
    };

    return (
        <Link href={getSrc()} underline='none' target='_blank' sx={{ textTransform: 'none' }}>
            <Card variant='outlined'>
                <CardActionArea>
                    {address?.latLon === null && <CardMedia height='140' component={'img'} src={getSrc()} alt={'No address'} sx={{ userSelect: 'none' }} />}
                    <CardContent>
                        {address?.latLon !== null && (
                            <Box sx={{ height: '140px' }}>
                                <MapContainer center={[address.latLon[0], address.latLon[1]]} zoom={zoomLevel} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                    />
                                    <Marker position={[address.latLon[0], address.latLon[1]]} icon={getIcon()}></Marker>
                                </MapContainer>
                            </Box>
                        )}
                        <Typography gutterBottom variant='h6' component='div'>
                            {event.label}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            {address.dt}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            {address.street} {address.postal} {address.city}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    );
}

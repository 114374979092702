import React from 'react';

export default function EditedSvg() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <g id='Gruppe_2967' data-name='Gruppe 2967' transform='translate(-45 -198)'>
                <g id='Gruppe_2966' data-name='Gruppe 2966' transform='translate(45 198)'>
                    <rect id='Rechteck_1464' data-name='Rechteck 1464' width='24' height='24' fill='none' />
                    <path
                        id='Pfad_3104'
                        data-name='Pfad 3104'
                        d='M11,7H2V9h9Zm0,8H2v2h9Zm5.34-4L12.8,7.46l1.41-1.41,2.12,2.12,4.24-4.24L22,5.34Zm0,8L12.8,15.46l1.41-1.41,2.12,2.12,4.24-4.24L22,13.34Z'
                        fill='#00360f'
                    />
                </g>
            </g>
        </svg>
    );
}

import React from 'react';
import i18n from 'i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';


const TimerPicker = ({ mobile, label, value, onChange, setShowHistory, error }) => {
    const { t } = useTranslation()
    const handleOnChange = (e) => {
        onChange(e)
        setShowHistory(true)
    }
    return (
        <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
            {mobile ? (
                <DesktopDateTimePicker
                    label={label}
                    inputFormat='DD.MM.YYYY HH:mm:ss'
                    value={value}
                    openTo="minutes"
                    onChange={(e) => handleOnChange(e)}
                    renderInput={(params) => <TextField
                        {...params}
                        sx={{
                            fontFamily: 'CircularProMedium',
                            color: theme.palette.grey[900],
                        }}
                        error={error}
                        helperText={error ? t('timeMoreThan10') : ''} />}

                    ampmInClock={false}

                />
            ) : (
                <MobileDateTimePicker
                    label={label}
                    inputFormat='DD.MM.YYYY HH:mm:ss'
                    value={value}
                    openTo="minutes"
                    onChange={(e) => handleOnChange(e)}
                    renderInput={(params) => <TextField
                        sx={{
                            fontFamily: 'CircularProMedium',
                            color: theme.palette.grey[900]
                        }}
                        {...params}
                        error={error}
                        helperText={error ? t('timeMoreThan10') : ''} />}
                    views={['day', 'month', 'year', 'hours', 'minutes']}
                    required
                />
            )}
        </LocalizationProvider>
    );
};

export default TimerPicker;

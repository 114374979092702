import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { Box, ListItemText, Typography } from '@mui/material';
import { useGetLatestUserCalibrationData } from '../../api/LogbookApi';
import RenameLogbook from './RenameLogbook';

const AssetsListItem = ({ row, setMessage, t, onDelete, logBooks, setLogBooks }) => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const calibrationData = useGetLatestUserCalibrationData(row.id, setMessage);

    const [logbookName, setLogBookName] = useState('')
    const [mileage, setMileage] = useState("")
    const [showDialog, setShowDialog] = useState(false)

    const handleMouseEnter = () => {
        setIsButtonVisible(true);
    };

    const handleMouseLeave = () => {
        setIsButtonVisible(false);
    };

    const handleButtonClick = (id) => {
        onDelete(id);

    };

    useEffect(() => {
        setLogBooks(prevLogBooks => {
            const updatedLogBooks = prevLogBooks.map((logBook) => {
                if (String(logBook.assetId) === String(row.id)) {
                    return {
                        ...logBook,
                        name: logbookName ? logbookName.trim() : row.name,
                        mileage: !!mileage ? Number(mileage) : calibrationData?.calibrationKm,
                    };
                }
                return logBook;
            });

            if (!updatedLogBooks.some((logBook) => String(logBook.assetId) === String(row.id))) {
                updatedLogBooks.push({
                    assetId: row.id,
                    name: logbookName ? logbookName.trim() : row.name,
                    mileage: !!mileage ? Number(mileage) : calibrationData?.calibrationKm,
                });
            }

            return updatedLogBooks;
        });

    }, [logbookName, mileage, row.id, calibrationData?.calibrationKm, row.name, setLogBooks]);
    useEffect(() => {
        setMileage(calibrationData?.calibrationKm)
    }, [calibrationData?.calibrationKm])
    return (
        <>
            <TableRow
                hover
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    position: 'relative',
                    cursor: 'pointer',
                }}
                onClick={() => setShowDialog(true)}
            >
                <TableCell >
                    <ListItemText
                        primary={
                            <Typography>
                                {row.name}
                            </Typography>}
                        secondary={
                            logbookName ?
                                <Typography sx={{
                                    color: '#757575',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    {logbookName}
                                </Typography> : ''
                        }
                    />

                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                    {mileage > 0 ? `${mileage} km` : calibrationData?.calibrationKm !== null ? calibrationData?.calibrationKm : '–'}
                </TableCell>
                {
                    isButtonVisible && (
                        <Box
                            component='td'
                            sx={{
                                position: 'absolute',
                                paddingRight: '16px',
                                right: '0',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            <Button
                                variant='outlined'
                                color='error'
                                sx={{ marginLeft: '8px' }}
                                onClick={() => {
                                    handleButtonClick(row.id);
                                }}
                            >
                                {t('delete')}
                            </Button>
                        </Box>
                    )
                }
            </TableRow >
            <RenameLogbook
                open={showDialog}
                setOpen={setShowDialog}
                t={t}
                name={logbookName}
                setLogBookName={setLogBookName}
                setMileage={setMileage}
                mileage={mileage}
            />
        </>
    );
};

export default AssetsListItem;

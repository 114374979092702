import * as React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { isUserCustomerAdmin } from '../../components/other/ConfigBasic';
import { useEffect } from 'react';
import AdminTable from '../../components/admin/AdminTable';
import { useGetAllLogbooksApi } from '../../api/LogbookApi';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useState } from 'react';
import { LinearProgress } from '@mui/material';

export default function Admin() {
    const loginUser = useOutletContext().loginUser;
    let navigate = useNavigate();
    const [message, setMessage] = useState('');
    const setBadgePendingLogbooks = useOutletContext().setBadgePendingLogbooks;
    let logbookList = useGetAllLogbooksApi(setMessage);

    useEffect(() => {
        if (!isUserCustomerAdmin(loginUser.adminRoles)) {
            navigate('/');
        }
    }, [loginUser.adminRoles, navigate]);

    useEffect(() => {
        setBadgePendingLogbooks(logbookList?.filter((logbook) => logbook.status === 'pending')?.length);
    }, [logbookList, setBadgePendingLogbooks]);

    if (!logbookList) {
        return (
            <>
                <LinearProgress />
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }

    if (!isUserCustomerAdmin(loginUser.adminRoles)) {
        return <>{message && <SimpleSnackbar message={message} setMessage={setMessage} />}</>;
    } else {
        return (
            <>
                <AdminTable providedlogbookList={logbookList} />
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }
}

import { Card, CardHeader } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { useTranslation } from 'react-i18next';
import DirectionsIcon from '@mui/icons-material/Directions';
import Link from '@mui/material/Link';

const getTourInfos = (lat, long, callback, setMessage) => {
    const baseUrl = 'https://geocode.bornemann.net/?latlng=';
    axios({
        method: 'get',
        url: `${baseUrl}${lat},${long}`,
    })
        .then((response) => callback(response.data))
        .catch((error) => setMessage(error.message));
};

export default function StatusLastPosition(props) {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [address, setAddress] = useState(t('unknown'));
    const href = props?.latlng?.length === 2 ? `https://www.google.com/maps/search/?api=1&query=${props?.latlng[0]},${props?.latlng[1]}` : 'https://www.google.com/maps';
    const setGeoCoderOutput = (output) => {
        const newAddress = `${output.line1.trim()}, ${output.line2}`;
        setAddress(newAddress);
    };

    useEffect(() => {
        if (props?.latlng?.length === 2) {
            getTourInfos(props.latlng[0], props.latlng[1], setGeoCoderOutput, setMessage);
        }
    }, [props.latlng]);

    return (
        <>
            <Link href={href} underline='none' target='_blank' sx={{ textTransform: 'none' }}>
                <Card
                    variant='outlined'
                    sx={{
                        maxWidth: '538px',
                        marginBottom: '8px',
                        cursor: 'pointer',
                    }}
                >
                    <CardHeader action={<DirectionsIcon />} title={t('lastPosition')} subheader={address} />
                </Card>
            </Link>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button } from '@mui/material';
import MergeTours from './MergeTours';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PrivateSvg from '../other/PrivateSvg';
import { GetTourTypes } from '../other/ConfigBasic';
import MarkTourAsTemplate from './MarkTourAsTemplate';
import { HomeWork } from '@mui/icons-material';

const ITEM_HEIGHT = 52;

export default function OpenToursActionBarMenu(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                sx={{ alignSelf: 'center', display: 'flex' }}
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={(event) => handleClick(event)}
            >
                <MoreVertIcon />
            </Box>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                <MenuItem>
                    <MarkTourAsTemplate
                        currentLogbook={props.logbookId}
                        saveTours={props.saveTours}
                        showIcon={false}
                        successMessage={t('tourMarkedAsCommute')}
                        icon={<HomeWork />}
                        buttonText={t('commute')}
                        tourType={GetTourTypes().COMMUTE}
                    />
                </MenuItem>
                <MenuItem>
                    <MarkTourAsTemplate
                        currentLogbook={props.logbookId}
                        saveTours={props.saveTours}
                        showIcon={false}
                        successMessage={t('tourMarkedAsPrivate')}
                        icon={<PrivateSvg />}
                        buttonText={t('privateTour')}
                        tourType={GetTourTypes().PRIVATE}
                    />
                </MenuItem>
                <MenuItem
                    sx={{
                        ':focus-visible': { backgroundColor: 'transparent' },
                    }}
                >
                    <MergeTours getSelectedToursData={props.getSelectedToursData} logbookId={props.logbookId} mergeTours={props.mergeTours} closeMobilMenu={handleClose} />
                </MenuItem>
                <MenuItem onClick={() => handleClose()}>
                    <Button variant='text' color='primary' disabled={props.selected.length > 1} onClick={() => navigate('/mixed/' + props.getSelectedToursData()[0].id)}>
                        {t('mixedTour')}
                    </Button>
                </MenuItem>
            </Menu>
        </>
    );
}

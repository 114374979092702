import React from 'react';

export default function ErrorSvg(props) {
    return (
        <svg id='Error-grey' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path id='Pfad_1061' data-name='Pfad 1061' d='M0,0H24V24H0Z' fill={props.fill} />
            <path id='Pfad_1062' data-name='Pfad 1062' d='M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15H11V15h2Zm0-4H11V7h2Z' fill='#ff3d00' />
        </svg>
    );
}

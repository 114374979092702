import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDeleteReceipt } from '../../../api/LogbookApi';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ITEM_HEIGHT = 52;

export default function CostActionBarMenu(props) {
    const { id } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const { deleteReceipt } = useDeleteReceipt(setMessage);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        await deleteReceipt({ variables: { id: id } });
    };

    useEffect(() => {
        if (message.length > 0) {
            navigate('/costsOverview', {
                state: {
                    snackbarMessage: message,
                },
            });
        }
    }, [message, navigate]);

    return (
        <>
            <Box
                sx={{ alignSelf: 'center', display: 'flex' }}
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={(event) => handleClick(event)}
            >
                <MoreVertIcon />
            </Box>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                <MenuItem onClick={() => handleDelete()}>{t('deleteEntry')}</MenuItem>
            </Menu>
        </>
    );
}

import * as React from 'react';
import { useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useGetContactsApi, useContactIsDriverApi, useGetLogbookDriversApi, useGetSelectedLogbookReasons } from '../../api/LogbookApi';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { GetTourTypes, searchUserAsDriver, getFullname, getBreakPoints } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, FormHelperText, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import theme from '../../themes/theme';
import { userHasEditAccess } from '../auth/authorization';
import { useCallback } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { v4 as uuidv4 } from 'uuid';
import EditAddress from './EditAddress';

const checkDriverData = (logbookDriverData, driverName) => {
    const foundedDriver = logbookDriverData.find((driver) => getFullname(driver) === driverName);
    if (foundedDriver === undefined) {
        logbookDriverData.push({
            id: '123456invalidDriver',
            firstname: driverName,
        });
    }
};

const checkCostCentre = (costCentreList, paramCostCentre) => {
    const foundedCostCentre = costCentreList.find((costcenter) => costcenter.name === paramCostCentre[0]);

    if (foundedCostCentre === undefined) {
        costCentreList.push({
            id: uuidv4(),
            name: paramCostCentre[0],
            active: true,
        });
    }
};

const checkCostObject = (costObjectList, paramCostObject) => {
    const foundedCostObject = costObjectList.find((costObject) => costObject.name === paramCostObject[0]);

    if (foundedCostObject === undefined) {
        costObjectList.push({
            id: uuidv4(),
            name: paramCostObject[0],
            active: true,
        });
    }
};

export default function EditTourForm(props) {
    const { costCentreList, costObjectList } = props;
    const saveButtonPressed = props.saveButtonPressed;
    const setSaveButtonPressed = props.setSaveButtonPressed;
    const updateEditTourAll = props.updateEditTourAll;
    const userAccess = useOutletContext().logbookInfo?.loginUserAccess;
    const selectedTour = props.selectedTour;
    const [type, setType] = useState('');
    const [reason, setReason] = useState('');
    const [contact, setContact] = useState('');
    const [contacts, setContacts] = useState([]);
    const [costCentre, setCostCentre] = useState('');
    const [costObject, setCostObject] = useState('');
    const [annotation, setAnnotation] = useState('');
    const [selectedDriver, setSelectedDriver] = useState('');
    const [message, setMessage] = useState('');
    const currentLogbook = useOutletContext().logbookId;
    const types = GetTourTypes();
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const { t } = useTranslation();
    const loginUser = useOutletContext().loginUser;
    const [validationErrors, setValidationErrors] = useState({
        driver: false,
        type: false,
        reason: false,
        contact: false,
    });

    const [reasons, setReasons] = useState([]);
    useGetSelectedLogbookReasons(currentLogbook, setMessage, setReasons, loginUser.id);
    const contactsData = useGetContactsApi(loginUser.custNr);
    const allDriverData = useContactIsDriverApi(setMessage);
    const driverIds = useGetLogbookDriversApi(currentLogbook);
    const location = useLocation();

    const formValidation = useCallback((setValidationErrors, selectedDriver, type, reason, contact, PRIVATE, COMMUTE, TWO_HOUSEHOLDS) => {
        let validationIsSuccessful = true;
        let validationErrors = {
            driver: false,
            type: false,
            reason: false,
            contact: false,
        };

        if (selectedDriver.length === 0) {
            validationErrors.driver = true;
            validationIsSuccessful = false;
        }
        if (type.length === 0) {
            validationErrors.type = true;
            validationIsSuccessful = false;
        }
        if (type !== PRIVATE && type !== COMMUTE && type !== TWO_HOUSEHOLDS) {
            if (reason === undefined || reason.length === 0) {
                validationErrors.reason = true;
                validationIsSuccessful = false;
            }
            if (contact === undefined || contact.length === 0) {
                validationErrors.contact = true;
                validationIsSuccessful = false;
            }
        }

        setValidationErrors(validationErrors);

        return validationIsSuccessful;
    }, []);

    const tourdetails = useCallback((selectedDriver, type, reason, contact, costCentre, costObject, annotation) => {
        return {
            driver: selectedDriver,
            type: type,
            reason: reason,
            contactName: contact,
            costCentre: costCentre,
            costObject: costObject,
            annotation: annotation,
        };
    }, []);

    useEffect(() => {
        if (saveButtonPressed) {
            setSaveButtonPressed(false);
            const editTourFormIsValid = formValidation(setValidationErrors, selectedDriver, type, reason, contact, types.PRIVATE, types.COMMUTE, types.TWO_HOUSEHOLDS);
            if (editTourFormIsValid) {
                if (type === types.PRIVATE || type === types.COMMUTE || type === types.TWO_HOUSEHOLDS) {
                    updateEditTourAll(tourdetails(selectedDriver, type, null, null, null, null, annotation));
                } else if (type === types.OTHER_INCOME) {
                    updateEditTourAll(tourdetails(selectedDriver, type, reason, contact, null, null, annotation));
                } else {
                    updateEditTourAll(tourdetails(selectedDriver, type, reason, contact, costCentre, costObject, annotation));
                }
            }
        }
    }, [
        saveButtonPressed,
        setSaveButtonPressed,
        contact,
        formValidation,
        reason,
        selectedDriver,
        costCentre,
        costObject,
        annotation,
        tourdetails,
        type,
        types.COMMUTE,
        types.PRIVATE,
        types.TWO_HOUSEHOLDS,
        types.OTHER_INCOME,
        updateEditTourAll,
    ]);

    useEffect(() => {
        setSelectedDriver(selectedTour?.driver === null ? '' : selectedTour?.driver);
        setType(selectedTour?.type === null ? '' : selectedTour?.type);
        setReason(selectedTour?.reason === null ? '' : selectedTour?.reason);
        setContact(selectedTour?.contactName === null ? '' : selectedTour?.contactName);
        setCostCentre(selectedTour?.costCentre === null ? '' : selectedTour?.costCentre);
        setCostObject(selectedTour?.costObject === null ? '' : selectedTour?.costObject);
        setAnnotation(selectedTour?.annotation === null ? '' : selectedTour?.annotation);
    }, [selectedTour]);

    useEffect(() => {
        if (location?.state?.type !== undefined) {
            setType(location?.state?.type);
        }
    }, [location]);

    if (!contactsData || !allDriverData || !driverIds || !costCentreList || !costObjectList) {
        return <LinearProgress />;
    }

    if (contacts.length === 0) {
        const contactsArray = contactsData.contactPersons.map(({ firstname, lastname, id }) => ({
            label: firstname + ' ' + lastname,
            value: id,
        }));
        contactsArray.push(
            ...contactsData.contactCompanies.map(({ legalName, id }) => ({
                label: legalName,
                value: id,
            }))
        );
        contactsArray.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        setContacts(contactsArray);
    }

    let logbookDriverData = allDriverData
        .filter((driver) => driverIds.includes(driver.id))
        .sort((a, b) => {
            if (a.firstname?.toLocaleUpperCase() < b.firstname?.toLocaleUpperCase() || b.firstname === null) {
                return -1;
            } else if (a.firstname?.toLocaleUpperCase() > b.firstname?.toLocaleUpperCase() || a.firstname === null) {
                return 1;
            } else {
                return 0;
            }
        });

    if (selectedDriver.length === 0) {
        searchUserAsDriver(loginUser.loginUuid, logbookDriverData, setSelectedDriver, false);
    }

    if (selectedTour.driver) {
        checkDriverData(logbookDriverData, selectedTour.driver);
    }

    if (selectedTour.costCentre && costCentre.length > 0) {
        checkCostCentre(costCentreList, costCentre);
    }

    if (selectedTour.costObject && costObject.length > 0) {
        checkCostObject(costObjectList, costObject);
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleReasonChange = (event) => {
        switch (event.type) {
            case 'change':
                setReason(event.target.value);
                break;
            default:
                setReason(event.target.innerText);
        }
    };

    const handleContactChange = (event) => {
        if (event !== null) {
            switch (event.type) {
                case 'change':
                    setContact(event.target.value);
                    break;
                default:
                    setContact(event.target.innerText);
            }
        }
    };

    const handleCostCentreChange = (event) => {
        setCostCentre([event.target.value]);
    };

    const handleCostObjectChange = (event) => {
        setCostObject([event.target.value]);
    };

    const handleAnnotationChange = (event) => {
        setAnnotation(event.target.value);
    };

    const handleDriverChange = (event) => {
        setSelectedDriver(event.target.value);
    };

    const disableElement = () => {
        let disableElement = true;
        if ((selectedTour.status === 'OPEN' || (selectedTour.status === 'EDITED' && selectedTour.type !== 'PRIVATE')) && userHasEditAccess(userAccess)) {
            disableElement = false;
        }
        return disableElement;
    };

    const disableElementTypeOfTour = () => {
        let disableElement = true;
        if (selectedTour.type !== 'PRIVATE' && userHasEditAccess(userAccess)) {
            disableElement = false;
        }
        return disableElement;
    };

    const getTranslation = (key) => {
        let translation = '';
        switch (key) {
            case types.BUSINESS:
                translation = t('business');
                break;
            case types.COMMUTE:
                translation = t('commute');
                break;
            case types.OTHER_INCOME:
                translation = t('otherIncome');
                break;
            case types.PRIVATE:
                translation = t('privateTour');
                break;
            case types.TWO_HOUSEHOLDS:
                translation = t('twoHouseholds');
                break;
            default:
                break;
        }
        return translation;
    };

    const sortAndGetTypes = () => {
        let typesWithTranslation = [];
        Object.values(types).map((type) =>
            typesWithTranslation.push({
                type: type,
                translation: getTranslation(type),
            })
        );
        typesWithTranslation.sort((a, b) => {
            if (a.translation?.toLocaleUpperCase() < b.translation?.toLocaleUpperCase() || b.translation === null) {
                return -1;
            } else if (a.translation?.toLocaleUpperCase() > b.translation?.toLocaleUpperCase() || a.translation === null) {
                return 1;
            } else {
                return 0;
            }
        });
        return typesWithTranslation;
    };
    return (
        <>
            {props.showEditAddress && (
                <EditAddress
                    mobile={desktop900}
                    open={props.showEditAddress}
                    setOpen={props.setShowEditAddress}
                    startInfos={props.startInfos}
                    endInfos={props.endInfos}
                    setMessage={setMessage}
                    tour={selectedTour}
                    setAnnotation={setAnnotation}
                />
            )}
            <Card variant='outlined'>
                <CardContent>
                    <Grid item xs={12} md={12} padding={theme.components.LogbookFormGrid.padding}>
                        <FormControl fullWidth error={validationErrors.driver} required={true}>
                            <InputLabel id='demo-simple-select-label'>{t('driver')}</InputLabel>
                            <Select
                                disabled={disableElement()}
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={selectedDriver}
                                label={t('driver')}
                                onChange={handleDriverChange}
                            >
                                {logbookDriverData.map((driver) => (
                                    <MenuItem key={driver.id} value={getFullname(driver)}>
                                        {getFullname(driver)}
                                    </MenuItem>
                                ))}
                            </Select>
                            {validationErrors.driver && <FormHelperText>{t('mandatoryField')}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} padding={theme.components.LogbookFormGrid.padding}>
                        <FormControl fullWidth error={validationErrors.type} required={true}>
                            <InputLabel id='demo-simple-select-label'>{t('typeOfTour')}</InputLabel>
                            <Select
                                disabled={disableElementTypeOfTour()}
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={type}
                                label={t('typeOfTour')}
                                onChange={handleTypeChange}
                            >
                                {sortAndGetTypes().map((type) => (
                                    <MenuItem key={type.type} value={type.type}>
                                        {type.translation}
                                    </MenuItem>
                                ))}
                            </Select>
                            {validationErrors.type && <FormHelperText>{t('mandatoryField')}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {type !== types.PRIVATE && type !== types.COMMUTE && type !== types.TWO_HOUSEHOLDS && (
                        <>
                            <Grid item xs={12} md={12} padding={theme.components.LogbookFormGrid.padding}>
                                <FormControl fullWidth error={validationErrors.reason}>
                                    <Autocomplete
                                        disabled={disableElement()}
                                        freeSolo
                                        inputValue={reason === undefined ? '' : reason}
                                        options={reasons
                                            .sort((a, b) => {
                                                if (a.label?.toLocaleUpperCase() < b.label?.toLocaleUpperCase() || b.label === null) {
                                                    return -1;
                                                } else if (a.label?.toLocaleUpperCase() > b.label?.toLocaleUpperCase() || a.label === null) {
                                                    return 1;
                                                } else {
                                                    return 0;
                                                }
                                            })
                                            .map((reason) => reason.label.trim())}
                                        onInputChange={handleReasonChange}
                                        renderInput={(params) => <TextField error={validationErrors.reason} required={true} {...params} label={t('reasonOfTheTour')} />}
                                    />
                                    {validationErrors.reason && <FormHelperText>{t('mandatoryField')}</FormHelperText>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12} padding={theme.components.LogbookFormGrid.padding}>
                                <FormControl fullWidth error={validationErrors.contact}>
                                    <Autocomplete
                                        disabled={disableElement()}
                                        freeSolo
                                        inputValue={contact === undefined ? '' : contact}
                                        options={contacts}
                                        getOptionLabel={(contact) => contact.label}
                                        renderOption={(props, contact) => {
                                            return (
                                                <li {...props} key={contact.value}>
                                                    {contact.label}
                                                </li>
                                            );
                                        }}
                                        onInputChange={handleContactChange}
                                        renderInput={(params) => <TextField error={validationErrors.contact} required={true} {...params} label={t('visitedContact')} />}
                                    />
                                    {validationErrors.contact && <FormHelperText>{t('mandatoryField')}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {(costCentreList.length > 0 || costObjectList.length > 0) && type === types.BUSINESS && (
                        <>
                            <Grid item xs={12} md={12} padding={theme.components.LogbookFormGrid.padding}>
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-label'>{t('costCentre')}</InputLabel>
                                    <Select
                                        endAdornment={
                                            costCentre.length > 0 && (
                                                <ClearIcon
                                                    sx={{ cursor: 'pointer', backgroundColor: '#fff', zIndex: 1, color: theme.palette.grey[900], marginRight: '-7px' }}
                                                    onClick={() => {
                                                        setCostCentre('');
                                                    }}
                                                />
                                            )
                                        }
                                        disabled={disableElement()}
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={costCentre === '' ? costCentre : costCentre[0]}
                                        label={t('costCentre')}
                                        onChange={handleCostCentreChange}
                                    >
                                        {costCentreList.map((costCentre) => (
                                            <MenuItem key={costCentre.id} value={costCentre.name}>
                                                {costCentre.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12} padding={theme.components.LogbookFormGrid.padding}>
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-label'>{t('costObject')}</InputLabel>
                                    <Select
                                        endAdornment={
                                            costObject.length > 0 && (
                                                <ClearIcon
                                                    sx={{ cursor: 'pointer', backgroundColor: '#fff', zIndex: 1, color: theme.palette.grey[900], marginRight: '-7px' }}
                                                    onClick={() => {
                                                        setCostObject('');
                                                    }}
                                                />
                                            )
                                        }
                                        disabled={disableElement()}
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={costObject === '' ? costObject : costObject[0]}
                                        label={t('costObject')}
                                        onChange={handleCostObjectChange}
                                    >
                                        {costObjectList.map((costObject) => (
                                            <MenuItem key={costObject.id} value={costObject.name}>
                                                {costObject.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} md={12} padding={theme.components.LogbookFormGrid.padding}>
                        <TextField
                            disabled={disableElement()}
                            fullWidth
                            id=''
                            label={t('annotation')}
                            variant='outlined'
                            value={annotation}
                            onChange={handleAnnotationChange}
                            multiline
                            maxRows={10}
                            minRows={2}
                            size='small'
                        />
                    </Grid>

                    {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
                </CardContent>
            </Card>
        </>
    );
}

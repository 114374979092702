import * as React from 'react';
import { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import Link from '@mui/material/Link';
import { useContactIsDriverApi } from '../../api/LogbookApi';
import { useTranslation } from 'react-i18next';
import SimpleSnackbar from './SimpleSnackbar';
import SelectionDialogTemplate from '../dialog/SelectionDialogTemplate';

export const mergeDriverData = (currentLogbookDriverIds, contactDriverData, t) => {
    let allDriverData = [];
    if (contactDriverData === undefined || contactDriverData?.length === 0) {
        currentLogbookDriverIds.forEach((driverId) => {
            allDriverData.push({
                firstname: t('unknown'),
                lastname: null,
                id: driverId,
                isDriver: null,
                imageUri: null,
                loginUuid: null,
            });
        });
    } else {
        currentLogbookDriverIds.forEach((driverId) => {
            if (
                contactDriverData.every((driver) => {
                    return driver.id !== driverId;
                })
            ) {
                allDriverData.push({
                    firstname: t('unknown'),
                    lastname: null,
                    id: driverId,
                    isDriver: null,
                    imageUri: null,
                    loginUuid: null,
                });
            }
        });

        allDriverData = allDriverData.concat(contactDriverData);
    }
    return allDriverData.sort((a, b) => {
        if (a.firstname < b.firstname) {
            return -1;
        } else if (a.firstname > b.firstname) {
            return 1;
        } else {
            return 0;
        }
    });
};

export default function DialogChangeLogbookDriver(props) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [checked, setChecked] = React.useState([]);
    const [driverList, setDriverList] = useState(undefined);
    const { t } = useTranslation();
    const currentLogbookDriverIds = props.logbook.idDrivers;
    let contactDriverData = useContactIsDriverApi(setMessage);
    let allDriverData = mergeDriverData(currentLogbookDriverIds, contactDriverData, t);

    //Set initial check
    useEffect(() => {
        setChecked(currentLogbookDriverIds);
    }, [currentLogbookDriverIds]);

    //Set initial driver list
    useEffect(() => {
        if (open && driverList === undefined) {
            setDriverList(allDriverData);
        }
    }, [open, driverList, allDriverData]);

    if (!contactDriverData) {
        return <LinearProgress />;
    }

    const handleToggle = (driverId) => () => {
        const currentIndex = checked.indexOf(driverId);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(driverId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleChangeAll = () => {
        if (checked.length < allDriverData.length) {
            setChecked(allDriverData.map((driver) => driver.id));
        } else {
            setChecked([]);
        }
    };

    const handleSave = () => {
        setOpen(false);
        props.changeLogbookDrivers(checked);
        setDriverList(undefined);
    };

    const handleCancel = () => {
        setOpen(false);
        setChecked(currentLogbookDriverIds);
        setDriverList(undefined);
    };

    const filterDrivers = (event) => {
        const searchValue = event.target.value;
        if (searchValue.length === 0) {
            setDriverList(mergeDriverData(currentLogbookDriverIds, contactDriverData, t));
        } else {
            setDriverList(
                mergeDriverData(currentLogbookDriverIds, contactDriverData, t).filter(
                    (driver) => driver?.firstname?.toLowerCase().includes(searchValue.toLowerCase()) || driver?.lastname?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    };

    return (
        <>
            <Link onClick={() => setOpen(true)} underline='none'>
                {checked.length} {t('selected')}
            </Link>
            <SelectionDialogTemplate
                open={open}
                title={t('selectDriver')}
                filterListFunction={filterDrivers}
                checked={checked}
                dataAll={allDriverData}
                handleChangeAll={handleChangeAll}
                dataList={driverList}
                handleToggle={handleToggle}
                showAvatar={true}
                emptyListMessage={t('noResult')}
                showEmptyListAction={true}
                emptyListActionMessage={t('addADriver')}
                handleCancel={handleCancel}
                handleAction={handleSave}
                handleActionMessage={t('save')}
            />
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

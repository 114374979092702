import * as React from 'react';
import { Card, CardContent, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useGetPersonsApi, useGetTeam } from '../../api/LogbookApi';
import { AccessContent } from './AccessContent';
import DialogAddLogbookUser from '../../components/ui/DialogAddLogbookUser';
import { useTranslation } from 'react-i18next';
import { getFullname } from '../../components/other/ConfigBasic';
import EMailNotification from './EMailNotification';

export default function UsersSettings(props) {
    const logbook = props.logbook;
    const { t } = useTranslation();
    const newPersonsData = useGetPersonsApi();
    let personsData;
    const team = useGetTeam();

    if (!newPersonsData) {
        return <LinearProgress />;
    } else {
        personsData = [...newPersonsData];
        personsData.sort((a, b) => {
            if (a.firstname?.toLocaleUpperCase() < b.firstname?.toLocaleUpperCase() || b.firstname === null) {
                return -1;
            } else if (a.firstname?.toLocaleUpperCase() > b.firstname?.toLocaleUpperCase() || a.firstname === null) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    const getAccessPersons = () => {
        const accessPersons = logbook.access.filter((a) => a.holder !== team);
        return accessPersons;
    };

    const getAccessTeam = () => {
        const accessTeam = logbook.access.filter((a) => a.holder === team);
        return accessTeam;
    };

    const getFullnameById = (personId) => {
        const person = personsData.find((person) => person.id === personId);
        return getFullname(person);
    };

    return (
        <>
            <Grid xs={12} md={12} sx={{ pt: '0px', fontSize: { xs: '14px', md: '16px' } }}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container xs={12} md={12} alignItems='center'>
                            <Grid xs={10} md={10} textAlign='left'>
                                <h4 style={{ padding: 0, marginBlock: 0 }}>{t('userWithLogbookAccess')}</h4>
                            </Grid>
                            <Grid xs={2} md={2} textAlign='right' alignSelf='center'>
                                <DialogAddLogbookUser
                                    access={logbook.access}
                                    personsData={personsData}
                                    getFullnameById={getFullnameById}
                                    changeLogbookAccess={props.changeLogbookAccess}
                                />
                            </Grid>
                            <Grid xs={10} md={10} textAlign='left'>
                                <h4 style={{ padding: '16px 0 0 0', marginBlock: 0 }}>{t('generalAccess')}</h4>
                            </Grid>
                            {getAccessTeam().length === 0 ? (
                                <AccessContent
                                    access={{
                                        holder: team,
                                        variant: 'Team',
                                        allowed: [],
                                        dtNotBefore: null,
                                        dtNotAfter: null,
                                    }}
                                    changeLogbookAccess={props.changeLogbookAccess}
                                    logbook={logbook}
                                    getFullnameById={getFullnameById}
                                />
                            ) : (
                                <>
                                    {getAccessTeam().map((accessTeam) => (
                                        <AccessContent
                                            key={accessTeam.holder}
                                            access={accessTeam}
                                            changeLogbookAccess={props.changeLogbookAccess}
                                            logbook={logbook}
                                            getFullnameById={getFullnameById}
                                        />
                                    ))}
                                </>
                            )}

                            <Grid xs={10} md={10} textAlign='left'>
                                <h4 style={{ padding: '16px 0 0 0', marginBlock: 0 }}>{t('individualAccess')}</h4>
                            </Grid>
                            {getAccessPersons().map((accessPerson) => (
                                <AccessContent
                                    key={accessPerson.holder}
                                    access={accessPerson}
                                    changeLogbookAccess={props.changeLogbookAccess}
                                    logbook={logbook}
                                    getFullnameById={getFullnameById}
                                />
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={12} sx={{ pt: '0px', fontSize: { xs: '14px', md: '16px' } }}>
                <Card variant='outlined'>
                    <CardContent>
                        <EMailNotification
                            personsData={personsData}
                            getFullnameById={getFullnameById}
                            notifications={logbook.notifications}
                            removedUser={props.removedUser}
                            setRemovedUser={props.setRemovedUser}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Checkbox, Divider, List, ListItem, ListItemText, ListSubheader, Pagination } from '@mui/material';
import theme from '../../themes/theme';
import { GetTourTypes, getFullname } from '../other/ConfigBasic';
import WarningIcon from '@mui/icons-material/Warning';
import { useState } from 'react';
import { LeadingActions, SwipeableList, SwipeableListItem, SwipeAction, TrailingActions } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { useOutletContext } from 'react-router';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { useLongPress } from 'use-long-press';
import OpenToursActionBar from './OpenToursActionBar';
import { useEffect } from 'react';
import OpenToursBanner from './OpenToursBanner';
import MarkTourAsTemplateSwipe from './MarkTourAsTemplateSwipe';

export default function OpenToursTableMobile(props) {
    const { showBanner } = props;
    const logbookId = useOutletContext().logbookId;
    const { t } = useTranslation();
    const daysUntilHint = props.daysUntilHint;
    const [message, setMessage] = useState('');
    const loginUuid = useOutletContext().loginUser.loginUuid;
    const [swipeActive, setSwipeActive] = useState(false);
    let driverName = '';
    const selected = props.selected;
    const [dialogContent, setDialogContent] = useState({
        open: false,
        tourId: '',
        tourType: '',
        successMessage: '',
        saveTourOneClick: props.saveTourOneClick,
    });
    const rowsPerPage = 20;
    const [page, setPage] = useState(1);
    const count = parseInt(Math.ceil(props.openTourList.length / rowsPerPage));
    const openTourListSorted = [...props.openTourList].sort((a, b) => {
        if (a.dtBegin < b.dtBegin) {
            return 1;
        } else if (a.dtBegin > b.dtBegin) {
            return -1;
        } else {
            return 0;
        }
    });
    const openTourList = openTourListSorted.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);

    useEffect(() => {
        if (selected.length === 0) {
            setSwipeActive(false);
        }
    }, [selected, logbookId]);

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const checkRowClick = (event, tourId) => {
        if (selected.length > 0) {
            props.handleCheckboxClick(event, tourId);
        } else {
            props.handleRowClick(event, tourId);
        }
    };

    const bind = useLongPress((event, tourId) => {
        if (!swipeActive) {
            props.handleCheckboxClick(event, tourId.context);
        } else {
            setSwipeActive(false);
        }
    });

    const getWeekDay = (weekday) => {
        switch (weekday) {
            case 'Sunday':
                return t('sunday');
            case 'Monday':
                return t('monday');
            case 'Tuesday':
                return t('tuesday');
            case 'Wednesday':
                return t('wednesday');
            case 'Thursday':
                return t('thursday');
            case 'Friday':
                return t('friday');
            case 'Saturday':
                return t('saturday');
            default:
                return 'n.a.';
        }
    };

    const formatDate = (date) => {
        return `${getWeekDay(Moment(date).format('dddd'))}, ${Moment(date).format('DD.MM.yyyy')}`;
    };

    const getOpenToursDate = () => {
        let datesOfOpenTours = openTourList.map((openTour) => {
            return formatDate(openTour.dtBegin);
        });
        return [...new Set(datesOfOpenTours)];
    };

    const getToursToDate = (date) => {
        let toursToDate = openTourList.filter((openTour) => formatDate(openTour.dtBegin) === date);
        return toursToDate;
    };

    const getStartAddress = (tour) => {
        if (tour.type === GetTourTypes().PRIVATE) {
            return '';
        } else if (tour.streetBegin === null && tour.postalBegin === null && tour.cityBegin === null) {
            return 'n.a. -';
        } else {
            return `${tour.streetBegin === null ? '' : `${tour.streetBegin},`} 
                    ${tour.postalBegin === null ? '' : `${tour.postalBegin}`}
                    ${tour.cityBegin === null ? ' -' : `${tour.cityBegin} -`}`;
        }
    };

    const getEndAddress = (tour) => {
        if (tour.type === GetTourTypes().PRIVATE) {
            return '';
        } else if (tour.streetEnd === null && tour.postalEnd === null && tour.cityEnd === null) {
            return 'n.a. -';
        } else {
            return `${tour.streetEnd === null ? '' : `${tour.streetEnd},`} 
                    ${tour.postalEnd === null ? '' : `${tour.postalEnd}`}
                    ${tour.cityEnd === null ? ' -' : `${tour.cityEnd} -`}`;
        }
    };

    const checkWarning = (tour) => {
        let date1 = new Date(Moment(tour.dtBegin).format('MM/DD/YYYY')).getTime();
        let date2 = new Date(Moment().subtract(daysUntilHint, 'days').format('MM/DD/YYYY')).getTime();
        if (tour.type !== GetTourTypes().PRIVATE && date1 < date2) {
            return (
                <>
                    <WarningIcon color='error' sx={{ height: '10px' }} />
                    {`${Moment(tour.dtBegin).format('HH:mm')} - ${Moment(tour.dtEnd).format('HH:mm')}`}
                </>
            );
        } else {
            return `${Moment(tour.dtBegin).format('HH:mm')} - ${Moment(tour.dtEnd).format('HH:mm')}`;
        }
    };

    const uniqueDriverFound = () => {
        let userDriverData = props.allDriverData.find((driver) => driver.loginUuid === loginUuid && props.driverIds.includes(driver.id));

        if (userDriverData === undefined && props.driverIds.length === 1) {
            userDriverData = props.allDriverData.find((driver) => props.driverIds[0] === driver.id);
        }

        if (userDriverData !== undefined) {
            driverName = getFullname(userDriverData);
            return true;
        } else {
            return false;
        }
    };

    const saveTourSwipe = (tourId, tourType, successMessage) => {
        if (uniqueDriverFound()) {
            props.saveTourOneClick(tourId, driverName, tourType, successMessage);
        } else {
            setDialogContent({
                ...dialogContent,
                open: true,
                tourId: tourId,
                tourType: tourType,
                successMessage: successMessage,
            });
        }
    };

    const swipeCommute = (tourId) => (
        <LeadingActions>
            <SwipeAction destructive={true} onClick={() => saveTourSwipe(tourId, GetTourTypes().COMMUTE, t('tourMarkedAsCommute'))}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.success.main,
                        fontSize: '14px',
                        color: theme.palette.grey[900],
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                    }}
                >
                    {t('commute')}
                </Box>
            </SwipeAction>
        </LeadingActions>
    );

    const swipePrivate = (tourId) => (
        <TrailingActions>
            <SwipeAction destructive={true} onClick={() => saveTourSwipe(tourId, GetTourTypes().PRIVATE, t('tourMarkedAsPrivate'))}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.error.main,
                        fontSize: '14px',
                        color: theme.palette.grey[900],
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                    }}
                >
                    {t('privateTour')}
                </Box>
            </SwipeAction>
        </TrailingActions>
    );

    const getInnerHeight = () => {
        let maxHeight = 0;
        const heigthBanner = document?.getElementById('openToursBanner')?.offsetHeight;
        const heightBarsAndHeadline = 175;
        const innerHeight = window.innerHeight;
        if (showBanner && heigthBanner) {
            maxHeight = innerHeight - (heightBarsAndHeadline + heigthBanner);
        } else {
            maxHeight = innerHeight - heightBarsAndHeadline;
        }
        return `${maxHeight}px`;
    };

    return (
        <Box sx={{ position: 'absolute', left: '0', top: '64px', width: '100%', maxHeight: `${window.innerHeight - 120}px` }}>
            {selected.length > 0 && (
                <OpenToursActionBar
                    selected={selected}
                    setSelected={props.setSelected}
                    saveTours={props.saveTours}
                    getSelectedToursData={props.getSelectedToursData}
                    mergeTours={props.mergeTours}
                />
            )}
            {showBanner && <OpenToursBanner desktop900={props.desktop900} />}
            <Typography
                variant='h3'
                sx={{
                    padding: '16px',
                }}
            >
                {openTourListSorted.length} {t('uneditedTours')}
            </Typography>
            <List
                sx={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: getInnerHeight(),
                    paddingTop: '0',
                    '& ul': { padding: 0 },
                    '& li': { userSelect: 'none', '-webkit-user-select': 'none' },
                }}
            >
                {getOpenToursDate().map((date) => (
                    <li key={date}>
                        <ul>
                            <ListSubheader>{date}</ListSubheader>
                            {
                                <SwipeableList destructiveCallbackDelay={100}>
                                    {getToursToDate(date).map((tour) => (
                                        <SwipeableListItem key={tour.id} leadingActions={swipeCommute(tour.id)} trailingActions={swipePrivate(tour.id)}>
                                            <Box sx={{ width: '100%' }} onClick={(event) => checkRowClick(event, tour.id)}>
                                                <ListItem {...bind(tour.id)} sx={{ minHeight: '56px' }} onTouchMove={() => setSwipeActive(true)}>
                                                    {selected.length > 0 && <Checkbox edge='start' checked={selected.indexOf(tour.id) !== -1} />}
                                                    <ListItemText
                                                        primary={checkWarning(tour)}
                                                        primaryTypographyProps={{
                                                            fontSize: '16px',
                                                        }}
                                                        secondary={
                                                            <>
                                                                <Typography
                                                                    sx={{
                                                                        display: 'block',
                                                                        fontSize: '14px',
                                                                        color: theme.palette.grey[600],
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                    component='span'
                                                                >
                                                                    {getStartAddress(tour)}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        display: 'block',
                                                                        fontSize: '14px',
                                                                        color: theme.palette.grey[600],
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                    component='span'
                                                                >
                                                                    {getEndAddress(tour)}
                                                                </Typography>
                                                            </>
                                                        }
                                                    />
                                                    <Typography
                                                        sx={{
                                                            position: 'absolute',
                                                            right: '16px',
                                                            top: '16px',
                                                            fontSize: '12px',
                                                            color: theme.palette.grey[600],
                                                        }}
                                                    >
                                                        {tour.distance === null ? 'n.a.' : `${(tour.distance / 1000).toFixed(2).replace('.', ',')} km`}
                                                    </Typography>
                                                </ListItem>
                                                <Divider />
                                            </Box>
                                        </SwipeableListItem>
                                    ))}
                                </SwipeableList>
                            }
                        </ul>
                    </li>
                ))}
                <Pagination sx={{ padding: '16px' }} count={count} page={page} siblingCount={0} boundaryCount={1} onChange={(event, page) => handleChangePage(page)} />
            </List>
            <MarkTourAsTemplateSwipe dialogContent={dialogContent} setDialogContent={setDialogContent} />
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </Box>
    );
}

import React from 'react';

export default function SvgNoCosts() {
    const cls1 = '#e6e6e6';
    const cls2 = '#fff';
    const cls3 = '#b9f6ca';
    const cls4 = '#a0616a';
    const cls5 = '#2f2e41';
    const cls6 = '#3f3d56';

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='280' height='252.02' viewBox='0 0 280 252.02'>
            <g id='no-costs' transform='translate(0 3.229)'>
                <path
                    id='Pfad_3600'
                    data-name='Pfad 3600'
                    fill={cls1}
                    d='M302.513,260.308a2.151,2.151,0,0,0-1.489,2.648l35.914,128.26a2.151,2.151,0,0,0,2.648,1.489l132.4-37.072a2.151,2.151,0,0,0,1.489-2.648l-35.914-128.26a2.151,2.151,0,0,0-2.648-1.49Z'
                    transform='translate(-300.944 -205.814)'
                />
                <path
                    id='Pfad_3601'
                    data-name='Pfad 3601'
                    fill={cls2}
                    d='M348.221,393.621l126.166-35.328-34.17-122.029L314.052,271.591Z'
                    transform='translate(-307.916 -212.786)'
                />
                <path
                    id='Pfad_3602'
                    data-name='Pfad 3602'
                    fill={cls1}
                    d='M348.606,328.19c-.842.236-1.189,1.634-.774,3.117s1.438,2.5,2.28,2.262l57.542-16.112c.842-.236,1.189-1.634.774-3.117s-1.438-2.5-2.28-2.262Z'
                    transform='translate(-325.789 -253.09)'
                />
                <path
                    id='Pfad_3603'
                    data-name='Pfad 3603'
                    fill={cls1}
                    d='M358.5,363.542c-.842.236-1.189,1.634-.774,3.117s1.438,2.5,2.28,2.262l57.542-16.112c.842-.236,1.189-1.634.774-3.117s-1.438-2.5-2.28-2.262Z'
                    transform='translate(-331.053 -271.892)'
                />
                <path
                    id='Pfad_3604'
                    data-name='Pfad 3604'
                    fill={cls1}
                    d='M368.289,398.486c-.842.236-1.189,1.634-.774,3.117s1.438,2.5,2.28,2.262l57.542-16.112c.842-.236,1.189-1.634.774-3.117s-1.438-2.5-2.28-2.262Z'
                    transform='translate(-336.257 -290.478)'
                />
                <path
                    id='Pfad_3605'
                    data-name='Pfad 3605'
                    fill={cls1}
                    d='M378.188,433.838c-.842.236-1.189,1.634-.774,3.117s1.438,2.5,2.28,2.262L437.236,423.1c.842-.236,1.189-1.634.774-3.117s-1.438-2.5-2.28-2.262Z'
                    transform='translate(-341.522 -309.28)'
                />
                <path
                    id='Pfad_3606'
                    data-name='Pfad 3606'
                    fill={cls1}
                    d='M532.662,291.517a2.793,2.793,0,0,0,1.506,5.379l10.757-3.012a2.793,2.793,0,0,0-1.506-5.379Z'
                    transform='translate(-423.1 -240.515)'
                />
                <path
                    id='Pfad_3607'
                    data-name='Pfad 3607'
                    fill={cls1}
                    d='M542.369,326.184a2.793,2.793,0,0,0,1.506,5.379l10.757-3.012a2.793,2.793,0,1,0-1.506-5.379Z'
                    transform='translate(-428.263 -258.953)'
                />
                <path
                    id='Pfad_3608'
                    data-name='Pfad 3608'
                    fill={cls1}
                    d='M552.076,360.851a2.793,2.793,0,0,0,1.506,5.379l10.757-3.012a2.793,2.793,0,0,0-1.506-5.379Z'
                    transform='translate(-433.426 -277.391)'
                />
                <path
                    id='Pfad_3609'
                    data-name='Pfad 3609'
                    fill={cls1}
                    d='M561.783,395.517a2.793,2.793,0,0,0,1.506,5.379l10.757-3.012a2.793,2.793,0,0,0-1.506-5.379Z'
                    transform='translate(-438.589 -295.828)'
                />
                <path
                    id='Pfad_3610'
                    data-name='Pfad 3610'
                    fill={cls3}
                    d='M570.421,454.635a3.559,3.559,0,1,0,1.919,6.854l13.707-3.838a3.559,3.559,0,1,0-1.919-6.854h0Z'
                    transform='translate(-442.885 -326.816)'
                />
                <rect id='Rechteck_1761' data-name='Rechteck 1761' fill={cls1} width='105.332' height='0.936' transform='matrix(0.963, -0.27, 0.27, 0.963, 40.212, 142.378)' />
                <path id='Pfad_3611' data-name='Pfad 3611' fill={cls4} d='M497.944,470.424l-8.135,1.967-11.457-30.441,12.006-2.9Z' transform='translate(-254.416 -235.229)' />
                <path
                    id='Pfad_3612'
                    data-name='Pfad 3612'
                    fill={cls5}
                    d='M773.4,698.671l-.055-.227A10.408,10.408,0,0,1,781,685.894l15.916-3.848,2.5,10.333Z'
                    transform='translate(-552.038 -449.88)'
                />
                <path id='Pfad_3613' data-name='Pfad 3613' fill={cls4} d='M429.245,481.234h-8.369l-3.982-32.281h12.352Z' transform='translate(-221.729 -240.498)' />
                <path
                    id='Pfad_3614'
                    data-name='Pfad 3614'
                    fill={cls5}
                    d='M718.136,705.375H691.365v-.234a10.4,10.4,0,0,1,10.4-10.4h16.375Z'
                    transform='translate(-508.593 -456.633)'
                />
                <path
                    id='Pfad_3615'
                    data-name='Pfad 3615'
                    fill={cls5}
                    d='M708.592,556.335l-8.126-96.845,33.639-6.2.133-.024,9.846,6.329-3.427,35.641L756.435,550.8l-13.624,3.585-15.8-51.536-3.616-15.671-1.854,20.364,1.376,50.221Z'
                    transform='translate(-513.434 -328.2)'
                />
                <path
                    id='Pfad_3616'
                    data-name='Pfad 3616'
                    fill={cls3}
                    d='M698.14,366.638l-.089-.113L709.675,279l.015-.115.082-.082c.171-.171,4.244-4.2,8.433-4.2.606,0,1.181-.015,1.734-.029,3.206-.083,5.738-.15,8.747,2.86,3.066,3.066,13.07,14.262,13.07,29.6,0,14.842,1.352,60.965,1.365,61.429l.019.651-.546-.355c-.135-.087-13.59-8.655-24.875-.69a16.234,16.234,0,0,1-9.4,3.362A13.964,13.964,0,0,1,698.14,366.638Z'
                    transform='translate(-512.149 -233.151)'
                />
                <circle id='Ellipse_498' data-name='Ellipse 498' fill={cls4} cx='16.767' cy='16.767' r='16.767' transform='translate(181.521 12.855) rotate(-28.663)' />
                <path
                    id='Pfad_3617'
                    data-name='Pfad 3617'
                    fill={cls4}
                    d='M669.79,473.966a6.865,6.865,0,0,0,.4-10.518l9.522-22.46-12.467,2.294-7.131,20.976a6.9,6.9,0,0,0,9.678,9.707Z'
                    transform='translate(-491.18 -321.67)'
                />
                <path
                    id='Pfad_3618'
                    data-name='Pfad 3618'
                    fill={cls3}
                    d='M662.824,373.9l3.066-6.473a1.26,1.26,0,0,1-.453-.469c-2.865-4.965,14.439-46.192,15.592-48.927-.176-1.487-1.992-17.248-.664-22.561,1.563-6.254,4.774-9.168,10.734-9.746,6.573-.617,8.347,8.309,8.364,8.4l.006,22.948-7.544,26.416-17.2,35.1Z'
                    transform='translate(-493.413 -239.076)'
                />
                <path
                    id='Pfad_3619'
                    data-name='Pfad 3619'
                    fill={cls5}
                    d='M718.425,210c-2.01.262-3.526-1.795-4.229-3.7s-1.239-4.114-2.986-5.141c-2.387-1.4-5.442.284-8.172-.178-3.083-.523-5.088-3.79-5.245-6.914s1.086-6.127,2.305-9.007l.426,3.579a7.1,7.1,0,0,1,3.1-6.2l-.549,5.251a5.573,5.573,0,0,1,6.412-4.612l-.086,3.129a51.68,51.68,0,0,1,10.725-.525,17.346,17.346,0,0,1,9.875,3.8c4.049,3.518,5.528,9.312,5.031,14.653s-2.7,10.359-5,15.206c-.578,1.22-1.378,2.6-2.719,2.751a2.739,2.739,0,0,1-2.681-2.021,7.326,7.326,0,0,1,.033-3.6,15.3,15.3,0,0,0,.447-5.445c-.319-1.8-1.616-3.587-3.435-3.808s-3.679,1.858-2.805,3.467Z'
                    transform='translate(-512.004 -184.161)'
                />
                <path id='Pfad_3620' data-name='Pfad 3620' fill={cls6} d='M460.649,533.972H339.991v-.986H460.827Z' transform='translate(-180.827 -285.191)' />
            </g>
        </svg>
    );
}

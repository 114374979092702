import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NoLogbooksSvg() {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', textAlign: 'center', maxWidth: '100%' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='513.299' height='383.658' viewBox='0 0 513.299 383.658'>
                <g id='Gruppe_3673' data-name='Gruppe 3673' transform='translate(0 0.028)'>
                    <path id='Pfad_3971' data-name='Pfad 3971' fill='#9e616a' d='M619.682,585.829H609.468l-4.86-39.394h15.074Z' transform='translate(-244.872 -221.323)' />
                    <path
                        id='Pfad_3972'
                        data-name='Pfad 3972'
                        fill='#2f2e41'
                        d='M783.3,752.522h-7.324l-1.307-6.915-3.348,6.915H751.895a4.367,4.367,0,0,1-2.481-7.96l15.513-10.714v-6.991l16.317.974Z'
                        transform='translate(-403.1 -370.321)'
                    />
                    <path id='Pfad_3973' data-name='Pfad 3973' fill='#9e616a' d='M707.688,569.463l-8.2,6.094-27.407-28.711,12.1-9Z' transform='translate(-272.201 -217.846)' />
                    <path
                        id='Pfad_3974'
                        data-name='Pfad 3974'
                        fill='#2f2e41'
                        d='M903.687,724.4l-5.877,4.37L892.634,724l1.439,7.547-15.588,11.592a4.367,4.367,0,0,1-6.741-4.907l6.055-17.854-4.172-5.61,13.675-8.955Z'
                        transform='translate(-453.315 -361.796)'
                    />
                    <path
                        id='Pfad_3975'
                        data-name='Pfad 3975'
                        fill='#2f2e41'
                        d='M788.636,398.647s19.378,16.742,10.437,64.468L788.158,509.88l35.6,59.056-11.35,13.808-46.278-63.922-16.77-39.337-4.705-70.012Z'
                        transform='translate(-401.935 -237.392)'
                    />
                    <path
                        id='Pfad_3976'
                        data-name='Pfad 3976'
                        fill='#2f2e41'
                        d='M598.654,425.746,597.488,443.8l3.567,76.921,16.784-2.923,3.208-54.766Z'
                        transform='translate(-241.988 -172.443)'
                    />
                    <path id='Pfad_3977' data-name='Pfad 3977' fill='#ffb6b6' d='M237.444,604.23h9.134L250.922,569H237.443Z' transform='translate(-96.167 -230.462)' />
                    <path
                        id='Pfad_3978'
                        data-name='Pfad 3978'
                        fill='#2f2e41'
                        d='M402.178,748.028h2.571l10.037-4.082,5.379,4.081h0a11.463,11.463,0,0,1,11.463,11.462v.373h-29.45Z'
                        transform='translate(-263.23 -377.242)'
                    />
                    <path id='Pfad_3979' data-name='Pfad 3979' fill='#ffb6b6' d='M296.932,595.146l8.909,2.011,12-33.406-13.149-2.969Z' transform='translate(-120.26 -227.134)' />
                    <path
                        id='Pfad_3980'
                        data-name='Pfad 3980'
                        fill='#2f2e41'
                        d='M461.091,739.573l2.508.566,10.689-1.771,4.348,5.166h0a11.463,11.463,0,0,1,8.656,13.706l-.082.363-28.727-6.486Z'
                        transform='translate(-286.034 -374.983)'
                    />
                    <path
                        id='Pfad_3981'
                        data-name='Pfad 3981'
                        fill='#2f2e41'
                        d='M384.654,393.843c-4.282,12.915-3.829,26.934,1.029,41.98l2.077,84.714s-3.1,55.554,6.891,75.1h14.482l7.17-80.176.9-53.814,31.048,45.4-19.775,87.278,17.824,1.593,32.09-94.717L438.8,409.313Z'
                        transform='translate(-254.943 -235.447)'
                    />
                    <path
                        id='Pfad_3982'
                        data-name='Pfad 3982'
                        fill='#e6e6e6'
                        d='M652.438,324.028c11.077-9.982,27.553-15,41.374-9.4a68.7,68.7,0,0,0-30.873,35.661c-2.2,5.854-4.038,12.594-9.45,15.728-3.367,1.95-7.557,2.045-11.332,1.1a45.611,45.611,0,0,1-10.65-4.7l-.971-.022C634.9,348.138,641.361,334.01,652.438,324.028Z'
                        transform='translate(-355.717 -202.419)'
                    />
                    <path
                        id='Pfad_3983'
                        data-name='Pfad 3983'
                        fill='#fff'
                        d='M693.84,316.27A58.721,58.721,0,0,0,661.171,331.7a25.288,25.288,0,0,0-4.965,6.174,14.5,14.5,0,0,0-1.676,7.447c.06,2.415.4,4.878-.128,7.265a8.88,8.88,0,0,1-4.417,5.765,23.072,23.072,0,0,1-8.857,2.419c-3.387.415-6.911.793-9.843,2.692-.355.23-.692-.334-.337-.564,5.1-3.3,11.554-2.1,17.039-4.308a8.993,8.993,0,0,0,5.625-5.567c.7-2.389.352-4.929.27-7.373a15.477,15.477,0,0,1,1.368-7.342,23.376,23.376,0,0,1,4.71-6.342A56.97,56.97,0,0,1,674.437,321.6a59.762,59.762,0,0,1,19.4-5.985c.418-.054.422.6.006.657Z'
                        transform='translate(-355.823 -203.761)'
                    />
                    <path
                        id='Pfad_3984'
                        data-name='Pfad 3984'
                        fill='#fff'
                        d='M685.334,329.988a8.81,8.81,0,0,1,.971-11.367c.3-.3.776.157.475.455a8.158,8.158,0,0,0-.882,10.575c.247.343-.319.678-.564.337Z'
                        transform='translate(-377.249 -204.942)'
                    />
                    <path
                        id='Pfad_3985'
                        data-name='Pfad 3985'
                        fill='#fff'
                        d='M670.087,361.8a16.98,16.98,0,0,0,12.134-2.43c.356-.229.693.336.337.564a17.662,17.662,0,0,1-12.632,2.5c-.417-.075-.254-.712.161-.637Z'
                        transform='translate(-371.572 -221.463)'
                    />
                    <path
                        id='Pfad_3986'
                        data-name='Pfad 3986'
                        fill='#fff'
                        d='M714.034,321.839a4.987,4.987,0,0,0,3.73,2.912c.418.07.254.707-.161.637a5.588,5.588,0,0,1-4.133-3.212.34.34,0,0,1,.114-.451.33.33,0,0,1,.451.114Z'
                        transform='translate(-389.289 -206.22)'
                    />
                    <path
                        id='Pfad_3987'
                        data-name='Pfad 3987'
                        fill='#e6e6e6'
                        d='M708.493,385.15c-.262-.036-.524-.072-.79-.1a65.673,65.673,0,0,0-10.64-.54c-.275,0-.554.012-.828.024a69.237,69.237,0,0,0-24.841,5.718,67.241,67.241,0,0,0-9.022,4.793c-3.979,2.519-8.093,5.568-12.541,6.6a11.54,11.54,0,0,1-1.405.25l-18.378-15.913c-.024-.057-.051-.11-.075-.168l-.763-.6c.142-.1.292-.21.434-.314.082-.061.168-.118.25-.179.056-.039.112-.079.161-.118.019-.013.037-.026.052-.035.048-.039.1-.07.142-.1q1.252-.886,2.52-1.758s0,0,.011,0a98.862,98.862,0,0,1,20.587-11.066c.218-.082.44-.169.667-.245a64.158,64.158,0,0,1,10.079-2.836,56.718,56.718,0,0,1,5.684-.794,47.168,47.168,0,0,1,14.71,1.042c9.605,2.227,18.391,7.5,23.595,15.727C708.235,384.729,708.364,384.936,708.493,385.15Z'
                        transform='translate(-355.18 -224.807)'
                    />
                    <path
                        id='Pfad_3988'
                        data-name='Pfad 3988'
                        fill='#fff'
                        d='M708.521,391.752a58.721,58.721,0,0,0-35.373-7.35,25.285,25.285,0,0,0-7.682,1.94,14.5,14.5,0,0,0-5.822,4.936c-1.406,1.964-2.617,4.136-4.476,5.724a8.88,8.88,0,0,1-7,1.943,23.073,23.073,0,0,1-8.528-3.4c-2.954-1.708-6-3.528-9.48-3.776-.422-.03-.351-.683.071-.653,6.062.432,10.491,5.278,16.2,6.819a8.994,8.994,0,0,0,7.843-1.059c2-1.485,3.248-3.724,4.655-5.725a15.476,15.476,0,0,1,5.513-5.039,23.377,23.377,0,0,1,7.579-2.228,56.97,56.97,0,0,1,17.8.441,59.763,59.763,0,0,1,19.09,6.9c.367.209-.027.736-.391.528Z'
                        transform='translate(-355.451 -231.211)'
                    />
                    <path
                        id='Pfad_3989'
                        data-name='Pfad 3989'
                        fill='#fff'
                        d='M709.2,378.2a8.81,8.81,0,0,1,7.619-8.491c.419-.056.525.593.105.649a8.158,8.158,0,0,0-7.071,7.913c-.009.423-.663.35-.653-.071Z'
                        transform='translate(-387.576 -225.671)'
                    />
                    <path
                        id='Pfad_3990'
                        data-name='Pfad 3990'
                        fill='#fff'
                        d='M680.477,394.744a16.98,16.98,0,0,0,11.151,5.366c.422.032.351.685-.071.653a17.662,17.662,0,0,1-11.593-5.607c-.288-.311.226-.721.512-.412Z'
                        transform='translate(-375.702 -235.767)'
                    />
                    <path
                        id='Pfad_3991'
                        data-name='Pfad 3991'
                        fill='#fff'
                        d='M740.555,387.944a4.987,4.987,0,0,0,1.225,4.571c.292.307-.222.717-.512.412a5.588,5.588,0,0,1-1.366-5.053.34.34,0,0,1,.362-.291A.33.33,0,0,1,740.555,387.944Z'
                        transform='translate(-399.955 -232.91)'
                    />
                    <path
                        id='Pfad_3992'
                        data-name='Pfad 3992'
                        fill='#e6e6e6'
                        d='M199.156,710.6a14.67,14.67,0,0,1-5.867,7.9c-.446.321-.9.619-1.368.9-.143.083-.286.172-.434.25q-.241.143-.482.268h-12.87c-.232-.47-.458-.946-.684-1.416-5.516-11.59-9.389-24.1-8.729-36.836a47.155,47.155,0,0,1,3.076-14.429c3.534-9.2,9.984-17.171,18.855-21.182.22-.1.452-.2.678-.3-.071.256-.143.506-.214.762a65.916,65.916,0,0,0-2.011,10.466c-.036.274-.065.547-.089.827a69.051,69.051,0,0,0,2.213,25.4c.006.018.012.042.018.059q.759,2.74,1.761,5.4c.524,1.4,1.089,2.779,1.708,4.135C197.3,698.493,200.762,704.562,199.156,710.6Z'
                        transform='translate(-168.648 -337.473)'
                    />
                    <path
                        id='Pfad_3993'
                        data-name='Pfad 3993'
                        fill='#fff'
                        d='M198.281,645.813c-.125.167-.25.327-.375.494a58.386,58.386,0,0,0-6.616,11.162c-.1.2-.184.393-.262.6a58.2,58.2,0,0,0-4.653,17.4.886.886,0,0,0-.012.125c-.149,1.4-.238,2.82-.274,4.236a25.276,25.276,0,0,0,.851,7.878,14.142,14.142,0,0,0,3.249,5.6c.149.161.3.321.458.476.119.125.25.25.375.369,1.2,1.148,2.517,2.213,3.647,3.445a12.748,12.748,0,0,1,1.4,1.785,8.868,8.868,0,0,1,.952,7.2c-.809,3.011-2.66,5.551-4.552,7.973-.946,1.214-1.922,2.439-2.767,3.737-.309.464-.595.934-.857,1.416h-.75c.25-.482.524-.952.821-1.416,2.172-3.421,5.26-6.36,6.86-10.127a8.976,8.976,0,0,0,.042-7.913,10.521,10.521,0,0,0-1.446-2.011c-1.089-1.232-2.392-2.285-3.576-3.4-.3-.28-.589-.565-.869-.863a14.854,14.854,0,0,1-3.356-5.3,23.341,23.341,0,0,1-1.154-7.812c0-1.69.089-3.391.256-5.081.018-.214.042-.434.065-.654a59.955,59.955,0,0,1,11.7-29.291c.119-.167.244-.327.369-.488C198.067,645.021,198.537,645.486,198.281,645.813Z'
                        transform='translate(-175.437 -337.265)'
                    />
                    <path
                        id='Pfad_3994'
                        data-name='Pfad 3994'
                        fill='#fff'
                        d='M181.361,690.518a8.81,8.81,0,0,1-7.351-8.724.329.329,0,0,1,.657-.014,8.158,8.158,0,0,0,6.854,8.1c.417.068.254.7-.161.637Z'
                        transform='translate(-170.82 -351.938)'
                    />
                    <path
                        id='Pfad_3995'
                        data-name='Pfad 3995'
                        fill='#fff'
                        d='M193.065,719.05a16.981,16.981,0,0,0,6.862-10.3c.09-.414.727-.252.637.161a17.662,17.662,0,0,1-7.162,10.7c-.348.242-.683-.324-.337-.564Z'
                        transform='translate(-178.478 -362.89)'
                    />
                    <path
                        id='Pfad_3996'
                        data-name='Pfad 3996'
                        fill='#fff'
                        d='M194.684,665.241a4.987,4.987,0,0,0,4.7-.578c.345-.246.679.32.337.564a5.588,5.588,0,0,1-5.194.651.34.34,0,0,1-.238-.4.33.33,0,0,1,.4-.238Z'
                        transform='translate(-179.028 -345.107)'
                    />
                    <path
                        id='Pfad_3997'
                        data-name='Pfad 3997'
                        fill='#e6e6e6'
                        d='M244.558,684.236c-.208.161-.422.321-.631.488a65.671,65.671,0,0,0-7.907,7.14c-.19.2-.381.4-.565.6l-.006.006a69.225,69.225,0,0,0-13.482,21.5l-.018.054c-.006.018-.012.03-.018.048a68.088,68.088,0,0,0-2.993,9.77,80.647,80.647,0,0,1-2.457,9.859c-.173.482-.363.958-.565,1.416H189.462c.089-.47.184-.946.28-1.416a95.38,95.38,0,0,1,6.271-20.051c.1-.214.19-.428.3-.643a64.437,64.437,0,0,1,5.123-9.133.006.006,0,0,1,0,0l0,0a56.484,56.484,0,0,1,3.451-4.575,47.078,47.078,0,0,1,11.138-9.663c.024-.018.054-.03.077-.048,8.354-5.182,18.254-7.652,27.721-5.516h.006C244.076,684.123,244.314,684.177,244.558,684.236Z'
                        transform='translate(-177.078 -352.68)'
                    />
                    <path
                        id='Pfad_3998'
                        data-name='Pfad 3998'
                        fill='#fff'
                        d='M251,684.949c-.2.054-.4.113-.6.172a58.663,58.663,0,0,0-12,4.921c-.19.1-.381.208-.571.315a58.459,58.459,0,0,0-14.155,11.061.021.021,0,0,0-.006.012c-.048.048-.1.1-.143.149-.952,1.023-1.868,2.088-2.737,3.183a25.447,25.447,0,0,0-4.058,6.8,14.086,14.086,0,0,0-.779,6.432c.018.22.048.434.077.654.024.173.048.345.077.524.393,2.386,1.071,4.778.881,7.211a8.871,8.871,0,0,1-3.576,6.325,14.167,14.167,0,0,1-2.19,1.392,21.928,21.928,0,0,1-3.433,1.416h-2.338l.94-.268a28.7,28.7,0,0,0,3.29-1.148,16.023,16.023,0,0,0,1.785-.881,9.008,9.008,0,0,0,4.8-6.3c.363-2.463-.339-4.927-.756-7.336-.071-.417-.137-.827-.173-1.238a14.54,14.54,0,0,1,.506-6.224,23.241,23.241,0,0,1,3.784-6.938,49.583,49.583,0,0,1,3.261-3.9q.214-.241.446-.482a60.034,60.034,0,0,1,14.4-11.144h.006a59.074,59.074,0,0,1,12.554-5.2h.006c.2-.059.4-.119.595-.172C251.314,684.188,251.409,684.836,251,684.949Z'
                        transform='translate(-183.554 -353.078)'
                    />
                    <path
                        id='Pfad_3999'
                        data-name='Pfad 3999'
                        fill='#fff'
                        d='M233.473,705.41a8.81,8.81,0,0,1-.617-11.391c.257-.336.791.048.533.384a8.158,8.158,0,0,0,.6,10.6c.292.306-.221.716-.512.412Z'
                        transform='translate(-193.925 -356.968)'
                    />
                    <path
                        id='Pfad_4000'
                        data-name='Pfad 4000'
                        fill='#fff'
                        d='M222.514,738.1a16.981,16.981,0,0,0,11.679-4.091c.321-.276.732.236.412.512a17.662,17.662,0,0,1-12.162,4.232c-.423-.016-.35-.67.071-.653Z'
                        transform='translate(-190.321 -373.184)'
                    />
                    <path
                        id='Pfad_4001'
                        data-name='Pfad 4001'
                        fill='#fff'
                        d='M260.249,693.591a4.987,4.987,0,0,0,4.1,2.366c.423.011.35.664-.071.653A5.588,5.588,0,0,1,259.737,694a.34.34,0,0,1,.05-.462A.33.33,0,0,1,260.249,693.591Z'
                        transform='translate(-205.512 -356.798)'
                    />
                    <path
                        id='Pfad_4002'
                        data-name='Pfad 4002'
                        fill='#ffb8b8'
                        d='M602.526,293.131a6.9,6.9,0,0,1-10.571.5l-22.491,9.782,2.191-12.555,21.022-7.364a6.939,6.939,0,0,1,9.849,9.639Z'
                        transform='translate(-330.982 -190.131)'
                    />
                    <path
                        id='Pfad_4003'
                        data-name='Pfad 4003'
                        fill='#e6e6e6'
                        d='M516.474,283.538l-52.239,23.269-.112-.04L404.585,285.55a19.611,19.611,0,0,1-8.795-25.439h0a19.59,19.59,0,0,1,27.957-8.709l44.308,26.691,42.965-5.568Z'
                        transform='translate(-259.939 -176.619)'
                    />
                    <path
                        id='Pfad_4004'
                        data-name='Pfad 4004'
                        fill='#ccc'
                        d='M681.96,770.721a.706.706,0,0,1-.708.708H169.388a.708.708,0,1,1,0-1.416H681.252a.706.706,0,0,1,.708.708Z'
                        transform='translate(-168.661 -387.799)'
                    />
                    <path
                        id='Pfad_4005'
                        data-name='Pfad 4005'
                        fill='#e6e6e6'
                        d='M441.391,342.969l-52.66-8.423a10.051,10.051,0,0,1-5.923-14.069L385.2,315.7l-.928-50.185A37.176,37.176,0,0,1,402.318,233l5.135-3.071,2.6-6.591,23.931.584.07,8.644,8.57,13.666v.057l-.538,74.382-2.362,7.676,3.571,8.928Z'
                        transform='translate(-254.955 -166.389)'
                    />
                    <circle id='Ellipse_538' data-name='Ellipse 538' fill='#ffb8b8' cx='21.743' cy='21.743' r='21.743' transform='translate(147.507 5.264)' />
                    <path
                        id='Pfad_4006'
                        data-name='Pfad 4006'
                        fill='#2f2e41'
                        d='M414.794,159.354c-.649-2.732-.351-6.577.016-9.328a26.109,26.109,0,0,1,11.051-18.13,7.96,7.96,0,0,1,3.965-1.576,3.423,3.423,0,0,1,3.442,2.082,7.135,7.135,0,0,1,4.025-3.992,12.576,12.576,0,0,1,5.73-.772,20.942,20.942,0,0,1,17.471,12.489c.581,1.38,2.2-3.716,2.939-2.413a5.8,5.8,0,0,0,3.287,2.888c1.442.4,2.027,6.343,2.595,4.958A6.6,6.6,0,0,1,463,154.768a36.366,36.366,0,0,1-4.321-.827A10.772,10.772,0,0,0,446.886,162a14.274,14.274,0,0,0-1.878-4.177,4.831,4.831,0,0,0-3.875-2.129,5.427,5.427,0,0,0-3.729,2.144c-.957,1.124-1.676,2.435-2.641,3.553-2.829,3.28-6.656,10.93-10.689,10.372C420.892,171.325,415.68,163.084,414.794,159.354Z'
                        transform='translate(-268.185 -127.607)'
                    />
                    <path
                        id='Pfad_4007'
                        data-name='Pfad 4007'
                        fill='#fff'
                        d='M596.727,325.543a4.244,4.244,0,0,0-2.86-4.673l-58.555-19.5a4.244,4.244,0,0,0-1.745-.2l-14.677,1.388-8.421.8-15.54,1.469a4.257,4.257,0,0,0-3.8,3.564l-7.89,49.288a4.276,4.276,0,0,0,2.668,4.638l59.166,22.838a4.131,4.131,0,0,0,.861.231,4.247,4.247,0,0,0,1.295.01l38.231-5.666a4.237,4.237,0,0,0,3.584-3.569Z'
                        transform='translate(-296.04 -197.905)'
                    />
                    <path
                        id='Pfad_4008'
                        data-name='Pfad 4008'
                        fill='#b9f6ca'
                        d='M489.782,361.374l59.166,22.837a.534.534,0,0,0,.272.032l38.226-5.666a.526.526,0,0,0,.449-.447l7.687-50.614a.538.538,0,0,0-.357-.584l-.394-.133-58.165-19.365a.4.4,0,0,0-.082-.02.526.526,0,0,0-.137-.007l-35.8,3.387-2.841.267a.537.537,0,0,0-.473.445l-7.889,49.286a.534.534,0,0,0,.333.582Z'
                        transform='translate(-298.573 -200.439)'
                    />
                    <path
                        id='Pfad_4009'
                        data-name='Pfad 4009'
                        fill='#2f2e41'
                        d='M508.282,310.8l35.8-3.387a.527.527,0,0,1,.137.007.4.4,0,0,1,.082.02L602.463,326.8l-15.343,1.624-5.742.6-16.339,1.726a1.151,1.151,0,0,1-.143,0l-.093-.027Z'
                        transform='translate(-306.203 -200.439)'
                    />
                    <path
                        id='Pfad_4010'
                        data-name='Pfad 4010'
                        fill='#3f3d56'
                        d='M485.905,362.315l59.166,22.837a4,4,0,0,0,.863.226,4.3,4.3,0,0,0,1.3.016l38.226-5.666a4.245,4.245,0,0,0,3.587-3.572l7.681-50.614a4.279,4.279,0,0,0-.249-2.211c-.039-.093-.078-.187-.123-.275a4.16,4.16,0,0,0-1.347-1.605,4.246,4.246,0,0,0-1.14-.581l-.069-.025-58.483-19.478a5.329,5.329,0,0,0-.73-.177,4.217,4.217,0,0,0-1.02-.02l-14.674,1.383-8.421.8-15.543,1.468a4.22,4.22,0,0,0-1.8.593.791.791,0,0,0-.12.072.708.708,0,0,0-.077.058,4.253,4.253,0,0,0-1.8,2.834l-7.9,49.291a4.281,4.281,0,0,0,2.671,4.642Zm6.273-53.761a3.153,3.153,0,0,1,.756-1.589,2.767,2.767,0,0,1,.4-.392,3.163,3.163,0,0,1,1.383-.645,2.4,2.4,0,0,1,.3-.043l26.171-2.472,12.474-1.187c.085-.01.162-.008.24-.013a2.981,2.981,0,0,1,.564.042,2.466,2.466,0,0,1,.5.12l58.552,19.5.133.049a3.188,3.188,0,0,1,2.019,3.45L587.988,376a3.183,3.183,0,0,1-2.683,2.678l-38.232,5.665a3.221,3.221,0,0,1-1.62-.185l-59.166-22.831a3.214,3.214,0,0,1-2-3.478Z'
                        transform='translate(-296.038 -197.904)'
                    />
                    <path
                        id='Pfad_4011'
                        data-name='Pfad 4011'
                        fill='#3f3d56'
                        d='M499.332,308.753a.522.522,0,0,1,.16-.23.62.62,0,0,1,.077-.052c.025-.01.05-.025.075-.035a.52.52,0,0,1,.371-.006l1.232.434,60.163,21.223L575.49,328.6l7.879-.831L600.047,326l1.491-.156.29-.032a.538.538,0,0,1,.567.389.443.443,0,0,1,.016.085.531.531,0,0,1-.327.549.59.59,0,0,1-.147.04l-2.029.213-16.423,1.736-5.742.6L561.4,331.156a1.152,1.152,0,0,1-.143,0l-.093-.027-61.3-21.619-.208-.074a.4.4,0,0,1-.131-.072A.542.542,0,0,1,499.332,308.753Z'
                        transform='translate(-302.567 -200.841)'
                    />
                    <path
                        id='Pfad_4012'
                        data-name='Pfad 4012'
                        fill='#3f3d56'
                        d='M588.614,403.239a.532.532,0,0,1-.442-.6l8.637-57.318a.532.532,0,0,1,.605-.447h0a.532.532,0,0,1,.442.6l-8.637,57.318a.532.532,0,0,1-.605.447Z'
                        transform='translate(-338.557 -215.61)'
                    />
                    <path
                        id='Pfad_4013'
                        data-name='Pfad 4013'
                        fill='#3f3d56'
                        d='M621.806,397.927l-5.745.606,6.719-56.842s7.672-1.389,7.877-.832C630.784,341.192,621.86,397.563,621.806,397.927Z'
                        transform='translate(-349.855 -213.934)'
                    />
                    <path
                        id='Pfad_4014'
                        data-name='Pfad 4014'
                        fill='#3f3d56'
                        d='M331.145,182.533l-.155,25.922,45.889,17.486L393.359,203.8Z'
                        transform='translate(-134.054 -73.939)'
                    />
                    <path
                        id='Pfad_4015'
                        data-name='Pfad 4015'
                        fill='#3f3d56'
                        d='M629.536,316.089a3.154,3.154,0,0,0-1.486-1.033l-58.55-19.5a3.04,3.04,0,0,0-.5-.123,2.978,2.978,0,0,0-.566-.042l.095-.593.586-.424,13.9-10.066,46.433,14.23.081,16.1Z'
                        transform='translate(-330.563 -191.082)'
                    />
                    <path
                        id='Pfad_4016'
                        data-name='Pfad 4016'
                        fill='#fff'
                        d='M503.164,380.077a.9.9,0,0,1,.194.041l17.965,5.9a.893.893,0,1,1-.557,1.7l-17.965-5.9a.893.893,0,0,1,.363-1.737Z'
                        transform='translate(-303.735 -229.87)'
                    />
                    <circle id='Ellipse_539' data-name='Ellipse 539' fill='#f2f2f2' cx='7.14' cy='7.14' r='7.14' transform='translate(264.773 244.67)' />
                    <circle id='Ellipse_540' data-name='Ellipse 540' fill='#f2f2f2' cx='7.14' cy='7.14' r='7.14' transform='translate(82.706 270.85)' />
                    <circle id='Ellipse_541' data-name='Ellipse 541' fill='#f2f2f2' cx='7.14' cy='7.14' r='7.14' transform='translate(231.453 34.638)' />
                    <path
                        id='Pfad_4017'
                        data-name='Pfad 4017'
                        fill='#ffb8b8'
                        d='M566.287,386.1a6.9,6.9,0,0,1-9.465-4.735l-24.4-2.465,8.05-9.881,21.938,3.856a6.939,6.939,0,0,1,3.878,13.224Z'
                        transform='translate(-315.98 -225.394)'
                    />
                    <path
                        id='Pfad_4018'
                        data-name='Pfad 4018'
                        fill='#e6e6e6'
                        d='M494.837,334.46l-56.946-5.247-.078-.09L396.253,281.5a19.611,19.611,0,0,1,4.768-26.491h0a19.59,19.59,0,0,1,28.645,6.074l25.6,44.948,40.2,16.152Z'
                        transform='translate(-259.538 -177.671)'
                    />
                    <path
                        id='Pfad_4019'
                        data-name='Pfad 4019'
                        fill='#2f2e41'
                        d='M767.607,212.442c-1.414-10.373-3.188-21.51-10.5-29a24.6,24.6,0,0,0-35.547.368c-5.326,5.681-7.678,13.659-7.893,21.443s1.518,15.482,3.442,23.028a70.977,70.977,0,0,0,29.622-5.831,47.729,47.729,0,0,1,7.1-2.788c2.468-.628,4.227.952,6.523,2.055l1.26-2.413c1.032,1.92,4.226,1.351,5.385-.5S767.9,214.6,767.607,212.442Z'
                        transform='translate(-389.377 -147.232)'
                    />
                    <path
                        id='Pfad_4020'
                        data-name='Pfad 4020'
                        fill='#9e616a'
                        d='M699.878,320.407,658.3,321.3a6.577,6.577,0,1,0-2.939,9.266c5.514.313,48.653,6.436,51.227,2.49,2.612-4,16.555-18.139,16.555-18.139l-10.717-15.258Z'
                        transform='translate(-361.993 -197.3)'
                    />
                    <circle id='Ellipse_542' data-name='Ellipse 542' fill='#9e616a' cx='19.534' cy='19.534' r='19.534' transform='translate(328.483 36.405)' />
                    <path id='Pfad_4021' data-name='Pfad 4021' fill='#43a047' d='M599.361,299.184' transform='translate(-343.091 -197.109)' />
                    <path
                        id='Pfad_4022'
                        data-name='Pfad 4022'
                        fill='#3f3d56'
                        d='M774.071,276.609c-2.3-4.581-3.42-10.241-8.328-11.726-3.314-1-16.717.5-19.738,2.193-5.024,2.816-.471,8.093-3.435,13.032-3.222,5.368-11.978,16.142-15.2,21.51-2.215,3.691,5.246,14.523,4.048,18.658s-1.255,8.775.785,12.565c1.826,3.392-.816,6.393,1.021,9.78,1.91,3.522,4.253,16.691,2.476,20.282l-1.19,3.57c11.809.694,21.738-13.414,33.473-14.9,2.915-.369,5.941-.851,8.343-2.543,3.538-2.492,4.937-7.014,5.811-11.253a94.8,94.8,0,0,0-8.066-61.167Z'
                        transform='translate(-394.769 -183.086)'
                    />
                    <path
                        id='Pfad_4023'
                        data-name='Pfad 4023'
                        fill='#9e616a'
                        d='M804.245,349.322c-1.645-4.49-4.622-24.119-4.622-24.119l-18.638-.546,9.112,22.474-24.865,34.809s.046.077.13.213a6.576,6.576,0,1,0,5.515,6.988.454.454,0,0,0,.57-.1C774.214,385.571,805.889,353.812,804.245,349.322Z'
                        transform='translate(-407.238 -207.426)'
                    />
                    <path
                        id='Pfad_4024'
                        data-name='Pfad 4024'
                        fill='#3f3d56'
                        d='M816.1,316.261c-1.711-7.22-3.434-14.479-6.318-21.316s-7.013-13.294-12.82-17.912a14.993,14.993,0,0,0-6.26-3.239,7.216,7.216,0,0,0-6.585,1.774c-2.616,2.751-1.829,7.167-.869,10.839l8.486,32.488,24.506-2.6Z'
                        transform='translate(-417.202 -186.761)'
                    />
                    <path
                        id='Pfad_4025'
                        data-name='Pfad 4025'
                        fill='#2f2e41'
                        d='M758.432,207.206a67.779,67.779,0,0,0-3.161-13.94c-1.69-4.449-4.414-8.617-8.322-11.137-6.187-3.991-14.2-3.221-21.134-.925-5.365,1.775-10.6,4.474-14.384,8.811s-5.908,10.528-4.5,16.2l22.2-5.245-.81.572a17.872,17.872,0,0,1,9.538,12.431,18.517,18.517,0,0,1-3.906,15.379l15.14-5.371c3.115-1.1,6.449-2.378,8.3-5.212C759.527,215.483,759.03,211.128,758.432,207.206Z'
                        transform='translate(-386.481 -148.558)'
                    />
                </g>
            </svg>
            <h4>{t('noLogbooks')}</h4>
        </Box>
    );
}

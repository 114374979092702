import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import CostCentres from './components/CostCentres';
import CostObjects from './components/CostObjects';
import { useGetLogbookSetting, useUpdateLogbookSetting } from '../../api/LogbookApi';
import theme from '../../themes/theme';

export default function CostCentresAndCostObjects() {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [value, setValue] = useState(0);
    const logbookSetting = useGetLogbookSetting(setMessage);
    const { updateLogbookSetting } = useUpdateLogbookSetting(setMessage);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const saveLogbookSetting = async (pKey, pValue) => {
        const newLogbookSetting = [];
        logbookSetting?.map?.set(pKey, sortList(pValue));
        logbookSetting?.map?.forEach((value, key) => newLogbookSetting.push({ key: key, value: value }));
        await updateLogbookSetting({ variables: { id: logbookSetting.id, logbookSetting: { key: 'logbook', value: newLogbookSetting } } });
    };

    const sortList = (list) => {
        list.sort((a, b) => {
            if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
                return -1;
            } else if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        return list;
    };

    return (
        <>
            <Box sx={{ backgroundColor: '#ffffff', maxWidth: '600px', flexGrow: 1, borderRadius: '12px', border: `1px solid ${theme.palette.grey[300]}` }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '8px' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant='fullWidth'
                        textColor='secondary'
                        indicatorColor='secondary'
                        sx={{
                            width: '100%',
                            borderBottom: `1px solid ${theme.palette.grey[300]}`,
                            '& .MuiTabs-indicator': {
                                maxWidth: '40px',
                                height: '4px',
                                borderTopLeftRadius: '12px',
                                borderTopRightRadius: '12px',
                                left: `calc(${value === 0 ? 25 : 75}% - 20px) !important`,
                            },
                        }}
                    >
                        <Tab label={t('costCentres')} sx={{ fontFamily: 'CircularProMedium', fontSize: '14px', textTransform: 'none' }} />
                        <Tab label={t('costObjects')} sx={{ fontFamily: 'CircularProMedium', fontSize: '14px', textTransform: 'none' }} />
                    </Tabs>
                </Box>
                <Box justifyContent='center' alignItems='center' padding='24px'>
                    {value === 0 && (
                        <CostCentres allCostCentre={logbookSetting?.map?.has('costCentre') ? logbookSetting?.map.get('costCentre') : []} saveLogbookSetting={saveLogbookSetting} />
                    )}
                    {value === 1 && (
                        <CostObjects allCostObject={logbookSetting?.map?.has('costObject') ? logbookSetting?.map.get('costObject') : []} saveLogbookSetting={saveLogbookSetting} />
                    )}
                </Box>
            </Box>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

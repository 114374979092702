import * as React from 'react';
import Box from '@mui/material/Box';
import theme from '../../themes/theme';
import { useNavigate } from 'react-router-dom';
import { CreateNewLogbook } from '../../api/LogbookApi';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export default function OpenToursActionBar(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Box
            sx={
                props.mobil
                    ? {
                          position: 'fixed',
                          left: '0',
                          top: '0',
                          backgroundColor: theme.palette.success.main,
                          height: '64px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '16px',
                          zIndex: theme.zIndex.drawer + 2,
                          color: theme.palette.secondary.main,
                      }
                    : {
                          position: 'relative',
                          top: '-24px',
                          height: '56px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          color: theme.palette.secondary.main,
                      }
            }
        >
            <Box sx={{ display: 'flex' }}>
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                <Typography variant='h3' paddingBottom='0' paddingLeft='8px'>
                    {t('newLogbook')}
                </Typography>
            </Box>
            <CreateNewLogbook
                setMessage={props.setMessage}
                createLogbook={props.createLogbook}
                formValidation={props.formValidation}
                setValidationErrors={props.setValidationErrors}
                resetForm={props.resetForm}
            />
        </Box>
    );
}

import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NoTours() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    if (pathname === '/open' || pathname === '/') {
        return (
            <Box sx={{ width: '100%', textAlign: 'center', maxWidth: '100%' }}>
                <svg id='Komponente_102_1' data-name='Komponente 102 – 1' xmlns='http://www.w3.org/2000/svg' width='451.67' height='271.091' viewBox='0 0 451.67 271.091'>
                    <path
                        id='Pfad_3083'
                        data-name='Pfad 3083'
                        fill='#f2f2f2'
                        d='M336.38,596.043a1.767,1.767,0,0,1-1.281-3.062l.121-.481-.048-.116a4.745,4.745,0,0,0-8.752.033c-1.431,3.448-3.254,6.9-3.7,10.547a14.038,14.038,0,0,0,.246,4.828,56.277,56.277,0,0,1-5.119-23.373,54.327,54.327,0,0,1,.337-6.06q.279-2.474.774-4.913a56.929,56.929,0,0,1,11.289-24.128,15.15,15.15,0,0,0,6.3-6.538,11.555,11.555,0,0,0,1.051-3.158c-.307.04-1.157-4.631-.925-4.918-.427-.649-1.193-.971-1.659-1.6-2.322-3.148-5.521-2.6-7.191,1.68-3.568,1.8-3.6,4.787-1.413,7.659,1.393,1.827,1.584,4.3,2.806,6.256-.126.161-.257.317-.382.478a57.293,57.293,0,0,0-5.98,9.474,23.819,23.819,0,0,0-1.422-11.063c-1.361-3.283-3.912-6.049-6.159-8.887a4.889,4.889,0,0,0-8.708,2.4q-.007.063-.013.125.5.282.98.6a2.4,2.4,0,0,1-.966,4.361l-.049.008a23.845,23.845,0,0,0,.629,3.565c-2.882,11.146,3.34,15.206,12.225,15.388.2.1.387.2.583.3a58.483,58.483,0,0,0-3.148,14.815,55.466,55.466,0,0,0,.04,8.956l-.015-.106a14.657,14.657,0,0,0-5-8.463c-3.851-3.163-9.291-4.328-13.445-6.87a2.751,2.751,0,0,0-4.213,2.676l.017.111a16.091,16.091,0,0,1,1.805.87q.5.282.98.6a2.4,2.4,0,0,1-.966,4.361l-.049.008-.1.015a23.867,23.867,0,0,0,4.39,6.874c1.8,9.73,9.542,10.653,17.822,7.82h.005A58.469,58.469,0,0,0,322,608.57h14.03c.05-.156.1-.317.141-.473a15.941,15.941,0,0,1-3.882-.231c1.041-1.277,2.082-2.565,3.123-3.842a.868.868,0,0,0,.065-.075c.528-.654,1.061-1.3,1.589-1.956h0a23.349,23.349,0,0,0-.684-5.948Z'
                        transform='translate(-260.587 -337.994)'
                    />
                    <path
                        id='Pfad_3084'
                        data-name='Pfad 3084'
                        fill='#f2f2f2'
                        d='M826.159,596.043a1.767,1.767,0,0,0,1.281-3.062l-.121-.481.048-.116a4.745,4.745,0,0,1,8.752.033c1.431,3.448,3.254,6.9,3.7,10.547a14.039,14.039,0,0,1-.246,4.828,56.278,56.278,0,0,0,5.119-23.373,54.311,54.311,0,0,0-.337-6.06q-.279-2.474-.774-4.913a56.928,56.928,0,0,0-11.289-24.128,15.15,15.15,0,0,1-6.3-6.538,11.556,11.556,0,0,1-1.051-3.158c.307.04,1.157-4.631.925-4.918.427-.649,1.193-.971,1.659-1.6,2.322-3.148,5.521-2.6,7.191,1.68,3.568,1.8,3.6,4.787,1.413,7.659-1.393,1.827-1.584,4.3-2.806,6.256.126.161.256.317.382.478a57.3,57.3,0,0,1,5.98,9.474,23.818,23.818,0,0,1,1.422-11.063c1.361-3.283,3.912-6.049,6.159-8.887a4.889,4.889,0,0,1,8.708,2.4q.007.063.013.125-.5.282-.98.6a2.4,2.4,0,0,0,.966,4.361l.049.008a23.851,23.851,0,0,1-.629,3.565c2.882,11.146-3.34,15.206-12.225,15.388-.2.1-.387.2-.583.3a58.487,58.487,0,0,1,3.148,14.815,55.473,55.473,0,0,1-.04,8.956l.015-.106a14.658,14.658,0,0,1,5-8.463c3.851-3.163,9.291-4.328,13.445-6.87a2.751,2.751,0,0,1,4.213,2.676l-.017.111a16.088,16.088,0,0,0-1.805.87q-.5.282-.98.6a2.4,2.4,0,0,0,.966,4.361l.049.008.1.015a23.866,23.866,0,0,1-4.39,6.874c-1.8,9.73-9.542,10.653-17.822,7.82h-.005a58.467,58.467,0,0,1-3.927,11.465h-14.03c-.05-.156-.1-.317-.141-.473a15.938,15.938,0,0,0,3.882-.231c-1.041-1.277-2.082-2.565-3.123-3.842a.855.855,0,0,1-.065-.075c-.528-.654-1.061-1.3-1.589-1.956h0a23.351,23.351,0,0,1,.684-5.948Z'
                        transform='translate(-457.325 -337.994)'
                    />
                    <path
                        id='Pfad_3085'
                        data-name='Pfad 3085'
                        fill='#f2f2f2'
                        d='M386.895,494.535h22.64c3.872-13.038.021-43.851,5.125-56.445,9.792-24.148,22.3-49.626,45.65-61.417,9.969-5.035,20.839-7,31.94-6.894,15.335.145,31.1,4.262,45.534,9.717,5.9,2.227,11.755,4.675,17.583,7.177,21.955,9.434,43.671,19.71,67.282,22.487,29.876,3.512,65-9.855,73.163-38.647,6.228-22.043-4.988-44.929-16.751-64.615s-24.911-40.751-22.763-63.544c.008-.077.015-.161.031-.237.808-8.187,5.7-14.071,12.525-17.843H639.121c-.393,21.263,11.909,40.032,22.979,58.563,11.763,19.679,22.979,42.572,16.751,64.615-8.16,28.784-75.729-4.957-105.605-8.477-8.437-.987,4.965,2.525-3.079,0-12.617-3.956-14.016,32.326-26.295,27-7.644-3.321-15.3-6.618-23.048-9.549-3.21-1.217-6.49-2.357-9.807-3.413-.008,0-.008,0-.015-.008-22.278-7.055-46.643-9.8-67.228.4-.139.069-.285.138-.423.207C420,365.4,407.492,390.874,397.7,415.022,389.61,434.985,390.883,473.479,386.895,494.535Z'
                        transform='translate(-294.965 -224.275)'
                    />
                    <circle id='Ellipse_376' data-name='Ellipse 376' fill='#fff' cx='103.984' cy='103.984' r='103.984' transform='translate(113.906 61.625)' />
                    <path
                        id='Pfad_3086'
                        data-name='Pfad 3086'
                        fill='#3f3d56'
                        d='M524.647,529.974c-102.882,2.364-105.138-46.514-105.138-104.652a105.435,105.435,0,0,1,210.87,0C630.379,483.46,582.783,529.974,524.647,529.974Zm.3-207.185c-56.538,0-101.582,46-102.533,102.533-.886,52.579,54.971,118.19,102.533,102.535,25.507-28.091,97.621-58.347,102.534-102.535,6.247-56.19-46-102.533-102.534-102.533Z'
                        transform='translate(-307.053 -259.713)'
                    />
                    <path
                        id='Pfad_3087'
                        data-name='Pfad 3087'
                        fill='#f2f2f2'
                        d='M540.978,509.66a11.563,11.563,0,0,1-9.253-4.628l-28.368-37.825a11.567,11.567,0,0,1,18.507-13.879l18.56,24.744,47.668-71.5A11.567,11.567,0,1,1,607.341,419.4L550.6,504.509a11.572,11.572,0,0,1-9.3,5.147C541.193,509.658,541.086,509.66,540.978,509.66Z'
                        transform='translate(-337.273 -289.932)'
                    />
                    <path
                        id='Pfad_3088'
                        data-name='Pfad 3088'
                        fill='#ccc'
                        d='M240.825,653.387a.747.747,0,0,0,.749.749H691.746a.749.749,0,0,0,0-1.5H241.574a.747.747,0,0,0-.749.749Z'
                        transform='translate(-240.825 -383.045)'
                    />
                    <path id='Pfad_3089' data-name='Pfad 3089' fill='#ffb6b6' d='M446.429,404.056H440.34l-2.9-23.486h8.987Z' transform='translate(-162.136 -141.056)' />
                    <path
                        id='Pfad_3090'
                        data-name='Pfad 3090'
                        fill='#2f2e41'
                        d='M681.859,646.3H663.135v-.237a7.288,7.288,0,0,1,7.287-7.288h0l3.42-2.595,6.381,2.6h1.634Z'
                        transform='translate(-397.352 -376.943)'
                    />
                    <path
                        id='Pfad_3091'
                        data-name='Pfad 3091'
                        fill='#ffb6b6'
                        d='M399.474,358.863,393.85,361.2l-11.673-20.584,8.3-3.442Z'
                        transform='translate(-141.652 -124.969)'
                    />
                    <path
                        id='Pfad_3092'
                        data-name='Pfad 3092'
                        fill='#2f2e41'
                        d='M644.1,601.013l-17.3,7.172-.091-.219a7.288,7.288,0,0,1,3.94-9.523h0l2.165-3.707,6.888-.047,1.51-.626Z'
                        transform='translate(-383.645 -361.334)'
                    />
                    <path
                        id='Pfad_3093'
                        data-name='Pfad 3093'
                        fill='#2f2e41'
                        d='M662.967,429.884l-43.21,4.515-5.78,19.468-20.017,32.168s13.968,23.509,12.656,23.253,1.31,6.152,3.146,5.78,3.745,3.806,2.476,4.549,3.823,7.025,3.823,7.025l7.783-6.986c1.656-12.488-.345-23.6-7.471-32.791l22.3-24.075,4.228-1.755h0l3.357,89.335,12.415.2c3.426-17.623,5.147-33.483,2.469-44.8,0,0,.385-33.991,1.853-33.619s.224-4.056.224-4.056-1.286-3.771.294-5.336.593-10.766.593-10.766C666.716,443.291,665.931,436.1,662.967,429.884Z'
                        transform='translate(-371.712 -300.482)'
                    />
                    <path
                        id='Pfad_3094'
                        data-name='Pfad 3094'
                        fill='#e6e6e6'
                        d='M643.389,309.381l11.946-3.1,5.576-7.691,13.242,1.628,2.819,10.49,9.428,5.472s-5.211,55.114-3.971,58.14,5.684,2.776,1.888,6.677-4.3,2.33-3.417,6.577,7.3,8.742,0,8.7-51.356-.674-50.1-5.709,1.239-3.756,3.137-6.913,3.175-1.393,2.537-6.051-.009-7.8-.009-10.322,2.109-25.394,2.109-25.394Z'
                        transform='translate(-385.358 -251.821)'
                    />
                    <circle id='Ellipse_377' data-name='Ellipse 377' fill='#ffb6b6' cx='14.033' cy='14.033' r='14.033' transform='translate(268.107 17.286)' />
                    <path
                        id='Pfad_3095'
                        data-name='Pfad 3095'
                        fill='#2f2e41'
                        d='M665.761,254.958c1.232,1,.95,5.838,2.323,5.049s1.659-2.2,3.24-2.218a5.177,5.177,0,0,1,3.409,1.543c.168.152.328.311.484.469.142-.05.283-.1.43-.132a4.459,4.459,0,0,1,3.27.46,4.375,4.375,0,0,0-2.491,1c.3.338.608.678.93.993,2.688,2.634,8.2-.171,11.9-.8-3.086,3.37-2.478,5.251-1.71,9.754l0,.246c.158-1.039,3.484-.987,2.936.391a17.716,17.716,0,0,1-2.564,3.769c4.533-3.14,8.192-7.468,9-12.7a7.685,7.685,0,0,0,.177-3.293,2.525,2.525,0,0,0-2.412-1.923,9.3,9.3,0,0,0-11.443-11.737,35.553,35.553,0,0,0-4.3,1.858,7.737,7.737,0,0,1-4.559.772c-.974-.2-1.96-.705-2.914-.42-1.106.332-1.581,1.5-2.024,2.627a4.454,4.454,0,0,1,2.075,1.565,4.358,4.358,0,0,0-2.681-.109.273.273,0,0,0-.048.013C667.791,253.935,667.733,254.375,665.761,254.958Z'
                        transform='translate(-398.325 -232.132)'
                    />
                    <path
                        id='Pfad_3096'
                        data-name='Pfad 3096'
                        fill='#3f3d56'
                        d='M655.5,304.817c-7.188-.579-13.751,4.918-16.743,11.479s-3.188,14-3.333,21.213l-.451,22.41c-.067,3.316-.134,6.633-.3,9.946a48.632,48.632,0,0,1-1.21,9.574c-1.465,5.768-4.689,10.908-7.866,15.94a215.534,215.534,0,0,0,24.77,5.916c4.178-13.513,8.4-27.415,7.376-41.522-.543-7.465-2.551-14.753-3.314-22.2-1.081-10.545.352-21.168,1.782-31.671'
                        transform='translate(-383.44 -254.112)'
                    />
                    <path
                        id='Pfad_3097'
                        data-name='Pfad 3097'
                        fill='#3f3d56'
                        d='M689.885,306.845c7.12,1.145,12.189,8.043,13.538,15.127s-.228,14.36-1.8,21.4l-4.883,21.877c-.723,3.237-1.445,6.475-2.072,9.732-.608,3.164,1.94,7.223,1.969,10.445.054,5.951-1.1,10.852.789,16.5a215.525,215.525,0,0,1-25.466-.136c-.849-14.119-1.653-28.626,2.695-42.085,2.3-7.122,5.982-13.725,8.491-20.777,3.554-9.987,4.685-20.646,5.79-31.188'
                        transform='translate(-400.374 -254.879)'
                    />
                    <path
                        id='Pfad_3098'
                        data-name='Pfad 3098'
                        fill='#ffb6b6'
                        d='M708.058,445.406a4.078,4.078,0,0,1,1.412-5.51,3.913,3.913,0,0,1,.583-.281l-.908-30.911,9.834-2.5-3.75,36.013a4.067,4.067,0,0,1-1.781,4.635,3.892,3.892,0,0,1-5.336-1.349Z'
                        transform='translate(-413.801 -291.704)'
                    />
                    <path
                        id='Pfad_3099'
                        data-name='Pfad 3099'
                        fill='#3f3d56'
                        d='M706.1,325.611s6.865-5.805,12.641,5.8,2.548,38.125,1.735,39.54-1.309-.556-.864,3.216c.362,3.068-1.791,2.684-1.019,3.339,2.037,1.726-3.667,1.923-.738,3.444s-10.182,1.806-10.182,1.806-1.586-1.133-.484-5.4c.619-2.4,2.167-3.276.515-4.386s1.706-4.542,1.709-5.295-5.237-17.221-5.237-17.221Z'
                        transform='translate(-412.565 -261.296)'
                    />
                    <path
                        id='Pfad_3100'
                        data-name='Pfad 3100'
                        fill='#43a047'
                        d='M593.458,489.928a11.564,11.564,0,0,1-9.253-4.628l-28.368-37.825A11.567,11.567,0,1,1,574.344,433.6L592.9,458.34l47.668-71.5a11.567,11.567,0,1,1,19.249,12.832l-56.737,85.105a11.571,11.571,0,0,1-9.3,5.147C593.673,489.926,593.565,489.928,593.458,489.928Z'
                        transform='translate(-356.725 -282.618)'
                    />
                    <path
                        id='Pfad_3101'
                        data-name='Pfad 3101'
                        fill='#ffb6b6'
                        d='M646.191,436.555a4.078,4.078,0,0,0-1.1-5.58,3.925,3.925,0,0,0-.566-.313l2.615-30.814-9.681-3.043,1.754,36.165a4.067,4.067,0,0,0,1.522,4.726,3.892,3.892,0,0,0,5.4-1.052Z'
                        transform='translate(-387.833 -288.222)'
                    />
                    <path
                        id='Pfad_3102'
                        data-name='Pfad 3102'
                        fill='#3f3d56'
                        d='M649.947,317.322s-6.533-6.176-12.942,5.095-4.652,37.926-3.918,39.384,1.338-.483.685,3.259c-.531,3.043,1.64,2.779.833,3.39-2.129,1.611,3.555,2.123.546,3.48s10.066,2.366,10.066,2.366,1.646-1.043.782-5.368c-.485-2.429-1.983-3.391-.272-4.408s-1.453-4.629-1.414-5.381,6.18-16.906,6.18-16.906Z'
                        transform='translate(-385.923 -258.136)'
                    />
                </svg>
                <h4>{t('noOpenRides')}</h4>
                <p>{t('viewOrExportTours')}</p>
                <Button variant='outlined' color='primary' onClick={() => navigate('/edited')}>
                    {t('view')}
                </Button>
            </Box>
        );
    } else if (pathname === '/edited') {
        return (
            <Box
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    maxWidth: '100%',
                    p: 1,
                }}
            >
                <svg id='Gruppe_3043' data-name='Gruppe 3043' xmlns='http://www.w3.org/2000/svg' width='213.142' height='296.704' viewBox='0 0 213.142 296.704'>
                    <circle id='Ellipse_396' data-name='Ellipse 396' cx='33.072' cy='33.072' r='33.072' transform='translate(0 0)' fill='rgba(255,61,0,0.42)' />
                    <path
                        id='Pfad_3171'
                        data-name='Pfad 3171'
                        d='M257.263,131.654l1.376-6.005a1.348,1.348,0,0,1,.62-1.665,4.944,4.944,0,0,1,4.705-2.526,1.348,1.348,0,0,1,1.622.27,5.712,5.712,0,0,1,4.076-2.188,1.349,1.349,0,0,1,.671-1.5,5.862,5.862,0,0,1,4.705-2.526,1.352,1.352,0,0,1,1.831.552l.264.492,4.764,2.024-.413.712-3.959-1.978a4.02,4.02,0,0,1-7.2,3.578l-.476-.886-3.987,2.14.476.886a4.022,4.022,0,0,1-7.088,3.8l-.21-.391-.643,5.236-1.141-.03Z'
                        transform='translate(-127.921 -57.358)'
                        fill='#3f3d56'
                    />
                    <path
                        id='Pfad_3172'
                        data-name='Pfad 3172'
                        d='M195.412,531.335a1.141,1.141,0,0,1-.827-1.978l.078-.311-.031-.075a3.064,3.064,0,0,0-5.652.021c-.924,2.226-2.1,4.456-2.391,6.81a9.063,9.063,0,0,0,.159,3.117,36.34,36.34,0,0,1-3.306-15.093,35.082,35.082,0,0,1,.218-3.913q.18-1.6.5-3.173a36.762,36.762,0,0,1,7.29-15.58,9.783,9.783,0,0,0,4.069-4.221,7.463,7.463,0,0,0,.679-2.039,9.209,9.209,0,0,1-.6-3.176c-.276-.419-.77-.627-1.072-1.036-1.5-2.033-3.565-1.678-4.644,1.085-2.3,1.163-2.326,3.091-.913,4.946.9,1.18,1.023,2.776,1.812,4.04-.081.1-.166.2-.247.309a37,37,0,0,0-3.862,6.118,15.381,15.381,0,0,0-.918-7.144c-.879-2.12-2.526-3.906-3.977-5.739a3.157,3.157,0,0,0-5.623,1.55l-.009.081q.323.182.633.387a1.548,1.548,0,0,1-.624,2.816l-.032,0a15.4,15.4,0,0,0,.406,2.3c-1.861,7.2,2.157,9.819,7.894,9.937.127.065.25.13.377.192a37.767,37.767,0,0,0-2.033,9.566,35.82,35.82,0,0,0,.026,5.783l-.01-.068a9.465,9.465,0,0,0-3.231-5.465c-2.486-2.042-6-2.795-8.682-4.436a1.776,1.776,0,0,0-2.721,1.728l.011.072a10.4,10.4,0,0,1,1.166.562q.323.182.633.387a1.548,1.548,0,0,1-.624,2.816l-.032,0-.065.01a15.414,15.414,0,0,0,2.835,4.439c1.164,6.283,6.162,6.879,11.508,5.049h0a37.755,37.755,0,0,0,2.536,7.4h9.06c.033-.1.062-.2.091-.305a10.3,10.3,0,0,1-2.507-.149c.672-.825,1.344-1.656,2.017-2.481a.561.561,0,0,0,.042-.049c.341-.422.685-.841,1.026-1.263h0a15.079,15.079,0,0,0-.442-3.841h0Z'
                        transform='translate(-83.602 -243.346)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_3173'
                        data-name='Pfad 3173'
                        d='M293.02,148.07l5.483,6.579-2.924-21.2-1.084.542a3.389,3.389,0,0,0-1.873,2.976l.4,11.1Z'
                        transform='translate(-145.503 -66.356)'
                        fill='#2f2e41'
                    />
                    <g id='Gruppe_3041' data-name='Gruppe 3041' transform='translate(136.482 257.774)'>
                        <path
                            id='Pfad_3174'
                            data-name='Pfad 3174'
                            d='M271.466,515.148l3.523,6.714.007.018,1.17,2.235,1.884,3.586,0,0,.791,1.511,1.179,2.247,2.98-1.133.888-.342.467-.176-.789-2.072-2.085-5.5-3.62-9.523-.04.015-.89.337-3.553,1.351-.89.337Z'
                            transform='translate(-271.466 -512.718)'
                            fill='#ffb6b6'
                        />
                        <path
                            id='Pfad_3175'
                            data-name='Pfad 3175'
                            d='M274.757,556.579a2.08,2.08,0,0,0,2.676,1.2l1.423-.542.89-.337,3.555-1.347.894-.339,1.859-.708.315-3.637.76,1.239,0,0,.987,1.608.521-.2,2.731-1.039L286.3,541.877l-1.159.362-.423.13-.909.284-3.389,1.061,0,0-.236.074-.909.283-.377.119,1.182,3.1-2.451,3.555-.669.971-1.961,2.845a2.074,2.074,0,0,0-.233,1.911Z'
                            transform='translate(-273.036 -527.217)'
                            fill='#2f2e41'
                        />
                    </g>
                    <g id='Gruppe_3042' data-name='Gruppe 3042' transform='translate(165.369 267.753)'>
                        <path
                            id='Pfad_3176'
                            data-name='Pfad 3176'
                            d='M343.676,532.709l1.065,7.507v.019l.352,2.5.571,4.011v0l.238,1.689.357,2.512,3.187-.067.951-.024.5-.01-.048-2.217-.119-5.88-.214-10.186h-.043l-.952.019-3.8.081-.952.019Z'
                            transform='translate(-336.258 -532.566)'
                            fill='#ffb6b6'
                        />
                        <path
                            id='Pfad_3177'
                            data-name='Pfad 3177'
                            d='M328.923,574.291a2.079,2.079,0,0,0,2.117,2.031l1.522-.033.951-.019,3.8-.076.956-.019,1.989-.043,1.518-3.321.3,1.422v0l.39,1.846.557-.009,2.921-.062-1.218-11.7-1.213-.048-.442-.019-.951-.038-3.549-.138h0l-.247-.01-.952-.038-.395-.014.071,3.321-3.5,2.526-.956.69-2.8,2.022a2.074,2.074,0,0,0-.861,1.722Z'
                            transform='translate(-328.922 -548.201)'
                            fill='#2f2e41'
                        />
                    </g>
                    <path
                        id='Pfad_3178'
                        data-name='Pfad 3178'
                        d='M362.364,313.742l-3.395-20.091,7.286-2.931,1.523,22.215a4.259,4.259,0,1,1-5.414.807Z'
                        transform='translate(-178.494 -144.557)'
                        fill='#ffb6b6'
                    />
                    <path
                        id='Pfad_3179'
                        data-name='Pfad 3179'
                        d='M233.991,150.59l-13.226,15.5-6.925-3.7L229.9,146.958a4.259,4.259,0,1,1,4.094,3.633Z'
                        transform='translate(-106.33 -70.642)'
                        fill='#ffb6b6'
                    />
                    <circle id='Ellipse_397' data-name='Ellipse 397' cx='10.951' cy='10.951' r='10.951' transform='translate(145.914 65.926)' fill='#ffb6b6' />
                    <path
                        id='Pfad_3180'
                        data-name='Pfad 3180'
                        d='M256.408,287.887l29.51-3.224,1.1,5.117v1.226l.63.731.231.918.6.049s-.35.62-.1.957c.194.26.945.17,1.25.616,3.1,4.519,11.129,18.29,7.255,34.977l.548,27.6-.507,2.17.586,1.409.419,2.11.6,43.653,1.462,5.117-2.193,6.579-13.158.731-2.193-8.406,1.1-10.6-3.289-31.068-.925-1.481.531-1.508.475-2.011-1.014-2.079L275.727,334.2h0a14.607,14.607,0,0,1-1.129-6.943l.082-1-.64-4.857-17.727,28.873-5.918,10.249.618,4.373,3.907,24.787,5.53,6.033,1.006,8.044-8.044,5.028-9.05-3.017v-6.033l-13.8-37.7,15.7-46.489,2.558-2.193-.365-2.924,2.415-2.415,4.829-10.836,2.218-4.769-1.508-4.525Z'
                        transform='translate(-114.644 -141.546)'
                        fill='#2f2e41'
                    />
                    <path
                        id='Pfad_3181'
                        data-name='Pfad 3181'
                        d='M289.845,175.979h11.631l2.623,8.041,16.448,7.676-3.655,17.179-4.386,14.986-1.292,1.292.561,2.363h-2.839l.463,3.107,3.107,4.569-33.429-.716v-7.456l2.616-10.526h0c-3.3-7.265-1.46-11.64-1.006-19.608l-2.9-11.037,9.138-.894,2.924-8.975Z'
                        transform='translate(-138.125 -87.504)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_3182'
                        data-name='Pfad 3182'
                        d='M248.716,192.83l2.559-6.214s-.366-4.386-8.041-5.482-22.248-2.193-22.248-2.193l3.524-3.839,1.508-4.022-7.039-3.519-4.036,1.983-1.495,2.541.5,1.006-2.183,1.324a9.555,9.555,0,0,0,2.638,12.932h0l1.63,1.63,2.39,1.294,2.924-.365,27.365,2.924Z'
                        transform='translate(-104.571 -83.318)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_3183'
                        data-name='Pfad 3183'
                        d='M345.757,207.617l6.214-2.559s4.386.365,5.482,8.041,2.193,27.778,2.193,27.778l1.828,4.021v1.124l1.616,3.445-.971.971,2.645,2.135L352.7,256.959l-2.6-6.633,1.133-2.87-1.63-1.63-1.294-2.39.365-2.924-2.924-32.9Z'
                        transform='translate(-171.924 -101.963)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_3184'
                        data-name='Pfad 3184'
                        d='M319.954,161.422h-3a5.089,5.089,0,0,1-1.091-3.911c-.141,1.121-2.524-2.308-2.388-1.011.03.282,1.483,5.153,1.533,5.425a8.521,8.521,0,0,1-4.776,2.172c.563-1.7-.01-2.624-1.744-2.474a5.95,5.95,0,0,1-.312,2.569c-5.239-.176-11.563-3.228-17.813-5.621a7.427,7.427,0,0,1,.819-4.615,22.142,22.142,0,0,0-1.725,3.323c-.1.261-.191.528-.271.794-.055.191-.106.382-.151.568l-2.9.156c2.459-3.555,2.011-8.537,5-11.217,4.761-4.258.744-10.457-1.247-16.234a3.44,3.44,0,0,1,2.272-3.374l3.866-1.388c4.917-1.765,4.671-2.775,9.422.146,4.751-1.458,13.072,6.3,13.072,12.066a7.279,7.279,0,0,0,.156,1.458,4.042,4.042,0,0,0-1.584.543,22.456,22.456,0,0,1,1.921,1.212,22.914,22.914,0,0,1-.242,8.929c-.774,3.3.634,8.909,1.171,10.483Z'
                        transform='translate(-142.282 -62.099)'
                        fill='#2f2e41'
                    />
                    <path
                        id='Pfad_3185'
                        data-name='Pfad 3185'
                        d='M296.815,588.368a.6.6,0,0,1-.6.6H168.872a.6.6,0,1,1,0-1.2H296.216a.6.6,0,0,1,.6.6Z'
                        transform='translate(-83.672 -292.262)'
                        fill='#ccc'
                    />
                </svg>
                <h4>{t('notripsIntheTime')}</h4>
                <p>{t('viewOrAdjustTime')}</p>
                <Button variant='outlined' color='primary' onClick={() => navigate('/open')}>
                    {t('openTours')}
                </Button>
            </Box>
        );
    }
}

import React from 'react';

export default function LogoutSvg() {
    return (
        <svg id='logout_black_24dp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path id='Pfad_2914' data-name='Pfad 2914' d='M0,0H24V24H0Z' fill='none' />
            <path
                id='Pfad_2915'
                data-name='Pfad 2915'
                d='M17,7,15.59,8.41,18.17,11H8v2H18.17l-2.58,2.58L17,17l5-5ZM4,5h8V3H4A2.006,2.006,0,0,0,2,5V19a2.006,2.006,0,0,0,2,2h8V19H4Z'
                fill='#757575'
            />
        </svg>
    );
}

import { Card, CardHeader } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLatestMileage } from '../../api/LogbookApi';
import SimpleSnackbar from '../ui/SimpleSnackbar';

export default function StatusMileage(props) {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const latestMileage = useGetLatestMileage(props.idAsset, setMessage);

    useEffect(() => {
        if (latestMileage !== undefined) {
            props.setMileage(latestMileage?.latestMileage);
        }
    }, [latestMileage, props]);

    return (
        <>
            {props.mileage !== null && (
                <Card
                    variant='outlined'
                    sx={{
                        maxWidth: props.fuelPercent !== null ? 'calc(50% - 4px)' : '538px',
                        marginLeft: props.fuelPercent !== null ? '4px' : '0px',
                        marginBottom: '8px',
                        flexGrow: '1',
                    }}
                >
                    <CardHeader title={t('mileage')} subheader={`${props.mileage} km`} />
                </Card>
            )}
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

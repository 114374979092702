import Moment from 'moment';

const checkvalidaty = (userData) => {
    let clientData = userData.me.zluser;
    let validty1 = clientData.permissions.filter((p) => p.name === '*' || p.name === 'logbook-cloud').length > 0;

    let validty2 = clientData.locked === false;

    let validty3 = false;

    if (clientData.expiration !== null) {
        let exipreDate = Moment(clientData.expiration);
        validty3 = Moment().diff(exipreDate, 'days') < 0;
    } else {
        validty3 = true;
    }

    return [validty1, validty2 && validty3];
};

export function authClient(userInfo) {
    if (userInfo && userInfo !== undefined) {
        let validation = checkvalidaty(userInfo);
        return validation;
    }
}

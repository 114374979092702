import React from 'react';

export default function PrivateSvg(props) {
    const color = props.color;

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <g id='Gruppe_3101' data-name='Gruppe 3101'>
                <path id='Pfad_3327' data-name='Pfad 3327' d='M12,11h0C9.975,11,1,12.343,1,14H23c0-1.657-8.975-3-11-3' fill={color === undefined ? '#00360F' : color} />
                <path
                    id='Pfad_3328'
                    data-name='Pfad 3328'
                    d='M15,3s-1.939,2.333-3,2.333S9,3,9,3C7.895,3,6,6.134,6,10H18c0-3.866-1.9-7-3-7'
                    fill={color === undefined ? '#00360F' : color}
                />
                <path
                    id='Pfad_3329'
                    data-name='Pfad 3329'
                    d='M16.5,13a3.482,3.482,0,0,0-3.24,2.21,3.885,3.885,0,0,0-2.52,0A3.495,3.495,0,1,0,7.5,20a3.179,3.179,0,0,0,.62-.06,3.307,3.307,0,0,0,1.08-.38A3.516,3.516,0,0,0,11,16.5a.97.97,0,0,0-.01-.17,2.779,2.779,0,0,1,2.02,0,.97.97,0,0,0-.01.17,3.516,3.516,0,0,0,1.8,3.06,3.307,3.307,0,0,0,1.08.38,3.179,3.179,0,0,0,.62.06,3.5,3.5,0,0,0,0-7M9.95,17.02l-.01.01a2.4,2.4,0,0,1-.71,1.27v.01A2.473,2.473,0,0,1,8,18.95a2.734,2.734,0,0,1-.5.05,2.5,2.5,0,0,1,0-5,2.474,2.474,0,0,1,2.34,1.64,2.266,2.266,0,0,1,.16.86,2.867,2.867,0,0,1-.05.52M16.5,19a2.734,2.734,0,0,1-.5-.05,2.473,2.473,0,0,1-1.23-.64V18.3a2.4,2.4,0,0,1-.71-1.27l-.01-.01A2.867,2.867,0,0,1,14,16.5a2.266,2.266,0,0,1,.16-.86A2.474,2.474,0,0,1,16.5,14a2.5,2.5,0,0,1,0,5'
                    fill={color === undefined ? '#00360F' : color}
                />
            </g>
        </svg>
    );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function DeleteDialog({ open, setOpen, handleDelete }) {
    const { t } = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => handleClose()}
        PaperProps={{
            style: {
                borderRadius: '28px',
            },
        }}
        >
            <DialogTitle sx={{padding: '24px 24px 16px 24px'}}>{t('delete')}</DialogTitle>
            <DialogContent sx={{padding:'0px 24px 0px 24px'}}>
                <DialogContentText>{t('confirmDelete')}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ height: '88px',padding: '24px'}}>
                <Button autoFocus onClick={() => handleClose()} sx={{ height: '40px', marginBottom: '0px !important'}}>
                    {t('cancel')}
                </Button>
                <Button sx={{ height: '40px', marginBottom: '0px !important'}}
                    onClick={() => {
                        handleDelete();
                        handleClose();
                    }}
                >
                    {t('delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import SimpleSnackbar from '../../ui/SimpleSnackbar';
import theme from '../../../themes/theme';

export default function DialogEditCost(props) {
    const { editCost, currentCost, title, label, checkHoveredLogbook } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState(currentCost.name);
    const [message, setMessage] = useState('');

    const handleSave = (event) => {
        handleCancel(event);
        editCost({
            id: currentCost.id,
            name: name.trim(),
            active: currentCost.active,
        });
        setName('');
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        setOpen(false);
        checkHoveredLogbook('');
    };

    const handleOpen = (event) => {
        event.stopPropagation();
        setOpen(true);
    };

    return (
        <>
            <EditIcon sx={{ color: theme.palette.grey[600] }} onClick={(event) => handleOpen(event)}></EditIcon>
            <Dialog open={open} PaperProps={{ sx: { borderRadius: '28px' } }} onClick={(event) => event.stopPropagation()}>
                <DialogTitle sx={{ padding: '24px 24px 16px 24px' }}>{title}</DialogTitle>
                <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                    <Box sx={{ padding: '5px 0', maxWidth: '296px', display: 'flex' }}>
                        <FormControl fullWidth>
                            <TextField label={label} autoFocus variant='outlined' value={name} onChange={(event) => setName(event.target.value)} />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ minHeight: '88px', padding: '24px' }}>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important' }} color='secondary' onClick={(event) => handleCancel(event)}>
                        {t('cancel')}
                    </Button>
                    {name.trim().length > 0 ? (
                        <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important' }} color='success' onClick={(event) => handleSave(event)}>
                            {t('save')}
                        </Button>
                    ) : (
                        <Button variant='contained' disabled={true} sx={{ height: '40px', marginBottom: '0px !important' }} color='success'>
                            {t('save')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

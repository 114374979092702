import React from 'react';
import { Box, Typography } from '@mui/material';

export default function PlaceholderMapSvg(props) {
    return (
        <Box sx={{ textAlign: 'center', py: '32px' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='541.878' height='290.108' viewBox='0 0 541.878 290.108'>
                <g id='Gruppe_3123' data-name='Gruppe 3123' transform='translate(-5.458 0.005)'>
                    <circle id='Ellipse_454' data-name='Ellipse 454' cx='29.954' cy='29.954' r='29.954' transform='translate(107.179 30.967)' fill='#ffc4b2' />
                    <path
                        id='Pfad_3343'
                        data-name='Pfad 3343'
                        d='M445.945,378.491H299a3,3,0,0,1-.549-.043l69.5-120.383a4.866,4.866,0,0,1,8.471,0l46.643,80.786,2.235,3.866Z'
                        transform='translate(-214.878 -225.26)'
                        fill='#3f3d56'
                    />
                    <path
                        id='Pfad_3344'
                        data-name='Pfad 3344'
                        d='M356.729,232.142H306.558l24.629-35.775,1.773-2.577.886-1.288,2.235,3.865Z'
                        transform='translate(-125.662 -78.912)'
                        opacity='0.2'
                    />
                    <path
                        id='Pfad_3345'
                        data-name='Pfad 3345'
                        d='M598.772,403.137h-127.1L496.3,367.363l1.772-2.577,32.094-46.621c2.1-3.056,7.166-3.246,9.64-.576a5.8,5.8,0,0,1,.462.576Z'
                        transform='translate(-285.886 -249.907)'
                        fill='#3f3d56'
                    />
                    <circle id='Ellipse_455' data-name='Ellipse 455' cx='9.026' cy='9.026' r='9.026' transform='translate(341.168 115.373)' fill='#b9f6ca' />
                    <path id='Pfad_3346' data-name='Pfad 3346' d='M593.654,237.063H591.8l.843-28.547Z' transform='translate(-242.589 -85.476)' fill='#3f3d56' />
                    <path id='Pfad_3347' data-name='Pfad 3347' d='M593.339,215.777l1.985-2.746-2.012,3.426-.217-.381Z' transform='translate(-243.118 -87.327)' fill='#3f3d56' />
                    <path id='Pfad_3348' data-name='Pfad 3348' d='M591.762,220.477l-1.985-2.746,2.012,3.425.217-.381Z' transform='translate(-241.758 -89.253)' fill='#3f3d56' />
                    <circle id='Ellipse_456' data-name='Ellipse 456' cx='9.026' cy='9.026' r='9.026' transform='translate(423.967 115.373)' fill='#b9f6ca' />
                    <path id='Pfad_3349' data-name='Pfad 3349' d='M733.97,237.063h-1.849l.843-28.547Z' transform='translate(-300.107 -85.476)' fill='#3f3d56' />
                    <path id='Pfad_3350' data-name='Pfad 3350' d='M733.656,215.777l1.985-2.746-2.012,3.426-.218-.381Z' transform='translate(-300.636 -87.327)' fill='#3f3d56' />
                    <path id='Pfad_3351' data-name='Pfad 3351' d='M732.079,220.477l-1.985-2.746,2.012,3.425.217-.381Z' transform='translate(-299.276 -89.253)' fill='#3f3d56' />
                    <circle id='Ellipse_457' data-name='Ellipse 457' cx='13.331' cy='13.331' r='13.331' transform='translate(363.011 98.102)' fill='#b9f6ca' />
                    <path id='Pfad_3352' data-name='Pfad 3352' d='M638.055,227.606h-2.73l1.245-42.162Z' transform='translate(-260.429 -76.018)' fill='#3f3d56' />
                    <path id='Pfad_3353' data-name='Pfad 3353' d='M637.592,196.168l2.931-4.055-2.971,5.059-.322-.562Z' transform='translate(-261.21 -78.752)' fill='#3f3d56' />
                    <path id='Pfad_3354' data-name='Pfad 3354' d='M635.262,203.109l-2.931-4.055,2.971,5.059.322-.562Z' transform='translate(-259.202 -81.597)' fill='#3f3d56' />
                    <circle id='Ellipse_458' data-name='Ellipse 458' cx='13.331' cy='13.331' r='13.331' transform='translate(395.521 98.102)' fill='#b9f6ca' />
                    <path id='Pfad_3355' data-name='Pfad 3355' d='M693.149,227.606h-2.73l1.244-42.162Z' transform='translate(-283.013 -76.018)' fill='#3f3d56' />
                    <path id='Pfad_3356' data-name='Pfad 3356' d='M692.685,196.168l2.932-4.055-2.972,5.059-.321-.562Z' transform='translate(-283.793 -78.752)' fill='#3f3d56' />
                    <path id='Pfad_3357' data-name='Pfad 3357' d='M690.356,203.109l-2.932-4.055,2.972,5.059.321-.562Z' transform='translate(-281.785 -81.597)' fill='#3f3d56' />
                    <circle id='Ellipse_459' data-name='Ellipse 459' cx='13.331' cy='13.331' r='13.331' transform='translate(311.198 98.102)' fill='#b9f6ca' />
                    <path id='Pfad_3358' data-name='Pfad 3358' d='M550.249,227.606h-2.73l1.245-42.162Z' transform='translate(-224.436 -76.018)' fill='#3f3d56' />
                    <path id='Pfad_3359' data-name='Pfad 3359' d='M549.786,196.168l2.931-4.055-2.971,5.059-.321-.562Z' transform='translate(-225.217 -78.752)' fill='#3f3d56' />
                    <path id='Pfad_3360' data-name='Pfad 3360' d='M547.457,203.109l-2.932-4.055,2.972,5.059.321-.562Z' transform='translate(-223.209 -81.597)' fill='#3f3d56' />
                    <path
                        id='Pfad_3361'
                        data-name='Pfad 3361'
                        d='M642.863,597.68H207.813a41.739,41.739,0,1,1,0-83.478H641.9a16.054,16.054,0,1,0,0-32.107H193.365V456.409H641.9a41.739,41.739,0,0,1,0,83.478H207.813a16.054,16.054,0,0,0,0,32.107h435.05Z'
                        transform='translate(-160.616 -307.577)'
                        fill='#e6e6e6'
                    />
                    <rect id='Rechteck_1573' data-name='Rechteck 1573' width='19.906' height='1.284' transform='translate(48.803 161.032)' fill='#fff' />
                    <rect id='Rechteck_1574' data-name='Rechteck 1574' width='19.906' height='1.284' transform='translate(105.311 161.032)' fill='#fff' />
                    <rect id='Rechteck_1575' data-name='Rechteck 1575' width='19.906' height='1.284' transform='translate(161.819 161.032)' fill='#fff' />
                    <rect id='Rechteck_1576' data-name='Rechteck 1576' width='19.906' height='1.284' transform='translate(218.328 161.032)' fill='#fff' />
                    <rect id='Rechteck_1577' data-name='Rechteck 1577' width='19.906' height='1.284' transform='translate(274.836 161.032)' fill='#fff' />
                    <rect id='Rechteck_1578' data-name='Rechteck 1578' width='19.906' height='1.284' transform='translate(331.344 161.032)' fill='#fff' />
                    <rect id='Rechteck_1579' data-name='Rechteck 1579' width='19.906' height='1.284' transform='translate(387.853 161.032)' fill='#fff' />
                    <rect id='Rechteck_1580' data-name='Rechteck 1580' width='19.906' height='1.284' transform='translate(444.361 161.032)' fill='#fff' />
                    <rect id='Rechteck_1581' data-name='Rechteck 1581' width='19.906' height='1.284' transform='translate(48.803 218.825)' fill='#fff' />
                    <rect id='Rechteck_1582' data-name='Rechteck 1582' width='19.906' height='1.284' transform='translate(105.311 218.825)' fill='#fff' />
                    <rect id='Rechteck_1583' data-name='Rechteck 1583' width='19.906' height='1.284' transform='translate(161.819 218.825)' fill='#fff' />
                    <rect id='Rechteck_1584' data-name='Rechteck 1584' width='19.906' height='1.284' transform='translate(218.328 218.825)' fill='#fff' />
                    <rect id='Rechteck_1585' data-name='Rechteck 1585' width='19.906' height='1.284' transform='translate(274.836 218.825)' fill='#fff' />
                    <rect id='Rechteck_1586' data-name='Rechteck 1586' width='19.906' height='1.284' transform='translate(331.344 218.825)' fill='#fff' />
                    <rect id='Rechteck_1587' data-name='Rechteck 1587' width='19.906' height='1.284' transform='translate(387.853 218.825)' fill='#fff' />
                    <rect id='Rechteck_1588' data-name='Rechteck 1588' width='19.906' height='1.284' transform='translate(444.361 218.825)' fill='#fff' />
                    <rect id='Rechteck_1589' data-name='Rechteck 1589' width='19.906' height='1.284' transform='translate(48.803 276.618)' fill='#fff' />
                    <rect id='Rechteck_1590' data-name='Rechteck 1590' width='19.906' height='1.284' transform='translate(105.311 276.618)' fill='#fff' />
                    <rect id='Rechteck_1591' data-name='Rechteck 1591' width='19.906' height='1.284' transform='translate(161.819 276.618)' fill='#fff' />
                    <rect id='Rechteck_1592' data-name='Rechteck 1592' width='19.906' height='1.284' transform='translate(218.328 276.618)' fill='#fff' />
                    <rect id='Rechteck_1593' data-name='Rechteck 1593' width='19.906' height='1.284' transform='translate(274.836 276.618)' fill='#fff' />
                    <rect id='Rechteck_1594' data-name='Rechteck 1594' width='19.906' height='1.284' transform='translate(331.344 276.618)' fill='#fff' />
                    <rect id='Rechteck_1595' data-name='Rechteck 1595' width='19.906' height='1.284' transform='translate(387.853 276.618)' fill='#fff' />
                    <rect id='Rechteck_1596' data-name='Rechteck 1596' width='19.906' height='1.284' transform='translate(444.361 276.618)' fill='#fff' />
                    <path
                        id='Pfad_3362'
                        data-name='Pfad 3362'
                        d='M729.532,296.541a17.112,17.112,0,0,0-3.567-3.366h7.449a12.459,12.459,0,0,0-3.882,3.366Z'
                        transform='translate(-390.123 -240.665)'
                        fill='none'
                    />
                    <path
                        id='Pfad_3363'
                        data-name='Pfad 3363'
                        d='M690.743,293.176h1.284c-.276.194-.553.387-.816.6C691.06,293.569,690.9,293.374,690.743,293.176Z'
                        transform='translate(-375.685 -240.666)'
                        fill='none'
                    />
                    <path
                        id='Pfad_3364'
                        data-name='Pfad 3364'
                        d='M744.551,290.559a12.293,12.293,0,0,1,5.977,1.544H738.589a12.239,12.239,0,0,1,5.962-1.544Z'
                        transform='translate(-395.298 -239.593)'
                        fill='#e6e6e6'
                    />
                    <path
                        id='Pfad_3365'
                        data-name='Pfad 3365'
                        d='M702.663,287.941a16.89,16.89,0,0,1,9.756,3.089h-19.5A16.887,16.887,0,0,1,702.663,287.941Z'
                        transform='translate(-376.577 -238.52)'
                        fill='#e6e6e6'
                    />
                    <path
                        id='Pfad_3366'
                        data-name='Pfad 3366'
                        d='M487.011,234.054a31.662,31.662,0,0,1,62.121-6.675c.384-.014.767-.029,1.154-.029a31.668,31.668,0,0,1,30.377,22.735,22.376,22.376,0,0,1,26.553,6.61H508.452a21.416,21.416,0,0,1-21.463-22.174Q487,234.288,487.011,234.054Z'
                        transform='translate(-292.158 -204.184)'
                        fill='#e6e6e6'
                    />
                    <path
                        id='Pfad_3367'
                        data-name='Pfad 3367'
                        d='M774.89,335.717a17.108,17.108,0,0,1,3.567-3.366h-7.449A12.459,12.459,0,0,1,774.89,335.717Z'
                        transform='translate(-408.587 -256.724)'
                        fill='none'
                    />
                    <path
                        id='Pfad_3368'
                        data-name='Pfad 3368'
                        d='M817.962,332.352h-1.284c.276.194.553.387.816.6C817.645,332.745,817.805,332.55,817.962,332.352Z'
                        transform='translate(-427.308 -256.724)'
                        fill='none'
                    />
                    <path
                        id='Pfad_3369'
                        data-name='Pfad 3369'
                        d='M756.753,329.734a12.293,12.293,0,0,0-5.977,1.544h11.939a12.24,12.24,0,0,0-5.962-1.544Z'
                        transform='translate(-400.294 -255.651)'
                        fill='#e6e6e6'
                    />
                    <path
                        id='Pfad_3370'
                        data-name='Pfad 3370'
                        d='M793.389,327.117a16.89,16.89,0,0,0-9.756,3.089h19.5A16.886,16.886,0,0,0,793.389,327.117Z'
                        transform='translate(-413.762 -254.579)'
                        fill='#e6e6e6'
                    />
                    <path
                        id='Pfad_3371'
                        data-name='Pfad 3371'
                        d='M939.058,273.23a31.662,31.662,0,0,0-62.121-6.675c-.384-.014-.767-.029-1.154-.029a31.668,31.668,0,0,0-30.377,22.735,22.376,22.376,0,0,0-26.552,6.61h98.764A21.416,21.416,0,0,0,939.081,273.7Q939.07,273.464,939.058,273.23Z'
                        transform='translate(-428.2 -220.243)'
                        fill='#e6e6e6'
                    />
                    <rect id='Rechteck_1597' data-name='Rechteck 1597' width='11.479' height='1.822' transform='matrix(-1, -0.001, 0.001, -1, 456.491, 272.161)' fill='#3f3d56' />
                    <path
                        id='Pfad_3372'
                        data-name='Pfad 3372'
                        d='M770.332,632.03l2.368.548,90.008.048.809-1.886a19.034,19.034,0,0,0,1.4-10.292c-.388-2.6-1.359-5.192-3.565-6.316l-3.995-26.6-49.8-.089-19.266,15.539s-9.088-.163-14.025,5.983a14.427,14.427,0,0,0-3.018,7.946l-.195,3.2Z'
                        transform='translate(-408.31 -361.288)'
                        fill='#b9f6ca'
                    />
                    <circle id='Ellipse_460' data-name='Ellipse 460' cx='11.114' cy='11.114' r='11.114' transform='translate(370.677 259.183)' fill='#3f3d56' />
                    <circle id='Ellipse_461' data-name='Ellipse 461' cx='5.85' cy='5.85' r='5.85' transform='translate(375.941 264.448)' fill='#ccc' />
                    <circle id='Ellipse_462' data-name='Ellipse 462' cx='11.114' cy='11.114' r='11.114' transform='translate(425.52 259.212)' fill='#3f3d56' />
                    <circle id='Ellipse_463' data-name='Ellipse 463' cx='5.85' cy='5.85' r='5.85' transform='translate(430.784 264.476)' fill='#ccc' />
                    <path
                        id='Pfad_3373'
                        data-name='Pfad 3373'
                        d='M656.92,399.047l26.886.011h3.282l5.541,0v-.4l0-4.873,0-5.471H688.95l-3.282,0-5.813,0h-3.282l-6.712,0Z'
                        transform='translate(-269.281 -159.173)'
                        fill='#fff'
                    />
                    <path
                        id='Pfad_3374'
                        data-name='Pfad 3374'
                        d='M724.849,399.077l14.209.008,0-2.788v-4.873l0-3.087h-2.081l-3.282,0-8.85,0Z'
                        transform='translate(-297.126 -159.184)'
                        fill='#fff'
                    />
                    <rect id='Rechteck_1598' data-name='Rechteck 1598' width='1.822' height='3.462' transform='matrix(-1, -0.001, 0.001, -1, 423.341, 251.372)' fill='#3f3d56' />
                    <rect id='Rechteck_1599' data-name='Rechteck 1599' width='1.822' height='3.462' transform='matrix(-0.001, 1, -1, -0.001, 394.926, 242.975)' fill='#3f3d56' />
                    <path
                        id='Pfad_3375'
                        data-name='Pfad 3375'
                        d='M0,0H.2A3.808,3.808,0,0,1,4.008,3.808h0A3.808,3.808,0,0,1,.2,7.616H0Z'
                        transform='matrix(-1, -0.001, 0.001, -1, 393.647, 243.777)'
                        fill='#3f3d56'
                    />
                    <path
                        id='Pfad_3376'
                        data-name='Pfad 3376'
                        d='M771.559,635.056a5.886,5.886,0,0,0,3.213-11.144,14.428,14.428,0,0,0-3.018,7.946Z'
                        transform='translate(-408.813 -376.239)'
                        fill='#3f3d56'
                    />
                    <path id='Pfad_3377' data-name='Pfad 3377' d='M705.639,388.316l6.961,10.348,0-4.873-3.683-5.471Z' transform='translate(-289.251 -159.179)' fill='#f2f2f2' />
                    <path id='Pfad_3378' data-name='Pfad 3378' d='M739.854,388.335l5.359,7.964v-4.873l-2.076-3.087Z' transform='translate(-303.277 -159.186)' fill='#f2f2f2' />
                    <path id='Pfad_3379' data-name='Pfad 3379' d='M690.225,388.309l7.233,10.752h3.282l-7.233-10.752Z' transform='translate(-282.933 -159.176)' fill='#f2f2f2' />
                    <g id='Place-grey' transform='translate(105.336 202.23)'>
                        <path
                            id='Pfad_1017'
                            data-name='Pfad 1017'
                            d='M26.583,2A21.568,21.568,0,0,0,5,23.583C5,39.771,26.583,63.667,26.583,63.667s21.583-23.9,21.583-40.083A21.568,21.568,0,0,0,26.583,2Zm0,29.292a7.708,7.708,0,1,1,7.708-7.708A7.711,7.711,0,0,1,26.583,31.292Z'
                            transform='translate(10.417 4.167)'
                            fill='#16a100'
                        />
                        <path id='Pfad_1018' data-name='Pfad 1018' d='M0,0H74V74H0Z' fill='none' />
                    </g>
                    <g id='Place-grey-2' data-name='Place-grey' transform='translate(473.336 120.23)'>
                        <path
                            id='Pfad_1017-2'
                            data-name='Pfad 1017'
                            d='M26.583,2A21.568,21.568,0,0,0,5,23.583C5,39.771,26.583,63.667,26.583,63.667s21.583-23.9,21.583-40.083A21.568,21.568,0,0,0,26.583,2Zm0,29.292a7.708,7.708,0,1,1,7.708-7.708A7.711,7.711,0,0,1,26.583,31.292Z'
                            transform='translate(10.417 4.167)'
                            fill='#16a100'
                        />
                        <path id='Pfad_1018-2' data-name='Pfad 1018' d='M0,0H74V74H0Z' fill='none' />
                    </g>
                    <g id='tour-grey' transform='translate(14.336 107.23)'>
                        <path id='Pfad_2468' data-name='Pfad 2468' d='M0,0H74V74H0Z' fill='none' />
                        <path
                            id='Pfad_2469'
                            data-name='Pfad 2469'
                            d='M54.333,8.167H11.167V2H5V63.667h6.167V39H54.333L48.167,23.583Zm-18.5,15.417a6.167,6.167,0,1,1-6.167-6.167A6.185,6.185,0,0,1,35.833,23.583Z'
                            transform='translate(10.417 4.167)'
                            fill='#16a100'
                        />
                    </g>
                </g>
            </svg>
            <Typography sx={{ width: '368px', margin: 'auto', my: '16px' }}>{props.text}</Typography>
        </Box>
    );
}

import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { List, Button, Box, InputBase, ListItem, ListItemText, Typography, ListItemIcon, Checkbox, useMediaQuery } from '@mui/material';
import theme from '../../themes/theme';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import SharedMenuAdmin from './SharedMenuAdmin';
import GetCurrentReasons from './GetCurrentReasons';
import { useImportLogbookReasonApi } from '../../api/LogbookApi';
import { useEffect } from 'react';
import ImportSvg from '../../img/ImportSvg';
import ImportErrorSvg from '../../img/ImportErrorSvg';
import { useCallback } from 'react';

const prepareData = (data) => {
    const reasonsSorted = [...data].sort((a, b) => {
        if (a.Zweck?.toLowerCase() < b.Zweck?.toLowerCase()) {
            return -1;
        } else if (a.Zweck?.toLowerCase() > b.Zweck?.toLowerCase()) {
            return 1;
        } else {
            return 0;
        }
    });

    let uniqueReasons = reasonsSorted.reduce((accumulator, current) => {
        if (!accumulator.find((reason) => reason.Zweck === current.Zweck)) {
            accumulator.push(current);
        }
        return accumulator;
    }, []);

    const reasonList = uniqueReasons.map((d, index) => {
        return {
            id: index,
            label: d?.Zweck,
            idPerson: null,
        };
    });
    return reasonList;
};

/**
 *
 * @param {*} props
 * @returns
 */
export default function ImportReasonDialog(props) {
    const { selected, setMessage, open, setOpen } = props;
    const { t } = useTranslation();
    const [importReasons, setImportReasons] = useState([]);
    const [renderedList, setRenderedList] = useState([]);
    const [idPersonAll, setIdPersonAll] = useState(null);
    const [currentReasons, setCurrentReasons] = useState([]);
    const { saveReasons } = useImportLogbookReasonApi();
    const [checked, setChecked] = useState([]);
    const [savePressed, setSavePressed] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const ismobil = useMediaQuery(theme.breakpoints.down('md'));

    const handleFileUpload = (event) => {
        const reader = new FileReader();
        reader.readAsBinaryString(event.target.files[0]);
        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            if (parsedData[0]?.Zweck === undefined) {
                setUploadError(true);
            } else {
                const preparedData = prepareData(parsedData);
                setImportReasons(preparedData);
                setRenderedList(preparedData);
                setChecked(preparedData.map((reason) => reason.id));
            }
        };
    };

    const checkFrequenceIdPerson = (reasonList, idPerson) => {
        const frequenceIdPerson = [];

        reasonList.forEach((reason) => {
            if (!frequenceIdPerson.includes(reason.idPerson)) {
                frequenceIdPerson.push(reason.idPerson);
            }
        });

        if (frequenceIdPerson.length === 1) {
            setIdPersonAll(idPerson);
        } else {
            setIdPersonAll('–');
        }
    };

    /**
     * Checks that only changed values in the current list are updated
     * @param {array} newList, with updated value
     * @returns a list with updated values
     */
    const filterCheck = (newList) => {
        const ids = renderedList.map((reason) => reason.id);
        return newList.filter((reason) => ids.includes(reason.id));
    };

    const updateImportReasons = (reasonId, idPerson) => {
        if (reasonId === null) {
            const newImportReasons = importReasons.map((reason) => {
                return {
                    ...reason,
                    idPerson: idPerson,
                };
            });
            checkFrequenceIdPerson(newImportReasons, idPerson);
            setImportReasons(newImportReasons);
            setRenderedList(filterCheck(newImportReasons));
        } else {
            const objIndex = importReasons.findIndex((obj) => obj.id === reasonId);
            importReasons[objIndex].idPerson = idPerson;
            checkFrequenceIdPerson(importReasons, idPerson);
            setImportReasons(importReasons);
            setRenderedList(filterCheck(importReasons));
        }
    };

    const filterListFunction = (event) => {
        const searchValue = event.target.value;
        if (searchValue.length === 0) {
            setRenderedList(importReasons);
        } else {
            setRenderedList(importReasons.filter((reason) => reason?.label?.toLowerCase().includes(searchValue.toLowerCase())));
        }
    };

    const handleClose = useCallback(() => {
        setOpen(false);
        setImportReasons([]);
        setRenderedList([]);
        setIdPersonAll(null);
        setCurrentReasons([]);
        setChecked([]);
        setUploadError(false);
    }, [setOpen]);

    const handleChangeAll = () => {
        if (checked.length < importReasons.length) {
            setChecked(importReasons.map((reason) => reason.id));
        } else {
            setChecked([]);
        }
    };

    const handleToggle = (reasonId) => {
        const currentIndex = checked.indexOf(reasonId);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(reasonId);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    useEffect(() => {
        if (currentReasons.length > 0) {
            setSavePressed(false);
            const newImportReasons = importReasons
                .filter((cR) => checked.includes(cR.id))
                .map((reason) => {
                    return {
                        label: reason.label,
                        idPerson: reason.idPerson,
                    };
                });

            const newCurrentReasons = currentReasons.map((cR) => {
                return {
                    idLogbook: cR.idLogbook,
                    reasons: cR.reasons.concat(newImportReasons),
                };
            });
            saveReasons(newCurrentReasons, setMessage);
            handleClose();
        }
    }, [currentReasons.length, checked, currentReasons, importReasons, saveReasons, setMessage, handleClose]);

    return (
        <>
            {savePressed && <GetCurrentReasons selectedLogbooks={selected} setCurrentReasons={setCurrentReasons} />}
            <Dialog open={open} fullScreen={ismobil}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <DialogTitle sx={{ fontFamily: 'CircularProMedium', color: '#212121', padding: '24px 24px 16px 24px' }}>
                    {t('reasonsImport')}</DialogTitle>
                <DialogContent sx={{ overflow: 'hidden', width: ismobil ? '100%' : '560px', padding:'0px 24px 0px 24px' }}>
                    {importReasons.length === 0 ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                {uploadError ? <ImportErrorSvg /> : <ImportSvg />}
                                <Button
                                    variant='contained'
                                    color='success'
                                    startIcon={<CloudUploadIcon sx={{ height: '18px', width: '18px' }} />}
                                    onClick={() => document.getElementById('importReasons').click()}
                                    sx={{ fontSize: '14px', fontFamily: 'CircularProBold' }}
                                >
                                    {t('uploadData')}
                                </Button>
                            </Box>
                            <input type='file' accept='.xlsx, .csv, .xls' id='importReasons' style={{ visibility: 'hidden' }} onChange={(event) => handleFileUpload(event)} />
                        </>
                    ) : (
                        <>
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                padding='0 8px'
                                border={`solid 1px ${theme.palette.grey[600]}`}
                                borderRadius='4px'
                                marginBottom='8px'
                            >
                                <InputBase fullWidth placeholder={t('search')} onChange={(value) => filterListFunction(value)} />
                                <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                            </Box>
                            {importReasons?.length > 0 && (
                                <ListItem key={'imported-reason-list-item-all'} disablePadding sx={{ padding: '4px 8px 8px 4px', width: ismobil ? '100% ' : '510px' }}>
                                    <ListItemIcon sx={{ marginRight: '-16px' }}>
                                        <Checkbox
                                            edge='start'
                                            checked={checked.length === importReasons.length}
                                            indeterminate={checked.length > 0 && checked.length < importReasons.length}
                                            onClick={() => handleChangeAll()}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={'imported-reason-list-label-all'}
                                        primary={
                                            <Typography
                                                sx={{
                                                    display: 'block',
                                                    fontSize: '16px',
                                                    color: '#212121',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                                component='span'
                                            >
                                                {`${t('all')} (${checked.length})`}
                                            </Typography>
                                        }
                                        sx={{ width: 'calc(100% - 196px)' }}
                                    />
                                    <Box sx={{ width: '156px', textAlign: 'right' }}>
                                        <SharedMenuAdmin
                                            reason={{
                                                id: null,
                                                label: t('all'),
                                                idPerson: idPersonAll,
                                            }}
                                            updateImportReasons={updateImportReasons}
                                        />
                                    </Box>
                                </ListItem>
                            )}
                            <List
                                dense
                                sx={{ height: ismobil ? `${window.innerHeight - 240}px` : '350px', overflow: 'scroll', overflowX: 'hidden', width: ismobil ? '100% ' : '510px' }}
                            >
                                {renderedList?.length > 0 ? (
                                    renderedList?.map((data) => {
                                        const itemId = `imported-reason-list-item-${data.id}`;
                                        const labelId = `imported-reason-list-label-${data.id}`;
                                        return (
                                            <ListItem key={itemId} disablePadding sx={{ padding: '4px', position: 'relative' }}>
                                                <ListItemIcon sx={{ marginRight: '-16px' }} onClick={() => handleToggle(data.id)}>
                                                    <Checkbox edge='start' checked={checked.indexOf(data.id) !== -1} inputProps={{ 'aria-labelledby': labelId }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={labelId}
                                                    primary={
                                                        <Typography
                                                            sx={{
                                                                display: 'block',
                                                                fontSize: '16px',
                                                                color: '#212121',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                            component='span'
                                                        >
                                                            {data.label}
                                                        </Typography>
                                                    }
                                                    sx={{ width: 'calc(100% - 196px)' }}
                                                />
                                                <Box sx={{ width: '156px', textAlign: 'right' }}>
                                                    {checked.indexOf(data.id) !== -1 && <SharedMenuAdmin reason={data} updateImportReasons={updateImportReasons} />}
                                                </Box>
                                            </ListItem>
                                        );
                                    })
                                ) : (
                                    <ListItem>
                                        <ListItemText>
                                            <Typography variant='caption' color='gray'>
                                                {t('noResult')}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                )}
                            </List>
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ height: '88px',padding: '24px'}}>
                    <Button variant='text' color='secondary' sx={{ fontSize: '14px', fontFamily: 'CircularProBold', height: '40px', marginBottom: '0px !important' }} 
                    onClick={() => handleClose()}>
                        {t('cancel')}
                    </Button>
                    <Button
                        variant='contained'
                        color='success'
                        sx={{ fontSize: '14px', fontFamily: 'CircularProBold', height: '40px', marginBottom: '0px !important' }}
                        onClick={() => setSavePressed(true)}
                        disabled={importReasons.length === 0 || checked.length === 0}
                    >
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

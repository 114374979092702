import { Autocomplete, Box, Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FotoUpload from './components/FotoUpload';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CostsType from './components/CostsType';
import moment from 'moment';
import EuroIcon from '@mui/icons-material/Euro';
import PercentIcon from '@mui/icons-material/Percent';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetReceipt, useUpdateReceipt } from '../../api/LogbookApi';
import { useTypesUnites } from '../other/Costsfunctions';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { GetCostCategories, GetLabel } from '../other/ConfigBasic';
import useAutoComplete from '../other/useAutoComplete';
import 'dayjs/locale/de';
import i18n from 'i18next';
import CostActionBarMenu from './components/CostActionBarMenu';
import ClearIcon from '@mui/icons-material/Clear';
import theme from '../../themes/theme';
import { v4 as uuidv4 } from 'uuid';

const checkCostCentre = (costCentreList, paramCostCentre) => {
    const foundedCostCentre = costCentreList.find((costcenter) => costcenter.name === paramCostCentre[0]);

    if (foundedCostCentre === undefined) {
        costCentreList.push({
            id: uuidv4(),
            name: paramCostCentre[0],
            active: true,
        });
    }
};

const checkCostObject = (costObjectList, paramCostObject) => {
    const foundedCostObject = costObjectList.find((costObject) => costObject.name === paramCostObject[0]);

    if (foundedCostObject === undefined) {
        costObjectList.push({
            id: uuidv4(),
            name: paramCostObject[0],
            active: true,
        });
    }
};

function EditReceptDesktop({ id, costCentreList, costObjectList }) {
    const { receipt, error: receiptError, loading: receiptLoading } = useGetReceipt(id);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [imageUrl, setImageUrl] = useState(null);
    const [vendor, setVendor] = useState('');
    const [addressChange, setAddressChange] = useState('');
    const [address, setAddress] = useState({});
    const [dtBilling, setDtBilling] = useState(new Date());
    const [number, setNumber] = useState('');
    const [category, setCategory] = useState([]);
    const [total, setTotal] = useState('');
    const [vat, setVat] = useState('');
    const [quantity, setQuantity] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [type, setType] = useState('');
    const [unit, setUnit] = useState('');
    const [costCentre, setCostCentre] = useState('');
    const [costObject, setCostObject] = useState('');
    // New state to track if user cleared the address
    const [userCleared, setUserCleared] = useState(false);
    // A state to set the address to the first one if the user did not select one
    const [selectedOption, setSelectedOption] = useState(null);
    // For the next version when we have more than one costs
    // const [addedCosts, setAddedCosts] = useState([]);
    // const [addedCostsTotal] = useState('');
    // const [addedCostsVat, setAddedCostsVat] = useState('');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [isPdf, setIsPdf] = useState(false);
    const categories = GetCostCategories();
    const { updateReceipt } = useUpdateReceipt(setMessage);

    //Address Autocomplete
    const { options } = useAutoComplete(addressChange, setAddress, setMessage);
    /** Error State */
    const [validateError, setValidateError] = useState({
        vendor: false,
        address: false,
        unitPrice: false,
        number: false,
        quantity: false,
        fuel: false,
        total: false,
        vat: false,
    });

    const typeUnits = useTypesUnites();

    if (receipt?.logbookReceipts.length === 0) {
        navigate(-1);
    }

    useEffect(() => {
        if (options.length > 0 && !selectedOption && !addressChange && !userCleared) {
            setSelectedOption(options[0]);
            setAddressChange(options[0].label);
        }
    }, [options, addressChange, selectedOption, userCleared]);

    const handleOptionChange = (event, newValue) => {
        setUserCleared(false);
        setSelectedOption(newValue);
        if (newValue) {
            setAddressChange(newValue.label);
        } else {
            setAddressChange('');
        }
    };
    useEffect(() => {
        const newTotal = String(total).includes(',') ? total.replace(',', '.') : total;
        const newQuantity = String(quantity).includes(',') ? quantity.replace(',', '.') : quantity;

        const calculatedUnitPrice = newTotal / newQuantity;
        if (!isNaN(calculatedUnitPrice) && isFinite(calculatedUnitPrice)) {
            if (category[0] !== categories.FUEL || type === 'FUEL_ELECTRICITY') {
                setUnitPrice(calculatedUnitPrice.toFixed(2));
            } else {
                setUnitPrice(calculatedUnitPrice.toFixed(3));
            }
        } else {
            setUnitPrice('');
        }
    }, [total, quantity, id, type, categories.FUEL, category]);

    const getUnit = (fuelType) => {
        switch (fuelType) {
            case 'FUEL_DIESEL':
                setUnit('LITRE');
                break;
            case 'FUEL_GAS':
                setUnit('LITRE');
                break;
            case 'FUEL_ELECTRICITY':
                setUnit('KWH');
                break;
            case 'FUEL_LPG':
                setUnit('LITRE');
                break;
            case 'FUEL_CNG':
                setUnit('KG');
                break;
            case 'FUEL_H2':
                setUnit('KG');
                break;
            case 'FUEL_ADBLUE':
                setUnit('LITRE');
                break;
            default:
                setUnit('PIECE');
                break;
        }
    };

    /** A Function for the Edit to set the states to the receipt being edited */
    const handleSetData = useCallback(() => {
        if (!receiptLoading && id && !receiptError) {
            const oneReceipt = receipt?.logbookReceipts[0];
            setVendor(oneReceipt?.vendor);
            if (oneReceipt?.imageUrl !== null) {
                setImageUrl(`${process.env.REACT_APP_MINX_API_URL}/${id}`);
                setIsPdf(oneReceipt?.imageUrl?.endsWith('.pdf'));
            }
            const oldAddress = `${oneReceipt?.address?.street} ${oneReceipt?.address?.houseNo}, ${oneReceipt?.address?.zip} ${oneReceipt?.address?.city}`;
            setAddressChange(oldAddress);

            setDtBilling(oneReceipt?.dtBilling);
            setNumber(oneReceipt?.number);
            setTotal(String(oneReceipt?.total).replace('.', ','));
            setNumber(oneReceipt?.number);
            setCategory([oneReceipt?.articles[0]?.category]);
            if (oneReceipt?.articles[0]?.type?.includes('FUEL')) {
                const value = oneReceipt?.articles[0]?.type;
                setType(value);
            }
            setVat(oneReceipt?.articles[0]?.vat);
            setQuantity(String(oneReceipt?.articles[0]?.quantity).replace('.', ','));
            setUnitPrice(String(oneReceipt?.articles[0]?.unitPrice).replace('.', ','));
            setFile(null);
            setCostCentre(oneReceipt?.articles[0]?.costCentre === null ? '' : oneReceipt?.articles[0]?.costCentre);
            setCostObject(oneReceipt?.articles[0]?.costObject === null ? '' : oneReceipt?.articles[0]?.costObject);
        }
    }, [id, receiptLoading, receiptError, receipt]);

    useEffect(() => {
        getUnit(type);
    }, [type]);

    useEffect(() => {
        handleSetData();
    }, [id, handleSetData]);

    const inputStyle = {
        height: '48px',

        input: {
            height: '100%',
            fontSize: '14px',
            fontFamily: 'CircularProMedium',
        },
        label: {
            height: '100%',
            fontSize: '14px',
            fontFamily: 'CircularProMedium',
        },
    };

    const getFile = () => {
        let sendFile = null;
        if (file !== null) {
            sendFile = new FormData();
            sendFile.append('file', file);
        }
        return sendFile;
    };

    /** A Function to set the validation of the data the being sent */
    const formValidation = (setValidState) => {
        let validData = true;
        const updatedValidateError = {
            vendor: false,
            address: false,
            unitPrice: false,
            number: false,
            quantity: false,
            fuel: false,
            total: false,
            vat: false,
        };

        if (vendor?.length === 0) {
            setMessage(t('verndorFehler'));
            updatedValidateError.vendor = true;
            validData = false;
        }
        if (addressChange?.length === 0) {
            setMessage(t('addressFehler'));
            updatedValidateError.address = true;
            validData = false;
        }
        if (unitPrice <= 0) {
            setMessage(t('unitPriceFehler'));
            updatedValidateError.unitPrice = true;
            validData = false;
        }
        if (number?.length === 0) {
            setMessage(t('receiptNumberFehler'));
            updatedValidateError.number = true;
            validData = false;
        }
        if (quantity <= 0) {
            setMessage(t('quantityFehler'));
            updatedValidateError.quantity = true;
            validData = false;
        }
        if (category[0] === categories.FUEL && type?.length === 0) {
            setMessage(t('typeFehler'));
            updatedValidateError.fuel = true;
            validData = false;
        }
        if (total <= 0) {
            setMessage(t('totalFehler'));
            updatedValidateError.total = true;
            validData = false;
        }
        if (vat <= 0) {
            setMessage(t('vatFehler'));
            updatedValidateError.vat = true;
            validData = false;
        }
        setValidState(updatedValidateError); // Update the state with the new object

        return validData;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const currency = 'EUR';
        const netTotal = (Number(total.replace(',', '.')) / 119) * 100;
        const isFormValid = formValidation(setValidateError);

        if (!receiptError && isFormValid) {
            await updateReceipt(
                address,
                currency,
                moment(dtBilling).format(),
                Number(netTotal),
                number,
                Number(total.replace(',', '.')),
                vendor,
                id,
                Number(quantity.replace(',', '.')),
                type,
                unit,
                Number(unitPrice),
                Number(vat),
                getFile(),
                category[0],
                costCentre !== '' ? costCentre : null,
                costObject !== '' ? costObject : null
            );
        }
    };

    const smallInoutCustomStle = {
        width: '244px',
        height: '48',
        input: {
            height: '100%',
            fontSize: '14px',
            fontFamily: 'CircularProBook',
        },
        label: {
            fontSize: '14px',
            fontFamily: 'CircularProBook',
        },
    };
    const handleEditCreateReceipt = () => {
        navigate('/costsOverview');
    };

    if (receipt?.logbookReceipts[0]?.articles[0]?.costCentre && costCentre.length > 0) {
        checkCostCentre(costCentreList, costCentre);
    }

    if (receipt?.logbookReceipts[0]?.articles[0]?.costObject && costObject.length > 0) {
        checkCostObject(costObjectList, costObject);
    }

    return (
        <Stack direction='column' alignItems='center'>
            <Stack
                direction='row'
                justifyContent='space-between'
                sx={{
                    width: '100%',
                    height: '65px',
                    top: '64px',
                    bgcolor: '#FAFAFA',
                    zIndex: '5',
                    py: '8px',
                }}
            >
                <Button
                    onClick={handleEditCreateReceipt}
                    sx={{
                        textAlign: 'right',
                    }}
                >
                    <CloseIcon
                        sx={{
                            width: '24px',
                            aspectRatio: 1,
                            color: 'black',
                        }}
                    />
                </Button>
                <Box display={'flex'} paddingRight={'24px'}>
                    <Button onClick={(e) => handleSubmit(e)}>{t('update')}</Button>
                    <CostActionBarMenu id={id} />
                </Box>
            </Stack>
            <Stack
                direction='row'
                spacing={2}
                sx={{
                    width: '100%',
                    overflow: 'auto',
                    height: `${window.innerHeight - 161}px`,
                }}
            >
                {/** Foto box */}
                <Stack
                    sx={{
                        width: '50%',
                        height: '875px',
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                    }}
                >
                    <FotoUpload imageUrlLocal={imageUrl} setImageUrlLocal={setImageUrl} id={id} setFile={setFile} isPdf={isPdf} setIsPdf={setIsPdf} />
                </Stack>

                {/** Other input Fields */}
                <Stack
                    spacing={3}
                    sx={{
                        width: '50%',
                        height: 'fit-content',
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                        p: 2,
                        bgcolor: 'white',
                    }}
                >
                    <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
                        <Stack spacing={4}>
                            {/** Provider Input */}
                            <TextField
                                sx={inputStyle}
                                label={t('provider')}
                                value={vendor}
                                onChange={(e) => setVendor(e.target.value)}
                                required
                                error={validateError.vendor}
                                helperText={validateError.vendor ? t('mandatoryField') : ''}
                            />
                            {/** Addrres input */}
                            <Autocomplete
                                id='country-customized-option-demo'
                                options={options}
                                isOptionEqualToValue={(option, value) => option.placeId === value.placeId}
                                getOptionLabel={(option) => (option.label ? option.label : addressChange)}
                                value={selectedOption === null ? addressChange : selectedOption}
                                onChange={handleOptionChange}
                                onInputChange={(event, newInputValue) => {
                                    setAddressChange(newInputValue);
                                }}
                                noOptionsText={t('enterAddress')}
                                onBlur={() => {
                                    if (!selectedOption && options.length > 0 && !userCleared) {
                                        setSelectedOption(options[0]);
                                        setAddressChange(options[0].label);
                                    }
                                }}
                                autoSelect={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: null,
                                        }}
                                        label={t('addressSearch')}
                                        onChange={(e) => {
                                            setAddressChange(e.target.value);
                                            if (!e.target.value) {
                                                setSelectedOption(null);
                                                setUserCleared(true);
                                            }
                                        }}
                                        required
                                        error={validateError.address}
                                        helperText={validateError.address ? t('mandatoryField') : ''}
                                    />
                                )}
                            />
                            {/** Time Input */}
                            <DesktopDateTimePicker
                                label={t('date')}
                                inputFormat='DD.MM.YYYY HH:mm'
                                value={dtBilling}
                                onChange={(value) => setDtBilling(value.$d)}
                                renderInput={(params) => {
                                    return <TextField sx={inputStyle} {...params} />;
                                }}
                                views={['day', 'month', 'year', 'hours', 'minutes']}
                            />
                            {/** Receipt Number Input */}
                            <TextField
                                sx={inputStyle}
                                label={t('receiptNumber')}
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                required
                                error={validateError.number}
                                helperText={validateError.number ? t('mandatoryField') : ''}
                            />
                        </Stack>
                    </LocalizationProvider>
                    <Divider />
                    <Stack spacing={4}>
                        {/** Cost Typs select */}
                        <CostsType category={category} setCategory={setCategory} label={t('costType')} fuelState={type} setFuelState={setType} validateError={validateError} />
                        {(costCentreList.length > 0 || costObjectList.length > 0) && (
                            <>
                                <FormControl fullWidth>
                                    <InputLabel id='costCentre-select-label'>{t('costCentre')}</InputLabel>
                                    <Select
                                        endAdornment={
                                            costCentre.length > 0 && (
                                                <ClearIcon
                                                    sx={{ cursor: 'pointer', backgroundColor: '#fff', zIndex: 1, color: theme.palette.grey[900], marginRight: '-7px' }}
                                                    onClick={() => {
                                                        setCostCentre('');
                                                    }}
                                                />
                                            )
                                        }
                                        labelId='costCentre-select-label'
                                        id='costCentre-select'
                                        value={costCentre === '' ? costCentre : costCentre[0]}
                                        label={t('costCentre')}
                                        onChange={(e) => setCostCentre([e.target.value])}
                                        sx={{ height: '48px' }}
                                    >
                                        {costCentreList.map((costCentre) => (
                                            <MenuItem key={costCentre.id} value={costCentre.name}>
                                                {costCentre.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id='costObject-select-label'>{t('costObject')}</InputLabel>
                                    <Select
                                        endAdornment={
                                            costObject.length > 0 && (
                                                <ClearIcon
                                                    sx={{ cursor: 'pointer', backgroundColor: '#fff', zIndex: 1, color: theme.palette.grey[900], marginRight: '-7px' }}
                                                    onClick={() => {
                                                        setCostObject('');
                                                    }}
                                                />
                                            )
                                        }
                                        labelId='costObject-select-label'
                                        id='costObject-select'
                                        value={costObject === '' ? costObject : costObject[0]}
                                        label={t('costObject')}
                                        onChange={(e) => setCostObject([e.target.value])}
                                        sx={{ height: '48px' }}
                                    >
                                        {costObjectList.map((costObject) => (
                                            <MenuItem key={costObject.id} value={costObject.name}>
                                                {costObject.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        <Stack direction='row' spacing={3} justifyContent='space-between'>
                            {/** Total Input */}
                            <TextField
                                sx={smallInoutCustomStle}
                                label={t('grossAmount')}
                                value={total}
                                onChange={(e) => setTotal(e.target.value)}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <EuroIcon
                                                sx={{
                                                    width: '20px',
                                                    aspectRatio: 1,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                error={validateError.total}
                                helperText={validateError.total ? t('mandatoryField') : ''}
                            />
                            {/** Taxes Input */}
                            <TextField
                                sx={smallInoutCustomStle}
                                label={t('vat')}
                                value={vat}
                                onChange={(e) => setVat(e.target.value)}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <PercentIcon
                                                sx={{
                                                    width: '23px',
                                                    aspectRatio: 1,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                error={validateError.vat}
                                helperText={validateError.vat ? t('mandatoryField') : ''}
                            />
                        </Stack>

                        <Stack direction='row' spacing={3} justifyContent='space-between'>
                            {/** Quantity Input */}
                            <TextField
                                sx={smallInoutCustomStle}
                                label={t('quantity')}
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {typeUnits(type)}
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                error={validateError.quantity}
                                helperText={validateError.quantity ? t('mandatoryField') : ''}
                            />
                            {category[0] === categories.FUEL ? (
                                <>
                                    {/** UnitPrice Input */}
                                    <TextField
                                        sx={smallInoutCustomStle}
                                        label={t(GetLabel(type))}
                                        value={unitPrice.replace('.', ',')}
                                        disabled
                                        error={validateError.unitPrice}
                                        helperText={validateError.unitPrice ? t('mandatoryField') : ''}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        €/
                                                    </Typography>

                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {typeUnits(type)}
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    {/** UnitPrice Input */}
                                    <TextField
                                        sx={smallInoutCustomStle}
                                        label={t('unitPrice')}
                                        value={unitPrice.replace('.', ',')}
                                        disabled
                                        error={validateError.unitPrice}
                                        helperText={validateError.unitPrice ? t('mandatoryField') : ''}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        €
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </>
                            )}
                        </Stack>

                        {/** A for the next version when we have another costs */}
                        {/* <Divider />
                                <CostsType label={t('addAnotherCostType')} category={addedCosts} setCategory={setAddedCosts} list={[...costArtNames.slice(1)]} />
                                {(addedCosts[0] === t('cleaningService') ||
                                    addedCosts[0] === t('Inspektion') ||
                                    addedCosts[0] === t('Reperatur') ||
                                    addedCosts[0] === t('Leasing')) && (
                                    <Stack direction='row' spacing={3} justifyContent='space-between'>
                                        <TextField
                                            sx={smallInoutCustomStle}
                                            label={t('grossAmount')}
                                            value={addedCostsTotal}
                                            type='number'
                                            onChange={(e) => setAddedCosts(e.target.value)}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <EuroIcon
                                                            sx={{
                                                                width: '20px',
                                                                aspectRatio: 1,
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <TextField
                                            sx={smallInoutCustomStle}
                                            label={t('vat')}
                                            value={addedCostsVat}
                                            onChange={(e) => setAddedCostsVat(e.target.value)}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <PercentIcon
                                                            sx={{
                                                                width: '23px',
                                                                aspectRatio: 1,
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                )} */}
                    </Stack>
                </Stack>
            </Stack>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </Stack>
    );
}

export default EditReceptDesktop;

import * as React from 'react';
import { Alert, Card } from '@mui/material';
import { GetTourTypes } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import theme from '../../themes/theme';

const getContent = (selectedTour, tourTypes, t) => {
    let content = null;
    if (selectedTour.type === tourTypes.PRIVATE && selectedTour.status === 'EDITED') {
        content = t('bannerPrivateTour');
    } else if (selectedTour.type === tourTypes.PRIVATE && selectedTour.status === 'OPEN') {
        content = t('bannerPrivateTypeEdit');
    } else if (selectedTour.status === 'EXPORTED') {
        content = t('bannerExportetTour');
    }
    return content;
};

export default function BannerEdit(props) {
    const { t } = useTranslation();
    const content = getContent(props.selectedTour, GetTourTypes(), t);
    return (
        <>
            {content && (
                <Grid container xs={12}>
                    <Grid xs={12}>
                        <Card variant='outlined' sx={{ backgroundColor: theme.palette.grey[200], border: 'none' }}>
                            <Alert severity='info'>{content}</Alert>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectionDialogVehicleChild from './SelectionDialogVehicleChild';



const AssetsDialog = (props) => {
    const { data, selectedItems, setSelectedItems, showDialog, setShowDialog, title } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [assetsList, setAssetsList] = useState(undefined)
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        setChecked(selectedItems.map(person => person.id));
    }, [selectedItems]);

    useEffect(() => {
        setOpen(showDialog || open);
    }, [showDialog, open]);

    useEffect(() => {
        if ((showDialog || open) && assetsList === undefined && data.length > 0) {
            setAssetsList(data);
        }
    }, [open, assetsList, data, showDialog, data.length]);


    const handleToggle = (driverId) => () => {
        const currentIndex = checked.indexOf(driverId);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(driverId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleSave = () => {
        const newPersonList = data.filter((assets) => checked.includes(assets.id));
        setOpen(false);
        setShowDialog(false);
        setSelectedItems(newPersonList);
        setChecked([]);
        setAssetsList(undefined)
    };

    const handleCancel = () => {
        setOpen(false);
        setShowDialog(false);
        setChecked(selectedItems.map(person => person.id));
        setAssetsList(undefined)
    };
    const filterItems = (event) => {
        const searchValue = event.target.value.toLowerCase();
        const filteredData = data?.filter((item) => {
            const carName = item.name.toLowerCase();
            const licensePlate = item.child.licensePlate ? item.child.licensePlate.toLowerCase() : '';
            return carName.includes(searchValue) || licensePlate.includes(searchValue);
        });


        setAssetsList(filteredData);
    };


    const handleChangeAll = () => {
        if (checked.length < data.length) {
            setChecked(data.map((driver) => driver.id));
        } else {
            setChecked([]);
        }
    };

    return (
        <SelectionDialogVehicleChild
            open={open}
            title={title}
            filterListFunction={filterItems}
            checked={checked}
            dataAll={data}
            handleToggle={handleToggle}
            showAvatar={true}
            emptyListMessage={data.length === 0 ? t('noAvailableAssets') : t('noResult')}
            dataList={assetsList}
            showEmptyListAction={true}
            emptyListActionMessage={t('addAssets')}
            handleCancel={handleCancel}
            handleAction={handleSave}
            handleActionMessage={t('confirm')}
            handleChangeAll={handleChangeAll}
        />
    );
};

export default AssetsDialog;

import { Button, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';
import React from 'react';
import NoDrivers from '../other/NoDrivers.svg';
import DriverListItem from './DriverListItem';

const DriversTable = ({ selectedPersons, rows = [], setOpen, t, setMessage, onDelete }) => {

    return (
        <>
            {
                selectedPersons.length === 0 ? (
                    <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} gap={'8px'}  >
                        <img src={NoDrivers} alt='icon when the list is empty' width={"179px"} height={'136px'} />
                        <Typography textAlign={'center'} sx={{
                            fontSize: '14px',
                            color: '#757575',
                            width: '200px'
                        }} >{t('chosseDrivers')}</Typography>
                        <Button onClick={() => setOpen(true)} sx={{ padding: '11px 24px 12px 24px', backgroundColor: '#B9F6CA' }} variant='contained'>
                            {t('select')}
                        </Button>
                    </Stack>
                ) : (
                    rows.length > 0 ? (
                        <TableContainer sx={{ overflowY: 'scroll', height: '90%' }} >
                            <Table sx={{
                                '.css-e0unlp-MuiTableCell-root': {
                                    border: 'none'
                                }
                            }}>
                                <TableBody>
                                    {rows.map(asset =>
                                        <DriverListItem row={asset} setMessage={setMessage} key={asset.id} t={t} onDelete={onDelete} />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} gap={'8px'}>
                            <Typography variant='caption' color='gray'>
                                {t('noResult')}
                            </Typography>
                        </Stack>
                    )
                )
            }
        </>
    );
};

export default DriversTable;

import React from 'react';

export default function StarSvg(props) {
    return (
        <svg id='star-grade-outlined' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' alignmentBaseline='central'>
            <path id='Pfad_2828' data-name='Pfad 2828' d='M0,0H24V24H0Z' fill='none' />
            <path
                id='Pfad_2829'
                data-name='Pfad 2829'
                d='M12,7.13l.97,2.29.47,1.11,1.2.1,2.47.21-1.88,1.63-.91.79.27,1.18.56,2.41-2.12-1.28L12,14.93l-1.03.62L8.85,16.83l.56-2.41.27-1.18-.91-.79L6.89,10.82l2.47-.21,1.2-.1.47-1.11L12,7.13M12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21,12,17.27,18.18,21l-1.64-7.03L22,9.24l-7.19-.61Z'
                fill='#212121'
            />
        </svg>
    );
}

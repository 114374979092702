import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import RouteIcon from '@mui/icons-material/Route';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentsIcon from '@mui/icons-material/Payments';
import Badge from '@mui/material/Badge';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useNavigate, useLocation } from 'react-router-dom';
import packageJson from '../../../package.json';
import theme from '../../themes/theme';
import Moment from 'moment';
import { Typography, useMediaQuery } from '@mui/material';
import EditedSvg from '../other/EditedSvg';
import { getBreakPoints, getMenuIndex } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NavigationHelper from './NavigationHelper';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PolicyIcon from '@mui/icons-material/Policy';
import { useGetAppSettings, useUpdateOnBoarding } from '../../api/LogbookApi';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import InvoiceReceipt from '../other/InvoiceReceipt';

export default function NavigationDrawer(props) {
    const { t } = useTranslation();
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    let navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const editAgain = NavigationHelper();
    const [message, setMessage] = useState('');
    const data = useGetAppSettings(props.id, setMessage);
    const [appId, setAppId] = useState('');
    const { updateOnBoarding } = useUpdateOnBoarding();
    useEffect(() => {
        setAppId(data?.appsettings[0]?.id);
    }, [data]);

    useEffect(() => {
        setOpen(props.menuOpen);
    }, [props.menuOpen]);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    const isOverviewPage = location.pathname.includes('/admin') ? true : false;

    const menuContent = (showHint) => {
        const upperMenuItems = isOverviewPage
            ? [
                  {
                      name: (
                          <Typography fontSize='14px' color={theme.palette.secondary.main}>
                              {t('administration')}
                          </Typography>
                      ),
                      icon: (
                          <Badge badgeContent={props.badgePendingLogbooks} color='error'>
                              <DashboardIcon color='secondary' />
                          </Badge>
                      ),
                      onClick: () => navigate('/admin'),
                  },
                  {
                      name: (
                          <Typography fontSize='14px' color={theme.palette.secondary.main}>
                              {t('costs')}
                          </Typography>
                      ),
                      icon: (
                          <PaymentsIcon
                              color='secondary'
                              sx={{
                                  '&:active': {
                                      backgroundColor: '#b9f6ca',
                                  },
                              }}
                          />
                      ),
                      onClick: () => navigate('/admin/costs'),
                  },
              ]
            : [
                  {
                      name: (
                          <Typography fontSize='14px' color={theme.palette.secondary.main}>
                              {t('openTours')}
                          </Typography>
                      ),
                      icon: (
                          <Badge badgeContent={props.badge} color='error'>
                              <RouteIcon color='secondary' />
                          </Badge>
                      ),
                      onClick: () => navigate('/open'),
                  },
                  {
                      name: (
                          <Typography fontSize='14px' color={theme.palette.secondary.main}>
                              {t('editedTours')}
                          </Typography>
                      ),
                      icon: <EditedSvg />,
                      onClick: () => navigate('/edited'),
                  },
                  {
                      name: (
                          <Typography fontSize='14px' color={theme.palette.secondary.main}>
                              {t('receipts')}
                          </Typography>
                      ),
                      icon: <InvoiceReceipt size={24} fill={'#00360F'} />,
                      onClick: () => navigate('/costsOverview'),
                  },
                  {
                      name: (
                          <Typography fontSize='14px' color={theme.palette.secondary.main}>
                              {t('settings')}
                          </Typography>
                      ),
                      icon: showHint?.settings ? (
                          <Badge badgeContent={<PriorityHighIcon />} color='error'>
                              <SettingsIcon color='secondary' />
                          </Badge>
                      ) : (
                          <SettingsIcon color='secondary' />
                      ),
                      onClick: () => navigate('/settings'),
                  },
              ];

        if (!desktop900 && !isOverviewPage) {
            upperMenuItems.unshift({
                name: (
                    <Typography fontSize='14px' color={theme.palette.secondary.main}>
                        {t('status')}
                    </Typography>
                ),
                icon: showHint?.status ? (
                    <Badge badgeContent={<PriorityHighIcon />} color='error'>
                        <DirectionsCarIcon color='secondary' />
                    </Badge>
                ) : (
                    <DirectionsCarIcon color='secondary' />
                ),
                onClick: () => navigate('/status'),
            });
        }

        const handleUpdateOnBoarding = () => {
            updateOnBoarding(appId, 'false');
            setTimeout(() => {
                navigate('/open');
                window.location.reload();
            }, 300);
        };
        const middleMenuItems = [
            {
                name: (
                    <Typography fontSize='14px' color={theme.palette.secondary.main}>
                        {t('feedback')}
                    </Typography>
                ),
                icon: <AnnouncementIcon color='secondary' />,
                href: process.env.REACT_APP_FEEDBACK,
                target: '_blank',
            },
            {
                name: (
                    <Typography fontSize='14px' color={theme.palette.secondary.main}>
                        {t('support')}
                    </Typography>
                ),
                icon: <HelpIcon color='secondary' />,
                href: process.env.REACT_APP_SUPPORT,
                target: '_blank',
            },
            {
                name: (
                    <Typography fontSize='14px' color={theme.palette.secondary.main}>
                        {t('onBoarding')}
                    </Typography>
                ),
                icon: <LightbulbIcon color='secondary' />,
                onClick: () => handleUpdateOnBoarding(),
            },
            {
                name: (
                    <Typography fontSize='14px' color={theme.palette.secondary.main}>
                        {t('legalNotes')}
                    </Typography>
                ),
                icon: <PolicyIcon color='secondary' />,
                onClick: () => navigate('/legal'),
            },
        ];

        return (
            <Box
                sx={{
                    width: 250,
                    position: 'relative',
                    height: '100%',
                    minHeight: '600px',
                    backgroundColor: '#B9F6CA',
                    padding: desktop900 ? '0px 8px 0px 8px' : '64px 8px 0px 8px',
                }}
                role='presentation'
                onClick={desktop900 ? () => {} : toggleDrawer(false).bind(this)}
                onKeyDown={desktop900 ? () => {} : toggleDrawer(false).bind(this)}
            >
                {desktop900 && (
                    <Box
                        sx={{
                            height: '64px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='h1'>{t('logbook')}</Typography>
                        <MenuOpenIcon onClick={() => props.updateMenuState()} cursor='pointer' />
                    </Box>
                )}

                {isOverviewPage && (
                    <Box
                        onClick={() => navigate('admin/logbooksCreate')}
                        sx={{
                            backgroundColor: '#EAFFF0',
                            height: '46px',
                            width: '200px',
                            borderRadius: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            cursor: 'pointer',
                            marginBottom: '15px',
                            '&:hover': {
                                backgroundColor: '#EEEEEE',
                            },
                        }}
                    >
                        <AddIcon color={theme.palette.secondary.main} />
                        <Typography fontSize='14px' color={theme.palette.secondary.main}>
                            {t('createNewLogbook')}
                        </Typography>
                    </Box>
                )}

                <List>
                    {upperMenuItems.map((item, index) => (
                        <ListItem key={item.name.props.children} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                selected={index === (desktop900 ? getMenuIndex(location.pathname, editAgain) : getMenuIndex(location.pathname, editAgain, true))}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'initial',
                                    px: 2.5,
                                }}
                                onClick={item.onClick}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText secondary={item.name} sx={{ opacity: 1 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {middleMenuItems.map((item) => (
                        <ListItem key={item.name.props.children} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'initial',
                                    px: 2.5,
                                }}
                                LinkComponent={'a'}
                                href={item.href}
                                target={item.target}
                                onClick={item.onClick}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} sx={{ opacity: 1 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ left: '20px', position: 'absolute', bottom: '20px' }}>
                    <Typography fontSize={12} color='#16A100'>
                        {packageJson.displayName}
                    </Typography>
                    <Typography fontSize={12} color='#16A100'>
                        Version: {packageJson.version}
                    </Typography>
                    <Typography fontSize={12} color='#16A100'>
                        Copyright {Moment().format('YYYY')} {packageJson.author}
                    </Typography>
                </Box>
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </Box>
        );
    };

    return (
        <div>
            {desktop900 && open && (
                <Drawer variant='permanent' sx={{ zIndex: '10', paddingLeft: '250px' }}>
                    {menuContent(props.showHint)}
                </Drawer>
            )}
            {!desktop900 && (
                <SwipeableDrawer
                    sx={{ zIndex: '10', p: 120 }}
                    open={open}
                    onClose={toggleDrawer(false).bind(this)}
                    onOpen={toggleDrawer(true).bind(this)}
                    onClick={props.updateMenuState}
                >
                    {menuContent(props.showHint)}
                </SwipeableDrawer>
            )}
        </div>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import theme from '../../themes/theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MarkTourAsTemplate from './MarkTourAsTemplate';
import { useOutletContext } from 'react-router-dom';
import { HomeWork } from '@mui/icons-material';
import PrivateSvg from '../other/PrivateSvg';
import { useTranslation } from 'react-i18next';
import { GetTourTypes } from '../other/ConfigBasic';
import OpenToursActionBarMenu from './OpenToursActionBarMenu';
import { Typography } from '@mui/material';

export default function OpenToursActionBar(props) {
    const { t } = useTranslation();
    const currentLogbook = useOutletContext().logbookId;

    return (
        <Box
            sx={{
                position: 'fixed',
                left: '0px',
                top: '0px',
                backgroundColor: theme.palette.success.main,
                height: '64px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '16px',
                zIndex: theme.zIndex.drawer + 2,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                    sx={{ marginRight: '8px' }}
                    onClick={() => {
                        props.setSelected([]);
                    }}
                />
                <Typography variant='h3' sx={{ fontWeight: '500', paddingBottom: '0' }}>
                    {props.selected.length}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <MarkTourAsTemplate
                    currentLogbook={currentLogbook}
                    saveTours={props.saveTours}
                    showIcon={true}
                    successMessage={t('tourMarkedAsCommute')}
                    icon={<HomeWork />}
                    buttonText={t('markAsCommute')}
                    tourType={GetTourTypes().COMMUTE}
                />
                <MarkTourAsTemplate
                    currentLogbook={currentLogbook}
                    saveTours={props.saveTours}
                    showIcon={true}
                    successMessage={t('tourMarkedAsPrivate')}
                    icon={<PrivateSvg />}
                    buttonText={t('markAsPrivatTour')}
                    tourType={GetTourTypes().PRIVATE}
                />
                <OpenToursActionBarMenu
                    selected={props.selected}
                    getSelectedToursData={props.getSelectedToursData}
                    logbookId={currentLogbook}
                    mergeTours={props.mergeTours}
                    saveTours={props.saveTours}
                />
            </Box>
        </Box>
    );
}

import { Autocomplete, Button, CircularProgress, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FotoUpload from './components/FotoUpload';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CostsType from './components/CostsType';
import { useCreateReceipt } from '../../api/LogbookApi';
import moment from 'moment';
import EuroIcon from '@mui/icons-material/Euro';
import PercentIcon from '@mui/icons-material/Percent';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTypesUnites } from '../other/Costsfunctions';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { GetCostCategories, GetLabel } from '../other/ConfigBasic';
import useAutoComplete from '../other/useAutoComplete';
import 'dayjs/locale/de';
import i18n from 'i18next';
import ClearIcon from '@mui/icons-material/Clear';
import theme from '../../themes/theme';

function CreateCostDesktop({ id, costCentreList, costObjectList }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [imageUrlLocal, setImageUrlLocal] = useState(null);
    const [vendor, setVendor] = useState('');
    const [addressChange, setAddressChange] = useState('');
    const [address, setAddress] = useState({});
    const [dtBilling, setDtBilling] = useState(new Date());
    const [number, setNumber] = useState('');
    const [category, setCategory] = useState([]);
    const [total, setTotal] = useState('');
    const [vat, setVat] = useState(19);
    const idLogbook = useOutletContext().logbookId;
    const [quantity, setQuantity] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [type, setType] = useState('');
    const [unit, setUnit] = useState('');
    const [costCentre, setCostCentre] = useState('');
    const [costObject, setCostObject] = useState('');
    // A state to set the address to the first one if the user did not select one
    const [selectedOption, setSelectedOption] = useState(null);

    // For the next version when we have more than one costs
    // const [addedCosts, setAddedCosts] = useState([]);
    // const [addedCostsTotal] = useState('');
    // const [addedCostsVat, setAddedCostsVat] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [isPdf, setIsPdf] = useState(false);
    const categories = GetCostCategories();
    const { options } = useAutoComplete(addressChange, setAddress, setMessage);
    const { createReceipt, loading } = useCreateReceipt(setMessage);

    const typeUnits = useTypesUnites();
    /** A Function to change the Vaule of the unit */
    const getUnit = (fuelType) => {
        switch (fuelType) {
            case 'FUEL_DIESEL':
                setUnit('LITRE');
                break;
            case 'FUEL_GAS':
                setUnit('LITRE');
                break;
            case 'FUEL_ELECTRICITY':
                setUnit('KWH');
                break;
            case 'FUEL_LPG':
                setUnit('LITRE');
                break;
            case 'FUEL_CNG':
                setUnit('KG');
                break;
            case 'FUEL_H2':
                setUnit('KG');
                break;
            case 'FUEL_ADBLUE':
                setUnit('LITRE');
                break;
            default:
                setUnit('PIECE');
                break;
        }
    };
    /** Error State */
    const [validateError, setValidateError] = useState({
        vendor: false,
        address: false,
        unitPrice: false,
        number: false,
        quantity: false,
        fuel: false,
        total: false,
        vat: false,
    });

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        setAddressChange(newValue ? newValue.label : '');
    };

    /** A Function to set the validation of the data the being sent */
    const formValidation = (setValidState) => {
        let validData = true;
        const updatedValidateError = {
            vendor: false,
            address: false,
            unitPrice: false,
            number: false,
            quantity: false,
            fuel: false,
            total: false,
            vat: false,
        };

        if (vendor?.length === 0) {
            setMessage(t('verndorFehler'));
            updatedValidateError.vendor = true;
            validData = false;
        }
        if (addressChange?.length === 0) {
            setMessage(t('addressFehler'));
            updatedValidateError.address = true;
            validData = false;
        }
        if (unitPrice <= 0) {
            setMessage(t('unitPriceFehler'));
            updatedValidateError.unitPrice = true;
            validData = false;
        }
        if (number?.length === 0) {
            setMessage(t('receiptNumberFehler'));
            updatedValidateError.number = true;
            validData = false;
        }
        if (quantity <= 0) {
            setMessage(t('quantityFehler'));
            updatedValidateError.quantity = true;
            validData = false;
        }
        if (category[0] === categories.FUEL && type?.length === 0) {
            setMessage(t('typeFehler'));
            updatedValidateError.fuel = true;
            validData = false;
        }
        if (total <= 0) {
            setMessage(t('totalFehler'));
            updatedValidateError.total = true;
            validData = false;
        }
        if (vat <= 0) {
            setMessage(t('vatFehler'));
            updatedValidateError.vat = true;
            validData = false;
        }
        setValidState(updatedValidateError); // Update the state with the new object

        return validData;
    };

    /** useEffect to calculate the unit price  */
    useEffect(() => {
        const newTotal = String(total).includes(',') ? total.replace(',', '.') : total;
        const newQuantity = String(quantity).includes(',') ? quantity.replace(',', '.') : quantity;

        const calculatedUnitPrice = newTotal / newQuantity;
        if (!isNaN(calculatedUnitPrice) && isFinite(calculatedUnitPrice)) {
            if (category[0] !== categories.FUEL || type === 'FUEL_ELECTRICITY') {
                setUnitPrice(calculatedUnitPrice.toFixed(2));
            } else {
                setUnitPrice(calculatedUnitPrice.toFixed(3));
            }
        } else {
            setUnitPrice('');
        }
    }, [total, quantity, id, type, categories.FUEL, category]);

    useEffect(() => {
        getUnit(type, setUnit);
    }, [type]);

    const getFile = () => {
        let sendFile = null;
        if (file !== null) {
            sendFile = new FormData();
            sendFile.append('file', file);
        }
        return sendFile;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const netTotal = (Number(total.replace(',', '.')) / 119) * 100;
        const currency = 'EUR';
        const isFormValid = formValidation(setValidateError);

        const sendFile = new FormData();
        sendFile.append('file', file);

        if (!id && isFormValid) {
            await createReceipt(
                address,
                currency,
                moment(dtBilling).format(),
                Number(netTotal),
                number,
                Number(total.replace(',', '.')),
                vendor,
                idLogbook,
                Number(quantity.replace(',', '.')),
                type,
                unit,
                Number(unitPrice),
                Number(vat),
                getFile(),
                category[0],
                costCentre !== '' ? costCentre : null,
                costObject !== '' ? costObject : null
            );
        }
    };

    /** Style for the normal inputs */
    const inputStyle = {
        height: '48px',

        '& .css-j08ujv-MuiFormControl-root-MuiTextField-root': {
            height: 'auto',
            bgcolor: 'transparent',
            mb: '0',
        },
        '& .MuiFormHelperText-root': {
            mt: -1,
        },
        fontSize: '14px',
        fontFamily: 'CircularProMedium',
        '& .css-1g3pltd-MuiFormHelperText-root-Mui-error': {
            color: 'red', // Add your desired styles here for the helper text
            mt: '0', // Adjust the spacing as needed
        },
    };

    /** Style for the small Inputs */
    const smallInoutCustomStle = {
        width: '244px',
        height: '48px',
        mb: '10px',
        '& .css-j08ujv-MuiFormControl-root-MuiTextField-root': {
            height: 'auto',
            bgcolor: 'transparent',
        },
        '& .MuiFormHelperText-root': {
            mt: -1,
        },
        fontSize: '14px',
        fontFamily: 'CircularProMedium',
    };
    const handleEditCreateReceipt = () => {
        navigate('/costsOverview');
    };

    return (
        <Stack direction='column' alignItems='center'>
            <Stack
                direction='row'
                justifyContent='space-between'
                sx={{
                    width: '100%',
                    height: '65px',
                    top: '64px',
                    bgcolor: '#FAFAFA',
                    zIndex: '5',
                    py: '8px',
                }}
            >
                <Button
                    onClick={handleEditCreateReceipt}
                    sx={{
                        textAlign: 'right',
                        m: 0,
                        p: 0,
                    }}
                >
                    <CloseIcon
                        sx={{
                            width: '24px',
                            aspectRatio: 1,
                            color: 'black',
                            m: 0,
                            p: 0,
                        }}
                    />
                </Button>

                {loading ? (
                    <CircularProgress size='24px' sx={{ marginTop: '16px' }} color='primary' />
                ) : (
                    <Button onClick={(e) => handleSubmit(e)} sx={{ m: 0, p: 0, paddingRight: '24px' }}>
                        {t('save')}
                    </Button>
                )}
            </Stack>

            <Stack
                direction='row'
                spacing={2}
                sx={{
                    width: '100%',
                    overflow: 'auto',
                    height: `${window.innerHeight - 161}px`,
                }}
            >
                {/** Foto box */}
                <Stack
                    sx={{
                        width: '50%',
                        height: '875px',
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                    }}
                >
                    <FotoUpload imageUrlLocal={imageUrlLocal} setImageUrlLocal={setImageUrlLocal} setFile={setFile} isPdf={isPdf} setIsPdf={setIsPdf} />
                </Stack>

                {/** Other input Fields */}
                <Stack
                    spacing={3}
                    sx={{
                        width: '50%',
                        height: 'fit-content',
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                        p: 2,
                        bgcolor: 'white',
                    }}
                >
                    <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
                        <Stack spacing={4}>
                            {/** Provider Input */}
                            <TextField
                                sx={inputStyle}
                                label={t('provider')}
                                value={vendor}
                                onChange={(e) => setVendor(e.target.value)}
                                required
                                error={validateError.vendor}
                                helperText={validateError.vendor ? t('mandatoryField') : ''}
                            />
                            {/** Addrres input */}
                            <Autocomplete
                                id='country-customized-option-demo'
                                options={options}
                                noOptionsText={t('enterAddress')}
                                isOptionEqualToValue={(option, value) => option.placeId === value.placeId}
                                getOptionLabel={(option) => option.label}
                                value={selectedOption}
                                onChange={handleOptionChange}
                                onBlur={() => {
                                    if (!selectedOption && options.length > 0) {
                                        setSelectedOption(options[0]);
                                        setAddressChange(options[0].label);
                                    }
                                }}
                                autoSelect={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('addressSearch')}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: null,
                                        }}
                                        onChange={(e) => {
                                            setAddressChange(e.target.value);
                                            if (!e.target.value) {
                                                setSelectedOption(null);
                                            }
                                        }}
                                        value={addressChange}
                                        required
                                        error={validateError.address}
                                        helperText={validateError.address ? t('mandatoryField') : ''}
                                    />
                                )}
                            />
                            {/* <TextField
                                    sx={inputStyle}
                                    label={t('addressSearch')}
                                    value={addressChange}
                                    placeholder={t('addressPlaceholder')}
                                    onChange={(e) => setAddressChange(e.target.value)}
                                    required
                                    error={validateError.address}
                                    helperText={validateError.address ? t('mandatoryField') : ''}
                                /> */}
                            {/** Time Input */}
                            <DesktopDateTimePicker
                                label={t('date')}
                                inputFormat='DD.MM.YYYY HH:mm'
                                value={dtBilling}
                                onChange={(value) => setDtBilling(value.$d)}
                                renderInput={(params) => {
                                    return <TextField sx={inputStyle} {...params} />;
                                }}
                                views={['day', 'month', 'year', 'hours', 'minutes']}
                            />
                            {/** Receipt Number Input */}
                            <TextField
                                sx={inputStyle}
                                label={t('receiptNumber')}
                                value={number}
                                onChange={(e) => setNumber(e.target.value.trim())}
                                required
                                error={validateError.number}
                                helperText={validateError.number ? t('mandatoryField') : ''}
                            />
                        </Stack>
                    </LocalizationProvider>
                    <Divider />
                    <Stack spacing={4}>
                        {/** Cost Typs select */}
                        <CostsType category={category} setCategory={setCategory} label={t('costType')} fuelState={type} setFuelState={setType} validateError={validateError} />
                        {(costCentreList.length > 0 || costObjectList.length > 0) && (
                            <>
                                <FormControl fullWidth>
                                    <InputLabel id='costCentre-select-label'>{t('costCentre')}</InputLabel>
                                    <Select
                                        endAdornment={
                                            costCentre.length > 0 && (
                                                <ClearIcon
                                                    sx={{ cursor: 'pointer', backgroundColor: '#fff', zIndex: 1, color: theme.palette.grey[900], marginRight: '-7px' }}
                                                    onClick={() => {
                                                        setCostCentre('');
                                                    }}
                                                />
                                            )
                                        }
                                        labelId='costCentre-select-label'
                                        id='costCentre-select'
                                        value={costCentre === '' ? costCentre : costCentre[0]}
                                        label={t('costCentre')}
                                        onChange={(e) => setCostCentre([e.target.value])}
                                        sx={{ height: '48px' }}
                                    >
                                        {costCentreList.map((costCentre) => (
                                            <MenuItem key={costCentre.id} value={costCentre.name}>
                                                {costCentre.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id='costObject-select-label'>{t('costObject')}</InputLabel>
                                    <Select
                                        endAdornment={
                                            costObject.length > 0 && (
                                                <ClearIcon
                                                    sx={{ cursor: 'pointer', backgroundColor: '#fff', zIndex: 1, color: theme.palette.grey[900], marginRight: '-7px' }}
                                                    onClick={() => {
                                                        setCostObject('');
                                                    }}
                                                />
                                            )
                                        }
                                        labelId='costObject-select-label'
                                        id='costObject-select'
                                        value={costObject === '' ? costObject : costObject[0]}
                                        label={t('costObject')}
                                        onChange={(e) => setCostObject([e.target.value])}
                                        sx={{ height: '48px' }}
                                    >
                                        {costObjectList.map((costObject) => (
                                            <MenuItem key={costObject.id} value={costObject.name}>
                                                {costObject.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        <Stack direction='row' spacing={3} justifyContent='space-between' sx={{ mb: '15px' }}>
                            {/** Total Input */}
                            <TextField
                                sx={smallInoutCustomStle}
                                label={t('grossAmount')}
                                value={total}
                                onChange={(e) => setTotal(e.target.value)}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <EuroIcon
                                                sx={{
                                                    width: '20px',
                                                    aspectRatio: 1,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                error={validateError.total}
                                helperText={validateError.total ? t('mandatoryField') : ''}
                            />
                            {/** Taxes Input */}
                            <TextField
                                sx={smallInoutCustomStle}
                                label={t('vat')}
                                value={vat}
                                onChange={(e) => setVat(e.target.value)}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <PercentIcon
                                                sx={{
                                                    width: '23px',
                                                    aspectRatio: 1,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                error={validateError.vat}
                                helperText={validateError.vat ? t('mandatoryField') : ''}
                            />
                        </Stack>

                        <Stack direction='row' spacing={3} justifyContent='space-between'>
                            {/** Quantity Input */}
                            <TextField
                                sx={smallInoutCustomStle}
                                label={t('quantity')}
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                required
                                onWheel={(e) => e.preventDefault()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {typeUnits(type)}
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                error={validateError.quantity}
                                helperText={validateError.quantity ? t('mandatoryField') : ''}
                            />
                            {category[0] === categories.FUEL ? (
                                <>
                                    {/** UnitPrice Input */}
                                    <TextField
                                        sx={smallInoutCustomStle}
                                        label={t(GetLabel(type))}
                                        value={unitPrice.replace('.', ',')}
                                        disabled
                                        error={validateError.unitPrice}
                                        helperText={validateError.unitPrice ? t('mandatoryField') : ''}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        €/
                                                    </Typography>

                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {typeUnits(type)}
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    {/** UnitPrice Input */}
                                    <TextField
                                        sx={smallInoutCustomStle}
                                        label={t('unitPrice')}
                                        value={unitPrice.replace('.', ',')}
                                        disabled
                                        error={validateError.unitPrice}
                                        helperText={validateError.unitPrice ? t('mandatoryField') : ''}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        €
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </>
                            )}
                        </Stack>

                        {/** A for the next version when we have another costs */}
                        {/* <Divider />
                                <CostsType label={t('addAnotherCostType')} category={addedCosts} setCategory={setAddedCosts} list={[...costArtNames.slice(1)]} />
                                {(addedCosts[0] === t('cleaningService') ||
                                    addedCosts[0] === t('Inspektion') ||
                                    addedCosts[0] === t('Reperatur') ||
                                    addedCosts[0] === t('Leasing')) && (
                                    <Stack direction='row' spacing={3} justifyContent='space-between'>
                                        <TextField
                                            sx={smallInoutCustomStle}
                                            label={t('grossAmount')}
                                            value={addedCostsTotal}
                                            onChange={(e) => setAddedCosts(e.target.value)}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <EuroIcon
                                                            sx={{
                                                                width: '20px',
                                                                aspectRatio: 1,
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <TextField
                                            sx={smallInoutCustomStle}
                                            label={t('vat')}
                                            value={addedCostsVat}
                                            onChange={(e) => setAddedCostsVat(e.target.value)}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <PercentIcon
                                                            sx={{
                                                                width: '23px',
                                                                aspectRatio: 1,
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                )} */}
                    </Stack>
                </Stack>
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </Stack>
        </Stack>
    );
}

export default CreateCostDesktop;

import React from 'react';
import { Box } from '@mui/material';

export default function VehicleSvg() {
    return (
        <Box sx={{ maxHeight: '220px', backgroundColor: '#e0e0e0' }}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 538 220'>
                <g id='Gruppe_3064' data-name='Gruppe 3064' transform='translate(-1)'>
                    <path
                        id='Rechteck_1423'
                        data-name='Rechteck 1423'
                        d='M12,0H526a12,12,0,0,1,12,12V220a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V12A12,12,0,0,1,12,0Z'
                        transform='translate(1)'
                        fill='#e0e0e0'
                    />
                    <g id='Gruppe_3063' data-name='Gruppe 3063' transform='translate(194.15 24.916)'>
                        <path
                            id='Pfad_3239'
                            data-name='Pfad 3239'
                            d='M374.639,255.323a7.76,7.76,0,0,1-3.732-.96L264.725,196.292a7.81,7.81,0,0,1-3.1-10.587l44.81-81.934a7.8,7.8,0,0,1,10.587-3.1L423.2,158.74a7.81,7.81,0,0,1,3.1,10.587l-44.81,81.934A7.75,7.75,0,0,1,376.843,255,7.833,7.833,0,0,1,374.639,255.323Zm-3.091-2.133a6.464,6.464,0,0,0,8.772-2.57l44.81-81.934a6.471,6.471,0,0,0-2.569-8.772L316.379,101.843a6.464,6.464,0,0,0-8.772,2.57L262.8,186.347a6.471,6.471,0,0,0,2.57,8.772Z'
                            transform='translate(-245.339 -99.701)'
                            fill='#f2f2f2'
                        />
                        <path
                            id='Pfad_3240'
                            data-name='Pfad 3240'
                            d='M457.194,284.47a7.289,7.289,0,0,1-3.5-.9l-85.845-46.948a7.237,7.237,0,0,1-2.88-9.831l26.762-48.934a7.243,7.243,0,0,1,9.831-2.88l85.845,46.948a7.243,7.243,0,0,1,2.88,9.831l-.1-.053.1.053L463.529,280.7a7.214,7.214,0,0,1-6.336,3.775Z'
                            transform='translate(-325.704 -157.504)'
                            fill='#f2f2f2'
                        />
                        <path
                            id='Pfad_3241'
                            data-name='Pfad 3241'
                            d='M322.657,421.673H201.633a7.252,7.252,0,0,1-7.244-7.244V321.043a7.252,7.252,0,0,1,7.244-7.244H322.657a7.252,7.252,0,0,1,7.244,7.244V414.43A7.252,7.252,0,0,1,322.657,421.673Z'
                            transform='translate(-193.832 -266.073)'
                            fill='#fff'
                        />
                        <path
                            id='Pfad_3242'
                            data-name='Pfad 3242'
                            d='M320.714,420.288H199.69a7.81,7.81,0,0,1-7.8-7.8V319.1a7.81,7.81,0,0,1,7.8-7.8H320.714a7.81,7.81,0,0,1,7.8,7.8v93.387A7.81,7.81,0,0,1,320.714,420.288ZM199.69,312.636a6.471,6.471,0,0,0-6.464,6.464v93.387a6.471,6.471,0,0,0,6.464,6.464H320.714a6.471,6.471,0,0,0,6.464-6.464V319.1a6.471,6.471,0,0,0-6.464-6.464Z'
                            transform='translate(-191.889 -264.13)'
                            fill='#e6e6e6'
                        />
                        <path
                            id='Pfad_3243'
                            data-name='Pfad 3243'
                            d='M528.494,451.586a9.085,9.085,0,0,0-7.255,3.608,5.982,5.982,0,0,0-8.347,5.493h24.7a9.1,9.1,0,0,0-9.1-9.1Z'
                            transform='translate(-469.281 -387.36)'
                            fill='#e6e6e6'
                        />
                        <circle id='Ellipse_406' data-name='Ellipse 406' cx='11.002' cy='11.002' r='11.002' transform='translate(10.241 58.781)' fill='#ff3d00' />
                        <path
                            id='Pfad_3245'
                            data-name='Pfad 3245'
                            d='M428.5,519.768a11.209,11.209,0,0,0-8.951,4.451,7.38,7.38,0,0,0-10.3,6.778h30.477A11.228,11.228,0,0,0,428.5,519.768Z'
                            transform='translate(-395.247 -447.986)'
                            fill='#ccc'
                        />
                        <g id='Gruppe_3065' data-name='Gruppe 3065' transform='matrix(0.966, -0.259, 0.259, 0.966, -6099.25, -10556.401)'>
                            <path
                                id='Pfad_3273'
                                data-name='Pfad 3273'
                                d='M827.607,749.96l-2.8,10.516a1.927,1.927,0,0,1-2.357,1.367l-125.666-33.43a1.381,1.381,0,0,1-.257-.093,1.924,1.924,0,0,1-1.11-2.265l2.8-10.516Z'
                                transform='translate(2426.759 11193.152)'
                                fill='#3f3d56'
                            />
                            <rect id='Rechteck_1535' data-name='Rechteck 1535' width='20.688' height='2.364' transform='translate(3178.845 11929.755) rotate(14.897)' fill='#ccc' />
                            <rect id='Rechteck_1536' data-name='Rechteck 1536' width='20.688' height='2.364' transform='translate(3132.863 11917.522) rotate(14.897)' fill='#ccc' />
                            <rect id='Rechteck_1537' data-name='Rechteck 1537' width='20.688' height='2.364' transform='translate(3224.828 11941.987) rotate(14.897)' fill='#ccc' />
                            <rect
                                id='Rechteck_1538'
                                data-name='Rechteck 1538'
                                width='11.498'
                                height='1.825'
                                transform='translate(3234.573 11941.277) rotate(-165.073)'
                                fill='#3f3d56'
                            />
                            <path
                                id='Pfad_3274'
                                data-name='Pfad 3274'
                                d='M731.516,703.956l2.152,1.14,87.116,23.224,1.269-1.617a19.066,19.066,0,0,0,4.005-9.6c.3-2.621.021-5.376-1.825-7.032l2.984-26.781-48.181-12.909-22.651,10.081s-8.755-2.5-15.118,2.18a14.451,14.451,0,0,0-4.967,6.915l-1.012,3.045Z'
                                transform='translate(2411.971 11211.621)'
                                fill='#16a100'
                            />
                            <circle id='Ellipse_426' data-name='Ellipse 426' cx='11.133' cy='11.133' r='11.133' transform='translate(3151.605 11909.103)' fill='#3f3d56' />
                            <circle id='Ellipse_427' data-name='Ellipse 427' cx='5.859' cy='5.859' r='5.859' transform='translate(3156.879 11914.378)' fill='#ccc' />
                            <circle id='Ellipse_428' data-name='Ellipse 428' cx='11.133' cy='11.133' r='11.133' transform='translate(3204.687 11923.255)' fill='#3f3d56' />
                            <circle id='Ellipse_429' data-name='Ellipse 429' cx='5.859' cy='5.859' r='5.859' transform='translate(3209.96 11928.528)' fill='#ccc' />
                            <path
                                id='Pfad_3275'
                                data-name='Pfad 3275'
                                d='M565.159,618.525l26.023,6.935,3.177.845,5.363,1.431.1-.392,1.258-4.716,1.413-5.3-3.568-.949-3.176-.849-5.626-1.5-3.177-.845-6.5-1.732Z'
                                transform='translate(2611.075 11273.769)'
                                fill='#fff'
                            />
                            <path
                                id='Pfad_3276'
                                data-name='Pfad 3276'
                                d='M630.8,633.713l13.753,3.666.722-2.7,1.254-4.716.8-2.988-2.014-.536-3.176-.849-8.566-2.283Z'
                                transform='translate(2584.234 11268.922)'
                                fill='#fff'
                            />
                            <rect
                                id='Rechteck_1539'
                                data-name='Rechteck 1539'
                                width='1.825'
                                height='3.468'
                                transform='translate(3207.837 11912.618) rotate(-165.073)'
                                fill='#3f3d56'
                            />
                            <rect
                                id='Rechteck_1540'
                                data-name='Rechteck 1540'
                                width='1.825'
                                height='3.468'
                                transform='translate(3182.49 11897.169) rotate(104.927)'
                                fill='#3f3d56'
                            />
                            <path
                                id='Pfad_3277'
                                data-name='Pfad 3277'
                                d='M0,0H.2A3.814,3.814,0,0,1,4.015,3.814h0A3.814,3.814,0,0,1,.2,7.629H0Z'
                                transform='translate(3181.048 11897.62) rotate(-165.073)'
                                fill='#3f3d56'
                            />
                            <path
                                id='Pfad_3278'
                                data-name='Pfad 3278'
                                d='M737.9,701.082a5.9,5.9,0,0,0,5.979-9.96,14.451,14.451,0,0,0-4.968,6.915Z'
                                transform='translate(2409.362 11203.138)'
                                fill='#3f3d56'
                            />
                            <path
                                id='Pfad_3279'
                                data-name='Pfad 3279'
                                d='M616.918,618.356l4.074,11.809,1.258-4.716-2.156-6.245Z'
                                transform='translate(2589.91 11270.946)'
                                fill='#f2f2f2'
                            />
                            <path
                                id='Pfad_3280'
                                data-name='Pfad 3280'
                                d='M649.978,627.171l3.137,9.089,1.254-4.716-1.215-3.524Z'
                                transform='translate(2576.39 11267.342)'
                                fill='#f2f2f2'
                            />
                            <path
                                id='Pfad_3281'
                                data-name='Pfad 3281'
                                d='M602.024,614.387l4.233,12.271,3.177.845L605.2,615.232Z'
                                transform='translate(2596 11272.57)'
                                fill='#f2f2f2'
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </Box>
    );
}

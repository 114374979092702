import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { GetAccessStrings } from '../other/ConfigBasic';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';
import 'dayjs/locale/de';
import i18n from 'i18next';

const getDatePlusThreeMonth = () => {
    let date = new Date();
    date.setMonth(date.getMonth() + 3);
    return date;
};

export default function DatePickerSettings(props) {
    const { variant, updateLogbookAccess } = props;
    const [dateFrom, setDateFrom] = useState(props.access.dtNotBefore !== null ? new Date(props.access.dtNotBefore) : new Date());
    const [dateUntil, setDateUntil] = useState(props.access.dtNotAfter !== null ? new Date(props.access.dtNotAfter) : getDatePlusThreeMonth());
    const { t } = useTranslation();

    const getRight = (allowedArray, accessObject) => {
        let right = '';

        if (allowedArray.includes('grant')) {
            right = accessObject.allowed.grant;
        } else if (allowedArray.includes('edit')) {
            right = accessObject.allowed.edit;
        } else if (allowedArray.includes('view')) {
            right = accessObject.allowed.view;
        }
        return right;
    };

    const handleSave = () => {
        const accessObject = GetAccessStrings();
        const changedAccess = [
            {
                holder: props.holder,
                allowed: [],
                variant: variant,
                dtNotAfter: dateUntil === '' ? null : Moment(dateUntil).format('YYYY-MM-DD') + 'T23:59:59.999Z',
                dtNotBefore: dateFrom === '' ? null : Moment(dateFrom).format('YYYY-MM-DD') + 'T00:00:00.000Z',
            },
        ];

        changedAccess[0].allowed = getRight(props.access.allowed, accessObject);
        updateLogbookAccess(changedAccess);
        props.setShowExpiryDate(false);
    };

    const handleDelete = () => {
        const accessObject = GetAccessStrings();
        const changedAccess = [
            {
                holder: props.holder,
                allowed: [],
                variant: variant,
                dtNotAfter: null,
                dtNotBefore: null,
            },
        ];
        changedAccess[0].allowed = getRight(props.access.allowed, accessObject);
        updateLogbookAccess(changedAccess);
        props.setShowExpiryDate(false);
    };

    return (
        <>
            <div>
                <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={t('accessStart')}
                        value={dateFrom}
                        inputFormat='DD.MM.YYYY'
                        onChange={(newValue) => {
                            setDateFrom(newValue.$d);
                        }}
                        renderInput={(params) => <TextField {...params} sx={theme.components.MuiDatePicker} />}
                    />
                    <DatePicker
                        label={t('accessEnd')}
                        value={dateUntil}
                        inputFormat='DD.MM.YYYY'
                        onChange={(newValue) => {
                            setDateUntil(newValue.$d);
                        }}
                        renderInput={(params) => <TextField {...params} sx={theme.components.MuiDatePicker} />}
                    />
                </LocalizationProvider>
            </div>

            <Stack direction='row-reverse' spacing={2}>
                <Link underline='none' onClick={() => handleSave()}>
                    {t('save')}
                </Link>
                <Link underline='none' sx={{ color: '#9E9E9E' }} onClick={() => handleDelete()}>
                    {t('remove')}
                </Link>
            </Stack>
        </>
    );
}

import { Steps } from 'intro.js-react';
import introJs from 'intro.js';
import { useTranslation } from 'react-i18next';

export default function StepsModule({ enableOnboarding, handleComplete }) {
    const { t } = useTranslation();

    const handleExit = () => {
        introJs().exit();
    };
    const steps = [
        {
            element: '#logbook-selection-button',
            intro: t('introStepOne'),
            position: 'bottom',
            tooltipClass: 'firstTip',
            highlightClass: 'myHighlightClass',
        },
        {
            element: '#onBoarding_Step1',
            intro: t('introStepTwo'),
            position: 'right',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'smallHiglight',
        },
        {
            element: '#onBoarding_Step2',
            intro: t('introStepThree'),
            position: 'right',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'smallHiglight',
        },
        {
            element: '#onBoarding_Step3',
            intro: t('introStepFour'),
            position: 'right',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'smallHiglight',
        },
        {
            element: '#onBoarding_Step4',
            intro: t('introStepFive'),
            position: 'right',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'smallHiglight',
        },
    ];
    const afterChange = (stepIndex, newElement) => {
        setTimeout(() => {
            const stepNumberElement = document.querySelector('.introjs-helperNumberLayer');
            const prevElement = document.querySelector('.introjs-prevbutton');
            if (prevElement && prevElement.classList.contains('introjs-disabled')) {
                prevElement.classList.remove('introjs-disabled');
                prevElement.addEventListener('click', handleComplete);
            }

            if (stepNumberElement) {
                stepNumberElement.textContent = stepNumberElement.textContent.replace(' of ', ` ${t('of')} `);
            }
        }, 400);
    };

    return (
        <Steps
            enabled={enableOnboarding}
            steps={steps}
            initialStep={0}
            onExit={handleExit}
            options={{
                doneLabel: t('done'),
                nextLabel: t('next'),
                prevLabel: t('skip'),
                showBullets: false,
                showStepNumbers: true,
            }}
            onComplete={handleComplete}
            onChange={afterChange}
        />
    );
}

import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';


import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';

function AllUsersAccessValues() {
    const { t } = useTranslation();
    const accessMenu = [t('noAccess'), t('viewer'), t('editor')];
    return accessMenu;
};


export default function AllUsersAccess(props) {
    const { teamRole, setTeamAccess } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [access, setAccess] = useState({})

    useEffect(() => {
        if (teamRole) {
            setAccess(teamRole)
        }
    }, [teamRole])


    const open = Boolean(anchorEl);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const getRoleId = (selectedRole) => {
        switch (selectedRole) {
            case t('viewer'):
            case t('editor'):
                return selectedRole;
            default:
                return null;
        }
    };

    const handleMenuItemClick = (selectedRole) => {
        const updatedTeamRole = { ...access, role: getRoleId(selectedRole) };
        setTeamAccess(updatedTeamRole)

        handleClose();
    };

    const getMenuValue = (roleId) => {
        switch (roleId) {
            case t('viewer'):
            case t('editor'):
                return roleId;
            default:
                return t('noAccess');
        }
    };


    return (
        <div>
            <Button
                sx={{ fontSize: '14px', width: '100%', p: .5 }}
                variant='outlined'
                color='primary'
                id='role-button'
                aria-controls={open ? 'role-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleButtonClick}
                endIcon={<KeyboardArrowDownIcon />}

            >
                {getMenuValue(access.role)}
            </Button>
            <Menu
                id='role-menu'
                sx={{ fontSize: '12px' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'role-button',
                    role: 'listbox',
                }}
            >
                {AllUsersAccessValues().map((option) => (
                    <MenuItem key={option} value={option} onClick={() => handleMenuItemClick(option)} sx={{ fontSize: '14px' }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

import { useMediaQuery } from '@mui/material';
import { Stack, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterOptions, FilterMenu, SummContainer, CostTable } from './components/index';
import DatePickerDesktop from './components/DatePickerDesktop';
import moment from 'moment';
import { useDeleteReceipt, useGetReceipts } from '../../api/LogbookApi';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteDialog from './components/DeleteDialog';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import LinearProgress from '@mui/material/LinearProgress';

/** The Main View of the costs on Destop */
function CostViewDesktop() {
    const [message, setMessage] = useState('');
    const [datum, setDatum] = useState(moment());
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [costFilter, setCostFilter] = useState([]);
    const [providerFilter, setProviderFilter] = useState([]);
    const [rowArray, setRowArray] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const isCustomScreen = useMediaQuery('(min-width: 900px) and (max-width: 1170px)');

    const [receiptId, setReceiptId] = useState('');
    const { t } = useTranslation();
    const idlogbook = useOutletContext().logbookId;

    const { deleteReceipt } = useDeleteReceipt(setMessage);
    const { receipts, loading, error } = useGetReceipts(idlogbook, datum);

    const handleDelete = async (rowId) => {
        await deleteReceipt({ variables: { id: rowId } });
        setTimeout(() => {
            setReceiptId('');
        }, 400);
    };

    useEffect(() => {
        if (receipts) {
            /** To filter the data according to the Data from the DataPickers */
            const filteredRows = receipts?.logbookReceipts?.filter((row) => {
                let hasMatchingCategory = true;
                let hasMatchingProvider = true;
                if (costFilter.length > 0) {
                    hasMatchingCategory = row.articles?.some((article) => costFilter.some((category) => category?.toLowerCase() === article?.category?.toLowerCase()));
                }
                if (providerFilter.length > 0) {
                    hasMatchingProvider = providerFilter.some((provider) => provider.toLowerCase() === row.vendor.toLowerCase());
                }
                return hasMatchingCategory && hasMatchingProvider;
            });

            setRowArray(filteredRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [costFilter, providerFilter, idlogbook, receipts, t]);

    /** A Fuction to get the sum of the fee */
    const totalFeeSum = rowArray?.reduce((sum, row) => {
        const fee = row.total;
        if (!isNaN(fee)) {
            return sum + fee;
        }
        return sum;
    }, 0);

    /** A Function to calculate the netTotal of the receipts */
    const netFeeSum = rowArray?.reduce((sum, row) => {
        const fee = row.netTotal;
        if (!isNaN(fee)) {
            return sum + fee;
        }
        return sum;
    }, 0);

    const changeCostFilter = (newCategory) => {
        let newCostFilter = [...costFilter];
        if (newCostFilter.every((cost) => cost.toLowerCase() !== newCategory.toLowerCase())) {
            newCostFilter.push(newCategory);
        } else {
            newCostFilter = newCostFilter.filter((cost) => cost.toLowerCase() !== newCategory.toLowerCase());
        }
        setCostFilter(newCostFilter);
    };

    const changeProviderFilter = (newProvider) => {
        let newProviderFilter = [...providerFilter];
        if (newProviderFilter.every((provider) => provider.toLowerCase() !== newProvider.toLowerCase())) {
            newProviderFilter.push(newProvider);
        } else {
            newProviderFilter = newProviderFilter.filter((provider) => provider.toLowerCase() !== newProvider.toLowerCase());
        }
        setProviderFilter(newProviderFilter);
    };

    const handleChangeDate = (newDate) => {
        setDatum(newDate);
        setCostFilter([]);
        setProviderFilter([]);
    };
    
    if (loading) {
        return <LinearProgress />;
    }

    return (
        <Box
            sx={{
                mx: 'auto',
                display: loading ? 'flex' : '',
            }}
        >
            {/** the Data Picker to chosse which month and year to show */}
            <DatePickerDesktop state={datum} setState={handleChangeDate} />
            <Stack
                direction={isCustomScreen ? 'column' : 'row'}
                spacing={2}
                alignItems={isCustomScreen ? 'left' : 'top'}
                sx={{
                    mt: '10px',
                }}
            >
                <Stack
                    sx={{
                        bgcolor: 'white',
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                    }}
                    spacing={1}
                >
                    <Stack sx={{ px: 2, pt: 1 }} direction='row' justifyContent='end'>
                        <Stack
                            sx={{
                                position: 'relative',
                            }}
                            direction='row'
                            spacing={2}
                        >
                            {/** Whicht Filter is chossen */}
                            <FilterOptions filter={costFilter} changeFilter={changeCostFilter} translate={true} />
                            <FilterOptions filter={providerFilter} changeFilter={changeProviderFilter} translate={false} />
                            <FilterListIcon onClick={() => setShowFilterMenu(!showFilterMenu)} sx={{ cursor: 'pointer' }} />
                            {/** Filter Menu */}
                            {showFilterMenu && rowArray.length > 0 && (
                                <FilterMenu
                                    items={receipts?.logbookReceipts}
                                    costFilter={costFilter}
                                    changeCostFilter={changeCostFilter}
                                    providerFilter={providerFilter}
                                    changeProviderFilter={changeProviderFilter}
                                />
                            )}
                        </Stack>
                    </Stack>
                    {/** The Cost Table  */}
                    {!loading && !error && <CostTable rows={rowArray !== undefined ? rowArray : []} setOpenDelete={setOpenDelete} setReceiptId={setReceiptId} />}
                </Stack>
                <Stack
                    sx={{
                        width: '402px',
                        minHeight: '172px',
                        bgcolor: 'white',
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                        p: 2,
                    }}
                    spacing={4}
                >
                    {/** NETTO */}
                    <SummContainer label={t('netFee')} summ={netFeeSum} />
                    {/** VAT */}
                    <SummContainer label={t('vat')} summ={parseFloat(totalFeeSum) - parseFloat(netFeeSum)} />
                    {/** BRUTTO */}
                    <SummContainer label={t('totalfee')} summ={totalFeeSum} />
                </Stack>
            </Stack>
            <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={() => handleDelete(receiptId)} />
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </Box>
    );
}

export default CostViewDesktop;

import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, TextField } from '@mui/material';
import moment from 'moment';
import 'dayjs/locale/de';
import i18n from 'i18next';

function DatePickerDesktop({ state, setState }) {
    const datePickerStyle = {
        width: '152px',
        height: '35px',
        p: 0,
        m: 0,       
        input: {
            width: '152px',
            height: '100%',
            p: '10px',
        },
        label: {
            p: 0,
            m: 0,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0px !important',
          },
    };

    return (
        <Box>
            <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
                <DatePicker
                    views={['month', 'year']}
                    value={state}
                    openTo='month'
                    onChange={(newValue) => {
                        setState(moment(newValue.$d));
                    }}
                    sx={datePickerStyle}
                    renderInput={(params) => <TextField {...params} error={false} sx={datePickerStyle} />}
                />
            </LocalizationProvider>
        </Box>
    );
}

export default DatePickerDesktop;

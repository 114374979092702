import * as React from 'react';
import { Box, Button, Chip, InputBase, List, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import MixedTourListItem from './MixedTourListItem';
import theme from '../../themes/theme';
import { useTranslation } from 'react-i18next';
import FilterListIcon from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export default function MixedTourList(props) {
    const { t } = useTranslation();
    const startEntries = 10;
    const next = 15;

    const [searchValue, setSearchValue] = useState('');
    const [numberOfEntries, setNumberOfEntries] = useState(startEntries);

    const filterdayLogs = (value) => {
        setSearchValue(value.target.value.trim());
    };

    const handleShowSuggestion = (value) => {
        props.setShowSuggestion(value);
        setNumberOfEntries(startEntries);
    };

    const handleClick = () => {
        const newNumberOfEntries = numberOfEntries + next;
        setNumberOfEntries(newNumberOfEntries);
    };

    return (
        <>
            <Grid container xs={12}>
                <Grid xs={12} textAlign='left'>
                    <Typography variant='h6' component='div'>
                        {t('searchAndSelectStops')}
                    </Typography>
                </Grid>
                <Grid xs={12} textAlign='left'>
                    <Box display='flex' alignItems='center'>
                        <Box
                            display='flex'
                            flexGrow='1'
                            alignItems='center'
                            justifyContent='space-between'
                            padding='0 8px'
                            border={`solid 1px ${theme.palette.grey[600]}`}
                            borderRadius='4px'
                        >
                            <InputBase disabled={props.showSuggestion} placeholder={t('search')} onChange={(value) => filterdayLogs(value)} />
                            <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                        </Box>
                        <FilterListIcon onClick={() => handleShowSuggestion(true)} cursor='pointer' />
                    </Box>
                </Grid>
                {props.showSuggestion && (
                    <Grid xs={12} textAlign='left' sx={{ my: '10px' }}>
                        <Chip label={t('suggestions')} onDelete={() => handleShowSuggestion(false)} />
                    </Grid>
                )}
            </Grid>
            <List
                sx={{
                    width: '100%',
                    height: !props.mobile
                        ? '300px'
                        : props.showSuggestion
                        ? props.selectedStopps.length === 0
                            ? '67.5vh'
                            : '405px'
                        : props.selectedStopps.length === 0
                        ? numberOfEntries < props.dayLogsFromTour.length
                            ? '67.5vh'
                            : '300px'
                        : numberOfEntries < props.dayLogsFromTour.length
                        ? '67.5vh'
                        : '300px',

                    overflowY: 'auto',
                }}
            >
                {props.showSuggestion &&
                    props.suggestionList.map((suggestion, index) => (
                        <MixedTourListItem
                            key={suggestion.date}
                            numberOfEntries={numberOfEntries}
                            index={index}
                            dataPoint={suggestion}
                            changeSelectedStopps={props.changeSelectedStopps}
                            selectedStopps={props.selectedStopps}
                            setAltLan={props.setAltLan}
                            hoverdPoint={props.hoverdPoint}
                            setAddress={props.setAddress}
                            setData={props.setData}
                        />
                    ))}
                {!props.showSuggestion &&
                    props.dayLogsFromTour.map((dayLogs, index) => (
                        <MixedTourListItem
                            key={dayLogs.date}
                            numberOfEntries={numberOfEntries}
                            index={index}
                            dataPoint={dayLogs}
                            searchValue={searchValue}
                            changeSelectedStopps={props.changeSelectedStopps}
                            selectedStopps={props.selectedStopps}
                            setAltLan={props.setAltLan}
                            hoverdPoint={props.hoverdPoint}
                            setAddress={props.setAddress}
                            setData={props.setData}
                        />
                    ))}
            </List>
            {!props.showSuggestion && numberOfEntries < props.dayLogsFromTour.length && (
                <Grid xs={12} textAlign='center'>
                    <Button variant='text' color='secondary' onClick={handleClick}>
                        {t('showMore')}
                    </Button>
                </Grid>
            )}
        </>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { FormHelperText, MenuItem } from '@mui/material';
import { compareArrayOfReasons, GetReasonsMenu } from '../../components/other/ConfigBasic';
import { useOutletContext } from 'react-router-dom';

export default function DialogAddLogbookReason(props) {
    const { t } = useTranslation();
    const currentUserId = useOutletContext().loginUser.id;
    const logbookId = useOutletContext().logbookId;
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState('');
    const [shared, setShared] = useState(t('all'));
    const [validationErrors, setValidationErrors] = useState({ reason: false });

    const handleSave = () => {
        if (formValidation()) {
            const newReason = createReason();
            setOpen(false);
            setReason('');
            setShared(t('all'));

            //Reasons neu laden, falls ein anderer User bereits Änderungen vorgenommen hat.
            props.getReasons({
                variables: {
                    id: logbookId,
                },
                onCompleted: (data) => {
                    let loadedReasons = data.logbooks[0].reasons.map((reason) => {
                        return {
                            label: reason.label,
                            idPerson: reason.idPerson,
                        };
                    });

                    //Eventuell vorhandene Duplikate entfernen
                    let uniqueReasons = loadedReasons.reduce((accumulator, current) => {
                        if (!accumulator.find((item) => item.label === current.label && item.idPerson === current.idPerson)) {
                            accumulator.push(current);
                        }
                        return accumulator;
                    }, []);

                    //Prüfen, ob der neue Reasons bereits im Array existiert
                    let duplicate = uniqueReasons.find((reason) => reason.label === newReason.label && reason.idPerson === newReason.idPerson);
                    if (duplicate === undefined) {
                        uniqueReasons.push(newReason);
                        props.changeLogbookReasons(uniqueReasons);
                    } else if (loadedReasons.length !== uniqueReasons.length) {
                        props.changeLogbookReasons(uniqueReasons);
                    } else {
                        props.setMessage(t('reasonAlreadyExists'));
                        props.setLogbookReasons(uniqueReasons.sort(compareArrayOfReasons));
                    }
                },
                onError: (error) => {
                    props.setMessage(error.graphQLErrors[0].message);
                },
            });
        }
    };

    const createReason = () => {
        return {
            label: reason,
            idPerson: shared === t('all') ? null : currentUserId,
        };
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const handleSharedChange = (event) => {
        setShared(event.target.value);
    };

    const formValidation = () => {
        let validationIsSuccessful = true;
        let validationErrors = {
            reason: false,
        };

        if (reason.length === 0) {
            validationErrors.reason = true;
            validationIsSuccessful = false;
        }

        setValidationErrors(validationErrors);

        return validationIsSuccessful;
    };

    return (
        <>
            <AddIcon color='primary' cursor='pointer' onClick={() => setOpen(true)} />
            <Dialog open={open}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <DialogTitle sx={{padding: '24px 24px 16px 24px'}}>{t('createReason')}</DialogTitle>
                <DialogContent sx={{padding:'0px 24px 0px 24px'}}>
                    <Box sx={{ padding: '5px 0', maxWidth: '296px', display: 'flex' }}>
                        <FormControl error={validationErrors.reason} sx={{ minWidth: '152px', paddingRight: '8px', flexFlow: 1 }}>
                            <TextField error={validationErrors.reason} label={t('reason')} variant='outlined' value={reason} onChange={(event) => handleReasonChange(event)} />
                            {validationErrors.reason && <FormHelperText>{t('mandatoryField')}</FormHelperText>}
                        </FormControl>

                        <FormControl sx={{ width: '140px' }}>
                            <TextField select label={t('sharedWith')} variant='outlined' value={shared} onChange={(event) => handleSharedChange(event)}>
                                {GetReasonsMenu().map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ height: '88px',padding: '24px'}}>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important'}} color='secondary' onClick={() => handleCancel()}>
                        {t('cancel')}
                    </Button>
                    <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important'}} color='success' onClick={() => handleSave()}>
                        {t('create')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

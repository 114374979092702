import Table from '../../components/Tour/ToursTable';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

export default function History() {
    return (
        <Box className='content' sx={{ flexGrow: 1, mt: '16px' }}>
            <Grid container spacing={2}>
                <Grid xs={12} md={3} xl={2} mdOffset={4} xlOffset={5}></Grid>
                <Grid xs={12} md={12} xl={12}>
                    <Table />
                </Grid>
            </Grid>
        </Box>
    );
}

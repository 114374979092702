import * as React from 'react';
import { ListItemText, List, Button, ListItem, Menu, ListItemButton, Box, InputBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function CostTableFilter(props) {
    const { allLogbooksData, selectedLogbooks, setSelectedLogbooks } = props;
    const [logbookList, setLogbookList] = useState(allLogbooksData);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setLogbookList(allLogbooksData);
    }, [allLogbooksData]);

    const filterLogbooks = (event) => {
        const searchValue = event.target.value.toLowerCase();
        if (searchValue.length === 0) {
            setLogbookList(allLogbooksData);
        } else {
            const filteredLogbooks = allLogbooksData.filter((logbook) => {
                const labelLowerCase = logbook.name?.toLowerCase();
                return labelLowerCase.includes(searchValue);
            });
            setLogbookList(filteredLogbooks);
        }
    };

    const handleClose = () => {
        setLogbookList(allLogbooksData);
        setAnchorEl(null);
    };

    const handleSelectItem = (event, logbook) => {
        if (logbook === t('allLogbooks')) {
            setSelectedLogbooks([]);
        } else if (!selectedLogbooks.includes(logbook)) {
            setSelectedLogbooks([...selectedLogbooks, logbook]);
        }
    };

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getSelectedLogbookLable = () => {
        let label = '';
        if (selectedLogbooks.length === 1) {
            label = selectedLogbooks[0].name;
        } else if (selectedLogbooks.length > 1) {
            label = selectedLogbooks.length + ' ' + t('logbooks');
        } else {
            label = t('allLogbooks');
        }
        return label;
    };

    return (
        <>
            <Button
                variant='none'
                color='primary'
                id='logbook-button'
                aria-controls={open ? 'logbook-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleButtonClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                    fontSize: '14px',
                    borderRadius: '12px',
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    textTransform: 'capitalize !important',
                    height: '35px',
                    marginLeft: '24px !important',
                }}
            >
                {getSelectedLogbookLable()}
            </Button>
            <Menu
                id='logbook-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'logbook-button',
                    role: 'listbox',
                }}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    padding='0 8px'
                    border={`solid 1px gray`}
                    borderRadius='4px'
                    marginBottom='8px'
                    marginLeft='8px'
                    marginRight='8px'
                >
                    <InputBase
                        placeholder={t('searchLogbook')}
                        onChange={(value) => {
                            filterLogbooks(value);
                        }}
                    />
                    <SearchIcon sx={{ color: '#00360F' }} />
                </Box>
                <List dense sx={{ height: '200px', overflow: 'scroll', overflowX: 'auto' }}>
                    <ListItem
                        sx={{ backgroundColor: selectedLogbooks.includes(t('allLogbooks')) ? '#f1f1f1' : 'transparent' }}
                        disablePadding
                        onClick={(event) => handleSelectItem(event, t('allLogbooks'))}
                    >
                        <ListItemButton
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                            }}
                        >
                            <ListItemText id={t('allLogbooks')} primary={t('allLogbooks')} />
                        </ListItemButton>
                    </ListItem>
                    {logbookList?.length > 0 &&
                        logbookList?.map((logbook) => {
                            return (
                                <ListItem
                                    sx={{ backgroundColor: selectedLogbooks.includes(logbook) ? '#f1f1f1' : 'transparent' }}
                                    key={logbook.id}
                                    id={logbook.id}
                                    value={logbook}
                                    disablePadding
                                    onClick={(event) => handleSelectItem(event, logbook)}
                                >
                                    <ListItemButton
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <ListItemText id={logbook.id} primary={logbook.name} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    {logbookList?.length === 0 && (
                        <ListItem>
                            <ListItemText>
                                <Typography variant='caption' color='#00360F'>
                                    {t('noResult')}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </Menu>
        </>
    );
}

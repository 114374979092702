import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { useGetFilteredModifidedTours } from '../../api/LogbookApi';
import NoTours from '../other/NoTours';
import Moment from 'moment';
import DatePickerModifiedTours from '../ui/DatePickerModifiedTours';
import { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { Card, CardContent, useMediaQuery } from '@mui/material';
import ExportComp from './ExportComp';
import { useTranslation } from 'react-i18next';
import { GetTourTypes, getBreakPoints, getNumberWithThousandSeparator } from '../other/ConfigBasic';
import ToursTableMobile from './ToursTableMobile';

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'streetBegin' && a[orderBy] === null && b[orderBy] === null) {
        orderBy = 'postalBegin';
    }
    if (orderBy === 'streetEnd' && a[orderBy] === null && b[orderBy] === null) {
        orderBy = 'postalEnd';
    }
    if (a[orderBy] === null) {
        return -1;
    }
    if (b[orderBy] === null) {
        return 1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const subtractWeeks = () => {
    let date = new Date();
    date.setDate(date.getDate() - 14);
    return date;
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (translations) => {
    return [
        {
            id: 'dtEdited',
            numeric: true,
            disablePadding: false,
            label: translations.dtEdited,
        },
        {
            id: 'dtBegin',
            numeric: false,
            disablePadding: false,
            label: translations.dtBegin,
        },
        {
            id: 'streetBegin',
            numeric: false,
            disablePadding: false,
            label: translations.streetBegin,
        },
        {
            id: 'mileageBegin',
            numeric: false,
            disablePadding: false,
            label: translations.mileageBegin,
        },
        {
            id: 'dtEnd',
            numeric: false,
            disablePadding: false,
            label: translations.dtEnd,
        },
        {
            id: 'streetEnd',
            numeric: false,
            disablePadding: false,
            label: translations.streetEnd,
        },
        {
            id: 'mileageEnd',
            numeric: false,
            disablePadding: false,
            label: translations.mileageEnd,
        },
        {
            id: 'type',
            numeric: false,
            disablePadding: false,
            label: translations.type,
        },
        {
            id: 'contactName',
            numeric: false,
            disablePadding: false,
            label: translations.contactName,
        },
        {
            id: 'driver',
            numeric: false,
            disablePadding: false,
            label: translations.driver,
        },
        {
            id: 'distance',
            numeric: true,
            disablePadding: false,
            label: translations.distance,
        },
    ];
};

function ToursTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const { t } = useTranslation();

    const headCellsTranslations = () => {
        return {
            dtEdited: t('editedOn'),
            dtBegin: t('start'),
            streetBegin: t('from'),
            mileageBegin: t('mileageBegin'),
            dtEnd: t('end'),
            streetEnd: t('to'),
            mileageEnd: t('mileageEnd'),
            type: t('typeOfTour'),
            contactName: t('visitedContact'),
            driver: t('driver'),
            distance: t('distance'),
        };
    };

    return (
        <TableHead>
            <TableRow>
                {headCells(headCellsTranslations()).map((headCell) => (
                    <TableCell key={headCell.id} align='center' padding='normal' sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ToursTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function ToursTable() {
    const types = GetTourTypes();
    const { t } = useTranslation();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('dtEdited');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dateFilter, setDateFilter] = useState({
        dateFrom: localStorage.getItem('editedDateFrom') !== null ? new Date(localStorage.getItem('editedDateFrom')) : subtractWeeks(),
        dateUntil: localStorage.getItem('editedDateUntil') !== null ? new Date(localStorage.getItem('editedDateUntil')) : new Date(),
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const client = useApolloClient();
    const currentLogbook = useOutletContext().logbookId;
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [scrollDown, setScrollDown] = useState(false);

    const customCss = {
        width: { xs: '100%', lg: 'max-content' },
        margin: 'auto',
        textAlign: 'center',
    };

    useEffect(() => {
        if (location.state !== null && location.state.snackbarMessage !== null) {
            setMessage(location.state.snackbarMessage);
            navigate(location.pathname);
            client.refetchQueries({
                include: ['getModifiedTours', 'getOpenToursInTimeRange'],
            });
        }
    }, [navigate, location, message, client]);

    let modifiedTourList = useGetFilteredModifidedTours(currentLogbook, dateFilter);

    if (!modifiedTourList) {
        return (
            <>
                <Box sx={customCss}>
                    <DatePickerModifiedTours dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Box>
            </>
        );
    }

    if (modifiedTourList.length === 0) {
        return (
            <>
                <Box sx={customCss}>
                    <DatePickerModifiedTours dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Box>
                <Box sx={{ width: '100%', paddingTop: '32px', m: 0 }}>
                    <NoTours md={5} mdOffset={0} />
                </Box>
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRowClick = (event, id) => {
        navigate('/edit/' + id);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getRowStyle = (status) => {
        if (status === 'OUTDATED') {
            return {
                textDecoration: 'line-through',
            };
        }
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - modifiedTourList.length) : 0;

    const getTranslation = (key) => {
        let translation = '';
        switch (key) {
            case types.BUSINESS:
                translation = t('business');
                break;
            case types.COMMUTE:
                translation = t('commute');
                break;
            case types.OTHER_INCOME:
                translation = t('otherIncome');
                break;
            case types.PRIVATE:
                translation = t('privateTour');
                break;
            case types.TWO_HOUSEHOLDS:
                translation = t('twoHouseholds');
                break;
            default:
                break;
        }
        return translation;
    };

    return (
        <>
            <Box sx={customCss}>
                <DatePickerModifiedTours dateFilter={dateFilter} setDateFilter={setDateFilter} />
            </Box>
            {desktop900 && (
                <>
                    <Box sx={{ width: '100%', marginBottom: '40px' }} mt={2}>
                        <Card variant='outlined'>
                            <CardContent>
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
                                        <ToursTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={modifiedTourList.length} />
                                        <TableBody>
                                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                rows.slice().sort(getComparator(order, orderBy)) */}
                                            {stableSort(modifiedTourList, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            style={getRowStyle(row.status)}
                                                            hover
                                                            onClick={(event) => handleRowClick(event, row.id)}
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell component='th' id={labelId} scope='row' align='left' padding='normal'>
                                                                {Moment(row.dtEdited).format('DD.MM.yyyy')}
                                                                <br />
                                                                {Moment(row.dtEdited).format('HH:mm:ss')}
                                                            </TableCell>
                                                            <TableCell align='left'>
                                                                {Moment(row.dtBegin).format('DD.MM.yyyy')}
                                                                <br />
                                                                {Moment(row.dtBegin).format('HH:mm:ss')}
                                                            </TableCell>
                                                            <TableCell align='left'>
                                                                {row.streetBegin ? row.streetBegin : ''}
                                                                <br />
                                                                {row.postalBegin} {row.cityBegin}
                                                            </TableCell>
                                                            <TableCell align='left'>
                                                                {row.mileageBegin === null ? 'n.a.' : getNumberWithThousandSeparator(row.mileageBegin / 1000, 0)}
                                                            </TableCell>
                                                            <TableCell align='left'>
                                                                {Moment(row.dtEnd).format('DD.MM.yyyy')}
                                                                <br />
                                                                {Moment(row.dtEnd).format('HH:mm:ss')}
                                                            </TableCell>
                                                            <TableCell align='left'>
                                                                {row.streetEnd ? row.streetEnd : ''}
                                                                <br />
                                                                {row.postalEnd} {row.cityEnd}
                                                            </TableCell>
                                                            <TableCell align='left'>
                                                                {row.mileageEnd === null ? 'n.a.' : getNumberWithThousandSeparator(row.mileageEnd / 1000, 0)}
                                                            </TableCell>
                                                            <TableCell align='left'>{getTranslation(row.type)}</TableCell>
                                                            <TableCell align='left'>{row.contactName}</TableCell>
                                                            <TableCell align='left'>{row.driver}</TableCell>
                                                            <TableCell align='right'>
                                                                {row.distance === null ? 'n.a.' : (row.distance / 1000).toFixed(2).replace('.', ',')}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: 53 * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component='div'
                                    count={modifiedTourList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={(page) => `${page.from} – ${page.to} ${t('of')} ${page.count}`}
                                    labelRowsPerPage={t('rowsPerPage')}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                </>
            )}
            {!desktop900 && <ToursTableMobile modifiedTourList={modifiedTourList} order={order} setOrder={setOrder} setScrollDown={setScrollDown} getRowStyle={getRowStyle} />}
            <ExportComp logbookId={currentLogbook} datesFilter={dateFilter} scrollDown={scrollDown} mobile={!desktop900} />
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

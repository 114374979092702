import React from 'react';

function ArchiveSvg() {
    return (
        <svg id="archive_black_24dp_1_" data-name="archive_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" viewBox="0 0 24 24">
        <path id="Pfad_3590" data-name="Pfad 3590" fill='None' d="M0,0H24V24H0Z"/>
        <path id="Pfad_3591" data-name="Pfad 3591" fill='#212121' 
            d="M20.54,5.23,19.15,3.55A1.451,1.451,0,0,0,18,3H6a1.486,1.486,0,0,0-1.16.55L3.46,5.23A1.958,1.958,0,0,0,3,6.5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6.5A1.958,1.958,0,0,0,20.54,5.23ZM6.24,5H17.76l.81.97H5.44L6.24,5ZM5,19V8H19V19Zm8.45-9h-2.9v3H8l4,4,4-4H13.45Z"
        />
      </svg>
    );
}

export default ArchiveSvg;

export const useTypesUnites = () => {
    const typesUnit = (type) => {
        switch (type) {
            case 'FUEL_DIESEL':
                return 'l';
            case 'FUEL_GAS':
                return 'l';
            case 'FUEL_ELECTRICITY':
                return 'kwh';
            case 'FUEL_LPG':
                return 'l';
            case 'FUEL_CNG':
                return 'kg';
            case 'FUEL_H2':
                return 'kg';
            case 'FUEL_ADBLUE':
                return 'l';
            default:
                return '';
        }
    };

    return typesUnit;
};

import { CardActions, CardMedia } from '@mui/material';

import CardContent from '@mui/material/CardContent';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import OnBoardingSvg from '../other/OnBoardingSVG.svg'

const OnboardingCard = ({ state, setState, setShowStep, handleComplete }) => {
    const { t } = useTranslation();



    return (
        <>
            {state &&
                <div style={{
                    position: 'absolute',
                    inset: '0',
                    backdropFilter: 'blur(10px)',
                    zIndex: '999999',
                    overflow: 'hidden',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}>
                    <div
                        style={{
                            maxWidth: '328px',
                            marginInline: 'auto',
                            marginTop: '10%',
                            borderRadius: '28px',
                            padding: '12px',
                            position: 'absolute',
                            zIndex: 100,
                            left: '40%',
                            top: '10%',
                            backgroundColor: '#B9F6CA',
                        }}
                    >

                        <CardMedia component="img"
                            sx={{
                                width: '136px',
                                aspectRatio: 3 / 2,
                                m: '20px auto'
                            }}
                            image={OnBoardingSvg}
                            alt="onboarding dialog" />

                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                {t('welcomToFB')}
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                {t('guideText')}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ display: 'flex', justifyContent: 'space-around' }} >
                            <Button
                                sx={{
                                    color: '#757575',
                                    padding: '6px 12px'
                                }}
                                onClick={() => {
                                    setState(false)
                                    setShowStep(false)
                                    handleComplete()
                                }}
                            >
                                {t('skip')}
                            </Button>
                            <Button
                                sx={{ padding: '6px 12px' }}
                                onClick={() => {
                                    setShowStep(true)
                                    setState(false)
                                }}
                            >
                                {t('letsgo')}
                            </Button>
                        </CardActions>

                    </div>
                </div >}

        </>
    );
};

export default OnboardingCard;

import * as React from 'react';
import { ListItemText, List, Button, ListItem, Menu, ListItemButton, Box, InputBase, ListItemAvatar, Avatar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getFullname, getLetterAvatar } from '../other/ConfigBasic';
import theme from '../../themes/theme';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function AddUserFilter(props) {
    const { selectedUser, setSelectedUser, onFilterChange, contactPersonData, emptyListMessage, showEmptyListAction, emptyListActionMessage } = props;

    const [userList, setUserList] = useState(contactPersonData);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setUserList(contactPersonData);
    }, [contactPersonData]);

    const filterListFunction = (event) => {
        const searchValue = event.target.value;
        if (searchValue.length === 0) {
            setUserList(contactPersonData);
        } else {
            setUserList(
                contactPersonData.filter(
                    (user) => user?.firstname?.toLowerCase().includes(searchValue.toLowerCase()) || user?.lastname?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    };

    const sortUserData = (userList) => {
        userList = [...userList];
        userList.sort((a, b) => {
            if (a.firstname?.toLocaleUpperCase() < b.firstname?.toLocaleUpperCase() || b.firstname === null) {
                return -1;
            } else if (a.firstname?.toLocaleUpperCase() > b.firstname?.toLocaleUpperCase() || a.firstname === null) {
                return 1;
            } else {
                return 0;
            }
        });
        return userList;
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getUserName = () => {
        if (selectedUser !== '' && selectedUser !== null) {
            const userFullname = contactPersonData.filter((user) => user.id === selectedUser);
            return getFullname(userFullname[0]);
        }
    };

    const handleSelectItem = (event, name) => {
        setAnchorEl(null);
        setSelectedUser(event.currentTarget.id);
        onFilterChange({ name: 'User', value: event.currentTarget.id });
    };

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleRemove = () => {
        setSelectedUser('');
        onFilterChange({ name: 'User', value: '' });
    };

    return (
        <>
            <Button
                variant='none'
                color='primary'
                id='user-button'
                aria-controls={open ? 'usver-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={selectedUser === '' || selectedUser === null ? handleButtonClick : null}
                endIcon={selectedUser === '' || selectedUser === null ? <ArrowDropDownIcon /> : <ClearIcon onClick={handleRemove} />}
                sx={{
                    fontSize: '14px',
                    borderRadius: '10px',
                    border:selectedUser === '' || selectedUser === null ? '1px solid #757575' : '',
                    textTransform: 'capitalize !important',
                    backgroundColor: selectedUser === '' || selectedUser === null ? '': '#B9F6CA'
                }}
            >
                {selectedUser === '' || selectedUser === null ? t('user') : getUserName()}
            </Button>
            <Menu
                id='user-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'user-button',
                    role: 'listbox',
                }}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    padding='0 8px'
                    border={`solid 1px ${theme.palette.grey[600]}`}
                    borderRadius='4px'
                    marginBottom='8px'
                >
                    <InputBase placeholder={t('search')} onChange={(value) => filterListFunction(value)} />
                    <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                </Box>
                <List dense sx={{ height: '200px', overflow: 'scroll', overflowX: 'hidden' }}>
                    {userList?.length > 0 &&
                        sortUserData(userList)?.map((data) => {
                            return (
                                <ListItem key={data.id} id={data.id} value={getFullname(data)} disablePadding onClick={(event) => handleSelectItem(event, getFullname(data))}>
                                    <ListItemButton sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={getFullname(data)}
                                                src={data.imageUri ? `${process.env.REACT_APP_AWS_CONTACT_PATH}${data.imageUri}` : ''}
                                                sx={{ color: '#212121', fontSize: '14px', backgroundColor: '#E0E0E0' }}
                                            >
                                                {getLetterAvatar(data)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText id={data.id} primary={getFullname(data)} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    {userList?.length === 0 && (
                        <ListItem>
                            <ListItemText>
                                <Typography variant='caption' color='gray'>
                                    {emptyListMessage}
                                </Typography>
                                {showEmptyListAction && (
                                    <Typography variant='h4' color='#00360F' sx={{ mt: '40px' }}>
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            variant='subtitle1'
                                            style={{
                                                color: '#00360F',
                                                textDecoration: 'none',
                                            }}
                                            href={process.env.REACT_APP_ADMIN_CONTACTS}
                                        >
                                            {emptyListActionMessage}
                                        </a>
                                    </Typography>
                                )}
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </Menu>
        </>
    );
}

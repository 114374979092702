import { Box, Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

const AccessTable = (props) => {
    const { rows = [], t, onDelete, team, setTeam } = props;
    const currentUser = useOutletContext().loginUser;
    const [showDeleteTeam, setShowDeleteTeam] = useState(false)

    const [buttonVisibility, setButtonVisibility] = useState({});
    const handleMouseEnter = (id) => {
        setButtonVisibility((prevState) => ({ ...prevState, [id]: true }));
    };

    const handleMouseLeave = (id) => {
        setButtonVisibility((prevState) => ({ ...prevState, [id]: false }));
    };

    const handleButtonClick = (id) => {
        onDelete(id);
        setButtonVisibility((prevState) => ({ ...prevState, [id]: false }));
    };

    return (
        <TableContainer sx={{ overflowY: 'scroll', height: '90%' }}>
            <Table
                sx={{
                    '.css-e0unlp-MuiTableCell-root': {
                        border: 'none',
                    },
                    '.css-4f9san-MuiTableCell-root': {
                        border: 'none',
                    },
                }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {currentUser.firstname !== null ? currentUser.firstname : ''}{' '}
                            {currentUser.lastname !== null ? currentUser.lastname : ''}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>{t('creator')}</TableCell>
                    </TableRow>
                    {rows.length > 0 &&
                        rows.map((row) => (
                            <TableRow
                                key={row.id}
                                hover
                                onMouseEnter={() => handleMouseEnter(row.id)}
                                onMouseLeave={() => handleMouseLeave(row.id)}
                                sx={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>{row.role}</TableCell>
                                {buttonVisibility[row.id] && (
                                    <Box
                                        component='td'
                                        sx={{
                                            position: 'absolute',
                                            paddingRight: '16px',
                                            right: '0',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            backgroundColor: '#f5f5f5',
                                        }}
                                    >
                                        <Button
                                            variant='outlined'
                                            color='error'
                                            sx={{ marginLeft: '8px' }}
                                            onClick={() => {
                                                handleButtonClick(row.id);
                                            }}
                                        >
                                            {t('delete')}
                                        </Button>
                                    </Box>
                                )}
                            </TableRow>
                        ))}

                    {Object.keys(team).length > 0 && <TableRow
                        sx={{
                            position: 'relative',
                            cursor: 'pointer',
                        }}
                        onMouseEnter={() => setShowDeleteTeam(true)}
                        onMouseLeave={() => setShowDeleteTeam(false)}
                        hover
                    >
                        <TableCell>
                            Team
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>{team.role}</TableCell>
                        {showDeleteTeam && (
                            <Box
                                component='td'
                                sx={{
                                    position: 'absolute',
                                    paddingRight: '16px',
                                    right: '0',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: '#f5f5f5',
                                }}
                            >
                                <Button
                                    variant='outlined'
                                    color='error'
                                    sx={{ marginLeft: '8px' }}
                                    onClick={() => setTeam({})}
                                >
                                    {t('delete')}
                                </Button>
                            </Box>
                        )}
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AccessTable;

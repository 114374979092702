import * as React from 'react';
import { ListItemText, List, Button, ListItem, Menu, ListItemButton, Box, InputBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function TypeFilter(props) {
    const { id, types, selectedType, setSelectedType } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectionList, setSelectionList] = useState([]);

    useEffect(() => {
        setSelectionList(types);
    }, [types]);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSelectionList(types);
        setAnchorEl(null);
    };

    const handleListItemClick = (selection) => {
        setSelectedType(selection);
        handleClose();
    };

    const filterType = (event) => {
        const searchValue = event.target.value.toLowerCase();
        if (searchValue.length === 0) {
            setSelectionList(types);
        } else {
            setSelectionList(
                types.filter((type) => {
                    let result = undefined;
                    if (id === 'costType' || type === 'all') {
                        result = t(type.toLowerCase()).toLowerCase().includes(searchValue);
                    } else {
                        result = type.toLowerCase().includes(searchValue);
                    }
                    return result;
                })
            );
        }
    };

    return (
        <>
            <Button
                variant='none'
                color='primary'
                id={`${id}-button`}
                aria-controls={open ? `${id}-menu` : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={(event) => handleButtonClick(event)}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                    fontSize: '14px',
                    borderRadius: '12px',
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    textTransform: 'capitalize !important',
                    height: '35px',
                }}
            >
                {id === 'costType' ? (selectedType.length === 0 ? t(id) : t(selectedType[0].toLowerCase())) : selectedType.length === 0 ? t(id) : selectedType[0]}
            </Button>
            <Menu
                id={`${id}-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{
                    'aria-labelledby': `${id}-button`,
                    role: 'listbox',
                }}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    padding='0 8px'
                    border={`solid 1px gray`}
                    borderRadius='4px'
                    marginBottom='8px'
                    marginLeft='8px'
                    marginRight='8px'
                >
                    <InputBase
                        placeholder={t(id)}
                        onChange={(value) => {
                            filterType(value);
                        }}
                    />
                    <SearchIcon sx={{ color: '#00360F' }} />
                </Box>
                <List dense sx={{ height: '200px', overflow: 'scroll', overflowX: 'auto' }}>
                    {selectionList?.length > 0 &&
                        selectionList.map((selection) => {
                            return (
                                <ListItem
                                    sx={{ backgroundColor: selectedType.includes(selection) ? '#f1f1f1' : 'transparent' }}
                                    key={selection}
                                    disablePadding
                                    onClick={() => handleListItemClick(selection === 'all' ? [] : [selection])}
                                >
                                    <ListItemButton
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <ListItemText>
                                            {id === 'costType' ? t(selection?.toLowerCase()) : selection === 'all' ? t(selection?.toLowerCase()) : selection}
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}

                    {selectionList?.length === 0 && (
                        <ListItem>
                            <ListItemText>
                                <Typography variant='caption' color='#00360F'>
                                    {t('noResult')}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </Menu>
        </>
    );
}

import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Chip } from '@mui/material';
import SharedMenu from './SharedMenu';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compareArrayOfReasons } from '../../components/other/ConfigBasic';

export default function LogbookReasonsListItem(props) {
    const { t } = useTranslation();
    const reason = props.reason;
    const logbookId = useOutletContext().logbookId;

    const handleDelete = (deletableReason) => {
        //Reasons neu laden, falls ein anderer User bereits Änderungen vorgenommen hat. Vll muss ich hier mit LazyLoading arbeiten
        props.getReasons({
            variables: {
                id: logbookId,
            },
            onCompleted: (data) => {
                let loadedReasons = data.logbooks[0].reasons.map((reason) => {
                    return {
                        label: reason.label,
                        idPerson: reason.idPerson,
                    };
                });

                //Eventuell vorhandene Duplikate entfernen
                let uniqueReasons = loadedReasons.reduce((accumulator, current) => {
                    if (!accumulator.find((item) => item.label === current.label && item.idPerson === current.idPerson)) {
                        accumulator.push(current);
                    }
                    return accumulator;
                }, []);

                //Reason der gelöscht werden soll aus dem neu geladenem, um duplikate geprüftem Array entfernen
                let newReasons = uniqueReasons.filter((reason) => reason.label !== deletableReason.label || reason.idPerson !== deletableReason.idPerson);
                if (newReasons.length === loadedReasons.length) {
                    props.setMessage(t('reasonAlreadyDeleted'));
                    props.setLogbookReasons(newReasons.sort(compareArrayOfReasons));
                } else {
                    props.changeLogbookReasons(newReasons);
                }
            },
            onError: (error) => {
                props.setMessage(error.graphQLErrors[0].message);
            },
        });
    };

    return (
        <>
            <Grid xs={6} md={6} textAlign='left'>
                <Chip label={reason.label} onDelete={() => handleDelete(reason)} />
            </Grid>

            <Grid xs={6} md={6} textAlign='right'>
                <SharedMenu
                    currentReason={reason}
                    setLogbookReasons={props.setLogbookReasons}
                    getReasons={props.getReasons}
                    setMessage={props.setMessage}
                    changeLogbookReasons={props.changeLogbookReasons}
                />
            </Grid>
        </>
    );
}

import { Logout } from '../components/auth/Logout';

export const errorHandling = (errorCode) => {
    switch (errorCode) {
        case 'B1009':
            Logout();
            break;
        default:
            console.log(`Untreated Errorcode: ${errorCode}`);
    }
};

import * as React from 'react';
import Check from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GetStatusMenu } from '../../components/other/ConfigBasic';
import { Menu, ListItemText, MenuItem, Typography, ListItemIcon, Button } from '@mui/material';
import { useState } from 'react';

export default function AddStatusFilter(props) {
    const { selectedStatus, setSelectedStatus, onFilterChange, selectedIndex } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const menuItemStyle = {
        display: 'flex',
        color: '#212121',
        fontSize: '14px',
        fontFamily: 'CircularProMedium',
        alignItems: 'center',
        height: '100%',
    };

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (key, value, index) => {
        setAnchorEl(null);
        setSelectedStatus(value);
        onFilterChange({ name: 'Status', value: key, index: index });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant='none'
                color='primary'
                id='lock-button'
                aria-controls={open ? 'lock-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={(event) => handleButtonClick(event)}
                endIcon={<ArrowDropDownIcon />}
                sx={{ fontSize: '14px', borderRadius: '10px', border:selectedIndex !== 0 ? '' : '1px solid #757575', textTransform: 'capitalize !important', backgroundColor: selectedIndex !== 0 ? '#B9F6CA' : '' }}
            >
                {GetStatusMenu()?.get(selectedStatus)?.split(' ')[0]}
            </Button>
            <Menu
                id='lock-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {Array.from(GetStatusMenu()).map(([key, value], index) => (
                    <MenuItem key={value} value={value} onClick={(event) => handleMenuItemClick(key, value, index)}>
                        {index === selectedIndex && (
                            <>
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                                <ListItemText primary={<Typography sx={menuItemStyle}>{value}</Typography>}></ListItemText>
                            </>
                        )}
                        {index !== selectedIndex && <ListItemText sx={{ paddingLeft: '36px' }} primary={<Typography sx={menuItemStyle}>{value}</Typography>}></ListItemText>}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

// CreateLogBooksAccessMenu.js
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';

import { LogBooksRoles } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';

export default function CreateLogBooksAccessMenu(props) {
    const { selectedRole, updateRole, disabled } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getRoleId = (selectedRole) => {
        switch (selectedRole) {
            case t('chooseRole'):
                return null;
            case t('viewer'):
            case t('editor'):
            case t('admin'):
                return selectedRole;
            default:
                return null;
        }
    };

    const handleChange = (selectedRole) => {
        updateRole(getRoleId(selectedRole));
    };


    const handleMenuItemClick = (selectedRole) => {
        handleClose();
        handleChange(selectedRole);
    };

    const getMenuValue = (roleId) => {
        switch (roleId) {
            case t('viewer'):
            case t('editor'):
            case t('admin'):
                return roleId;
            default:
                return t('chooseRole');
        }
    };

    return (
        <div>
            <Button
                sx={{ fontSize: '14px', width: '100%' }}
                variant='outlined'
                color='primary'
                id='role-button'
                aria-controls={open ? 'role-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleButtonClick}
                endIcon={<KeyboardArrowDownIcon />}
                disabled={disabled}
            >
                {getMenuValue(selectedRole)}
            </Button>
            <Menu
                id='role-menu'
                sx={{ fontSize: '14px' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'role-button',
                    role: 'listbox',
                }}
            >
                {LogBooksRoles().map((option) => (
                    <MenuItem key={option} value={option} onClick={() => handleMenuItemClick(option)} sx={{ fontSize: '14px' }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

import * as React from 'react';
import { FormControl } from '@mui/material';
import { useState, useEffect } from 'react';
import { useContactIsDriverApi, useGetPersonsApi } from '../../api/LogbookApi';
import AddStatusFilter from '../admin/AddStatusFilter';
import AddDriverFilter from '../admin/AddDriverFilter';
import AddUserFilter from '../admin/AddUserFilter';
import SimpleSnackbar from '../ui/SimpleSnackbar';

export default function AdminTableFilterMenu(props) {
    const [message, setMessage] = useState('');
    let contactDriverData = useContactIsDriverApi(setMessage);
    const newPersonsData = useGetPersonsApi();
    const { logbookFilters, onFilterChange } = props;
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        logbookFilters.forEach((filter) => {
            switch (filter.name) {
                case 'Status':
                    setSelectedStatus(filter.value);
                    setSelectedIndex(filter.index);
                    break;
                case 'Driver':
                    setSelectedDriver(filter.value);
                    break;
                case 'User':
                    setSelectedUser(filter.value);
                    break;
                default:
                    console.log('Unknown filter');
            }
        });
    }, [logbookFilters]);

    useEffect(() => {
        if (logbookFilters.length === 0) {
            onFilterChange({ name: 'Status', value: 'active', index: 1 });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <FormControl sx={{ marginRight: '8px' }}>
                <AddStatusFilter onFilterChange={onFilterChange} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} selectedIndex={selectedIndex} />
            </FormControl>
            <FormControl sx={{ marginRight: '8px' }}>
                <AddDriverFilter contactDriverData={contactDriverData} selectedDriver={selectedDriver} setSelectedDriver={setSelectedDriver} onFilterChange={onFilterChange} />
            </FormControl>
            <FormControl sx={{ marginRight: '8px' }}>
                <AddUserFilter contactPersonData={newPersonsData} selectedUser={selectedUser} setSelectedUser={setSelectedUser} onFilterChange={onFilterChange} />
            </FormControl>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

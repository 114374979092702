import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import { ListItemText } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../themes/theme';

export default function CostFilter(props) {
    const { filterName, setFilterName } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (name) => {
        setAnchorEl(null);
        setFilterName(name);
    };

    return (
        <div>
            <Button
                variant='outlined'
                id='lock-button'
                aria-controls={open ? 'lock-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleButtonClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                    fontSize: '14px',
                    marginBottom: '16px',
                    borderColor: theme.palette.grey[600],
                    '&:hover': {
                        borderColor: theme.palette.grey[600],
                    },
                    borderRadius: '8px',
                    color: theme.palette.grey[900],
                    fontFamily: 'CircularProMedium',
                    textTransform: 'none',
                }}
            >
                {filterName}
            </Button>
            <Menu
                id='lock-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                <MenuItem onClick={() => handleMenuItemClick(t('active'))}>
                    <ListItemText>{t('active')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(t('deactivated'))}>
                    <ListItemText>{t('deactivated')}</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}

import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchAndAdd from './SearchAndAdd';
import DriversTable from './DriversTable';
import InfoIcon from '@mui/icons-material/Info';
import { useContactIsDriverApi, } from '../../api/LogbookApi';
import { useOutletContext } from 'react-router-dom';
import SearchAndAddAssets from './SearchAndAddAssets';
import AssetsTable from './AssetsTable';
import AccessTable from './AccessTable';
import SearchAndAddAccess from './SearchAndAddAccess';

const LogbooksAccordion = (props) => {
    const { setMessage, assetsWithOutLogbook, setDisableCreate, logBooks, setLogBooks, setUsersAccess, setDrivers, teamAccess, setTeamAccess } = props
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState('panel1');
    const [personName, setPersonName] = useState([]);
    const [openAssetsDialog, setOpenAssetsDialog] = useState(false)
    const [openDriversDialog, setOpenDriversDialog] = useState(false)
    const [assetsSelected, setAssetsSelected] = useState([]);
    const [initialPersonSet, setInitialPersonSet] = useState(false);
    const [driverSearch, setDriverSearch] = useState('')
    const [assetsSearch, setAssetsSearch] = useState('')

    const [userSearch, setUserSearch] = useState('')

    const [userData, setUserData] = useState([]);
    const [userSelected, setUserSelected] = useState([])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleAssetDelete = (id) => {
        setAssetsSelected((prevSelectedAssets) =>
            prevSelectedAssets.filter((asset) => asset.id !== id)
        );
        setLogBooks((prevLogBooks) =>
            prevLogBooks.filter((logBook) => logBook.assetId !== id)
        );
    };

    const handleDriverDelete = (id) => {
        setPersonName((prevSelectedAssets) =>
            prevSelectedAssets.filter((asset) => asset.id !== id)
        );
    };
    const handleUserAccessDelete = (id) => {
        setUserData((prevUserData) =>
            prevUserData.filter((user) => user.id !== id)
        );
        setUserSelected((prevUserSelected) =>
            prevUserSelected.filter((userId) => userId !== id)
        );
    };

    const filteredDrivers = personName.filter(
        (driver) =>
            driver.firstname?.toLowerCase().includes(driverSearch.toLowerCase()) ||
            driver.lastname?.toLowerCase().includes(driverSearch.toLowerCase())
    );

    const filteredData = assetsSelected?.filter((item) => {
        const carName = item.name.toLowerCase();
        const licensePlate = item.child.licensePlate ? item.child.licensePlate.toLowerCase() : '';
        return carName.includes(assetsSearch.toLowerCase()) || licensePlate.includes(assetsSearch.toLowerCase());
    });

    const updateUserData = (newUserData) => {
        setUserData(newUserData);
    };

    const accrodionStyle = {
        borderRadius: '24px',

        '&:before': {
            display: 'none',
        },

    };
    const titleStyle = {
        flexShrink: 0,
        fontSize: '14px',
        fontFamily: 'CircularProMedium',
    };

    let driverData = useContactIsDriverApi(setMessage);
    const loginUser = useOutletContext().loginUser;
    useEffect(() => {
        if (driverData && !initialPersonSet) {
            setPersonName(driverData.filter((driver) => driver.loginUuid === loginUser.loginUuid));
            setInitialPersonSet(true);
        }
    }, [driverData, loginUser.loginUuid, initialPersonSet, setInitialPersonSet]);

    driverData?.sort((a, b) => {
        if (a.firstname?.toLocaleUpperCase() < b.firstname?.toLocaleUpperCase() || b.firstname === null) {
            return -1;
        } else if (a.firstname?.toLocaleUpperCase() > b.firstname?.toLocaleUpperCase() || a.firstname === null) {
            return 1;
        } else {
            return 0;
        }
    });
    const filteredUsers = userData.filter(
        (user) =>
            user.name?.toLowerCase().includes(userSearch.toLowerCase())
    );

    const getArrays = useCallback(() => {
        setUsersAccess([...filteredUsers, {
            id: loginUser.id,
            role: t('admin')
        }])
        setDrivers(filteredDrivers.map(driver => driver.id))
        // eslint-disable-next-line
    }, [setUsersAccess, setDrivers, filteredDrivers.length, filteredUsers.length])

    useEffect(() => {
        if (filteredData.length > 0 && filteredDrivers.length > 0) {
            setDisableCreate(false)
        }
        getArrays()
    }, [filteredData.length, filteredDrivers.length, userData.length, setDisableCreate, getArrays])

    return (
        <Stack gap={2}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={accrodionStyle} square={!!'false'} defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                    <Typography sx={{ color: '#212121', marginRight: '20px', ...titleStyle }}>{t('vehicles')}</Typography>
                    <Stack direction={'row'} gap={1}>
                        <Typography sx={{ color: '#BDBDBD', ...titleStyle }}>{assetsSelected ? assetsSelected.length : 0}</Typography>
                        <Typography sx={{ color: '#BDBDBD', ...titleStyle }}>{t('selected')}</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ height: '350px', overflowY: 'hidden' }} >
                    <SearchAndAddAssets
                        setSelectedAssets={setAssetsSelected}
                        selectedAssets={assetsSelected}
                        initialPersonSet={false}
                        title={'selectVehicles'}
                        t={t}
                        assetsWithOutLogbook={assetsWithOutLogbook}
                        open={openAssetsDialog}
                        setOpen={setOpenAssetsDialog}
                        searchTerm={assetsSearch}
                        setSearchTerm={setAssetsSearch}
                    />
                    <AssetsTable
                        selectedAssets={assetsSelected}
                        rows={filteredData}
                        t={t} setOpen={setOpenAssetsDialog}
                        setMessage={setMessage}
                        onDelete={handleAssetDelete}
                        logBooks={logBooks}
                        setLogBooks={setLogBooks} />
                </AccordionDetails>
                <Stack direction='row' alignItems='center' gap={1} mx={4} my={2}>
                    <InfoIcon color='info' />
                    <Typography sx={{ fontSize: '14px', color: '#757575', fontFamily: 'CircularProBook' }}>{t('logbooksInfo')}</Typography>
                </Stack>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={accrodionStyle} square={!!'false'}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2bh-content' id='panel2bh-header'>
                    <Typography sx={{ color: '#212121', marginRight: '20px', ...titleStyle }}>{t('drivers')}</Typography>
                    <Stack direction={'row'} gap={1}>
                        <Typography sx={{ color: '#BDBDBD', ...titleStyle }}>{personName ? personName.length : 0}</Typography>
                        <Typography sx={{ color: '#BDBDBD', ...titleStyle }}>{t('selected')}</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ height: '350px', overflowY: 'hidden' }} >
                    <SearchAndAdd
                        t={t}
                        driverData={driverData}
                        personName={personName}
                        setPersonName={setPersonName}
                        initialPersonSet={initialPersonSet}
                        title={t('selectDriver')}
                        open={openDriversDialog}
                        setOpen={setOpenDriversDialog}
                        searchTerm={driverSearch}
                        setSearchTerm={setDriverSearch}
                    />
                    <DriversTable selectedPersons={personName} rows={filteredDrivers} t={t} setOpen={setOpenDriversDialog} setMessage={setMessage} onDelete={handleDriverDelete} />
                </AccordionDetails>
                <Stack direction='row' alignItems='center' gap={1} mx={4} my={2} />
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={accrodionStyle} square={!!'false'}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2bh-content' id='panel2bh-header'>
                    <Typography sx={{ color: '#212121', marginRight: '20px', ...titleStyle }}>{t('access')}</Typography>
                    <Stack direction={'row'} gap={1}>
                        <Typography sx={{ color: '#BDBDBD', ...titleStyle }}>{userData ? userData.length + 1 : 0}</Typography>
                        <Typography sx={{ color: '#BDBDBD', ...titleStyle }}>{t('selected')}</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ height: '350px', overflowY: 'hidden' }} >
                    <SearchAndAddAccess
                        t={t}
                        updateUserData={updateUserData}
                        searchTerm={userSearch}
                        setSearchTerm={setUserSearch}
                        userSelected={userSelected}
                        setUserSelected={setUserSelected}
                        teamAccess={teamAccess}
                        setTeamAccess={setTeamAccess}
                        userData={userData}
                    />

                    <AccessTable rows={filteredUsers} t={t} onDelete={handleUserAccessDelete} team={teamAccess} setTeam={setTeamAccess} />
                </AccordionDetails>
                <Stack direction='row' alignItems='center' gap={1} mx={4} my={2}>
                    <InfoIcon color='info' />
                    <Typography sx={{ fontSize: '14px', color: '#757575', fontFamily: 'CircularProBook' }}>{t('accessInfo')}</Typography>
                </Stack>
            </Accordion>
        </Stack>
    );
};

export default LogbooksAccordion;

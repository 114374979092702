import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Checkbox, CircularProgress, InputBase, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material';
import theme from '../../themes/theme';
import SearchIcon from '@mui/icons-material/Search';
import CreateLogBooksAccessMenu from './CreateLogBooksAccessMenu';
import AllAccessMenu from './AllAccessMenu';

import { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import AllUsersAccess from './AllUsersAccess';


export default function AccessDialog(props) {
    const { open, setOpen, title, userList, t, updateUserData, userSelected, setUserSelected, teamAccess, setTeamAccess, userData, accessChecked, indeterminate } = props;

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [checked, setChecked] = useState([]);
    const [userRoles, setUserRoles] = useState({});
    const [userSearch, setUserSearch] = useState('')
    const [tempSelected, setTempSelected] = useState([...checked]);
    const [tempTeamAccess, setTempTeamAccess] = useState({ ...teamAccess })

    useEffect(() => {
        setTempTeamAccess({ ...teamAccess })
    }, [teamAccess])

    useEffect(() => {
        setTempSelected(checked);
    }, [checked]);

    useEffect(() => {
        if (accessChecked) {
            setChecked(accessChecked)
        } else {

            setChecked(userSelected);
        }
    }, [userSelected, accessChecked]);

    useEffect(() => {
        setUserRoles(prevState => {
            const newState = { ...prevState };
            userData.forEach((user) => {
                newState[user.id] = user.role;
            });
            return newState;
        });
    }, [userData]);

    const handleClose = () => {
        setOpen(false);
        setUserSearch('');
        setTempSelected([...checked]);
        setUserRoles(prevState => {
            const newState = { ...prevState };
            userData.forEach(user => {
                newState[user.id] = user.role;
            });
            return newState;
        });

        setTempTeamAccess({ ...teamAccess })
    };

    const handleChangeAll = () => {
        if (checked.length < userList.length) {
            setChecked(userList.map((user) => user.id));
            setUserRoles({});
        } else {
            setChecked([]);
        }
    };

    const handleCheckboxChange = (userId) => {
        const updatedTempSelected = [...tempSelected];
        const index = updatedTempSelected.indexOf(userId);

        if (index === -1) {
            updatedTempSelected.push(userId);
            setUserRoles((prevRoles) => ({ ...prevRoles, [userId]: null }));
        } else {
            updatedTempSelected.splice(index, 1);
            setUserRoles((prevRoles) => {
                const { [userId]: removedRole, ...restRoles } = prevRoles;
                return restRoles;
            });
        }
        setTempSelected(updatedTempSelected);
    };
    const handleRoleChange = (users, role) => {
        setUserRoles((prevRoles) => {
            const updatedRoles = { ...prevRoles };

            if (Array.isArray(users)) {
                users.forEach((userId) => {
                    updatedRoles[userId] = role;
                });
            } else {
                updatedRoles[users] = role;
            }

            return updatedRoles;
        });
    };

    const getCombinedRoleForSelectedUsers = () => {
        const selectedRoles = tempSelected.map((userId) => userRoles[userId]);
        const uniqueRoles = Array.from(new Set(selectedRoles));

        if (uniqueRoles.length === 1) {
            return uniqueRoles[0];
        } else {
            return 'Various Roles';
        }
    };

    const handleSave = () => {
        const selectedUsers = userList.filter((user) => tempSelected.includes(user.id));
        const changedUserData = selectedUsers.map((user) => ({
            name: `${user.firstname !== null ? user.firstname : ''} ${user.lastname !== null ? user.lastname : ''}`,
            id: user.id,
            role: userRoles[user.id] || '',
        }));
        setChecked([...tempSelected]);
        updateUserData(changedUserData);
        setUserSearch('');
        setUserSelected([...tempSelected]);
        setOpen(false);
        setTeamAccess(tempTeamAccess)

    };

    const filteredUsers = userList?.filter(
        (user) =>
            (user.firstname && user.firstname.toLowerCase().includes(userSearch.toLowerCase())) || (user.lastname && user.lastname.toLowerCase().includes(userSearch.toLowerCase()))
    );
    const handleDisableSave = () => {
        if (tempSelected.length === Object.values(userRoles).length && (tempSelected.length !== 0 && Object.values(userRoles).every(role => role !== null))) {
            return false
        }

        if (tempTeamAccess.hasOwnProperty('role')) {
            return false
        }
        return true
    }
    return (
        <Dialog open={open}
            PaperProps={{
                style: {
                    borderRadius: '28px',
                },
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2, padding: '24px 24px 16px 24px' }} id='customized-dialog-title'>
                {title}
            </DialogTitle>
            <DialogContent sx={{ padding: '0px 24px 0px 24px', mb: 2 }}>
                <Typography color={theme.palette.grey[600]} sx={{ fontFamily: 'CircularProBook', fontSize: '12px' }}>{t('generalAccess')}</Typography>
                {/** All users Access */}
                <ListItem key={'imported-reason-list-item-all'} sx={{ width: isMobile ? '100% ' : '510px', }}>
                    <ListItemText
                        id={'imported-reason-list-label-all'}
                        primary={
                            <Typography
                                sx={{
                                    display: 'block',
                                    fontSize: '16px',
                                    color: '#212121',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                component='span'
                            >
                                {`${t('all')} ${t('users')}`}
                            </Typography>
                        }

                    />
                    <Box sx={{ width: '204px' }} >
                        <AllUsersAccess
                            teamRole={tempTeamAccess}
                            setTeamAccess={setTempTeamAccess}
                        />
                    </Box>
                </ListItem>

            </DialogContent>
            <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                <Typography color={theme.palette.grey[600]} sx={{ mb: 1, fontFamily: 'CircularProBook', fontSize: '12px' }}>{t('individualAccess')}</Typography>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    padding='0 8px'
                    border={`solid 1px ${theme.palette.grey[600]}`}
                    borderRadius='4px'
                    marginBottom='8px'
                >
                    <InputBase fullWidth placeholder={t('search')} onChange={(e) => setUserSearch(e.target.value)} value={userSearch} />
                    <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                </Box>
                <ListItem key={'imported-reason-list-item-all'} sx={{ width: isMobile ? '100% ' : '510px' }}>
                    <ListItemIcon sx={{ marginRight: '-16px' }}>
                        <Checkbox
                            edge='start'
                            checked={tempSelected.length === userList.length}
                            indeterminate={tempSelected.length > 0 && tempSelected.length < userList.length}
                            onClick={() => handleChangeAll()}
                        />
                    </ListItemIcon>
                    <ListItemText
                        id={'imported-reason-list-label-all'}
                        primary={
                            <Typography
                                sx={{
                                    display: 'block',
                                    fontSize: '16px',
                                    color: '#212121',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                component='span'
                            >
                                {`${t('all')} (${tempSelected.length})`}
                            </Typography>
                        }
                    />
                    <Box sx={{ width: '204px' }}>
                        <AllAccessMenu
                            selectedRole={getCombinedRoleForSelectedUsers()}
                            updateRole={(role) => handleRoleChange(tempSelected, role)}
                            extended={false} />
                    </Box>
                </ListItem>
                <List dense sx={{ height: '300px', overflow: 'auto', overflowX: 'hidden' }}>
                    {filteredUsers.length > 0 ? (
                        filteredUsers.map((user) => (
                            <ListItem key={user.id}>
                                <ListItemIcon sx={{ marginRight: '-16px' }}>
                                    <Checkbox edge='start'
                                        checked={tempSelected.includes(user.id)}
                                        onChange={() => handleCheckboxChange(user.id)}
                                        indeterminate={accessChecked ? indeterminate(user.id) : ''} />
                                </ListItemIcon>
                                <ListItemText>
                                    {user.firstname} {user.lastname}
                                </ListItemText>
                                <Box sx={{ width: '204px' }}>
                                    <CreateLogBooksAccessMenu
                                        selectedRole={userRoles[user.id]}
                                        updateRole={(role) => handleRoleChange(user.id, role)}
                                        extended={false}
                                        disabled={!tempSelected.includes(user.id)}
                                    />
                                </Box>
                            </ListItem>
                        ))
                    ) : filteredUsers.length === 0 && userSearch.length !== 0 ? (
                        <ListItem>
                            <ListItemText>
                                <Typography variant='caption' color='gray'>
                                    {t('noResult')}
                                </Typography>
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    variant='subtitle1'
                                    style={{
                                        color: '#00360F',
                                        textDecoration: 'none',
                                    }}
                                    href={process.env.REACT_APP_ADMIN_CONTACTS}
                                >
                                    <Typography variant='h4' color='#00360F' sx={{ mt: '40px' }}>
                                        {t('userDoseNotExist')}
                                    </Typography>
                                </a>
                            </ListItemText>
                        </ListItem>
                    ) : (
                        <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                            <CircularProgress />
                        </Stack>
                    )}
                </List>
            </DialogContent>
            <DialogActions sx={{ height: '88px', padding: '24px' }}>
                <Button variant='text' color='secondary' sx={{ height: '40px', marginBottom: '0px !important' }} onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Tooltip title={t('selectUserAndRole')} disabled={handleDisableSave()} placement='top-end'>
                    <span>
                        <Button variant='contained' color='success' sx={{ height: '40px', marginBottom: '0px !important' }} onClick={handleSave} disabled={handleDisableSave()}>
                            {t('save')}
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}

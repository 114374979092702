import * as React from 'react';
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../other/ConfigBasic';

export default function SimpleSnackbar(props) {
    const [open, setOpen] = useState(true);
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);

    const handleClose = () => {
        setOpen(false);
        props.setMessage('');
    };

    return <Snackbar sx={desktop900 ? {} : { position: 'fixed', bottom: '64px' }} open={open} autoHideDuration={5000} message={props.message} onClose={handleClose} />;
}

import { Stack, Box, Card, CardContent, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePickerDesktop from './components/DatePickerDesktop';
import moment from 'moment';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import LinearProgress from '@mui/material/LinearProgress';
import { useGetAllLogbooksApi, useGetAllReceipts, useGetToursWithCost } from '../../api/LogbookApi';
import CostTableFilter from './components/CostTableFilter';
import { DataGrid } from '@mui/x-data-grid';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';
import TypeFilter from './components/TypeFilter';

function AdminCostViewDesktop() {
    const [message, setMessage] = useState('');
    const [datum, setDatum] = useState(moment());
    let allLogbooksData = useGetAllLogbooksApi(setMessage);
    const { receipts } = useGetAllReceipts(datum);
    const { t } = useTranslation();
    const [selectedLogbooks, setSelectedLogbooks] = useState([]);
    const [selectedCostType, setSelectedCostType] = useState([]);
    const [selectedCostCentre, setSelectedCostCentre] = useState([]);
    const [selectedCostObject, setSelectedCostObject] = useState([]);
    const [costTypes, setCostTypes] = useState([]);
    const [costCentres, setCostCentres] = useState([]);
    const [costObjects, setCostObjects] = useState([]);
    const [rows, setRows] = useState(undefined);
    const [totalRow, setTotalRow] = useState(undefined);
    let toursWithCost = useGetToursWithCost(datum, setMessage);
    const ismobil = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (receipts !== undefined && toursWithCost !== undefined) {
            const newCostTypeSet = new Set();
            const newCostCentreSet = new Set();
            const newCostObjectSet = new Set();
            let filteredRows = [];
            receipts?.logbookReceipts?.forEach((row, index) => {
                newCostTypeSet.add(row?.articles[0]?.category, row?.articles[0]?.category);
                if (row?.articles[0]?.costCentre !== null) {
                    newCostCentreSet.add(row?.articles[0]?.costCentre[0], row?.articles[0]?.costCentre[0]);
                }
                if (row?.articles[0]?.costObject !== null) {
                    newCostObjectSet.add(row?.articles[0]?.costObject[0], row?.articles[0]?.costObject[0]);
                }

                filteredRows.push({
                    id: index,
                    date: row?.dtBilling,
                    logbook: allLogbooksData?.find((logbook) => logbook?.id === row?.idLogbook)?.name,
                    costType: row?.articles[0]?.category,
                    costCentre: row?.articles[0]?.costCentre !== null ? row?.articles[0]?.costCentre[0] : '-',
                    costObject: row?.articles[0]?.costObject !== null ? row?.articles[0]?.costObject[0] : '-',
                    receiptNo: row?.number,
                    distance: '-',
                    net: row?.netTotal,
                    vat: (row?.netTotal / 100) * row?.articles[0]?.vat,
                    total: row?.total,
                });
            });

            toursWithCost?.forEach((row, index) => {
                newCostTypeSet.add(row?.type, row?.type);
                if (row?.costCentre !== null) {
                    newCostCentreSet.add(row?.costCentre[0], row?.costCentre[0]);
                }
                if (row?.costObject !== null) {
                    newCostObjectSet.add(row?.costObject[0], row?.costObject[0]);
                }

                filteredRows.push({
                    id: filteredRows.length,
                    date: row?.dtBegin,
                    logbook: allLogbooksData?.find((logbook) => logbook?.id === row?.idLogbook)?.name,
                    costType: row?.type,
                    costCentre: row?.costCentre !== null ? row?.costCentre[0] : '-',
                    costObject: row?.costObject !== null ? row?.costObject[0] : '-',
                    receiptNo: '-',
                    distance: row?.distance / 1000,
                    net: '-',
                    vat: '-',
                    total: '-',
                });
            });

            let selectedLogbooksName = selectedLogbooks.map((Lgb) => Lgb.name);

            if (selectedLogbooksName.length > 0) {
                filteredRows = filteredRows?.filter((row) => {
                    return selectedLogbooksName.includes(row?.logbook);
                });
            }
            if (selectedCostType.length > 0) {
                filteredRows = filteredRows?.filter((row) => {
                    return selectedCostType.includes(row?.costType);
                });
            }
            if (selectedCostCentre.length > 0) {
                filteredRows = filteredRows?.filter((row) => {
                    return selectedCostCentre.includes(row?.costCentre);
                });
            }
            if (selectedCostObject.length > 0) {
                filteredRows = filteredRows?.filter((row) => {
                    return selectedCostObject.includes(row?.costObject);
                });
            }

            setRows(filteredRows);

            const newCostTypes = ['all'];
            newCostTypeSet.forEach((value, key) => {
                newCostTypes.push(value);
            });
            const newCostCentres = ['all'];
            newCostCentreSet.forEach((value, key) => {
                newCostCentres.push(value);
            });
            const newCostObject = ['all'];
            newCostObjectSet.forEach((value, key) => {
                newCostObject.push(value);
            });

            setCostTypes(newCostTypes);
            setCostCentres(newCostCentres);
            setCostObjects(newCostObject);
        }
    }, [receipts, toursWithCost, allLogbooksData, selectedLogbooks, selectedCostType, selectedCostCentre, selectedCostObject]);

    useEffect(() => {
        const getTotalCostObject = rows?.reduce(
            function (acc, row) {
                if (row?.distance !== '-') {
                    acc.totalDistance += row?.distance;
                }
                if (row?.net !== '-') {
                    acc.totalNet += row?.net;
                }
                if (row?.vat !== '-') {
                    acc.totalVat += row?.vat;
                }
                if (row?.total !== '-') {
                    acc.totalTotal += row?.total;
                }

                return acc;
            },
            {
                totalDistance: 0,
                totalNet: 0,
                totalVat: 0,
                totalTotal: 0,
            }
        );

        if (rows !== undefined) {
            const totalRow = [];

            totalRow.push({
                id: 'costSummaryRow',
                total: 'Total',
                totalDistance: getTotalCostObject.totalDistance,
                totalNet: getTotalCostObject.totalNet,
                totalVat: getTotalCostObject.totalVat,
                totalTotal: getTotalCostObject.totalTotal,
            });
            setTotalRow(totalRow);
        }
    }, [rows]);

    const handleChangeDate = (newDate) => {
        setDatum(newDate);
    };

    const sortLogbookData = (logbookList) => {
        if (logbookList !== undefined) {
            logbookList = [...logbookList];
            logbookList.sort((a, b) => {
                if (a.name?.toLocaleUpperCase() < b.name?.toLocaleUpperCase() || b.name === null) {
                    return -1;
                } else if (a.name?.toLocaleUpperCase() > b.name?.toLocaleUpperCase() || a.name === null) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        return logbookList;
    };

    const sortColumn = (a, b) => {
        if (a === '-') {
            return -1;
        }
        if (b === '-') {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
    };

    if (rows === undefined || totalRow === undefined) {
        return <LinearProgress />;
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Card variant='outlined'>
                    <CardContent>
                        <Stack direction='row' spacing={1} paddingBottom={'16px'}>
                            <DatePickerDesktop state={datum} setState={handleChangeDate} />
                            {!ismobil && (
                                <>
                                    <CostTableFilter
                                        allLogbooksData={sortLogbookData(allLogbooksData)}
                                        selectedLogbooks={selectedLogbooks}
                                        setSelectedLogbooks={setSelectedLogbooks}
                                    />
                                    <TypeFilter id={'costType'} types={costTypes} selectedType={selectedCostType} setSelectedType={setSelectedCostType} />
                                    <TypeFilter id={'costCentre'} types={costCentres} selectedType={selectedCostCentre} setSelectedType={setSelectedCostCentre} />
                                    <TypeFilter id={'costObject'} types={costObjects} selectedType={selectedCostObject} setSelectedType={setSelectedCostObject} />
                                </>
                            )}
                        </Stack>
                        {rows !== undefined && totalRow !== undefined && (
                            <>
                                <DataGrid
                                    localeText={{ noRowsLabel: t('noCostEntries') }}
                                    sx={{
                                        height: `${window.innerHeight - 309}px`,
                                        '& > .MuiDataGrid-main': {
                                            height: `${window.innerHeight - 415}px`,
                                        },
                                        '& > .MuiDataGrid-main > .MuiDataGrid-columnHeaders': {
                                            color: theme.palette.grey[600],
                                        },
                                        '.adminCostViewColumnId': {
                                            color: theme.palette.grey[600],
                                        },
                                    }}
                                    disableColumnMenu
                                    slots={{
                                        footer: () => (
                                            <DataGrid
                                                columnHeaderHeight={0}
                                                autoHeight
                                                sx={{ border: 'none' }}
                                                hideFooter={true}
                                                columns={[
                                                    { field: 'total', flex: 1 },
                                                    {
                                                        field: 'totalDistance',
                                                        width: 110,
                                                        valueFormatter: ({ value }) => {
                                                            return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' km';
                                                        },
                                                        align: 'right',
                                                    },
                                                    {
                                                        field: 'totalNet',
                                                        width: 100,
                                                        valueFormatter: ({ value }) => {
                                                            return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
                                                        },
                                                        align: 'right',
                                                    },
                                                    {
                                                        field: 'totalVat',
                                                        width: 100,
                                                        valueFormatter: ({ value }) => {
                                                            return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
                                                        },
                                                        align: 'right',
                                                    },
                                                    {
                                                        field: 'totalTotal',
                                                        width: 100,
                                                        valueFormatter: ({ value }) => {
                                                            return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
                                                        },
                                                        align: 'right',
                                                    },
                                                ]}
                                                rows={totalRow}
                                            />
                                        ),
                                    }}
                                    columns={[
                                        { field: 'id', width: 20, headerName: '#', cellClassName: 'adminCostViewColumnId' },
                                        {
                                            field: 'date',
                                            headerName: t('dateShort'),
                                            width: 104,
                                            type: 'date',
                                            valueFormatter: ({ value }) => {
                                                return Moment(value).format('DD.MM.yyyy');
                                            },
                                            align: 'left',
                                            headerAlign: 'left',
                                        },
                                        { field: 'logbook', headerName: t('logbook'), flex: 1 },
                                        {
                                            field: 'costType',
                                            headerName: t('costType'),
                                            flex: 1,
                                            valueFormatter: ({ value }) => {
                                                return t(value.toLowerCase());
                                            },
                                        },
                                        { field: 'costCentre', headerName: t('costCentre'), flex: 1 },
                                        { field: 'costObject', headerName: t('costObject'), flex: 1 },
                                        { field: 'receiptNo', headerName: t('receiptNumber'), flex: 1 },
                                        {
                                            field: 'distance',
                                            headerName: t('distance'),
                                            width: 110,
                                            valueFormatter: ({ value }) => {
                                                if (value === '-') {
                                                    return '-';
                                                } else {
                                                    return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' km';
                                                }
                                            },
                                            sortComparator: sortColumn,
                                            align: 'right',
                                            headerAlign: 'right',
                                        },
                                        {
                                            field: 'net',
                                            headerName: t('net'),
                                            width: 100,
                                            valueFormatter: ({ value }) => {
                                                if (value === '-') {
                                                    return '-';
                                                } else {
                                                    return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
                                                }
                                            },
                                            align: 'right',
                                            headerAlign: 'right',
                                        },
                                        {
                                            field: 'vat',
                                            headerName: t('vatShort'),
                                            width: 100,
                                            valueFormatter: ({ value }) => {
                                                if (value === '-') {
                                                    return '-';
                                                } else {
                                                    return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
                                                }
                                            },
                                            align: 'right',
                                            headerAlign: 'right',
                                        },
                                        {
                                            field: 'total',
                                            headerName: t('gross'),
                                            width: 100,
                                            valueFormatter: ({ value }) => {
                                                if (value === '-') {
                                                    return '-';
                                                } else {
                                                    return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
                                                }
                                            },
                                            align: 'right',
                                            headerAlign: 'right',
                                        },
                                    ]}
                                    rows={rows}
                                />
                            </>
                        )}
                    </CardContent>
                </Card>
            </Box>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

export default AdminCostViewDesktop;

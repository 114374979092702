import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AppsIcon from '@mui/icons-material/Apps';
import Badge from '@mui/material/Badge';
import LogbookSelection from '../ui/LogbookSelection';
import { Divider, useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../other/ConfigBasic';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetLicenses } from '../../api/LogbookApi';
import AccountMenu from './AccountMenu';
// For further development
// import NotificationsIcon from '@mui/icons-material/Notifications';

function createData(name, license, testLink, prodLink, icon, inactiveIcon) {
    return { name, license, testLink, prodLink, icon, inactiveIcon };
}

//value of process.env.PUBLIC_URL = /v2 automatic from create React app reverse to the public directory
const cloudApps = [
    createData(
        'Maps',
        'map',
        'https://maps-test.bornemann.net/',
        'https://maps.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/mape-karte-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/mape-karte-app-icon.svg'
    ),
    createData(
        'Archive',
        'archiv',
        'https://archive-test.bornemann.net/',
        'https://archive.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/archive-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/archive-app-icon.svg'
    ),
    createData(
        'Reports',
        'reports',
        'https://reports-test.bornemann.net/',
        'https://reports.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/reports-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/Reports-App-icon.svg'
    ),
    createData(
        'Alarm',
        'events',
        'https://alarm-test.bornemann.net/',
        'https://alarm.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/events-ereignisse-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/events-ereignisse-app-icon.svg'
    ),
    createData(
        'Calendar',
        'dates',
        'https://calendar-test.bornemann.net/',
        'https://calendar.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/dates-termine-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/dates-termine-app-icon.svg'
    ),
    createData(
        'Tours',
        'tours',
        'https://tours-test.bornemann.net/',
        'https://tours.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/tours-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/tours-app-Icon.svg'
    ),
    createData(
        'Dashboard',
        'dashboard',
        'https://dashboard-test.bornemann.net/',
        'https://dashboard.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/dashboard-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/dashboard-app-icon.svg'
    ),

    createData(
        'Logbook',
        'logbook-cloud',
        'https://logbook-test.bornemann.net/v2/',
        'https://logbook.bornemann.net/v2/',
        process.env.PUBLIC_URL + '/imgs/apps/logbook-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/logbook-app-icon.svg'
    ),
    createData(
        'Admin',
        'admin',
        'https://admin-test.bornemann.net/',
        'https://admin.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/admin-verwaltung-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/admin-verwaltung-app-icon.svg'
    ),
];

const portalApps = [
    createData(
        'Infleet',
        'infleet',
        'https://infleet-test.bornemann.net/',
        'https://infleet.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/infleet-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/infleet-app-icon.svg'
    ),
    createData(
        'Seconity',
        'seconity',
        'https://seconity-test.bornemann.net/',
        'https://seconity.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/seconity-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/seconity-app-icon.svg'
    ),
    createData(
        'Migardo',
        'migardo',
        'https://migardo-test.bornemann.net/',
        'https://migardo.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/migardo-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/migardo-app-icon.svg'
    ),
    createData(
        'Work',
        'work',
        'https://work-test.bornemann.net/',
        'https://work.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/work-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/work-app-icon.svg'
    ),
    createData(
        'Tacho',
        'tacho',
        'https://tacho-test.bornemann.net/',
        'https://tacho.bornemann.net/',
        process.env.PUBLIC_URL + '/imgs/apps/tacho-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/tacho-app-icon.svg'
    ),
];

function checkLicense(license, permissions) {
    if (permissions) {
        return permissions.filter((p) => p.name === '*' || p.name === license.toLowerCase()).length > 0;
    }
}

export default function LogBookBar(props) {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [anchorEl, setAnchorEl] = useState(null);
    const [appAnchorEl, setAppAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isAppMenuOpen = Boolean(appAnchorEl);
    const logbookList = props.logbookList;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    let navigate = useNavigate();
    const permissions = useGetLicenses();

    useEffect(() => {
        setIsDrawerOpen(props.menuOpen);
    }, [props.menuOpen]);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const enviro = process.env.REACT_APP_ENV;
    const handleAppMenuOpen = (event) => {
        setAppAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAppAnchorEl(null);
    };

    const handleLogbookChange = (logbookId) => {
        if (logbookList.find((logbook) => logbook.value === logbookId) !== undefined) {
            localStorage.setItem('logbookId', logbookId);
        }
        props.setLogTour(logbookId);
    };

    const customMenuCss = {
        display: 'block',
        width: '314px',
        textAlign: 'left',
        padding: '10px',
    };

    const customMenuItemCss = {
        display: 'inline-block',
    };

    cloudApps.sort((a, b) => {
        if (checkLicense(a.license, permissions) && !checkLicense(b.license, permissions)) {
            return -1;
        } else if (!checkLicense(a.license, permissions) && checkLicense(b.license, permissions)) {
            return 1;
        } else {
            return 0;
        }
    });

    portalApps.sort((a, b) => {
        if (checkLicense(a.license, permissions) && !checkLicense(b.license, permissions)) {
            return -1;
        } else if (!checkLicense(a.license, permissions) && checkLicense(b.license, permissions)) {
            return 1;
        } else {
            return 0;
        }
    });

    const cloudAppItems = (
        <Box sx={customMenuCss}>
            {cloudApps.map((app, index) => {
                return (
                    <MenuItem className='customMenuItem' sx={customMenuItemCss} onClick={handleMenuClose} key={index}>
                        <a
                            className='customMenuItemContent'
                            target='_blank'
                            rel='noreferrer'
                            variant='subtitle1'
                            href={enviro === 'test' ? app.testLink : enviro === 'development' ? app.testLink : app.prodLink}
                        >
                            <img src={checkLicense(app.license, permissions) ? app.icon : app.inactiveIcon} alt={app.name} loading='lazy' />
                            <Typography variant='caption text' component='p' sx={{ margin: '2px' }}>
                                {app.name}
                            </Typography>
                        </a>
                    </MenuItem>
                );
            })}
        </Box>
    );

    const portalAppItems = (
        <Box sx={customMenuCss}>
            {portalApps.map((app, index) => {
                return (
                    <MenuItem className='customMenuItem' sx={customMenuItemCss} onClick={handleMenuClose} key={index}>
                        <a
                            className='customMenuItemContent'
                            target='_blank'
                            rel='noreferrer'
                            variant='subtitle1'
                            href={enviro === 'test' ? app.testLink : enviro === 'development' ? app.testLink : app.prodLink}
                        >
                            <img src={checkLicense(app.license, permissions) ? app.icon : app.inactiveIcon} alt={app.name} loading='lazy' />
                            <Typography variant='caption text' component='p' sx={{ margin: '2px' }}>
                                {app.name}
                            </Typography>
                        </a>
                    </MenuItem>
                );
            })}
        </Box>
    );

    const appId = 'primary-app-menu';
    const renderAppMenu = (
        <Menu
            sx={{
                '& .MuiPaper-root': {
                    width: 300,
                    maxWidth: 300,
                    maxHeight: '464px',
                },
            }}
            anchorEl={appAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id={appId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isAppMenuOpen}
            onClose={handleMenuClose}
        >
            {cloudAppItems}
            <Divider />
            {portalAppItems}
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position='fixed'
                sx={{
                    paddingLeft: isDrawerOpen && desktop900 ? '250px' : '0px',
                }}
            >
                <Toolbar>
                    {!desktop900 && (
                        <IconButton size='medium' edge='start' color='inherit' aria-label='open drawer' sx={{ margin: 'auto' }} onClick={props.updateMenuState}>
                            {props.showHint?.settings ? (
                                <Badge color='error' variant='dot'>
                                    <MenuIcon />
                                </Badge>
                            ) : (
                                <MenuIcon />
                            )}
                        </IconButton>
                    )}
                    {desktop900 && !isDrawerOpen && (
                        <IconButton size='medium' edge='start' color='inherit' aria-label='open drawer' sx={{ margin: 'auto' }} onClick={props.updateMenuState}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    <LogbookSelection
                        logbookList={logbookList.filter((logbook) => logbook.status !== 'archived')}
                        handleLogbookChange={handleLogbookChange}
                        logbookId={props.logbookId}
                        loginUser={props.loginUser}
                    />
                    {desktop900 && (
                        <Typography
                            component='div'
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='#43A047'
                                width='158.49'
                                height='21.43'
                                viewBox='0 0 546.5 73.9'
                                onClick={() => navigate('/')}
                                cursor='pointer'
                            >
                                <path d='M81.8,61.8c-6.5,0-12.5-4.8-12.5-14s6-14,12.5-14,12.5,4.7,12.5,14-6,14-12.5,14M37.4,47.7c0,9-5.4,14-12.2,14s-12.3-5.1-12.3-14,5.6-13.9,12.3-13.9,12.2,4.9,12.2,13.9m317.9-.1c0-9,5.2-14.3,12.3-14.3s12.1,5.3,12.1,14.3-5.2,14.7-12.1,14.7c-7.2,0-12.3-5.8-12.3-14.7M237.9,42.2h-22a10.65,10.65,0,0,1,11-9.7c7.6,0,10.8,4.8,11,9.7m-94.2-6.7V22.4a30.28,30.28,0,0,0-3.1-.2c-13.5,0-25,5.3-25,25.1V72.4h13.3V49.3c0-8.6,2.3-14.1,10.8-14.1a30.23,30.23,0,0,1,4,.3M379.8,67.4v5h12.8V23.2H379.7v4.5c-2.6-3.2-7.1-5.9-14.1-5.9-14.3,0-23.7,11.4-23.7,25.8,0,14.9,9.6,26.2,24.1,26.2,5.8,0,10.1-2.3,13.8-6.4M481,21.7c-11.4,0-22.5,5.4-22.5,20.7v30h13.3V45.1c0-7.4,3.3-11.3,9.2-11.3s9.1,3.9,9.1,11.3V72.4h13.3v-30c0-15.3-11-20.7-22.4-20.7m-54.9,0c-11.4,0-22.5,5.4-22.5,20.7v30h13.3V45.1c0-7.4,3.3-11.3,9.2-11.3s9.1,3.9,9.1,11.3V72.4h13.3v-30c0-15.3-11-20.7-22.4-20.7m-129.3,6c-3.8-4.5-9.2-6-15.6-6-10.3,0-22.2,4.9-22.2,20.7v30h13.3V45.5c0-8.1,3.4-11.7,8.9-11.7,5.7,0,8.9,3.6,8.9,11.7V72.4h13.4V45.5c0-8.1,3.2-11.7,8.9-11.7,5.5,0,8.9,3.6,8.9,11.7V72.4h13.3v-30c0-15.8-11.9-20.7-22.2-20.7-6.4,0-11.8,1.5-15.6,6M249.2,61.2l-10.5-5c-1.8,3.7-5.1,6.3-10.7,6.3-6.4,0-11.7-4.4-12.3-10.5h35c.1-1,.3-2.9.3-4.8,0-15.8-9.1-25.5-24.3-25.5-12.6,0-24.2,10.2-24.2,25.9,0,16.6,11.9,26.3,25.4,26.3,10.7,0,18-5.5,21.3-12.7M172.8,21.7c-11.4,0-22.5,5.4-22.5,20.7v30h13.3V45.1c0-7.4,3.3-11.3,9.2-11.3s9.1,3.9,9.1,11.3V72.4h13.3v-30c0-15.3-11-20.7-22.4-20.7m-91,0C67.1,21.7,56,32.6,56,47.8S67.1,73.9,81.8,73.9s25.8-11,25.8-26.1S96.5,21.7,81.8,21.7m446-2.9h-2.3V13.4h2.3c1.8,0,3,1,3,2.7s-1.2,2.7-3,2.7m-2.3,4h1.4l3.6,7.3h5.4L531.8,22a6.36,6.36,0,0,0,4-5.9,6.86,6.86,0,0,0-7.1-6.7h-8.1V30.1h4.9Zm-12.7-3a14.61,14.61,0,1,1,29.2,0c0,8.5-6.4,15.1-14.6,15.1s-14.6-6.6-14.6-15.1m-4.4,0A19.05,19.05,0,1,0,527.4.7a19,19,0,0,0-19,19.1M13.1,0H0V47.7C0,62.5,11.2,73.6,25.2,73.6s25.5-11.1,25.5-26C50.7,33,42.4,21.9,28,21.9a21.38,21.38,0,0,0-14.9,5.8Z'></path>
                            </svg>
                        </Typography>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: 'flex' }}>
                        {desktop900 ? (
                            <IconButton size='large' edge='end' aria-label='Borneamnn Apps' aria-controls={appId} aria-haspopup='true' onClick={handleAppMenuOpen} color='inherit'>
                                <AppsIcon />
                            </IconButton>
                        ) : (
                            <></>
                            // For further development
                            // <IconButton size='large' edge='end' aria-label='Notifications' aria-haspopup='false' color='inherit'>
                            //     <NotificationsIcon />
                            // </IconButton>
                        )}
                        <AccountMenu
                            anchorEl={anchorEl}
                            isMenuOpen={isMenuOpen}
                            handleProfileMenuOpen={handleProfileMenuOpen}
                            handleMenuClose={handleMenuClose}
                            loginUser={props.loginUser}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            {renderAppMenu}
        </Box>
    );
}

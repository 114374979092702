import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useGetAssetDataApi, useGetLatestMileage, useGetLatestUserCalibrationData } from '../../api/LogbookApi';
import LinearProgress from '@mui/material/LinearProgress';
import ErrorIcon from '@mui/icons-material/Error';
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import VehicleSvg from '../../components/other/VehicleSvg';
import { useState } from 'react';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { Box } from '@mui/material';
import Moment from 'moment';
import { useEffect } from 'react';
import DialogCalibrateOdometer from './DialogCalibrateOdometer';

export default function BasicSettings(props) {
    const [message, setMessage] = useState('');

    const assetData = useGetAssetDataApi(props.idAsset, setMessage);
    const calibrationData = useGetLatestUserCalibrationData(props.idAsset, setMessage);
    const latestMileage = useGetLatestMileage(props.idAsset, setMessage);
    const [showHint, setShowHint] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (calibrationData !== undefined) {
            let hint = false;
            if (calibrationData.ymd === null) {
                hint = true;
            } else {
                const date1 = Moment(calibrationData.ymd);
                const date2 = Moment(new Date());
                if (date2.diff(date1, 'M') >= 6) {
                    hint = true;
                }
            }
            setShowHint(hint);
        }
    }, [calibrationData]);

    if (!assetData) {
        return (
            <>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }

    return (
        <>
            <Grid xs={12} md={12}>
                <Card variant='outlined' sx={{ maxWidth: 538 }}>
                    {assetData.imageUri && (
                        <CardMedia
                            component='img'
                            alt={assetData.make + ' ' + assetData.model}
                            sx={{ height: '100%', maxHeight: '240px' }}
                            src={`${process.env.REACT_APP_AWS_ASSET_PATH}${assetData.imageUri}`}
                        />
                    )}
                    {!assetData.imageUri && <VehicleSvg />}
                    <CardContent>
                        <Grid container xs={12} md={12}>
                            <Grid xs={10} md={10} textAlign='left'>
                                <Typography gutterBottom variant='h6' component='div'>
                                    {assetData.licensePlate ? assetData.licensePlate : t('unknownLicensePlate')}
                                </Typography>
                            </Grid>
                            {showHint && (
                                <Grid xs={2} md={2} textAlign='right'>
                                    <Tooltip title={t('mileageMustBeCompared')} placement='top-start' enterTouchDelay={0}>
                                        <span>
                                            <ErrorIcon color='error' />
                                        </span>
                                    </Tooltip>
                                </Grid>
                            )}
                            <Grid xs={12} md={12} textAlign='left'>
                                <Typography variant='body2' color='text.secondary'>
                                    {assetData.make} {assetData.model} (VIN: {assetData.vin ? assetData.vin : t('unknown')})
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={12} textAlign='left'>
                                <Typography variant='body2' color='text.secondary'>
                                    {t('mileage')}: {latestMileage === undefined || latestMileage?.latestMileage === null ? t('unknown') : `${latestMileage?.latestMileage} km`}
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={12} textAlign='left' sx={{ paddingTop: '24px' }}>
                                <Link href={`${process.env.REACT_APP_ADMIN_VEHICLES}?id=${props.idAsset}`} underline='none' target='_blank' sx={{ marginRight: '24px' }}>
                                    {t('edit')}
                                </Link>
                                <DialogCalibrateOdometer access={props.access} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

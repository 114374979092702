import { Box, Button, Checkbox, InputBase, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../themes/theme';
import DialogAddCost from './DialogAddCost';
import { useState } from 'react';
import SimpleSnackbar from '../../ui/SimpleSnackbar';
import { useEffect } from 'react';
import CostFilter from './CostFilter';
import DialogEditCost from './DialogEditCost';

export default function CostCentres(props) {
    const { allCostCentre, saveLogbookSetting } = props;
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [costCentres, setCostCentres] = useState([]);
    const [checked, setChecked] = useState([]);
    const [hoveredListItem, setHoveredListItem] = useState('');
    const [filterName, setFilterName] = useState(t('active'));
    const [searchValue, setSearchValue] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setChecked([]);
    }, [filterName]);

    useEffect(() => {
        if (searchValue.length === 0) {
            setCostCentres(
                allCostCentre?.filter((costCentre) => {
                    return (filterName === t('active') && costCentre.active === true) || (filterName === t('deactivated') && costCentre.active === false);
                })
            );
        } else {
            setCostCentres(
                allCostCentre
                    ?.filter((costCentre) => {
                        return (filterName === t('active') && costCentre.active === true) || (filterName === t('deactivated') && costCentre.active === false);
                    })
                    ?.filter((costCentre) => costCentre?.name?.toLowerCase().includes(searchValue.toLowerCase()))
            );
        }
    }, [searchValue, filterName, allCostCentre, t]);

    const handleListItemClick = (event, costCentreId) => {
        if (isMobile) {
            checkHoveredLogbook(costCentreId);
        } else {
            handleToggle(event, costCentreId);
        }
    };

    const handleToggle = (event, costCentreId) => {
        event.stopPropagation();
        const currentIndex = checked.indexOf(costCentreId);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(costCentreId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleChangeCheckedAll = () => {
        if (checked.length < getCostCentreInList()?.length) {
            setChecked(getCostCentreInList()?.map((costCentre) => costCentre.id));
        } else {
            setChecked([]);
        }
    };

    const handleChangeStatusAll = (value) => {
        let newCostCentreList = allCostCentre.map((costCentre) => {
            let newCostCentre = null;
            if (!checked.includes(costCentre.id)) {
                newCostCentre = costCentre;
            } else {
                if (value === t('activate')) {
                    newCostCentre = {
                        id: costCentre.id,
                        name: costCentre.name,
                        active: true,
                    };
                } else if (value === t('deactivate')) {
                    newCostCentre = {
                        id: costCentre.id,
                        name: costCentre.name,
                        active: false,
                    };
                }
            }
            return newCostCentre;
        });
        setChecked([]);
        checkHoveredLogbook('');
        saveLogbookSetting('costCentre', newCostCentreList);
    };

    const checkHoveredLogbook = (id) => {
        if (hoveredListItem !== id) {
            setHoveredListItem(id);
        } else {
            setHoveredListItem('');
        }
    };

    const changeActiveStatus = (event, id, value) => {
        event.stopPropagation();
        let newCostCentreList = allCostCentre.map((costCentre) => {
            let newCostCentre = null;
            if (id !== costCentre.id) {
                newCostCentre = costCentre;
            } else {
                if (value === t('activate')) {
                    newCostCentre = {
                        id: costCentre.id,
                        name: costCentre.name,
                        active: true,
                    };
                } else if (value === t('deactivate')) {
                    newCostCentre = {
                        id: costCentre.id,
                        name: costCentre.name,
                        active: false,
                    };
                }
            }
            return newCostCentre;
        });
        setChecked([]);
        saveLogbookSetting('costCentre', newCostCentreList);
        if (isMobile) {
            checkHoveredLogbook('');
        }
    };

    const addCost = (costCentre) => {
        const newCostCentreList = [...allCostCentre];
        newCostCentreList.push(costCentre);
        saveLogbookSetting('costCentre', newCostCentreList);
    };

    const editCost = (costCentres) => {
        const newCostCentreList = [...allCostCentre];
        const index = newCostCentreList.findIndex((cC) => cC.id === costCentres.id);
        newCostCentreList[index] = costCentres;
        saveLogbookSetting('costCentre', newCostCentreList);
    };

    const getCostCentreInList = () => {
        const costCentreInList = allCostCentre?.filter((costCentre) => {
            return (filterName === t('active') && costCentre.active === true) || (filterName === t('deactivated') && costCentre.active === false);
        });
        return costCentreInList;
    };

    const getWidth = () => {
        const widthList = document?.getElementById('costCentreList')?.offsetWidth;
        return widthList === undefined ? '0px' : `${widthList}px`;
    };

    return (
        <>
            <CostFilter filterName={filterName} setFilterName={setFilterName} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '16px' }}>
                <Box display='flex' alignItems='center' padding='0 8px' borderRadius='24px' marginBottom='8px' maxWidth='264px' height='40px' bgcolor={theme.palette.grey[100]}>
                    <InputBase placeholder={t('searchInList')} value={searchValue} onChange={(event) => setSearchValue(event.target.value)} />
                </Box>
                <DialogAddCost addCost={addCost} title={t('addCostCentre')} label={t('costCentre')} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <ListItem key='all' disablePadding sx={{ height: '48px' }}>
                    <ListItemIcon sx={{ marginRight: '-16px' }}>
                        <Checkbox
                            edge='end'
                            checked={checked.length === getCostCentreInList()?.length}
                            indeterminate={checked.length > 0 && checked.length < getCostCentreInList()?.length}
                            onClick={() => handleChangeCheckedAll()}
                        />
                    </ListItemIcon>
                    <ListItemText
                        id='all'
                        primary={`${t('all')} (${checked.length} ${t('of')} ${getCostCentreInList()?.length})`}
                        sx={{ textAlign: 'left', paddingRight: '16px' }}
                    />
                    {checked.length > 0 && (
                        <Button
                            variant='outlined'
                            sx={{
                                marginLeft: '8px',
                                color: filterName === t('active') ? '#FF3D00' : '#16A100',
                                '&:hover': { borderColor: filterName === t('active') ? '#FF3D00' : '#16A100' },
                            }}
                            onClick={() => handleChangeStatusAll(filterName === t('active') ? t('deactivate') : t('activate'))}
                        >
                            {filterName === t('active') ? t('deactivate') : t('activate')}
                        </Button>
                    )}
                </ListItem>
            </Box>
            <List dense sx={{ height: 'calc(100vh - 474px)', overflowY: 'auto' }} id='costCentreList'>
                {costCentres?.length > 0 &&
                    costCentres?.map((data) => {
                        return (
                            <ListItem
                                key={data.id}
                                disablePadding
                                sx={{
                                    height: '48px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: theme.palette.grey[100],
                                    },
                                }}
                                onClick={(event) => handleListItemClick(event, data.id)}
                                onMouseEnter={() => checkHoveredLogbook(data.id)}
                                onMouseLeave={() => checkHoveredLogbook('')}
                            >
                                <ListItemIcon sx={{ marginRight: '-16px' }} onClick={(event) => handleToggle(event, data.id)}>
                                    <Checkbox edge='end' checked={checked.indexOf(data.id) !== -1} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={data.name}
                                    primaryTypographyProps={{
                                        width: isMobile
                                            ? hoveredListItem === data.id
                                                ? `calc(${getWidth()} - 220px)`
                                                : `calc(${getWidth()} - 40px)`
                                            : hoveredListItem === data.id
                                            ? `calc(${getWidth()} - 240px)`
                                            : `calc(${getWidth()} - 40px)`,
                                        position: 'relative',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                />
                                {hoveredListItem === data.id && (
                                    <>
                                        <DialogEditCost
                                            editCost={editCost}
                                            currentCost={data}
                                            title={t('editCostCentre')}
                                            label={t('costCentre')}
                                            checkHoveredLogbook={checkHoveredLogbook}
                                        />
                                        <Button
                                            variant='outlined'
                                            sx={{
                                                marginLeft: '8px',
                                                color: filterName === t('active') ? '#FF3D00' : '#16A100',
                                                '&:hover': { borderColor: filterName === t('active') ? '#FF3D00' : '#16A100' },
                                            }}
                                            onClick={(event) => changeActiveStatus(event, data.id, data?.active ? t('deactivate') : t('activate'))}
                                        >
                                            {data?.active ? t('deactivate') : t('activate')}
                                        </Button>
                                    </>
                                )}
                            </ListItem>
                        );
                    })}
                {costCentres?.length === 0 && (
                    <ListItem>
                        <ListItemText>
                            <Typography variant='caption' color='gray'>
                                {t('noResult')}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                )}
            </List>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

import { Stack, Typography } from '@mui/material'


import React from 'react'
import { useTranslation } from 'react-i18next';

const EditedToutHistory = (props) => {
    const { t } = useTranslation();


    const originalStyle = {
        fontSize: "12.5px",
        color: "#212121",
        fontFamily: "CircularProBook"
    }

    const editedStyle = {
        fontSize: "12px",
        color: "#212121",
        fontFamily: "CircularProBold"
    }
    return (
        <Stack justifyContent={"space-between"} gap={2} p={2} sx={{ backgroundColor: "#0000001F", borderRadius: "4px" }}>


            <Stack textAlign={'left'} >
                <Typography sx={{
                    fontSize: "13px",
                    color: "#757575",
                    fontFamily: "CircularProBook"
                }}>Original</Typography>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <Typography sx={originalStyle} textAlign={"left"}>
                        {props.date}  {props.address}
                    </Typography>
                    <Typography sx={originalStyle} textAlign={'right'} >
                        {props.mileage}
                    </Typography>

                </Stack>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <Typography sx={originalStyle} textAlign={"left"}>
                        {props.endDate}  {props.endAddress}
                    </Typography>
                    <Typography sx={originalStyle} textAlign={'right'} >
                        {props.endMileage}
                    </Typography>

                </Stack>
                <Typography sx={originalStyle}>
                    Distance: {props.startDistance}
                </Typography>
            </Stack>
            <Stack >
                <Typography sx={{
                    fontSize: "13px",
                    color: "#757575",
                    fontFamily: "CircularProBook"
                }}>
                    {t("changed")}
                </Typography>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <Stack direction={"row"} gap={.5}>
                        <Typography sx={props.newDate === props.date ? originalStyle : editedStyle}>
                            {props.newDate ? props.newDate : props.date}
                        </Typography>
                        <Typography sx={props.newAddress === props.address ? originalStyle : editedStyle} >
                            {props.newAddress}
                        </Typography>
                    </Stack>

                </Stack>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <Stack direction={"row"} gap={.2}>
                        <Typography sx={props.changedEndDate === props.endDate ? originalStyle : editedStyle}>
                            {props.changedEndDate}
                        </Typography>
                        <Typography sx={props.changedEndAddress === props.endAddress ? originalStyle : editedStyle} >
                            {props.changedEndAddress}
                        </Typography>
                    </Stack>


                </Stack>
                {props.changedEndAddress !== props.endAddress && <Typography sx={originalStyle}>
                    Distance : {props.endDistance}
                </Typography>}
            </Stack>

        </Stack>
    )
}

export default EditedToutHistory
import { CircularProgress, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CreateDialog = (props) => {
    const { open, setOpen, t, totalSteps, error, loading } = props;
    const [value, setValue] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        let timer;

        if (open) {
            timer = setInterval(() => {
                setValue((prevValue) => {
                    const newValue = prevValue + (100 / totalSteps);
                    setCurrentStep((prevStep) => {
                        const newStep = prevStep + 1;
                        if (newStep > totalSteps) {
                            clearInterval(timer);
                            setOpen(false);
                            if (!error && !loading) {
                                navigate('/admin')
                            }
                            return prevStep;
                        }
                        return newStep;
                    });
                    return newValue;
                });
            }, 200);

        } else {
            clearInterval(timer);
            setValue(0);
            setCurrentStep(0);
        }

        return () => clearInterval(timer);
    }, [open, setOpen, totalSteps, navigate, error, loading]);

    return (
        <Dialog open={open}
            PaperProps={{
                style: {
                    borderRadius: '28px',
                },
            }}
        >
            <DialogContent sx={{
                width: '150px',
                padding: '24px',
            }}>
                <Stack justifyContent={'center'} alignItems={'center'} gap={2}>
                    <CircularProgress variant="determinate" value={value} size={80} />
                    <Typography>
                        {currentStep} {t('of')} {totalSteps}
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateDialog;

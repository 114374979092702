import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ImportSvg() {
    const { t } = useTranslation();
    return (
        <>
            <svg xmlns='http://www.w3.org/2000/svg' width='288' height='254.361' viewBox='0 0 288 254.361'>
                <g id='Gruppe_3675' data-name='Gruppe 3675' transform='translate(0 0)'>
                    <path
                        id='Pfad_4028'
                        data-name='Pfad 4028'
                        d='M782.642,510.969a3.332,3.332,0,0,1,1.745-4.8l-.172-11.839,5.185,3.312-.632,10.734a3.35,3.35,0,0,1-6.127,2.593Z'
                        transform='translate(-559.578 -348.021)'
                        fill='#a0616a'
                    />
                    <path
                        id='Pfad_4029'
                        data-name='Pfad 4029'
                        d='M761.4,383.8a6.748,6.748,0,0,0-5.572,6.5c-.075,3.465,1.081,4.757,2.715,5.223,3.02.863,7.831,18.316,7.831,18.316s.511,16.279,1.334,15.475-.108,1.072.142,1.644-1.186,1.43.179,2.08.5,5.828.5,5.828l6.041-.432s-1-4.218.361-4.482.745-1.465.192-2.375.978-2.207.213-2.637,1.392-17.259,1.392-17.259L768.3,386.86a6.749,6.749,0,0,0-6.894-3.06Z'
                        transform='translate(-544.589 -285.125)'
                        fill='#b9f6ca'
                    />
                    <path id='Pfad_4030' data-name='Pfad 4030' d='M460,220.908l4.346-7.617h5.871l4.4,8.048Z' transform='translate(-261.512 -121.257)' fill='#b9f6ca' />
                    <path
                        id='Pfad_4031'
                        data-name='Pfad 4031'
                        d='M504.679,199.03c-3.472-11.631-13.282-19.224-25.135-20.889-.13-.018-.254.006-.384,0a3.746,3.746,0,0,0-.512-.118,74.888,74.888,0,0,0-48.259,10.528A84.3,84.3,0,0,1,385.2,201.729q-.746,0-1.5.032a29.663,29.663,0,0,0-26.532,21.385,3.48,3.48,0,0,0,1.55,3.809c2.153,1.542,3.623,2.623,6.272,3.184q7.09,1.5,14.228,2.762V213.946a6.776,6.776,0,0,1,6.769-6.769h92.974a6.776,6.776,0,0,1,6.769,6.769v23.606a27.841,27.841,0,0,0,3.725-1.466,28.84,28.84,0,0,0,11.119-9.7A32.717,32.717,0,0,0,504.679,199.03Z'
                        transform='translate(-317.88 -167.874)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4032'
                        data-name='Pfad 4032'
                        d='M413.931,428.8h-82.19a7.162,7.162,0,0,1-7.154-7.154V389.752a74.633,74.633,0,0,0-30.945-2.712,3.75,3.75,0,0,0-.512.118c-.13.008-.254-.016-.384,0-11.853,1.666-21.663,9.259-25.135,20.889a32.717,32.717,0,0,0,4.1,27.353,28.84,28.84,0,0,0,11.119,9.7c6.165,2.97,12.654,3.193,19.382,3.4a439.4,439.4,0,0,0,60.522-2.288q14.836-1.591,29.548-4.159,7.53-1.318,15.011-2.9c2.649-.561,4.119-1.642,6.272-3.184a3.48,3.48,0,0,0,1.55-3.809A28.4,28.4,0,0,0,413.931,428.8Z'
                        transform='translate(-266.277 -286.71)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4033'
                        data-name='Pfad 4033'
                        d='M511.314,348.385H430.652a5.879,5.879,0,0,1-5.872-5.872V267.552a5.879,5.879,0,0,1,5.872-5.872h80.662a5.879,5.879,0,0,1,5.872,5.872v74.961A5.879,5.879,0,0,1,511.314,348.385Z'
                        transform='translate(-356.387 -215.759)'
                        fill='#e6e6e6'
                    />
                    <path
                        id='Pfad_4034'
                        data-name='Pfad 4034'
                        d='M499.557,349.647H439.833a5.879,5.879,0,0,1-5.872-5.872V276.739a5.879,5.879,0,0,1,5.872-5.872h72.739a5.879,5.879,0,0,1,5.872,5.872v54.02A18.91,18.91,0,0,1,499.557,349.647Z'
                        transform='translate(-361.606 -220.982)'
                        fill='#fff'
                    />
                    <circle id='Ellipse_543' data-name='Ellipse 543' cx='26.321' cy='26.321' r='26.321' transform='translate(88.275 62.954)' fill='#b9f6ca' />
                    <path
                        id='Pfad_4035'
                        data-name='Pfad 4035'
                        d='M527.56,343.768l-8.784-10.681a2.589,2.589,0,0,0-3.944-.065L505.44,343.7a2.589,2.589,0,0,0,3.889,3.42l4.86-5.526v13.858a2.589,2.589,0,0,0,5.178,0v-13.5l4.195,5.1a2.589,2.589,0,1,0,4-3.289Z'
                        transform='translate(-401.875 -255.817)'
                        fill='#fff'
                    />
                    <path id='Pfad_4036' data-name='Pfad 4036' d='M520.861,558.479h-3.9L515.1,543.416h5.763Z' transform='translate(-292.836 -308.934)' fill='#a0616a' />
                    <path
                        id='Pfad_4037'
                        data-name='Pfad 4037'
                        d='M779.229,734.47H767.221v-.152a4.674,4.674,0,0,1,4.674-4.674h0l2.193-1.664,4.093,1.664h1.048Z'
                        transform='translate(-551.066 -480.852)'
                        fill='#2f2e41'
                    />
                    <path id='Pfad_4038' data-name='Pfad 4038' d='M452.861,558.479h-3.9L447.1,543.416h5.763Z' transform='translate(-254.178 -308.934)' fill='#a0616a' />
                    <path
                        id='Pfad_4039'
                        data-name='Pfad 4039'
                        d='M711.229,734.47H699.221v-.152a4.674,4.674,0,0,1,4.674-4.674h0l2.193-1.664,4.093,1.664h1.048Z'
                        transform='translate(-512.407 -480.852)'
                        fill='#2f2e41'
                    />
                    <path
                        id='Pfad_4040'
                        data-name='Pfad 4040'
                        d='M741.38,583.555c1.425-.931.6-.871.6-.871s-3.186-25.829-5.609-33.657l-2.8-38.117s-.178.76,0-1.088,1.473-.553.178-1.848-2.158-1.954-1.295-2.919,3.244-2.916,1.838-4.1-4.173-8.888-4.173-8.888l-26.969.216,1.079-.216s-3.366-1.249-1.824.378-.863,5.609-1.726,9.493c-.715,3.216-2.837,4.07,0,4.746s.27,2.594.27,2.594-4.27,20.33-1.034,33.706c0,0,2.233,33.084,3.363,35.929s1.562,1.119,1.13,2.845-1.294-1.294-.431,1.726a39.9,39.9,0,0,1,1.116,4.807l5.852-.432s1.359-4.284,0-5.408-.421,2.158,1.052-.346-.253-1.641-.685-5.093,3.527-19.533.685-29.4l5.253-27.686,8.339,28.667s4.423,35.012,5.861,36.246.857-1.761,1.437,1.234c.587,3.027,1.919,2.224,1.881,2.6-.4,3.958,6.619.054,6.619.054a22.15,22.15,0,0,1,0-3.02C741.51,584.781,739.956,584.485,741.38,583.555Z'
                        transform='translate(-512.109 -346.476)'
                        fill='#2f2e41'
                    />
                    <path
                        id='Pfad_4041'
                        data-name='Pfad 4041'
                        d='M707.75,436.231c-1.726,0-6.472-2.338-5.609-3.326s.863-4.271.863-5.219,3.883-35.9,3.883-35.9l-.3-8.558a3.824,3.824,0,0,1,3.822-3.956l3.809-1.294,10.787-.431,5.379,1.726a3.118,3.118,0,0,1,2.778,4.536h0a28.08,28.08,0,0,1,4.122,15.514c-.124,4.087-2.194,9.078-2.693,13.695-.044.405-.076.808-.093,1.206,0,.111-.888.665-.013.333s-.469.841-.013,1.549,1.342.274.036,1.138,1.567,9.21,1.284,9.494c-.863.863-2.589,1.295-1.294,2.589s2.589.431.863,2.157-6.9,4.746-6.9,4.746Z'
                        transform='translate(-514.008 -281.63)'
                        fill='#3f3d56'
                    />
                    <circle id='Ellipse_544' data-name='Ellipse 544' cx='9.287' cy='9.287' r='9.287' transform='translate(195.99 72.333)' fill='#a0616a' />
                    <path
                        id='Pfad_4042'
                        data-name='Pfad 4042'
                        d='M608.265,410.817a3.332,3.332,0,0,1,4.583,2.256l11.787,1.115-3.856,4.795-10.6-1.794a3.35,3.35,0,0,1-1.913-6.372Z'
                        transform='translate(-459.553 -300.382)'
                        fill='#a0616a'
                    />
                    <path
                        id='Pfad_4043'
                        data-name='Pfad 4043'
                        d='M676.107,387.824a6.748,6.748,0,0,0-6.5-5.572c-3.465-.075-4.757,1.081-5.224,2.715-.863,3.021-14.756,13.638-14.756,13.638L637.7,397.313c-.717-.233-.377-.886-3.075-.333a2.557,2.557,0,0,0-2.928-.317l-4.419-.478.432,6.9s4.731-1.125,5.609.863.678,2.274,2.589.863,1.726,0,1.726,0l13.808,3.452,21.606-13.549a6.749,6.749,0,0,0,3.06-6.894Z'
                        transform='translate(-471.507 -284.302)'
                        fill='#b9f6ca'
                    />
                    <path
                        id='Pfad_4044'
                        data-name='Pfad 4044'
                        d='M730.954,335.469l.608.194c-1.9.16-5.294,1.221-4.962.634s-.923-2.8-.311-3.081a3.9,3.9,0,0,0,1.648-1.118,1.258,1.258,0,0,0-.2-1.773c-.565-.328-1.264.026-1.825.358s-1.609-4.5-1.342-6c-1.575-.863-3.482.79-5.251.466a3.505,3.505,0,0,1-2.356-2.1,6.578,6.578,0,0,0,1.739-2.77,8.589,8.589,0,0,1-2.382.79,5.6,5.6,0,0,1,.061-2.365,3.859,3.859,0,0,1,2.4-2.736,2.8,2.8,0,0,1,3.279,1.217,2.781,2.781,0,0,0-1.76-2.762,2.924,2.924,0,0,1,4.207,1.989,9.033,9.033,0,0,1,6.3-.673,16.766,16.766,0,0,1,4.859,2.865C740.374,319.646,740.15,337.562,730.954,335.469Z'
                        transform='translate(-522.072 -245.558)'
                        fill='#2f2e41'
                    />
                    <path
                        id='Pfad_4045'
                        data-name='Pfad 4045'
                        d='M863.917,709.018a.525.525,0,0,0,.38-.91l-.036-.143.014-.034a1.409,1.409,0,0,1,2.6.01,12.513,12.513,0,0,1,1.1,3.133,4.172,4.172,0,0,1-.073,1.434,16.72,16.72,0,0,0,1.521-6.943,16.128,16.128,0,0,0-.1-1.8q-.083-.735-.23-1.46a16.913,16.913,0,0,0-3.354-7.167,4.5,4.5,0,0,1-1.872-1.942,3.433,3.433,0,0,1-.312-.938,4.237,4.237,0,0,0,.275-1.461c.127-.193.354-.289.493-.477.69-.935,1.64-.772,2.136.5,1.06.535,1.07,1.422.42,2.275-.414.543-.471,1.277-.834,1.858.037.048.076.094.114.142a17.023,17.023,0,0,1,1.777,2.814,7.077,7.077,0,0,1,.422-3.287,11.855,11.855,0,0,1,1.83-2.64,1.452,1.452,0,0,1,2.587.713l0,.037q-.149.084-.291.178a.712.712,0,0,0,.287,1.3l.014,0a7.078,7.078,0,0,1-.187,1.059c.856,3.311-.992,4.517-3.632,4.571-.058.03-.115.06-.173.088a17.375,17.375,0,0,1,.935,4.4,16.472,16.472,0,0,1-.012,2.661l0-.031a4.354,4.354,0,0,1,1.486-2.514c1.144-.94,2.76-1.286,3.994-2.041a.817.817,0,0,1,1.252.795l-.005.033a4.787,4.787,0,0,0-.536.258q-.149.084-.291.178a.712.712,0,0,0,.287,1.3l.015,0,.03,0a7.09,7.09,0,0,1-1.3,2.042c-.535,2.89-2.835,3.165-5.294,2.323h0a17.361,17.361,0,0,1-1.167,3.406h-4.168c-.015-.046-.028-.094-.042-.14a4.737,4.737,0,0,0,1.153-.069c-.309-.379-.618-.762-.928-1.141l-.019-.022c-.157-.194-.315-.387-.472-.581h0a6.938,6.938,0,0,1,.2-1.767Z'
                        transform='translate(-605.832 -459.103)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4046'
                        data-name='Pfad 4046'
                        d='M542.129,743.082l168.165.133a.514.514,0,1,0,0-1.027l-168.165-.133a.514.514,0,1,0,0,1.028Z'
                        transform='translate(-422.808 -488.854)'
                        fill='#cacaca'
                    />
                    <path
                        id='Pfad_4047'
                        data-name='Pfad 4047'
                        d='M514.854,561.464a6.1,6.1,0,1,1,6.1-6.1,6.1,6.1,0,0,1-6.1,6.1Zm0-11.344a5.241,5.241,0,1,0,5.24,5.241A5.241,5.241,0,0,0,514.854,550.12Z'
                        transform='translate(-404.124 -379.248)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4048'
                        data-name='Pfad 4048'
                        d='M521.969,559.44l-1.893-2.3a.558.558,0,0,0-.85-.014l-2.024,2.3a.558.558,0,1,0,.838.737l1.047-1.191v2.986a.558.558,0,1,0,1.116,0V559.05l.9,1.1a.558.558,0,1,0,.862-.709Z'
                        transform='translate(-408.85 -383.613)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4049'
                        data-name='Pfad 4049'
                        d='M675.854,352.464a6.1,6.1,0,1,1,6.1-6.1A6.1,6.1,0,0,1,675.854,352.464Zm0-11.344a5.241,5.241,0,1,0,5.241,5.241A5.241,5.241,0,0,0,675.854,341.12Z'
                        transform='translate(-495.653 -260.43)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4050'
                        data-name='Pfad 4050'
                        d='M682.969,350.44l-1.893-2.3a.558.558,0,0,0-.85-.014l-2.024,2.3a.558.558,0,0,0,.838.737l1.047-1.191v2.986a.558.558,0,0,0,1.116,0V350.05l.9,1.1a.558.558,0,1,0,.862-.709Z'
                        transform='translate(-500.379 -264.795)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4051'
                        data-name='Pfad 4051'
                        d='M356.854,340.464a6.1,6.1,0,1,1,6.1-6.1A6.1,6.1,0,0,1,356.854,340.464Zm0-11.344a5.241,5.241,0,1,0,5.241,5.241A5.241,5.241,0,0,0,356.854,329.12Z'
                        transform='translate(-314.3 -253.608)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4052'
                        data-name='Pfad 4052'
                        d='M363.969,338.44l-1.893-2.3a.558.558,0,0,0-.85-.014l-2.024,2.3a.558.558,0,1,0,.838.737l1.047-1.191v2.986a.558.558,0,1,0,1.116,0V338.05l.9,1.1a.558.558,0,1,0,.862-.709Z'
                        transform='translate(-319.026 -257.973)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4053'
                        data-name='Pfad 4053'
                        d='M469.854,167.464a6.1,6.1,0,1,1,6.1-6.1,6.1,6.1,0,0,1-6.1,6.1Zm0-11.344a5.241,5.241,0,1,0,5.241,5.241,5.241,5.241,0,0,0-5.241-5.241Z'
                        transform='translate(-378.541 -155.257)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4054'
                        data-name='Pfad 4054'
                        d='M476.969,165.44l-1.893-2.3a.558.558,0,0,0-.85-.014l-2.024,2.3a.558.558,0,1,0,.838.737l1.047-1.191v2.986a.558.558,0,1,0,1.116,0V165.05l.9,1.1a.558.558,0,1,0,.862-.709Z'
                        transform='translate(-383.267 -159.622)'
                        fill='#f2f2f2'
                    />
                    <path
                        id='Pfad_4055'
                        data-name='Pfad 4055'
                        d='M318.854,445.464a6.1,6.1,0,1,1,6.1-6.1,6.1,6.1,0,0,1-6.1,6.1Zm0-11.345a5.241,5.241,0,1,0,5.241,5.241,5.241,5.241,0,0,0-5.241-5.241Z'
                        transform='translate(-292.697 -313.301)'
                        fill='#fff'
                    />
                    <path
                        id='Pfad_4056'
                        data-name='Pfad 4056'
                        d='M325.969,443.44l-1.893-2.3a.558.558,0,0,0-.85-.014l-2.024,2.3a.558.558,0,1,0,.838.737l1.047-1.191v2.986a.558.558,0,1,0,1.116,0V443.05l.9,1.1a.558.558,0,0,0,.862-.709Z'
                        transform='translate(-297.423 -317.666)'
                        fill='#fff'
                    />
                    <path
                        id='Pfad_4057'
                        data-name='Pfad 4057'
                        d='M624.854,208.464a6.1,6.1,0,1,1,6.1-6.1A6.1,6.1,0,0,1,624.854,208.464Zm0-11.345a5.241,5.241,0,1,0,5.241,5.241,5.241,5.241,0,0,0-5.241-5.241Z'
                        transform='translate(-466.659 -178.565)'
                        fill='#fff'
                    />
                    <path
                        id='Pfad_4058'
                        data-name='Pfad 4058'
                        d='M631.969,206.44l-1.893-2.3a.558.558,0,0,0-.85-.014l-2.024,2.3a.558.558,0,0,0,.838.737l1.047-1.191v2.986a.558.558,0,0,0,1.116,0V206.05l.9,1.1a.558.558,0,0,0,.862-.709Z'
                        transform='translate(-471.386 -182.931)'
                        fill='#fff'
                    />
                </g>
            </svg>
            <Typography component='span' fontFamily='CircularProMedium' fontSize='14px' color='#212121' margin='24px' flexBasis='100%' textAlign='center'>
                {t('selectFileToUpload')}
            </Typography>
        </>
    );
}

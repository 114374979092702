import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { useOutletContext } from 'react-router-dom';
import { GetReasonsMenu } from '../../components/other/ConfigBasic';
import { useTranslation } from 'react-i18next';

export default function SharedMenu(props) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const currentReason = props.currentReason;
    const currentUserId = useOutletContext().loginUser.id;
    const logbookId = useOutletContext().logbookId;

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (option) => {
        setAnchorEl(null);
        handleChange(option);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const createReason = (option) => {
        return {
            label: currentReason.label,
            idPerson: option === t('all') ? null : currentUserId,
        };
    };

    const handleChange = (option) => {
        const newReason = createReason(option);

        //Reasons neu laden, falls ein anderer User bereits Änderungen vorgenommen hat. Vll muss ich hier mit LazyLoading arbeiten
        props.getReasons({
            variables: {
                id: logbookId,
            },
            onCompleted: (data) => {
                let loadedReasons = data.logbooks[0].reasons.map((reason) => {
                    return {
                        label: reason.label,
                        idPerson: reason.idPerson,
                    };
                });

                //Eventuell vorhandene Duplikate entfernen
                let uniqueReasons = loadedReasons.reduce((accumulator, current) => {
                    if (!accumulator.find((item) => item.label === current.label && item.idPerson === current.idPerson)) {
                        accumulator.push(current);
                    }
                    return accumulator;
                }, []);

                //Aktueller Reason der verändert wurde soll aus dem neu geladenem, um duplikate geprüftem Array entfernt werden
                let newReasons = uniqueReasons.filter((reason) => reason.label !== currentReason.label || reason.idPerson !== currentReason.idPerson);

                //Prüfen, ob der neue veränderte Reasons bereits im Array existiert
                let duplicate = newReasons.find((reason) => reason.label === newReason.label && reason.idPerson === newReason.idPerson);
                if (duplicate === undefined) {
                    newReasons.push(newReason);
                }
                props.changeLogbookReasons(newReasons);
            },
            onError: (error) => {
                props.setMessage(error.graphQLErrors[0].message);
            },
        });
    };

    const checkId = () => {
        return currentReason.idPerson !== null && currentReason.idPerson === currentUserId;
    };

    return (
        <div>
            <Button
                sx={{ fontSize: '14px' }}
                variant='outlined'
                color='primary'
                id='lock-button'
                aria-controls={open ? 'lock-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleButtonClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {checkId() ? t('onlyMe') : t('all')}
            </Button>
            <Menu
                id='lock-menu'
                sx={{ fontSize: '14px' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {GetReasonsMenu().map((option) => (
                    <MenuItem key={option} value={option} onClick={() => handleMenuItemClick(option)} sx={{ fontSize: '14px' }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

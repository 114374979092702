import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { calculateDistance } from './ConfigBasic';

export default function useDistance(oldAddress, newAddress, setMessage) {
    const newAddressFormat = (info) => {
        return `${info.street} ${info.houseNo}, ${info.zip}, ${info.city}`;
    };

    const [distance, setDistance] = useState(0);
    const newAddressFormated = newAddressFormat(newAddress);

    useEffect(() => {
        let isMounted = true;

        if (!newAddressFormated) return;

        const fetchAddress = async () => {
            const sessionId = uuidv4();
            try {
                const autocompleteResp = await fetch(`${process.env.REACT_APP_AUTOCOMLETE}input=${newAddressFormated}&session=${sessionId}&provider=google`);
                const autocompleteData = await autocompleteResp.json();

                if (autocompleteData.length > 0 && newAddressFormated.includes(' ')) {
                    const placeId = autocompleteData[0].placeId;
                    const detailsResp = await fetch(`${process.env.REACT_APP_PLACES_DETAILS}session=${sessionId}&provider=google&placeid=${placeId}`);
                    const detailsData = await detailsResp.json();
                    const location = await detailsData.location;
                    setDistance(calculateDistance(location.lat, location.long, oldAddress.latLon[0], oldAddress.latLon[1]));
                }
            } catch (err) {
                if (isMounted) setMessage(err.message);
            }
        };

        fetchAddress();

        return () => {
            isMounted = false;
        };
    }, [oldAddress, newAddressFormated, setMessage]);

    return distance;
}

import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, Box, InputBase, useMediaQuery, ListItem, ListItemText, Typography, List } from '@mui/material';
import theme from '../../themes/theme';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useRef } from 'react';
import GetCurrentReasons from './GetCurrentReasons';
import SharedMenuAdmin from './SharedMenuAdmin';
import { useSaveLogbookReasons } from '../../api/LogbookApi';

const getReasonsForLogbook = (changedAll, originalReasons, listOfChanges, t) => {
    const newReasonsMap = new Map();
    listOfChanges.forEach((cReason) => {
        newReasonsMap.set(cReason.label, cReason.idPerson);
    });

    if (!changedAll) {
        originalReasons.forEach((oReason) => {
            if (!newReasonsMap.has(oReason.label)) {
                newReasonsMap.set(oReason.label, oReason.idPerson);
            }
        });
    }

    const newListOfReasons = [];
    newReasonsMap.forEach((value, key) => {
        newListOfReasons.push({
            label: key,
            idPerson: value,
        });
    });

    return newListOfReasons.filter((reason) => reason.idPerson !== t('delete'));
};

/**
 *
 * @param {*} props
 * @returns
 */
export default function EditReasonDialog(props) {
    const { setMessage, selected, open, setOpen } = props;
    const { t } = useTranslation();
    const ismobil = useMediaQuery(theme.breakpoints.down('md'));
    const [originalData, setOriginalData] = useState(undefined);
    const allReasonsList = useRef([]);
    const listOfChanges = useRef([]);
    const [renderedList, setRenderedList] = useState([]);
    const [idPersonAll, setIdPersonAll] = useState(null);
    const savingResults = useRef([]);
    const { saveReasons } = useSaveLogbookReasons(savingResults);

    const setInitialData = (data) => {
        setOriginalData(data);
        const reasons = new Map();
        data.forEach((d, index) => {
            const reasonsInThisLogbook = new Map();
            d.reasons.forEach((reason) => {
                if (reasonsInThisLogbook.has(reason.label)) {
                    //Various found
                    //Reason exist more than once in one logbook
                    reasons.set(reason.label, t('various'));
                } else {
                    //Reason found in this logbook for the first time
                    reasonsInThisLogbook.set(reason.label, reason.idPerson);
                    if (index === 0) {
                        reasons.set(reason.label, reason.idPerson);
                    } else if (reasons.has(reason.label) && reasons.get(reason.label) === reason.idPerson) {
                        //Reason has so far been present in all logbooks, once with the identical value
                    } else {
                        //Various found
                        //Either this reason was not present in the previous logbooks
                        //Or this reason was present in the previous logbooks with a different value
                        reasons.set(reason.label, t('various'));
                    }
                }
            });

            reasons.forEach((value, key) => {
                if (!reasonsInThisLogbook.has(key)) {
                    //Various found
                    //A previous reason does not exist in this logbook
                    reasons.set(key, t('various'));
                }
            });
        });
        const reasonsList = [];
        reasons.forEach((value, key) => {
            reasonsList.push({
                id: key,
                label: key,
                idPerson: value,
            });
        });
        reasonsList.sort((a, b) => {
            if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
                return -1;
            } else if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        allReasonsList.current = reasonsList;
        setRenderedList(reasonsList);
        checkFrequenceIdPerson(reasonsList, reasonsList[0]?.idPerson);
    };

    const handleClose = () => {
        setOpen(false);
        setOriginalData(undefined);
        allReasonsList.current = [];
        listOfChanges.current = [];
        savingResults.current = [];
        setRenderedList([]);
        setIdPersonAll(null);
    };

    const checkFrequenceIdPerson = (reasonList, idPerson) => {
        const frequenceIdPerson = [];

        reasonList.forEach((reason) => {
            if (!frequenceIdPerson.includes(reason.idPerson)) {
                frequenceIdPerson.push(reason.idPerson);
            }
        });

        if (frequenceIdPerson.length === 1) {
            setIdPersonAll(idPerson);
        } else {
            setIdPersonAll(t('various'));
        }
    };

    /**
     * Checks that only changed values in the current list are updated
     * @param {array} newList, with updated value
     * @returns a list with updated values
     */
    const filterCheck = (newList) => {
        const ids = renderedList.map((reason) => reason.id);
        return newList.filter((reason) => ids.includes(reason.id));
    };

    const updateListOfChanges = (list, updateAll, reasonId, idPerson) => {
        if (updateAll) {
            listOfChanges.current = list;
        } else {
            const objIndex = listOfChanges.current.findIndex((obj) => obj.id === reasonId);
            if (objIndex < 0) {
                const objIndexAll = allReasonsList.current.findIndex((obj) => obj.id === reasonId);
                listOfChanges.current.push(allReasonsList.current[objIndexAll]);
            } else {
                listOfChanges.current[objIndex].idPerson = idPerson;
            }
        }
    };

    const updateImportReasons = (reasonId, idPerson) => {
        if (reasonId === null) {
            const newReasonsList = allReasonsList.current.map((reason) => {
                return {
                    ...reason,
                    idPerson: idPerson,
                };
            });
            checkFrequenceIdPerson(newReasonsList, idPerson);
            allReasonsList.current = newReasonsList;
            updateListOfChanges(newReasonsList, true, reasonId, idPerson);
            setRenderedList(filterCheck(newReasonsList));
        } else {
            const objIndex = allReasonsList.current.findIndex((obj) => obj.id === reasonId);
            allReasonsList.current[objIndex].idPerson = idPerson;
            checkFrequenceIdPerson(allReasonsList.current, idPerson);
            updateListOfChanges(allReasonsList.current, false, reasonId, idPerson);
            setRenderedList(filterCheck(allReasonsList.current));
        }
    };

    const filterListFunction = (event) => {
        const searchValue = event.target.value;
        if (searchValue.length === 0) {
            setRenderedList(allReasonsList.current);
        } else {
            setRenderedList(allReasonsList.current.filter((reason) => reason?.label?.toLowerCase().includes(searchValue.toLowerCase())));
        }
    };

    const save = async (idLogbook, reasons) => {
        await saveReasons({ variables: { id: idLogbook, reasons: reasons } });
        if (originalData.length === savingResults.current.length) {
            if (savingResults.current.includes('Error')) {
                let countErrors = savingResults.current.filter((sResult) => sResult === 'Error');
                countErrors.length > 1
                    ? setMessage(t('errorsUpdateMultipleLogbooks', { number: countErrors.length }))
                    : setMessage(t('errorUpdateMultipleLogbooks', { number: countErrors.length }));
            } else {
                setMessage(t('updateSuccessful'));
                handleClose();
            }
        }
    };

    const handleSave = () => {
        const newLogbooksReasons = [];
        const changedAll = listOfChanges.current.length === allReasonsList.current.length;

        originalData.forEach((reasonsPerLogbook) => {
            newLogbooksReasons.push({
                idLogbook: reasonsPerLogbook.idLogbook,
                reasons: getReasonsForLogbook(changedAll, reasonsPerLogbook.reasons, listOfChanges.current, t),
            });
        });
        newLogbooksReasons.forEach((logbookReasons) => save(logbookReasons.idLogbook, logbookReasons.reasons));
    };

    return (
        <>
            {open && originalData === undefined && <GetCurrentReasons selectedLogbooks={selected} setCurrentReasons={setInitialData} />}
            <Dialog open={open} fullScreen={ismobil}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },           
                }}
                >
                <DialogTitle sx={{ fontFamily: 'CircularProMedium', color: '#212121', padding: '24px 24px 16px 24px' }}>{t('editReasons')}</DialogTitle>
                <DialogContent sx={{ overflow: 'hidden', width: ismobil ? '100%' : '560px', padding:'0px 24px 0px 24px' }}>
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        padding='0 8px'
                        border={`solid 1px ${theme.palette.grey[600]}`}
                        borderRadius='4px'
                        marginBottom='8px'
                    >
                        <InputBase fullWidth placeholder={t('search')} onChange={(value) => filterListFunction(value)} />
                        <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                    </Box>
                    {allReasonsList.current?.length > 0 && (
                        <ListItem key={'reason-list-item-all'} disablePadding sx={{ padding: '4px 8px 8px 4px', width: ismobil ? '100% ' : '510px' }}>
                            <ListItemText
                                id={'reason-list-label-all'}
                                primary={
                                    <Typography
                                        sx={{
                                            display: 'block',
                                            fontSize: '16px',
                                            color: '#212121',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        component='span'
                                    >
                                        {t('all')}
                                    </Typography>
                                }
                                sx={{ width: 'calc(100% - 204px)' }}
                            />
                            <Box sx={{ width: '204px', textAlign: 'right' }}>
                                <SharedMenuAdmin
                                    reason={{
                                        id: null,
                                        label: null,
                                        idPerson: idPersonAll,
                                    }}
                                    updateImportReasons={updateImportReasons}
                                    extended={true}
                                />
                            </Box>
                        </ListItem>
                    )}
                    <List dense sx={{ height: ismobil ? `${window.innerHeight - 240}px` : '350px', overflow: 'scroll', overflowX: 'hidden', width: ismobil ? '100% ' : '510px' }}>
                        {renderedList?.length > 0 ? (
                            renderedList?.map((data) => {
                                const itemId = `reason-list-item-${data.label}`;
                                const labelId = `reason-list-label-${data.label}`;
                                return (
                                    <ListItem key={itemId} disablePadding sx={{ padding: '4px', position: 'relative' }}>
                                        <ListItemText
                                            id={labelId}
                                            primary={
                                                <Typography
                                                    sx={{
                                                        display: 'block',
                                                        fontSize: '16px',
                                                        color: '#212121',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        paddingRight: '8px',
                                                    }}
                                                    component='span'
                                                >
                                                    {data.label}
                                                </Typography>
                                            }
                                            sx={{ width: 'calc(100% - 204px)' }}
                                        />
                                        <Box sx={{ width: '204px', textAlign: 'right' }}>
                                            <SharedMenuAdmin reason={data} updateImportReasons={updateImportReasons} extended={true} />
                                        </Box>
                                    </ListItem>
                                );
                            })
                        ) : (
                            <ListItem>
                                <ListItemText>
                                    <Typography variant='caption' color='gray'>
                                        {t('noResult')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
                <DialogActions sx={{ height: '88px',padding: '24px'}}>
                    <Button variant='text' color='secondary' 
                        sx={{ fontSize: '14px', fontFamily: 'CircularProBold', height: '40px', marginBottom: '0px !important' }} 
                        onClick={() => handleClose()}>
                        {t('cancel')}
                    </Button>
                    <Button
                        variant='contained'
                        color='success'
                        sx={{ fontSize: '14px', fontFamily: 'CircularProBold', height: '40px', marginBottom: '0px !important' }}
                        disabled={listOfChanges.current.length === 0}
                        onClick={() => handleSave()}
                    >
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

import * as React from 'react';
import { CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import theme from '../../themes/theme';
import { useTranslation } from 'react-i18next';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { useContactIsDriverApi, useGetLogbookDriversApi, useUpdateDriverRegistration, useUpdateLogbookPrivateJourney } from '../../api/LogbookApi';
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { useState } from 'react';

/**
 * Return an empty String if no one is registered or
 * the contact person id from the registered driver
 * @param {Array} driverRegistration
 * @returns
 */
const checkDriverRegistration = (driverRegistration) => {
    const activDriver = driverRegistration?.filter((dR) => dR.end === null);

    if (activDriver === undefined || activDriver.length === 0) {
        return '';
    } else {
        return activDriver[0]?.idContactPerson;
    }
};

/**
 * Return an empty String if the user is an invalid driver of this logbook
 * otherwise the contact person id from the user
 * @param {Object} allDriverData
 * @param {Array} driverIds
 * @param {Object} loginUser
 */
const checkUserIsLogbookDriver = (allDriverData, driverIds, loginUser) => {
    let logbookDriverData = allDriverData.filter((driver) => driverIds.includes(driver.id) && driver.loginUuid === loginUser.loginUuid);
    if (logbookDriverData.length === 0) {
        return '';
    } else {
        return logbookDriverData[0].id;
    }
};

export default function StatusLink(props) {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const currentLogbook = useOutletContext().logbookId;
    const driverIds = useGetLogbookDriversApi(currentLogbook);
    const allDriverData = useContactIsDriverApi(setMessage);
    const loginUser = useOutletContext().loginUser;
    let userDriverid = '';
    let registeredDriverId = '';
    const isPrivatModusActive = props.isPrivatModusActive;
    const { updateDriverRegistration } = useUpdateDriverRegistration();
    const { updateLogbookPrivateJourney } = useUpdateLogbookPrivateJourney();

    useEffect(() => {
        if (props.connectionStatus > 0 && props.connectionStatus < 1) {
        } else if (
            userDriverid?.length > 0 &&
            registeredDriverId?.length > 0 &&
            userDriverid === registeredDriverId &&
            props?.connectionStatus !== 2 &&
            props?.connectionStatus !== 3
        ) {
            props?.setConnectionStatus(2);
        } else if (userDriverid.length > 0 && registeredDriverId.length > 0 && userDriverid !== registeredDriverId && props?.connectionStatus !== 1) {
            props?.setConnectionStatus(1);
        } else if (registeredDriverId?.length === 0 && props?.connectionStatus !== 0 && props?.connectionStatus !== 2) {
            props?.setConnectionStatus(0);
        }
    });

    if (!allDriverData || !driverIds) {
        return <LinearProgress />;
    }

    userDriverid = checkUserIsLogbookDriver(allDriverData, driverIds, loginUser);
    registeredDriverId = checkDriverRegistration(props.driverRegistration);

    const checkStatus = () => {
        if (userDriverid.length === 0) {
            props.setMessage(t('notADriver'));
            props.setConnectionStatus(0);
        } else if (userDriverid.length > 0 && registeredDriverId.length === 0) {
            updateDriverRegistration({
                variables: { id: currentLogbook, register: true },
                onCompleted: () => {
                    props.setMessage(t('driverLoginInfo'));
                    props.setConnectionStatus(2);
                },
                onError: (error) => {
                    props.setMessage(error.graphQLErrors[0].message);
                    props.setConnectionStatus(0);
                },
                refetchQueries: ['getAssetDataForStatus'],
            });
        } else if (userDriverid.length > 0 && registeredDriverId.length > 0 && userDriverid !== registeredDriverId) {
            props.setMessage(t('otherDriverRegistered'));
            props.setConnectionStatus(0);
        }
    };

    const handleConnection = () => {
        props.setConnectionStatus(0.5); // set the status to loading
        new Promise(
            (resolve) => setTimeout(() => resolve(), 500) // simulate a delay
        ).then(() => {
            checkStatus();
        });
    };

    return (
        <>
            {props.connectionStatus === 0 && (
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={1}
                    sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        bgcolor: theme.palette.primary.light,
                        width: '226px',
                        height: '48px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleConnection}
                >
                    <Typography sx={{ fontFamily: 'CircularProBold', textTransform: 'uppercase' }}>{t('registerAsDriver')}</Typography>
                    <LoginIcon
                        sx={{
                            height: '100%',
                            verticalAlign: 'middle',
                        }}
                    />
                </Stack>
            )}
            {props.connectionStatus > 0 && props.connectionStatus < 1 && (
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        bgcolor: theme.palette.primary.light,
                        width: '226px',
                        height: '48px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <CircularProgress
                        size={30}
                        color='secondary'
                        sx={{
                            height: '100%',
                            verticalAlign: 'middle',
                        }}
                    />
                </Stack>
            )}
            {props.connectionStatus === 1 && (
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={1}
                    sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        bgcolor: theme.palette.grey[300],
                        width: 'auto',
                        height: '48px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        padding: '0 16px',
                        color: theme.palette.grey[600],
                    }}
                    onClick={handleConnection}
                >
                    <Typography sx={{ fontFamily: 'CircularProBold', textTransform: 'uppercase' }}>{t('otherDriverRegistered')}</Typography>
                    <LoginIcon
                        sx={{
                            height: '100%',
                            verticalAlign: 'middle',
                        }}
                    />
                </Stack>
            )}
            {props.connectionStatus === 2 && (
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={1}
                    sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        bgcolor: theme.palette.primary.light,
                        width: '48px',
                        height: '48px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <LogoutIcon
                        sx={{
                            height: '100%',
                            verticalAlign: 'middle',
                        }}
                        onClick={() => props.setConnectionStatus(3)}
                    />
                </Stack>
            )}
            {props.connectionStatus === 3 && (
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={1}
                    sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        bgcolor: '#FBE9E7',
                        width: '226px',
                        height: '48px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        if (isPrivatModusActive) {
                            updateLogbookPrivateJourney({
                                variables: {
                                    idLogbook: currentLogbook,
                                    privateJourneyOn: !isPrivatModusActive,
                                },
                                onCompleted: () => {
                                    updateDriverRegistration({
                                        variables: { id: currentLogbook, register: false },
                                        onCompleted: () => {
                                            props.setMessage(t('driverLogoutInfoExtension'));
                                        },
                                        onError: (error) => {
                                            props.setMessage(error.graphQLErrors[0].message);
                                        },
                                        refetchQueries: ['getAssetDataForStatus', 'getLogbookPrivateJourney'],
                                    });
                                },
                                onError: (error) => {
                                    props.setMessage(error.graphQLErrors[0].message);
                                },
                            });
                        } else {
                            updateDriverRegistration({
                                variables: { id: currentLogbook, register: false },
                                onCompleted: () => {
                                    props.setMessage(t('driverLogoutInfo'));
                                },
                                onError: (error) => {
                                    props.setMessage(error.graphQLErrors[0].message);
                                },
                                refetchQueries: ['getAssetDataForStatus'],
                            });
                        }
                    }}
                >
                    <Typography sx={{ fontFamily: 'CircularProBold', textTransform: 'uppercase' }}>{t('driverLogout')}</Typography>
                    <LogoutIcon
                        sx={{
                            height: '100%',
                            verticalAlign: 'middle',
                        }}
                    />
                </Stack>
            )}
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

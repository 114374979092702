import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import theme from '../../themes/theme';
import 'dayjs/locale/de';
import i18n from 'i18next';

export default function DatePickerCosts(props) {
    return (
        <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
            <DatePicker
                value={props.date}
                openTo='month'
                views={['month', 'year']}
                onChange={(newValue) => {
                    props.handleChange(newValue.$d);
                }}
                renderInput={(params) => <TextField {...params} sx={theme.components.MuiDatePicker} />}
            />
        </LocalizationProvider>
    );
}

import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import StepperMobile from './Components/StepperMobile';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';


// Importing images for onboarding
import welcomImage from '../other/image-onboarding-welcome.svg';
import startImage from '../other/image-onboarding-Start.png';
import statusImage from '../other/image-onboarding-Status.png';
import openImage from '../other/image-onboarding-Offen.png';
import editImage from '../other/image-onboarding-Bearbeitung.png';
import costsImage from '../other/image-onboarding-Kosten.png';
import einStellungImage from '../other/image-onboarding-Einstellung.png';

function MobileOnBoarding({ showOnBoarding, setShowOnBoardin, handleComplete }) {
    const { t } = useTranslation();
    const [next, setNext] = useState(0);
    const [onBoardingStart, setOnBoardingStart] = useState(true);
    const [touchStartX, setTouchStartX] = useState(null);


    // Each object represents a step in the onboarding process
    const onBoardingContant = [
        {
            image: startImage,
            content: t('introStepOne'),
            name: t('introStepOneName')
        },
        {
            image: statusImage,
            content: t('vehicleStatusContent'),
            name: t('vehicleStatus'),
        },
        {
            image: openImage,
            content: t('introStepTwoMobile'),
            name: t('openTours')
        },
        {
            image: editImage,
            content: t('introStepThree'),
            name: t('editedTours')
        },
        {
            image: costsImage,
            content: t('introStepFour'),
            name: t('costStepIntro')
        },
        {
            image: einStellungImage,
            content: t('introStepFive'),
            name: t('settings')
        },
        {
            image: einStellungImage,
            content: t('introStepFive'),
            name: t('settings')
        }

    ]


    // Functions to manage onboarding flow
    const startOnBoarding = () => {
        setOnBoardingStart(!onBoardingStart);
    };

    const handleNext = () => {
        if (next < onBoardingContant.length - 1) {
            setNext(prevNext => prevNext + 1);
        }
    };

    const handlePrevious = () => {
        if (next > 0) {
            setNext(prevNext => prevNext - 1);
        }
    };

    const handleSkip = () => {
        setOnBoardingStart(!onBoardingStart);
        handleComplete();
    };
    // Functions to detect swipe gestures for navigationtha
    const handleTouchStart = (event) => {
        setTouchStartX(event.touches[0].clientX);
    };

    const handleTouchEnd = (event) => {
        const touchEndX = event.changedTouches[0].clientX;
        // Detect swipe to the right (previous slide)
        if (touchEndX - touchStartX > 50) {
            handlePrevious();
        }
        // Detect swipe to the left (next slide)
        else if (touchStartX - touchEndX > 50) {
            if (onBoardingStart) {
                setOnBoardingStart(false);
            } else {
                handleNext();
            }
        }
    };
    return (
        <>
            {showOnBoarding &&
                <Card id='onBoarding' sx={{
                    width: "100dvw", height: "100dvh", zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute',
                    inset: 0,
                    bgcolor: '#B9F6CA',
                    borderRadius: 0
                }}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >

                    {onBoardingStart && <>
                        <Box sx={{ mt: { xs: '70px', sm: '60px' }, marginInline: 'auto', mb: '47px', textAlign: 'center' }}>
                            <img src={welcomImage} alt="Start onBoarding" style={{ width: '100%', maxWidth: '560px', minWidth: '296px', paddingInline: '32px' }} />
                        </Box>
                        <CardContent sx={{ width: "100%", paddingInline: '36px', m: 0, paddingBlock: 0 }}>
                            <Typography variant='h2' sx={{ fontFamily: 'CircularProMedium', fontSize: '16px' }}>
                                {t('welcomToFB')}
                            </Typography>
                            <Typography sx={{ fontFamily: 'CircularProBook' }} >
                                {t('guideText')}
                            </Typography>
                        </CardContent>
                        <Stack sx={{ position: 'fixed', bottom: '24px', right: '24px', }} direction='row' spacing={2.6}>
                            <Button onTouchEnd={handleSkip} sx={{ color: '#757575', fontFamily: 'CircularProBold', p: 0 }}>
                                {t('skip')}
                            </Button>
                            <Button onClick={startOnBoarding} sx={{ fontFamily: 'CircularProBold', p: 0 }}>
                                {t('letsgo')}
                            </Button>
                        </Stack>
                    </>}


                    {!onBoardingStart && <>
                        <Box sx={{ mt: { xs: '70px', md: '24px' }, marginInline: 'auto', mb: '10px', textAlign: 'center', textJustify: 'justify' }}>
                            <img src={onBoardingContant[next].image} alt="onBoarding" style={{ width: '100%', maxWidth: '560px', minWidth: '296px', paddingInline: '32px' }} />
                        </Box>
                        <CardContent sx={{ maxWidth: '560px', paddingInline: '36px', m: 0, paddingBlock: 0 }}>
                            <Typography variant='h2' sx={{ mb: '16px', fontFamily: 'CircularProMedium', fontSize: '16px' }}>
                                {onBoardingContant[next].name}
                            </Typography>
                            <Typography >
                                {onBoardingContant[next].content}
                            </Typography>
                        </CardContent>
                        <StepperMobile setShowOnBoarding={setShowOnBoardin} activeStep={next} setActiveStep={setNext} handleComplete={handleComplete} handleNext={handleNext} /> </>}


                </Card >}
        </>
    )
}

export default MobileOnBoarding
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'dayjs/locale/de';
import i18n from 'i18next';

function DatePickerDesktop({ state, setState }) {
    const { t } = useTranslation();
    const datePickerStyle = {
        width: '232px',
        height: '48px',
        p: 0,
        m: 0,
        borderRadius: '12px',

        input: {
            width: '232px',
            height: '100%',
            p: '14px',
        },
        label: {
            p: 0,
            m: 0,
        },
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Box
                sx={{
                    bgcolor: 'white',
                    width: '266px',
                    height: '80px',
                    border: '1px solid #E0E0E0',
                    borderRadius: '12px',
                    p: 2,
                }}
            >
                <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['month', 'year']}
                        label={t('monthandYear')}
                        value={state}
                        openTo='month'
                        onChange={(newValue) => {
                            setState(moment(newValue.$d));
                        }}
                        renderInput={(params) => <TextField {...params} error={false} sx={datePickerStyle} />}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
}

export default DatePickerDesktop;

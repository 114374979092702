import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import RouteIcon from '@mui/icons-material/Route';
import PaymentsIcon from '@mui/icons-material/Payments';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Badge from '@mui/material/Badge';
import EditedSvg from '../other/EditedSvg';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMenuIndex } from '../other/ConfigBasic';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationHelper from './NavigationHelper';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AddIcon from '@mui/icons-material/Add';
import theme from '../../themes/theme';
import InvoiceReceipt from '../other/InvoiceReceipt';

export default function NavigationRail(props) {
    const { badgePendingLogbooks } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    const editAgain = NavigationHelper();
    const upperMenuItems = location.pathname.includes('/admin')
        ? [
              {
                  name: t('administration'),
                  icon: (
                      <Badge badgeContent={badgePendingLogbooks} color='error'>
                          <DashboardIcon color='secondary' />
                      </Badge>
                  ),
                  onClick: () => navigate('/admin'),
              },
              {
                  name: t('costs'),
                  icon: (
                      <PaymentsIcon
                          color='secondary'
                          sx={{
                              '&:active': {
                                  backgroundColor: '#b9f6ca',
                              },
                          }}
                      />
                  ),
                  onClick: () => navigate('/admin/costs'),
              },
          ]
        : [
              {
                  name: t('open'),
                  icon: (
                      <Badge badgeContent={props.badge} color='error'>
                          <RouteIcon color='secondary' />
                      </Badge>
                  ),
                  onClick: () => navigate('/open'),
                  boardingStep: 'onBoarding_Step1',
              },
              {
                  name: t('edited'),
                  icon: <EditedSvg />,
                  onClick: () => navigate('/edited'),
                  boardingStep: 'onBoarding_Step2',
              },
              {
                  name: t('receipts'),
                  icon: <InvoiceReceipt size={24} fill={'#00360F'} />,
                  onClick: () => navigate('/costsOverview'),
                  boardingStep: 'onBoarding_Step3',
              },
              {
                  name: t('settings'),
                  icon: props.showHint?.settings ? (
                      <Badge badgeContent={<PriorityHighIcon />} color='error'>
                          <SettingsIcon color='secondary' />
                      </Badge>
                  ) : (
                      <SettingsIcon color='secondary' />
                  ),
                  onClick: () => navigate('/settings'),
                  boardingStep: 'onBoarding_Step4',
              },
          ];

    const navigationRailContent = (
        <>
            <List>
                {upperMenuItems.map((item, index) => (
                    <ListItem key={item.name} disablePadding sx={{ display: 'block', padding: '0 4px 8px 4px' }} id={item.boardingStep}>
                        <ListItemButton
                            selected={index === getMenuIndex(location.pathname, editAgain)}
                            sx={{
                                height: '40px',
                                justifyContent: 'center',
                                padding: '0',
                                margin: '0 4px',
                            }}
                            onClick={item.onClick}
                        >
                            <ListItemIcon sx={{ minWidth: 0 }}>{item.icon}</ListItemIcon>
                        </ListItemButton>
                        <Typography
                            sx={{
                                margin: '0',
                                padding: '0',
                                fontSize: '12px',
                                textAlign: 'center',
                            }}
                        >
                            {item.name}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </>
    );

    return (
        <Drawer sx={{ left: '0px', position: 'fixed' }} position='fixed' variant='permanent' open={true}>
            <Toolbar />
            <Box
                sx={{
                    backgroundColor: 'success.main',
                    width: '80px',
                    height: '100%',
                    paddingTop: '4px',
                }}
            >
                {location.pathname.includes('/admin') && (
                    <Box
                        onClick={() => navigate('admin/logbooksCreate')}
                        sx={{
                            backgroundColor: '#EAFFF0',
                            height: '56px',
                            width: '56px',
                            borderRadius: '16px',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            top: '-4px',
                            cursor: 'pointer',
                            marginBottom: '28px',
                            '&:hover': {
                                backgroundColor: '#EEEEEE',
                            },
                        }}
                    >
                        <AddIcon color={theme.palette.secondary.main} />
                    </Box>
                )}
                {navigationRailContent}
            </Box>
        </Drawer>
    );
}

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from '../../App';
import Create from './create';
import Tour from './tour';
import History from './history';
import Dashboard from './dashboard';
import CostsOverview from './costsOverview';
import Costs from './costs';
import Settings from '../Setting/settings';
import Error from './errors/error';
import NoPage from './errors/noPage';
import { ApolloProvider } from '@apollo/client';
import { client } from '../../api/ApolloClient';
import Unauthorized from './errors/401';
import Mixed from './mixed';
import Status from './status';
import { useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../../components/other/ConfigBasic';
import Legal from './Legal';
import Admin from './Admin';
import CreateLogBooks from './CreateLogBooks';
import AdminCostOverview from './AdminCostOverview';

export default function LogbookApp() {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    return (
        <BrowserRouter basename='/v2'>
            <Routes>
                <Route
                    path='/'
                    element={
                        <ApolloProvider client={client}>
                            <App />
                        </ApolloProvider>
                    }
                >
                    {desktop900 ? <Route index element={<Dashboard />} /> : <Route index element={<Status />} />}
                    <Route exact path='create' element={<Create />} />
                    <Route path='edit/:id' element={<Tour />} />
                    <Route path='edited' element={<History />} />
                    <Route path='settings' element={<Settings />} />
                    <Route path='costsOverview' element={<CostsOverview />} />
                    <Route path='costs/:id?' element={<Costs />} />
                    <Route path='status' element={<Status />} />
                    <Route path='error/:code' element={<Error />} />
                    <Route path='mixed/:id' element={<Mixed />} />
                    <Route path='legal' element={<Legal />} />
                    <Route path='/admin' element={<Admin />} />
                    <Route exact path='/admin/logbooksCreate' element={<CreateLogBooks />} />
                    <Route exact path='/admin/costs' element={<AdminCostOverview />} />
                    <Route path='open/:id?' element={<Dashboard />} />

                    <Route path='*' element={<NoPage />} />
                    <Route path='unauthorized' element={<Unauthorized />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

import * as React from 'react';
import SortIcon from '@mui/icons-material/Sort';
import { Box, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import theme from '../../themes/theme';
import ToursTableMobileList from './ToursTableMobileList';

export default function ToursTableMobile(props) {
    const { order, setOrder, setScrollDown } = props;
    const modifiedTourList = [...props.modifiedTourList].sort((a, b) => {
        if (a.dtBegin < b.dtBegin) {
            return order === 'asc' ? -1 : 1;
        } else if (a.dtBegin > b.dtBegin) {
            return order === 'asc' ? 1 : -1;
        } else {
            return 0;
        }
    });

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    left: '0',
                    top: '140px',
                    maxHeight: `${window.innerHeight - 196}px`,
                    width: '100%',
                }}
            >
                <Button
                    variant='outlined'
                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                    startIcon={
                        order === 'asc' ? (
                            <ArrowUpwardIcon sx={{ color: theme.palette.grey[600], margin: '0' }} />
                        ) : (
                            <ArrowDownwardIcon sx={{ color: theme.palette.grey[600], margin: '0' }} />
                        )
                    }
                    endIcon={<SortIcon sx={{ color: theme.palette.grey[600], margin: '0' }} />}
                    sx={{
                        marginLeft: '16px',
                        borderRadius: '4px',
                        borderColor: theme.palette.grey[600],
                        padding: '7px 8px',
                        ':hover': { borderColor: theme.palette.grey[600] },
                    }}
                ></Button>
                <ToursTableMobileList modifiedTourList={modifiedTourList} setScrollDown={setScrollDown} getRowStyle={props.getRowStyle} />
            </Box>
        </>
    );
}

import CreateLogbookForm from '../../components/Tour/CreateLogbookForm';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

export default function Create() {
    return (
        <Box className='content' sx={{ flexGrow: 1 }}>
            <Grid container>
                <Grid xs={12} sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4}>
                    <CreateLogbookForm />
                </Grid>
            </Grid>
        </Box>
    );
}

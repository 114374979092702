import * as React from 'react';
import { ListItemText, List, Menu, ListItem, ListItemButton, Box, InputBase, ListItemAvatar, Avatar, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getFullname, getLetterAvatar } from '../other/ConfigBasic';
import theme from '../../themes/theme';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function AddDriverFilter(props) {
    const { selectedDriver, setSelectedDriver, onFilterChange, contactDriverData, emptyListMessage, showEmptyListAction, emptyListActionMessage } = props;
    const [driverList, setDriverList] = useState(contactDriverData);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        setDriverList(contactDriverData);
    }, [contactDriverData]);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDriverName = () => {
        if (selectedDriver !== '' && selectedDriver !== null) {
            const driverFullname = contactDriverData.filter((driver) => driver.id === selectedDriver);
            return getFullname(driverFullname[0]);
        }
    };

    const filterListFunction = (event) => {
        const searchValue = event.target.value;
        if (searchValue.length === 0) {
            setDriverList(contactDriverData);
        } else {
            setDriverList(
                contactDriverData.filter(
                    (driver) => driver?.firstname?.toLowerCase().includes(searchValue.toLowerCase()) || driver?.lastname?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    };

    const handleSelectItem = (event, name) => {
        setAnchorEl(null);
        setSelectedDriver(event.currentTarget.id);
        onFilterChange({ name: 'Driver', value: event.currentTarget.id });
    };

    const handleRemove = () => {
        setSelectedDriver('');
        onFilterChange({ name: 'Driver', value: '' });
    };

    return (
        <>
            <Button
                variant='none'
                color='primary'
                id='driver-button'
                aria-controls={open ? 'driver-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={selectedDriver === '' || selectedDriver === null ? handleButtonClick : null}
                endIcon={selectedDriver === '' || selectedDriver === null ? <ArrowDropDownIcon /> : <ClearIcon onClick={handleRemove} />}
                sx={{ fontSize: '14px', borderRadius: '10px', border: selectedDriver === null ||  selectedDriver ===  '' ?  '1px solid #757575' : '', textTransform: 'capitalize !important', backgroundColor: selectedDriver === null ||  selectedDriver ===  '' ? '' : '#B9F6CA' }}
            >
                {selectedDriver === '' || selectedDriver === null ? t('driver') : getDriverName()}
            </Button>
            <Menu
                id='driver-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'driver-button',
                    role: 'listbox',
                }}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    padding='0 8px'
                    border={`solid 1px ${theme.palette.grey[600]}`}
                    borderRadius='4px'
                    marginBottom='8px'
                >
                    <InputBase placeholder={t('search')} onChange={(value) => filterListFunction(value)} />
                    <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                </Box>
                <List dense sx={{ height: '200px', overflow: 'scroll', overflowX: 'hidden' }}>
                    {driverList?.length > 0 &&
                        driverList?.map((data) => {
                            return (
                                <ListItem key={data.id} id={data.id} value={getFullname(data)} disablePadding onClick={(event) => handleSelectItem(event, getFullname(data))}>
                                    <ListItemButton sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={getFullname(data)}
                                                src={data.imageUri ? `${process.env.REACT_APP_AWS_CONTACT_PATH}${data.imageUri}` : ''}
                                                sx={{ color: '#212121', fontSize: '14px', backgroundColor: '#E0E0E0' }}
                                            >
                                                {getLetterAvatar(data)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText id={data.id} primary={getFullname(data)} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    {driverList?.length === 0 && (
                        <ListItem>
                            <ListItemText>
                                <Typography variant='caption' color='gray'>
                                    {emptyListMessage}
                                </Typography>
                                {showEmptyListAction && (
                                    <Typography variant='h4' color='#00360F' sx={{ mt: '40px' }}>
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            variant='subtitle1'
                                            style={{
                                                color: '#00360F',
                                                textDecoration: 'none',
                                            }}
                                            href={process.env.REACT_APP_ADMIN_CONTACTS}
                                        >
                                            {emptyListActionMessage}
                                        </a>
                                    </Typography>
                                )}
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </Menu>
        </>
    );
}

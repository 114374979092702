import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { useOutletContext } from 'react-router-dom';
import { GetReasonsMenu, GetReasonsMenuExtended } from '../../components/other/ConfigBasic';
import { useTranslation } from 'react-i18next';

export default function SharedMenuAdmin(props) {
    const { reason, updateImportReasons, extended } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const currentUserId = useOutletContext().loginUser.id;

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getIdPersonValue = (option) => {
        switch (option) {
            case t('all'):
                return null;
            case t('onlyMe'):
                return currentUserId;
            case t('delete'):
                return t('delete');
            default:
                return null;
        }
    };

    const handleChange = (reasonId, option) => {
        updateImportReasons(reasonId, getIdPersonValue(option));
    };

    const handleMenuItemClick = (reasonId, option) => {
        handleClose();
        handleChange(reasonId, option);
    };

    const getMenuValue = (idPerson) => {
        switch (idPerson) {
            case null:
                return t('all');
            case '–':
                return '–';
            case t('delete'):
                return t('delete');
            case t('various'):
                return t('various');
            case currentUserId:
                return t('onlyMe');
            default:
                return t('otherUser');
        }
    };

    return (
        <div>
            <Button
                sx={{ fontSize: '14px' }}
                variant='outlined'
                color='primary'
                id='lock-button'
                aria-controls={open ? 'lock-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleButtonClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {getMenuValue(reason.idPerson)}
            </Button>
            <Menu
                id='lock-menu'
                sx={{ fontSize: '14px' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {extended
                    ? GetReasonsMenuExtended().map((option) => (
                          <MenuItem key={option} value={option} onClick={() => handleMenuItemClick(reason.id, option)} sx={{ fontSize: '14px' }}>
                              {option}
                          </MenuItem>
                      ))
                    : GetReasonsMenu().map((option) => (
                          <MenuItem key={option} value={option} onClick={() => handleMenuItemClick(reason.id, option)} sx={{ fontSize: '14px' }}>
                              {option}
                          </MenuItem>
                      ))}
            </Menu>
        </div>
    );
}

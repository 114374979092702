import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { MapContainer, Marker, Polyline, TileLayer, useMap, Tooltip } from 'react-leaflet';
import { Icon } from 'leaflet';
import startIcon from '../other/tour-start-icon.svg';
import endIcon from '../other/tour-end-icon.svg';
import axios from 'axios';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import TourPoint from '../other/TourPoint';

const getTourInfos = (lat, long, callback, setMessage) => {
    const baseUrl = process.env.REACT_APP_GEOCODER_API;
    axios({
        method: 'get',
        url: `${baseUrl}${lat},${long}`,
    })
        .then((response) => callback(response.data))
        .catch((error) => setMessage(error.message));
};

function TourMap({ startPosition, endPosition, tourPointList, altLan, setHoverdPoint, changeSelectedStopps, stopPoints, hoverPoint }) {
    const [message, setMessage] = useState('');
    const [address, setAddress] = useState('');

    const firstUpdate = useRef(true);

    function ChangeView({ bounds }) {
        const map = useMap();

        useEffect(() => {
            const updateView = () => {
                if (!map || !map._container) {
                    return;
                }
                if (firstUpdate.current && bounds && bounds.length >= 2) {
                    map.fitBounds(bounds);
                    firstUpdate.current = false;
                }
            };

            updateView();
            window.addEventListener('resize', updateView);

            return () => window.removeEventListener('resize', updateView);
        }, [map, bounds]);

        return null;
    }

    const handleMarkerMouseOver = useCallback(
        (point, index) => {
            setHoverdPoint(point);
            getTourInfos(point?.latlng[0], point?.latlng[1], setAddress, setMessage);
        },
        [setHoverdPoint, setMessage]
    );

    const handleMarkerMouseOut = useCallback(() => {
        setHoverdPoint([]);
    }, [setHoverdPoint]);

    useEffect(() => {
        if (address === '' && altLan.length > 0) {
            getTourInfos(altLan[0], altLan[1], setAddress, setMessage);
        }
    }, [altLan, address, startPosition, hoverPoint]);

    const tourStartIcon = useMemo(() => new Icon({ iconUrl: startIcon, iconSize: [40, 46.67] }), []);

    const tourEndtIcon = useMemo(() => new Icon({ iconUrl: endIcon, iconSize: [40, 46.67] }), []);

    const stroks = useMemo(
        () => [startPosition?.latlng, ...tourPointList?.map((element) => element?.latlng), endPosition?.latlng],
        [startPosition?.latlng, endPosition?.latlng, tourPointList]
    );

    const bounds = stroks.filter(Boolean);

    return (
        <div style={{ height: '100%', margin: 0, padding: 0, width: '100%' }} id='map'>
            <MapContainer center={startPosition?.latlng || [0, 0]} zoom={13} attributionControl={false}>
                <ChangeView bounds={bounds} />
                <TileLayer
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={startPosition?.latlng} icon={tourStartIcon} />
                <Polyline positions={stroks} color='red' />

                {tourPointList?.map((point, index) => {
                    if (altLan[0] === point?.latlng[0] && altLan[1] === point?.latlng[1]) {
                        return (
                            <Marker
                                position={point?.latlng}
                                icon={
                                    new Icon({
                                        iconUrl: `data:image/svg+xml,${encodeURIComponent(TourPoint('black', point?.heading))}`,
                                        iconSize: [25, 26.67],
                                    })
                                }
                                key={index}
                            />
                        );
                    }
                    return null;
                })}

                {stopPoints?.map((point, index) => (
                    <Marker
                        position={point?.latlng}
                        icon={
                            new Icon({
                                iconUrl: `data:image/svg+xml,${encodeURIComponent(TourPoint('red', point?.heading))}`,
                                iconSize: [25, 26.67],
                            })
                        }
                        key={point.date}
                        eventHandlers={{
                            click: () => changeSelectedStopps(address, point),
                            mouseover: () => handleMarkerMouseOver(point, index),
                            mouseout: handleMarkerMouseOut,
                        }}
                    >
                        <Tooltip permanent={false}>
                            <Stack spacing={1} sx={{ borderRadius: '12px' }}>
                                <Stack direction='row' justifyContent='space-between'>
                                    <Typography sx={{ fontSize: '16px', fontFamily: 'CircularProBold' }}>{moment(point?.date).format('HH:mm:SS')} Uhr</Typography>
                                    <Typography sx={{ fontSize: '14px', fontFamily: 'CircularProBook' }}>{point?.speed || '-'} Kmh</Typography>
                                </Stack>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'CircularProBook' }}>
                                    {address?.street} {address?.house}, {address?.postal} {address?.city}
                                </Typography>
                            </Stack>
                        </Tooltip>
                    </Marker>
                ))}

                <Marker position={endPosition?.latlng} icon={tourEndtIcon} />
            </MapContainer>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </div>
    );
}

export default TourMap;

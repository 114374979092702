import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, LinearProgress, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useGetSelectedMixedTour } from '../../api/LogbookApi';
import { useState } from 'react';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { getBreakPoints } from '../other/ConfigBasic';
import theme from '../../themes/theme';

const SPLIT_LOGBOOKTOUR = gql`
    mutation splitLogbooktour($idLogbookTour: ID!, $timestamps: [DateTime]!) {
        splitLogbooktour(idLogbookTour: $idLogbookTour, timestamps: $timestamps) {
            id
        }
    }
`;

export default function MixedTourActionBar(props) {
    const [splitLogbooktour] = useMutation(SPLIT_LOGBOOKTOUR);
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const tourData = useGetSelectedMixedTour(props.tourId, setMessage);
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const splitTour = (tourId, timeStamps) => {
        splitLogbooktour({
            variables: {
                idLogbookTour: tourId,
                timestamps: timeStamps,
            },
            onCompleted: () => {
                navigate('/open', {
                    state: {
                        snackbarMessage: t('splittedTourSuccessfully'),
                    },
                });
            },
            onError: (error) => {
                setMessage(error.graphQLErrors[0].message);
            },
        });
    };

    if (!tourData) {
        return (
            <>
                <LinearProgress />
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }

    const getTourInfos = (tourData) => {
        let tourInfo;

        if (tourData[0].streetBegin === null && tourData[0].postalBegin === null && tourData[0].cityBegin === null) {
            tourInfo = 'n.a. ';
        } else {
            tourInfo = `${tourData[0].streetBegin !== null ? tourData[0].streetBegin + ', ' : ''} 
                        ${tourData[0].postalBegin !== null ? tourData[0].postalBegin + ' ' : ''}
                        ${tourData[0].cityBegin !== null ? tourData[0].cityBegin + ' ' : ''}`;
        }

        tourInfo = tourInfo + '- ';

        if (tourData[0].streetEnd === null && tourData[0].postalEnd === null && tourData[0].cityEnd === null) {
            tourInfo = tourInfo + ' n.a.';
        } else {
            tourInfo =
                tourInfo +
                `${tourData[0].streetEnd !== null ? tourData[0].streetEnd + ', ' : ''}
                        ${tourData[0].postalEnd !== null ? tourData[0].postalEnd + ' ' : ''}
                        ${tourData[0].cityEnd !== null ? tourData[0].cityEnd : ''}`;
        }

        return tourInfo.trim();
    };

    const disableButton = () => {
        return props.noData || props.timeStamps?.length === 0 || props.tourId?.length === 0;
    };

    return (
        <>
            <Box
                sx={
                    desktop900
                        ? {
                              position: 'relative',
                              top: '-24px',
                              height: '56px',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              paddingLeft: '4px',
                              color: theme.palette.secondary.main,
                          }
                        : {
                              position: 'fixed',
                              left: '0',
                              top: '0',
                              backgroundColor: theme.palette.success.main,
                              height: '64px',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '16px',
                              zIndex: theme.zIndex.drawer + 2,
                              color: theme.palette.secondary.main,
                          }
                }
            >
                <Box sx={{ display: 'flex', overflow: 'hidden' }}>
                    <CloseIcon sx={{ cursor: 'pointer', marginRight: '16px' }} onClick={() => navigate(-1)} />
                    {desktop900 ? (
                        <Typography
                            sx={{
                                fontSize: '16px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {getTourInfos(tourData)}
                        </Typography>
                    ) : (
                        ''
                    )}
                </Box>
                <Tooltip title={disableButton() ? t('noStopSelected') : ''} placement='top-start' enterTouchDelay={0}>
                    <span>
                        <Button
                            disabled={disableButton()}
                            variant='text'
                            size='small'
                            sx={{
                                alignSelf: 'center',
                                color: '#16A100',
                                whiteSpace: 'nowrap',
                                flexShrink: '0',
                            }}
                            onClick={() => splitTour(props.tourId, props.timeStamps)}
                        >
                            <ContentCutIcon
                                sx={{
                                    height: '18px',
                                    width: '18px',
                                    marginRight: '8px',
                                }}
                            />
                            {t('splitTour')}
                        </Button>
                    </span>
                </Tooltip>
            </Box>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Container, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import GeneralSettings from './settings-general';
import BasicSettings from './settings-basic';
import UsersSettings from './settings-users';
import { useGetSelectedLogbookApi } from '../../api/LogbookApi';
import { useOutletContext } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { gql, useMutation } from '@apollo/client';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { getBreakPoints } from '../../components/other/ConfigBasic';

const SAVE_LOGBOOK_NAME = gql`
    mutation saveLogbookName($id: ID!, $name: String!) {
        updateLogbook(input: { name: $name }, id: $id) {
            id
        }
    }
`;

const SAVE_LOGBOOK_DRIVERS = gql`
    mutation saveLogbookDrivers($id: ID!, $idDrivers: [String]!) {
        updateLogbook(input: { idDrivers: $idDrivers }, id: $id) {
            id
        }
    }
`;

const SAVE_LOGBOOK_ACCESS = gql`
    mutation saveLogbookAccess($id: ID!, $access: [AccessInput]!) {
        updateLogbook(input: { access: $access }, id: $id) {
            id
        }
    }
`;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Settings() {
    const [value, setValue] = useState(0);
    const currentLogbookId = useOutletContext().logbookId;
    const showHint = useOutletContext().showHint;
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const [removedUser, setRemovedUser] = useState('');
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);

    const [saveLogbookName] = useMutation(SAVE_LOGBOOK_NAME, {
        refetchQueries: ['getLogbooks'],
    });

    const [saveLogbookDrivers] = useMutation(SAVE_LOGBOOK_DRIVERS, {
        refetchQueries: ['getSelectedLogbook'],
    });

    const [saveLogbookAccess] = useMutation(SAVE_LOGBOOK_ACCESS, {
        refetchQueries: ['getSelectedLogbook'],
    });

    const changeLogbookName = (name) => {
        saveLogbookName({
            variables: {
                id: currentLogbookId,
                name: name,
            },
            onCompleted: () => {
                setMessage(t('changeLogbookName'));
            },
            onError: (error) => {
                setMessage(error.graphQLErrors[0].message);
            },
        });
    };

    const changeLogbookDrivers = (idDrivers) => {
        saveLogbookDrivers({
            variables: {
                id: currentLogbookId,
                idDrivers: idDrivers,
            },
            onCompleted: () => {
                setMessage(t('changeLogbookDrivers'));
            },
            onError: (error) => {
                setMessage(error.graphQLErrors[0].message);
            },
        });
    };

    const accessContainAdmin = (newAccess) => {
        let accessContainAdmin = newAccess.filter((access) => access.allowed.includes('grant'));
        if (accessContainAdmin.length > 0) {
            return true;
        } else {
            setMessage(t('adminIsRequired'));
            return false;
        }
    };

    const changeLogbookAccess = (access) => {
        if (accessContainAdmin(access)) {
            const removedUser = access.filter((a) => a.allowed.length === 0);
            saveLogbookAccess({
                variables: {
                    id: currentLogbookId,
                    access: access,
                },
                onCompleted: () => {
                    setMessage(t('accessChangedSuccessfully'));
                    if (removedUser.length === 1) {
                        setRemovedUser(removedUser[0].holder);
                    }
                },
                onError: (error) => {
                    setMessage(error.graphQLErrors[0].message);
                },
            });
        }
    };

    const selectedLogbook = useGetSelectedLogbookApi(currentLogbookId);

    if (!selectedLogbook) {
        return <LinearProgress />;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getInnerHeight = () => {
        let maxHeight = 0;
        const innerHeight = window.innerHeight;
        const tabsHeight = 48;
        const appBarHeight = 64;
        const padding = 48;
        const bottomNavigationHeight = 56;
        if (desktop900) {
            maxHeight = innerHeight - tabsHeight - appBarHeight - padding;
        } else {
            maxHeight = innerHeight - tabsHeight - appBarHeight - padding - bottomNavigationHeight;
        }
        return `${maxHeight}px`;
    };

    return (
        <Container maxWidth='sm' sx={{ px: 0 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '8px' }}>
                <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                    <Tab label={t('general')} {...a11yProps(0)} sx={{ fontFamily: 'CircularProBold' }} />
                    <Tab
                        label={t('vehicle')}
                        icon={showHint?.settingsVehicle ? <CircleIcon sx={{ height: '8px', width: '8px' }} color='error' /> : ''}
                        iconPosition='start'
                        {...a11yProps(1)}
                        sx={{ fontFamily: 'CircularProBold' }}
                    />
                    <Tab label={t('user')} {...a11yProps(2)} sx={{ fontFamily: 'CircularProBold' }} />
                </Tabs>
            </Box>
            <Box sx={{ flexGrow: 1, height: getInnerHeight(), overflowY: 'auto', overflowX: 'hidden', paddingTop: '8px' }}>
                <Grid container spacing={2}>
                    {value === 0 && <GeneralSettings logbook={selectedLogbook} changeLogbookName={changeLogbookName} changeLogbookDrivers={changeLogbookDrivers} />}
                    {value === 1 && <BasicSettings idAsset={selectedLogbook.idAsset} access={selectedLogbook.access} />}
                    {value === 2 && <UsersSettings logbook={selectedLogbook} changeLogbookAccess={changeLogbookAccess} removedUser={removedUser} setRemovedUser={setRemovedUser} />}
                </Grid>
            </Box>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </Container>
    );
}

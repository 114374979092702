import * as React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { userHasEditAccess } from '../auth/authorization';
import theme from '../../themes/theme';
import SaveSvg from '../other/SaveSvg';

export default function EditActionBar(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userAccess = useOutletContext().logbookInfo?.loginUserAccess;
    const mobil = props.mobil;

    const goBack = () => {
        if (props.status === 'OPEN') {
            navigate('/open');
        } else {
            navigate('/edited');
        }
    };

    const disableElement = () => {
        let disableElement = true;
        if ((props.status === 'OPEN' || (props.status === 'EDITED' && props.type !== 'PRIVATE')) && userHasEditAccess(userAccess)) {
            disableElement = false;
        }
        return disableElement;
    };

    return (
        <>
            {mobil && (
                <Box
                    sx={{
                        position: 'fixed',
                        left: '0',
                        top: '0',
                        backgroundColor: theme.palette.success.main,
                        height: '64px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '16px',
                        zIndex: theme.zIndex.drawer + 2,
                        color: theme.palette.secondary.main,
                    }}
                >
                    <CloseIcon onClick={() => goBack()} />
                    <Box>
                        {props.status === 'OPEN' && (
                            <Button variant='text' disabled={props.type !== null && props.type === 'PRIVATE'} color='primary' onClick={() => navigate('/mixed/' + props.id)}>
                                {t('mixedTour')}
                            </Button>
                        )}
                        <IconButton disabled={disableElement()} onClick={() => props.setSaveButtonPressed(true)}>
                            <SaveSvg disabled={disableElement()} />
                        </IconButton>
                    </Box>
                </Box>
            )}
            {!mobil && (
                <Grid container xs={12}>
                    <Grid xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'none' }}>
                            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => goBack()} />
                            <Box>
                                {props.status === 'OPEN' && (
                                    <Button
                                        variant='text'
                                        color='primary'
                                        disabled={props.type !== null && props.type === 'PRIVATE'}
                                        onClick={() => navigate('/mixed/' + props.id)}
                                    >
                                        {t('mixedTour')}
                                    </Button>
                                )}
                                <Button variant='contained' color='success' disabled={disableElement()} onClick={() => props.setSaveButtonPressed(true)}>
                                    {t('save')}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

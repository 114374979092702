import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import MapCard from '../../components/ui/MapCard';
import EditTourForm from '../../components/Tour/EditTourForm';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useGetLogbookSetting, useGetSelectedTour } from '../../api/LogbookApi';
import { LinearProgress, useMediaQuery } from '@mui/material';
import AdditionalTourInfos from '../../components/Tour/AdditionalTourInfos';
import ChangeHistory from '../../components/Tour/ChangeHistory';
import BannerEdit from '../../components/ui/BannerEdit';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import EditActionBar from '../../components/Tour/EditActionBar';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { GetTourTypes, getBreakPoints } from '../../components/other/ConfigBasic';
import theme from '../../themes/theme';
import { useEffect } from 'react';
import { userHasEditAccess } from '../../components/auth/authorization';

const SAVE_EDIT_TOUR_ALL = gql`
    mutation saveEditTourAll(
        $id: ID!
        $driver: String!
        $type: EnumLogbookTourType!
        $reason: String
        $costCentre: [String]
        $costObject: [String]
        $annotation: String!
        $contactName: String
    ) {
        updateLogbooktours(
            input: { driver: $driver, type: $type, reason: $reason, costCentre: $costCentre, costObject: $costObject, annotation: $annotation, contactName: $contactName }
            id: $id
        ) {
            id
        }
    }
`;

export default function Tour() {
    const { t } = useTranslation();
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const selectedTour = useGetSelectedTour(useParams().id);
    const [saveButtonPressed, setSaveButtonPressed] = useState(false);
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const logbookSetting = useGetLogbookSetting(setMessage);
    const [costCentreList, setCostCentreList] = useState([]);
    const [costObjectList, setCostObjectList] = useState([]);
    const [showEditAddress, setShowEditAddress] = useState(false);
    const [saveEditTourAll] = useMutation(SAVE_EDIT_TOUR_ALL);
    const userAccess = useOutletContext().logbookInfo?.loginUserAccess;

    useEffect(() => {
        if (logbookSetting !== undefined) {
            const allCostCentre = logbookSetting?.map?.has('costCentre') ? logbookSetting?.map.get('costCentre') : [];
            const allCostObject = logbookSetting?.map?.has('costObject') ? logbookSetting?.map.get('costObject') : [];
            setCostCentreList(allCostCentre.filter((costCentre) => costCentre.active === true));
            setCostObjectList(allCostObject.filter((costObject) => costObject.active === true));
        }
    }, [logbookSetting]);

    const updateEditTourAll = (tourdetails) => {
        saveEditTourAll({
            variables: {
                id: selectedTour[0].id,
                driver: tourdetails.driver,
                type: tourdetails.type,
                contactName: tourdetails.contactName,
                reason: tourdetails.reason,
                costCentre: tourdetails.costCentre !== '' ? tourdetails.costCentre : null,
                costObject: tourdetails.costObject !== '' ? tourdetails.costObject : null,
                annotation: tourdetails.annotation,
            },
            onCompleted: () => {
                if (selectedTour[0].status === 'OPEN') {
                    navigate('/open', {
                        state: {
                            snackbarMessage: t('tourEditedSuccessfully'),
                        },
                    });
                } else {
                    navigate('/edited', {
                        state: {
                            snackbarMessage: t('tourEditedSuccessfully'),
                        },
                    });
                }
            },
            onError: (error) => {
                setMessage(error.graphQLErrors[0].message);
            },
        });
    };

    if (!selectedTour) {
        return <LinearProgress />;
    }

    const editAgain = selectedTour[0].status === 'OPEN' ? false : true;

    const startInfos = () => {
        return {
            dt: Moment(selectedTour[0].dtBegin).format('DD.MM.yyyy HH:mm:ss'),
            street: selectedTour[0].streetBegin,
            postal: selectedTour[0].postalBegin,
            city: selectedTour[0].cityBegin,
            latLon: selectedTour[0].latlonBegin,
        };
    };

    const endInfos = () => {
        return {
            dt: Moment(selectedTour[0].dtEnd).format('DD.MM.yyyy HH:mm:ss'),
            street: selectedTour[0].streetEnd,
            postal: selectedTour[0].postalEnd,
            city: selectedTour[0].cityEnd,
            latLon: selectedTour[0].latlonEnd,
        };
    };
    const disableElement = () => {
        let disableElement = true;
        if ((selectedTour[0].status === 'OPEN' || (selectedTour[0].status === 'EDITED' && selectedTour[0].type !== 'PRIVATE')) && userHasEditAccess(userAccess)) {
            disableElement = false;
        }
        return disableElement;
    };

    return (
        <>
            {!desktop900 && (
                <EditActionBar mobil={true} setSaveButtonPressed={setSaveButtonPressed} status={selectedTour[0].status} type={selectedTour[0].type} id={selectedTour[0].id} />
            )}
            <Box
                sx={
                    desktop900
                        ? { flexGrow: 1 }
                        : {
                              position: 'absolute',
                              left: '0',
                              top: '64px',
                              backgroundColor: theme.palette.background.default,
                              height: 'calc(100vh - 64px)',
                              width: '100%',
                              padding: '16px',
                              zIndex: theme.zIndex.drawer + 2,
                              overflow: 'auto',
                          }
                }
            >
                <Grid
                    container
                    spacing={1}
                    sm={12}
                    md={editAgain ? 8 : 8}
                    mdOffset={2}
                    lg={editAgain ? 12 : 10}
                    lgOffset={editAgain ? 0 : 1}
                    xl={editAgain ? 10 : 8}
                    xlOffset={editAgain ? 1 : 2}
                >
                    {desktop900 && (
                        <EditActionBar
                            mobil={false}
                            setSaveButtonPressed={setSaveButtonPressed}
                            status={selectedTour[0].status}
                            type={selectedTour[0].type}
                            id={selectedTour[0].id}
                        />
                    )}
                    <BannerEdit selectedTour={selectedTour[0]} />
                    <Grid
                        container
                        xs={12}
                        sx={{
                            overflowY: 'auto',
                            height: `${window.innerHeight - (desktop900 ? 218 : 100)}px`,
                            position: 'relative',
                            alignContent: 'flex-start',
                            overflowX: 'hidden',
                        }}
                    >
                        <Grid container xs={12} sm={12} md={12} lg={editAgain ? 3 : 5}>
                            <Grid xs={selectedTour[0].type === GetTourTypes().PRIVATE ? 6 : 12} sm={6} md={6} lg={12}>
                                <MapCard event={{ type: 'start', label: 'Start' }} address={startInfos()} />
                            </Grid>
                            <Grid xs={selectedTour[0].type === GetTourTypes().PRIVATE ? 6 : 12} sm={6} md={6} lg={12}>
                                <MapCard event={{ type: 'end', label: t('ziel') }} address={endInfos()} />
                            </Grid>
                        </Grid>

                        <Grid container xs={12} sm={12} md={12} lg={editAgain ? 5 : 7} justifyContent='flex-end'>
                            <Grid xs={12} md={12}>
                                <EditTourForm
                                    selectedTour={selectedTour[0]}
                                    saveButtonPressed={saveButtonPressed}
                                    setSaveButtonPressed={setSaveButtonPressed}
                                    updateEditTourAll={updateEditTourAll}
                                    showEditAddress={showEditAddress}
                                    setShowEditAddress={setShowEditAddress}
                                    startInfos={startInfos}
                                    endInfos={endInfos}
                                    costCentreList={costCentreList}
                                    costObjectList={costObjectList}
                                />
                            </Grid>
                            {!disableElement() ? (
                                <Button color='info' variant='text' onClick={() => setShowEditAddress(true)}>
                                    {t('addressEdit').toUpperCase()}
                                </Button>
                            ) : (
                                ''
                            )}
                        </Grid>

                        {editAgain && (
                            <Grid container xs={12} sm={12} md={12} lg={4}>
                                <Grid xs={12} md={12}>
                                    <AdditionalTourInfos selectedTour={selectedTour[0]} />
                                </Grid>
                                <Grid xs={12} md={12}>
                                    <ChangeHistory selectedTour={selectedTour[0]} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </Box>
        </>
    );
}

import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** A Fuction to show the Value of the Filter Buttons as a chip  */
function FilterOptions({ filter, changeFilter, translate }) {
    const { t } = useTranslation();
    return (
        <>
            {filter.map((entry) => (
                <Chip key={entry} sx={{ fontSize: '12px' }} label={translate ? t(entry.toLowerCase()) : entry} onDelete={() => changeFilter(entry)} />
            ))}
        </>
    );
}

export default FilterOptions;

import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import AdminCostViewDesktop from '../../components/adminCost/AdminCostViewDesktop';
import CostCentresAndCostObjects from '../../components/adminCost/CostCentresAndCostObjects';

export default function AdminCostOverview() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState('');
    const client = useApolloClient();
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (location.state !== null && location.state.snackbarMessage !== null) {
            setMessage(location.state.snackbarMessage);
            navigate(location.pathname);
            client.refetchQueries({
                include: ['receipts'],
            });
        }
    }, [navigate, location, client]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ height: 'calc(100vh - 142px)', margin: 'auto' }}>
                <Tabs value={value} onChange={handleChange} centered sx={{ paddingBottom: '24px' }}>
                    <Tab label={t('overview')} sx={{ fontFamily: 'CircularProBold' }} />
                    <Tab label={t('dataManagement')} sx={{ fontFamily: 'CircularProBold' }} />
                </Tabs>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {value === 0 && <AdminCostViewDesktop />}
                    {value === 1 && <CostCentresAndCostObjects />}
                </Box>
            </Box>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

import * as React from 'react';
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Pagination, Typography } from '@mui/material';
import theme from '../../themes/theme';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { GetTourTypes } from '../other/ConfigBasic';
import WorkIcon from '@mui/icons-material/Work';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PrivateSvg from '../other/PrivateSvg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function ToursTableMobileList(props) {
    const rowsPerPage = 20;
    const [page, setPage] = useState(1);
    const { setScrollDown } = props;
    const count = parseInt(Math.ceil(props.modifiedTourList.length / rowsPerPage));
    const modifiedTourList = props.modifiedTourList.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const checkScrollDown = (scrollTop) => {
        setScrollDown(scrollTop > 0 ? true : false);
    };

    const getWeekDay = (weekday) => {
        switch (weekday) {
            case 'Sunday':
                return t('sunday');
            case 'Monday':
                return t('monday');
            case 'Tuesday':
                return t('tuesday');
            case 'Wednesday':
                return t('wednesday');
            case 'Thursday':
                return t('thursday');
            case 'Friday':
                return t('friday');
            case 'Saturday':
                return t('saturday');
            default:
                return 'n.a.';
        }
    };

    const formatDate = (date) => {
        return `${getWeekDay(Moment(date).format('dddd'))}, ${Moment(date).format('DD.MM.yyyy')}`;
    };

    const getToursDate = () => {
        let datesOfTours = modifiedTourList.map((modifiedTour) => {
            return formatDate(modifiedTour.dtBegin);
        });
        return [...new Set(datesOfTours)];
    };

    const getToursToDate = (date) => {
        let toursToDate = modifiedTourList.filter((modifiedTour) => formatDate(modifiedTour.dtBegin) === date);
        return toursToDate;
    };

    const getStartAddress = (tour) => {
        if (tour.type === GetTourTypes().PRIVATE) {
            return '';
        } else if (tour.streetBegin === null && tour.postalBegin === null && tour.cityBegin === null) {
            return 'n.a. -';
        } else {
            return `${tour.streetBegin === null ? '' : `${tour.streetBegin},`} 
                    ${tour.postalBegin === null ? '' : `${tour.postalBegin}`}
                    ${tour.cityBegin === null ? ' -' : `${tour.cityBegin} -`}`;
        }
    };

    const getEndAddress = (tour) => {
        if (tour.type === GetTourTypes().PRIVATE) {
            return '';
        } else if (tour.streetEnd === null && tour.postalEnd === null && tour.cityEnd === null) {
            return 'n.a. -';
        } else {
            return `${tour.streetEnd === null ? '' : `${tour.streetEnd},`} 
                    ${tour.postalEnd === null ? '' : `${tour.postalEnd}`}
                    ${tour.cityEnd === null ? ' -' : `${tour.cityEnd} -`}`;
        }
    };

    const getTypeIcon = (tour) => {
        let tourTypes = GetTourTypes();
        switch (tour.type) {
            case tourTypes.BUSINESS:
                return <WorkIcon sx={{ color: theme.palette.grey[600] }} />;
            case tourTypes.COMMUTE:
                return <HomeWorkIcon sx={{ color: '#16A100' }} />;
            case tourTypes.OTHER_INCOME:
                return <WorkIcon sx={{ color: theme.palette.grey[600] }} />;
            case tourTypes.PRIVATE:
                return <PrivateSvg color={theme.palette.error.main} />;
            case tourTypes.TWO_HOUSEHOLDS:
                return <HomeWorkIcon sx={{ color: '#16A100' }} />;
            default:
                return <WorkIcon sx={{ color: theme.palette.grey[600] }} />;
        }
    };

    const getTypeColor = (tour) => {
        let tourTypes = GetTourTypes();
        switch (tour.type) {
            case tourTypes.BUSINESS:
                return '#EEEEEE';
            case tourTypes.COMMUTE:
                return theme.palette.success.main;
            case tourTypes.OTHER_INCOME:
                return '#EEEEEE';
            case tourTypes.PRIVATE:
                return '#FFC9B7';
            case tourTypes.TWO_HOUSEHOLDS:
                return theme.palette.success.main;
            default:
                return '#EEEEEE';
        }
    };

    const getInnerHeight = () => {
        const maxHeight = window.innerHeight - 240;
        return `${maxHeight}px`;
    };

    return (
        <List
            onScroll={(event) => checkScrollDown(event.target.scrollTop)}
            sx={{
                overflow: 'auto',
                maxHeight: getInnerHeight(),
                paddingTop: '0',
                marginTop: '8px',
                '& ul': { padding: 0 },
            }}
        >
            {getToursDate().map((date) => (
                <li key={date}>
                    <ul>
                        <ListSubheader>{date}</ListSubheader>
                        {getToursToDate(date).map((tour) => (
                            <Box key={tour.id} sx={{ width: '100%' }} onClick={() => navigate('/edit/' + tour.id)}>
                                <ListItem sx={{ minHeight: '56px' }}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ backgroundColor: getTypeColor(tour) }}>{getTypeIcon(tour)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${Moment(tour.dtBegin).format('HH:mm')} - ${Moment(tour.dtEnd).format('HH:mm')}`}
                                        primaryTypographyProps={{ fontSize: '16px' }}
                                        secondary={
                                            <>
                                                <Typography
                                                    sx={{
                                                        display: 'block',
                                                        fontSize: '14px',
                                                        color: theme.palette.grey[600],
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    component='span'
                                                >
                                                    {getStartAddress(tour)}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        display: 'block',
                                                        fontSize: '14px',
                                                        color: theme.palette.grey[600],
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    component='span'
                                                >
                                                    {getEndAddress(tour)}
                                                </Typography>
                                            </>
                                        }
                                        sx={props.getRowStyle(tour.status)}
                                    />
                                    <Typography
                                        sx={[
                                            props.getRowStyle(tour.status),
                                            {
                                                position: 'absolute',
                                                right: '16px',
                                                top: '16px',
                                                fontSize: '12px',
                                                color: theme.palette.grey[600],
                                            },
                                        ]}
                                    >
                                        {tour.distance === null ? 'n.a.' : `${(tour.distance / 1000).toFixed(2).replace('.', ',')} km`}
                                    </Typography>
                                </ListItem>
                                <Divider />
                            </Box>
                        ))}
                    </ul>
                </li>
            ))}
            <Pagination sx={{ padding: '16px' }} count={count} page={page} siblingCount={0} boundaryCount={1} onChange={(event, page) => handleChangePage(page)} />
        </List>
    );
}

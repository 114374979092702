import { Card, CardHeader } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function StatusFuelLevel(props) {
    const { t } = useTranslation();

    return (
        <>
            {props.fuelPercent !== null && (
                <Card
                    variant='outlined'
                    sx={{
                        maxWidth: props.mileage !== null ? 'calc(50% - 4px)' : '538px',
                        marginRight: props.mileage !== null ? '4px' : '0px',
                        marginBottom: '8px',
                        flexGrow: '1',
                    }}
                >
                    <CardHeader title={t('fuelLevel')} subheader={`${props.fuelPercent} %`} />
                </Card>
            )}
        </>
    );
}

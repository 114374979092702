import React from 'react';

export default function InvoiceReceipt(props) {
    return (
        <svg id='Invoice-Receipt-grey' xmlns='http://www.w3.org/2000/svg' width={props.size} height={props.size} viewBox='0 0 24 24'>
            <path id='Pfad_2853' data-name='Pfad 2853' d='M0,0H24V24H0Z' fill='none' />
            <path
                id='Pfad_2854'
                data-name='Pfad 2854'
                d='M18,17H6V15H18Zm0-4H6V11H18Zm0-4H6V7H18ZM3,22l1.5-1.5L6,22l1.5-1.5L9,22l1.5-1.5L12,22l1.5-1.5L15,22l1.5-1.5L18,22l1.5-1.5L21,22V2L19.5,3.5,18,2,16.5,3.5,15,2,13.5,3.5,12,2,10.5,3.5,9,2,7.5,3.5,6,2,4.5,3.5,3,2Z'
                fill={props.fill}
            />
        </svg>
    );
}

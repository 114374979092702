import { Box, Divider, List, ListItemButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../../themes/theme';

/** A Component for the filter menu in the destopview */
function FilterMenu({ items, costFilter, changeCostFilter, providerFilter, changeProviderFilter }) {
    const { t } = useTranslation();
    const uniqueCategory = [];
    const uniqueVendor = [];
    for (const item of items) {
        const newCategory = item.articles ? item.articles[0]?.category : undefined;
        const newVendor = item.vendor;
        if (newCategory !== undefined && !uniqueCategory.some((category) => category?.toLowerCase() === newCategory?.toLowerCase())) {
            uniqueCategory.push(newCategory);
            uniqueCategory.sort();
        }

        if (!uniqueVendor.some((vendor) => vendor.toLowerCase() === newVendor.toLowerCase())) {
            uniqueVendor.push(newVendor);
            uniqueVendor.sort();
        }
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '30px',
                right: '20px',
                bgcolor: 'white',
                boxShadow: '0px 10px 13px -7px #000000, 0px 20px 23px 9px rgba(0,0,0,0)',
                zIndex: 2,
                textAlign: 'center',
                border: 'none',
                width: '170px',
            }}
        >
            <List>
                {uniqueCategory.map((unique) => (
                    <ListItemButton
                        selected={costFilter.includes(unique)}
                        key={unique}
                        onClick={(e) => {
                            changeCostFilter(unique);
                        }}
                        sx={{
                            color: '#212121',
                            fontFamily: 'CircularProMedium',
                            fontSize: '14px',
                            justifyContent: 'flex-start',
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.grey[100],
                            },
                        }}
                    >
                        {t(unique.toLowerCase())}
                    </ListItemButton>
                ))}
            </List>
            <Divider />
            <List>
                {uniqueVendor.map((unique) => (
                    <ListItemButton
                        selected={providerFilter.includes(unique)}
                        key={unique}
                        onClick={(e) => {
                            changeProviderFilter(unique);
                        }}
                        sx={{
                            color: '#212121',
                            fontFamily: 'CircularProMedium',
                            fontSize: '14px',
                            justifyContent: 'flex-start',
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.grey[100],
                            },
                        }}
                    >
                        {unique}
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
}

export default FilterMenu;

import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import SimpleSnackbar from '../../ui/SimpleSnackbar';
import { v4 as uuidv4 } from 'uuid';

export default function DialogAddCost(props) {
    const { addCost, title, label } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const handleSave = () => {
        handleCancel();
        addCost({
            id: uuidv4(),
            name: name.trim(),
            active: true,
        });
        setName('');
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant='contained' color='success' sx={{ height: '40px' }} startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                {t('add')}
            </Button>
            <Dialog open={open} PaperProps={{ sx: { borderRadius: '28px' } }}>
                <DialogTitle sx={{ padding: '24px 24px 16px 24px' }}>{title}</DialogTitle>
                <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                    <Box sx={{ padding: '5px 0', maxWidth: '296px', display: 'flex' }}>
                        <FormControl fullWidth>
                            <TextField label={label} autoFocus variant='outlined' value={name} onChange={(event) => setName(event.target.value)} />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ minHeight: '88px', padding: '24px' }}>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important' }} color='secondary' onClick={() => handleCancel()}>
                        {t('cancel')}
                    </Button>
                    {name.trim().length > 0 ? (
                        <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important' }} color='success' onClick={() => handleSave()}>
                            {t('add')}
                        </Button>
                    ) : (
                        <Button variant='contained' disabled={true} sx={{ height: '40px', marginBottom: '0px !important' }} color='success'>
                            {t('add')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

export default function DialogChangeLogbookName(props) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setName(props.logbookName);
    }, [props]);

    const handleSave = () => {
        setOpen(false);
        props.changeLogbookName(name);
    };

    const handleCancel = () => {
        setOpen(false);
        setName(props.logbookName);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    return (
        <>
            <EditIcon cursor='pointer' onClick={() => setOpen(true)} sx={{ float: 'right' }} />
            <Dialog open={open}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <DialogTitle sx={{padding: '24px 24px 16px 24px'}}>{t('nameOfLogbook')}</DialogTitle>
                <DialogContent sx={{padding:'0px 24px 0px 24px'}}>
                    <Box
                        sx={{
                            paddingTop: '5px',
                        }}
                    >
                        <FormControl fullWidth>
                            <TextField fullWidth label='Name' variant='outlined' value={name} onChange={(event) => handleNameChange(event)} />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ height: '88px',padding: '24px'}}>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important'}} color='secondary' onClick={() => handleCancel()}>
                        {t('cancel')} 
                    </Button>
                    {name && (
                        <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important' }} color='success' onClick={() => handleSave()}>
                            {t('save')} 
                        </Button>
                    )}
                    {!name && (
                        <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important' }} color='success' disabled>
                            {t('save')} 
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export default function OpenToursBanner(props) {
    const { desktop900 } = props;
    const { t } = useTranslation();

    return (
        <>
            {desktop900 && (
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: '#FBE9E7',
                        display: 'flex',
                        padding: '16px',
                        borderRadius: '12px',
                        marginBottom: '8px',
                    }}
                >
                    <WarningIcon color='error' sx={{ alignSelf: 'center', marginRight: '8px' }} />
                    <Typography sx={{ fontSize: '14px' }}>{t('sevenDaysWarning')}</Typography>
                </Box>
            )}
            {!desktop900 && (
                <Box
                    id='openToursBanner'
                    sx={{
                        width: '100%',
                        backgroundColor: '#FBE9E7',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '16px',
                        zIndex: 0,
                    }}
                >
                    <WarningIcon color='error' sx={{ marginRight: '8px' }} />
                    <Typography sx={{ fontSize: '14px' }}>{t('sevenDaysWarning')}</Typography>
                </Box>
            )}
        </>
    );
}

import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';

export default function StepperMobile({ setShowOnBoarding, activeStep, handleComplete, handleNext }) {
    const { t } = useTranslation();

    const handleBack = () => {
        setShowOnBoarding(false);
        handleComplete();
    };

    return (
        <MobileStepper
            variant='dots'
            steps={6}
            position='static'
            activeStep={activeStep}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 2,
                maxWidth: 800,
                bgcolor: '#B9F6CA',
                '& .MuiMobileStepper-dotActive': {
                    width: 12,
                    height: 12,
                },
                '& .MuiMobileStepper-dot': {
                    width: 12,
                    height: 12,
                    margin: '0 5px',
                },
                '& .MuiMobileStepper-dots': {
                    marginInline: 'auto',
                    mt: '68px',
                    position: 'fixed',
                    bottom: { xs: '15%', sm: '10%' },
                    left: { xs: '35%', sm: '42%' },
                },
            }}
            nextButton={
                activeStep > 4 ? (
                    <Button onTouchEnd={handleComplete} sx={{ position: 'absolute', bottom: '24px', right: '24px', fontFamily: 'CircularProBold', p: 0 }}>
                        {t('done')}
                    </Button>
                ) : (
                    <Button onTouchEnd={handleNext} sx={{ position: 'absolute', bottom: '24px', right: '24px', fontFamily: 'CircularProBold', p: 0 }}>
                        {t('next')}
                    </Button>
                )
            }
            backButton={
                <Button onClick={handleBack} sx={{ position: 'absolute', bottom: '24px', right: '124px', color: '#757575', fontFamily: 'CircularProBold', p: 0 }}>
                    {t('skip')}
                </Button>
            }
        />
    );
}

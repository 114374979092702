import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { CircularProgress, FormHelperText, Link, Typography, FormControl, Checkbox } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import theme from '../../themes/theme';
import { UPDATE_MILEAGE, useGetAssetRealOdom, useGetLatestUserCalibrationData } from '../../api/LogbookApi';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useMutation } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import { getNumberWithThousandSeparator, isUserAssetAdmin } from '../../components/other/ConfigBasic';
import { NumericFormatCustom } from '../../components/other/ConfigBasic';
import moment from 'moment';

export default function DialogCalibrateOdometer(props) {
    const { show, setCalibrateOdometerData, asset } = props;
    const { t } = useTranslation();
    const idAsset = useOutletContext().logbookInfo?.assetId;
    const assetId = asset !== undefined ? asset : idAsset;
    const user = useOutletContext().loginUser;
    const [open, setOpen] = useState(show !== undefined ? show : false);
    const [message, setMessage] = useState('');

    const [validationErrors, setValidationErrors] = useState({ currentMileage: false });
    const calibrationData = useGetLatestUserCalibrationData(assetId, setMessage);
    const [currentMileage, setCurrentMileage] = useState('');
    const [realOdom, setRealOdom] = useState(undefined);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

    const callback = (realMileage) => {
        setRealOdom(realMileage);
        setCurrentMileage(realMileage);
    };
    useGetAssetRealOdom(assetId, open, callback, setMessage);

    const [updateMileage] = useMutation(UPDATE_MILEAGE, {
        refetchQueries: ['getAssetCalibrationData', 'getLogbooks'],
    });

    const resetDialog = () => {
        if (setCalibrateOdometerData !== undefined) {
            setCalibrateOdometerData({
                access: undefined,
                asset: undefined,
                show: false,
            });
        }
        setOpen(false);
        setCurrentMileage('');
        setRealOdom(undefined);
        setValidationErrors({ currentMileage: false });
    };

    const handleApply = () => {
        if (formValidation()) {
            updateMileage({
                variables: {
                    id: assetId,
                    km: Number(currentMileage),
                },
                onCompleted: () => {
                    setMessage(t('calibrationSuccessful'));
                    resetDialog();
                },
                onError: (error) => {
                    setMessage(error.graphQLErrors[0].message);
                },
            });
        }
    };

    const handleCancel = () => {
        resetDialog();
    };

    const handleMileageChange = (event) => {
        let newMileage = event.target.value;
        setCurrentMileage(newMileage);
    };

    const formValidation = () => {
        let validationIsSuccessful = true;
        let validationErrors = {
            currentMileage: false,
        };
        if (currentMileage.length === 0 || currentMileage <= 0) {
            validationErrors.currentMileage = true;
            validationIsSuccessful = false;
        }
        setValidationErrors(validationErrors);
        return validationIsSuccessful;
    };

    const userHasEditAccess = () => {
        const userHasEditAccess = props.access.some((a) => {
            return a.holder === user.id && a.allowed.includes('view');
        });

        if (userHasEditAccess || isUserAssetAdmin(user.adminRoles)) {
            setOpen(true);
            setIsSaveButtonEnabled(false);
        } else {
            setMessage(t('noAssetEditAccess'));
        }
    };

    const enableSaveButton = (event) => {
        setIsSaveButtonEnabled((prevEnabled) => !prevEnabled);
    };

    return (
        <>
            {show === undefined && (
                <Link underline='none'>
                    <span onClick={() => userHasEditAccess()}>{t('compareMileage')}</span>
                </Link>
            )}
            <Dialog
                open={open}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <DialogTitle sx={{ padding: '24px 24px 16px 24px' }}>{t('calibrateOdometer')}</DialogTitle>
                <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                    {realOdom === undefined && (
                        <Box sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography variant='body2'>{t('dataRequestInProgress')}</Typography>
                        </Box>
                    )}
                    {realOdom !== undefined && calibrationData && (
                        <Grid container xs={12}>
                            <Grid xs={7} textAlign='left'>
                                {t('lastCalibration')}
                            </Grid>
                            <Grid xs={5} textAlign='right'>
                                {calibrationData?.dtCalibration !== null || calibrationData?.ymd !== null
                                    ? `${
                                          calibrationData?.dtCalibration
                                              ? getNumberWithThousandSeparator(calibrationData?.calibrationKm, 0)
                                              : getNumberWithThousandSeparator(calibrationData?.km, 0)
                                      } km`
                                    : 'n.a.'}
                            </Grid>
                            <Grid xs={7} textAlign='left'>
                                {t('readingDate')}
                            </Grid>
                            <Grid xs={5} textAlign='right'>
                                {calibrationData?.dtCalibration !== null || calibrationData?.ymd !== null
                                    ? calibrationData?.dtCalibration
                                        ? moment(calibrationData?.dtCalibration).format('DD.MM.YYYY')
                                        : moment(calibrationData?.ymd).format('DD.MM.YYYY')
                                    : 'n.a.'}
                            </Grid>
                            <Grid item xs={12} padding={theme.components.LogbookFormGrid.padding}>
                                <FormControl fullWidth error={validationErrors.currentMileage}>
                                    <TextField
                                        fullWidth
                                        error={validationErrors.currentMileage}
                                        InputProps={{ inputComponent: NumericFormatCustom }}
                                        required={true}
                                        label={t('currentMileageInKm')}
                                        variant='outlined'
                                        value={currentMileage}
                                        onChange={handleMileageChange}
                                    />
                                    {validationErrors.currentMileage && <FormHelperText>{t('mandatoryField')}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {realOdom !== undefined && (
                        <Grid container xs={12}>
                            {realOdom.length !== 0 && (
                                <Grid xs={12} textAlign='left' sx={{ display: 'flex', color: theme.palette.grey[600] }}>
                                    <InfoIcon sx={{ alignSelf: 'center', marginRight: '8px' }} /> {t('infoCalibrateOdometer')}
                                </Grid>
                            )}
                            <Grid xs={12} textAlign='left' sx={{ display: 'flex', color: theme.palette.grey[600] }}>
                                <Box sx={{ minWidth: '40px', marginLeft: '-8px' }}>
                                    <Checkbox onChange={(event) => enableSaveButton(event)} />
                                </Box>
                                {t('additionalConfirmationCalibration')}
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions sx={{ height: '88px', padding: '24px' }}>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important' }} color='secondary' onClick={() => handleCancel()}>
                        {t('cancel')}
                    </Button>
                    <Button
                        variant='contained'
                        sx={{ height: '40px', marginBottom: '0px !important' }}
                        color='success'
                        disabled={realOdom === undefined || !isSaveButtonEnabled}
                        onClick={() => handleApply()}
                    >
                        {t('apply')}
                    </Button>
                </DialogActions>
            </Dialog>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

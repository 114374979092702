import React from 'react'
import { Button, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';
import NologBooks from '../other/NologBooks.svg';
import AssetsListItem from './AssetsListItem';

const AssetsTable = ({ selectedAssets, rows = [], setOpen, t, setMessage, onDelete, logBooks, setLogBooks }) => {

    return (
        <>
            {    
                selectedAssets.length === 0 ? (
                    <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} gap={'8px'}>
                        <img src={NologBooks} alt='icon when the list is empty' width={"179px"} height={'136px'} />
                        <Typography textAlign={'center'} sx={{
                            fontSize: '14px',
                            color: '#757575',
                            width: '200px'
                        }} >{t('chosseAssets')}</Typography>
                        <Button onClick={() => setOpen(true)} sx={{ padding: '11px 24px 12px 24px', backgroundColor: '#B9F6CA' }} variant='contained'>
                            {t('select')}
                        </Button>
                    </Stack>
                ) : (
                    rows.length > 0 ? (
                        <TableContainer sx={{ overflowY: 'scroll', height: '90%' }}>
                            <Table sx={{
                                overflowY: 'scroll',
                                '.css-e0unlp-MuiTableCell-root': {
                                    border: 'none'
                                },
                                '.css-4f9san-MuiTableCell-root': {
                                    border: 'none'
                                }
                            }}>
                                <TableBody sx={{ overflowY: 'scroll' }}>
                                    {rows.map(asset =>
                                        <AssetsListItem
                                            row={asset}
                                            setMessage={setMessage}
                                            key={asset.id}
                                            t={t}
                                            onDelete={onDelete}
                                            logBooks={logBooks}
                                            setLogBooks={setLogBooks} />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} gap={'8px'}>
                            <Typography variant='caption' color='gray'>
                                {t('noResult')}
                            </Typography>
                        </Stack>
                    )
                )
            }  
        </>
    );
}

export default AssetsTable
import { Card, CardHeader, Switch, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLogbookPrivateJourney, useUpdateLogbookPrivateJourney } from '../../api/LogbookApi';
import { useOutletContext } from 'react-router';
import { isUserCustomerAdmin } from '../other/ConfigBasic';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import ConfirmDialog from '../dialog/ConfirmDialog';

export default function StatusPrivateModus(props) {
    const { t } = useTranslation();
    const currentLogbook = useOutletContext().logbookId;
    const loginUser = useOutletContext().loginUser;
    const [message, setMessage] = useState('');
    const isPrivatModusActive = props.isPrivatModusActive;
    const [idPrivateJourneyPerson, setIdPrivateJourneyPerson] = useState(undefined);
    const { updateLogbookPrivateJourney } = useUpdateLogbookPrivateJourney();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const callback = (idPrivateJourney) => {
        idPrivateJourney === null ? props.setIsPrivateModusActive(false) : props.setIsPrivateModusActive(true);
        setIdPrivateJourneyPerson(idPrivateJourney);
    };

    useGetLogbookPrivateJourney(currentLogbook, setMessage, callback);

    const updatePrivateJourney = () => {
        if (!isDisabled()) {
            updateLogbookPrivateJourney({
                variables: {
                    idLogbook: currentLogbook,
                    privateJourneyOn: !isPrivatModusActive,
                },
                onCompleted: () => {
                    setMessage(!isPrivatModusActive ? t('modeActivated') : t('modeDisabled'));
                },
                onError: (error) => {
                    setMessage(error.graphQLErrors[0].message);
                },
                refetchQueries: ['getLogbookPrivateJourney'],
            });
        }
    };

    const isDisabled = () => {
        if (idPrivateJourneyPerson === undefined) {
            return true;
        } else if (!isPrivatModusActive) {
            return false;
        } else if (idPrivateJourneyPerson === loginUser.id) {
            return false;
        } else if (isUserCustomerAdmin(loginUser.adminRoles)) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <Card
                variant='outlined'
                sx={{
                    maxWidth: '538px',
                    marginBottom: '8px',
                    cursor: 'pointer',
                }}
                onClick={() => (isPrivatModusActive ? updatePrivateJourney() : setOpenConfirmDialog(true))}
            >
                <CardHeader
                    action={
                        <Typography sx={{ marginRight: '-10px' }}>
                            <Switch color='error' checked={isPrivatModusActive} disabled={isDisabled()} />
                        </Typography>
                    }
                    title={t('privateSwitch')}
                    subheader={isPrivatModusActive ? t('modeActivated') : t('modeDisabled')}
                />
            </Card>
            <ConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                handleConfirm={updatePrivateJourney}
                dialogContentText={t('confirmPrivateSwitch')}
                confirmButtonText={t('activate')}
            ></ConfirmDialog>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

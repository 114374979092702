import { useEffect, useRef, useState } from 'react';

import { Container, Button, Stack, useMediaQuery, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getBreakPoints } from '../../components/other/ConfigBasic';
import { useNavigate } from 'react-router-dom';
import LogbooksAccordion from '../../components/createLogbooks/LogbooksAccordion';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useCreateLogbooks, useGetAllAssets, useGetInitialData, useGetTeam } from '../../api/LogbookApi';
import CreateDailog from '../../components/createLogbooks/CreateDailog';

const CreateLogBooks = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [message, setMessage] = useState('');
    const [disableCreate, setDisableCreate] = useState(true);
    const [openCreate, setOpenCreate] = useState(false);
    const assetsData = useRef([]);
    const [teamAccess, setTeamAccess] = useState({});

    let initialData = useGetInitialData();
    const { getAllAssets, assetsWithOutLogbook } = useGetAllAssets(
        initialData?.logbookList.map((Lgb) => Lgb.assetId),
        assetsData
    );
    const teamId = useGetTeam();
    const [logBooks, setLogBooks] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [usersAccess, setUsersAccess] = useState([]);
    const { createLogbookItem, loading, error } = useCreateLogbooks(setMessage);
    const [steps, setSteps] = useState();

    const handleCreate = async (e) => {
        setOpenCreate(true);
        const getRolePermissions = (selectedRole) => {
            switch (selectedRole) {
                case t('chooseRole'):
                    return null;
                case t('viewer'):
                    return ['view'];
                case t('editor'):
                    return ['view', 'edit'];
                case t('admin'):
                    return ['view', 'edit', 'delete', 'grant'];

                default:
                    return null;
            }
        };

        let mergedData = logBooks.map((logbook) => ({
            access: [
                ...usersAccess.map((user) => ({
                    allowed: getRolePermissions(user.role),
                    holder: user.id,
                    variant: 'Person',
                })),
                ...(Object.keys(teamAccess).length > 0
                    ? [
                          {
                              allowed: getRolePermissions(teamAccess.role),
                              holder: teamId,
                              variant: 'Team',
                          },
                      ]
                    : []),
            ],

            idAsset: logbook.assetId,
            id: logbook.assetId,
            idDrivers: drivers,
            name: logbook.name,
            mileage: logbook.mileage,
        }));
        setSteps(mergedData.length);
        for (const logbookData of mergedData) {
            await createLogbookItem(logbookData.name, logbookData.idAsset, logbookData.idDrivers, logbookData.access, logbookData.mileage, logbookData.id);
        }
        setMessage(`${mergedData.length} ${t('logbookSuccessfulCreated')}`);
    };
    useEffect(() => {
        getAllAssets();
    }, [initialData?.logbookList.length, getAllAssets]);

    return (
        <Container
            maxWidth='md'
            sx={{
                m: 0,
                p: 0,
                pb: 4,
                mx: `${desktop900 ? 'auto' : '0'}`,
                height: '95%',
                overflowY: desktop900 ? '' : 'auto',
                maxHeight: 'calc(100vh - 100px)',
            }}
        >
            <Stack direction='row' justifyContent='space-between' sx={{ width: '100%', height: '65px', top: '64px', bgcolor: '#FAFAFA', zIndex: '5', py: '8px' }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                    <Button sx={{ textAlign: 'right', m: 0, p: 0 }} onClick={() => navigate('/admin')}>
                        <CloseIcon sx={{ width: '24px', aspectRatio: 1, color: 'black', m: 0, p: 0 }} />
                    </Button>
                    <Typography>{t('newLogBooks')}</Typography>
                </Stack>

                <Button onClick={(e) => handleCreate(e)} sx={{ padding: '11px 24px 12px 24px', backgroundColor: '#B9F6CA' }} variant='contained' disabled={disableCreate}>
                    {t('create')}
                </Button>
                <CreateDailog open={openCreate} setOpen={setOpenCreate} t={t} totalSteps={steps} error={error} loading={loading} />
            </Stack>
            <LogbooksAccordion
                setMessage={setMessage}
                assetsWithOutLogbook={assetsWithOutLogbook}
                setDisableCreate={setDisableCreate}
                logBooks={logBooks}
                setLogBooks={setLogBooks}
                setUsersAccess={setUsersAccess}
                setDrivers={setDrivers}
                teamAccess={teamAccess}
                setTeamAccess={setTeamAccess}
            />
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </Container>
    );
};

export default CreateLogBooks;

import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Moment from 'moment';
import DialogChangeLogbookName from '../../components/ui/DialogChangeLogbookName';
import DialogChangeLogbookDrivers from '../../components/ui/DialogChangeLogbookDrivers';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, LinearProgress } from '@mui/material';
import LogbookReasonsList from './LogbookReasonsList';
import { getFullname } from '../../components/other/ConfigBasic';
import { useGetPersonsApi } from '../../api/LogbookApi';

export default function GeneralSettings(props) {
    const logbook = props.logbook;
    const { t } = useTranslation();
    const personsData = useGetPersonsApi();

    if (!personsData) {
        return <LinearProgress />;
    }

    const getFullnameById = (personId) => {
        const person = personsData.find((person) => person.id === personId);
        return getFullname(person);
    };

    return (
        <>
            <Grid xs={12} md={12} sx={{ pt: '0px', fontSize: { xs: '14px', md: '16px' }, fontFamily: 'CircularProBook' }}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container xs={12} md={12}>
                            <Grid xs={12} md={12} textAlign='left'>
                                <h4
                                    style={{
                                        padding: 0,
                                        marginBlock: 0,
                                        fontFamily: 'CircularProBook',
                                    }}
                                >
                                    {t('logbook')}: {logbook.name}
                                    <DialogChangeLogbookName changeLogbookName={props.changeLogbookName} logbookName={logbook.name} />
                                </h4>
                            </Grid>
                            <Grid xs={6} md={6} textAlign='left'>
                                {t('createdOn')}
                            </Grid>
                            <Grid xs={6} md={6} textAlign='right'>
                                {Moment(logbook.dtCreated).format('DD.MM.yyyy')}
                            </Grid>
                            <Grid xs={6} md={6} textAlign='left'>
                                {t('creator')}
                            </Grid>
                            <Grid xs={6} md={6} textAlign='right'>
                                {getFullnameById(logbook.idCreated)}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={12} sx={{ pt: '0px', fontSize: { xs: '14px', md: '16px' } }}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container xs={12} md={12}>
                            <Grid xs={12} md={12} textAlign='left'>
                                <h4 style={{ padding: 0, marginBlock: 0, fontFamily: 'CircularProBook' }}>{t('baseData')}</h4>
                            </Grid>
                            <Grid xs={6} md={6} textAlign='left'>
                                {t('driver')}
                            </Grid>
                            <Grid xs={6} md={6} textAlign='right'>
                                <DialogChangeLogbookDrivers changeLogbookDrivers={props.changeLogbookDrivers} logbook={logbook} />
                            </Grid>
                            <Grid xs={6} md={6} textAlign='left'>
                                {t('companies')}
                            </Grid>
                            <Grid xs={6} md={6} textAlign='right'>
                                <Link href={`${process.env.REACT_APP_ADMIN_CONTACTS}`} underline='none' target='_blank'>
                                    {t('edit')}
                                </Link>
                            </Grid>
                            <Grid xs={6} md={6} textAlign='left'>
                                {t('contacts')}
                            </Grid>
                            <Grid xs={6} md={6} textAlign='right'>
                                <Link href={`${process.env.REACT_APP_ADMIN_CONTACTS}`} underline='none' target='_blank'>
                                    {t('edit')}
                                </Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={12} sx={{ fontSize: { xs: '14px', md: '16px' }, pt: 0 }}>
                <LogbookReasonsList />
            </Grid>
        </>
    );
}

import * as React from 'react';
import { useGetFilteredTours } from '../../api/LogbookApi';
import { Button, Dialog, Tooltip, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetTourTypes, getBreakPoints } from '../other/ConfigBasic';
import { useState } from 'react';
import MergeToursDialog from './MergeToursDialog';

export default function MergeTours(props) {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const selectedTourData = props.getSelectedToursData();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    let minDtBegin = '';
    let maxDtEnd = '';
    selectedTourData.forEach((tour) => {
        if (minDtBegin === '') {
            minDtBegin = tour.dtBegin;
        } else if (minDtBegin > tour.dtBegin) {
            minDtBegin = tour.dtBegin;
        }

        if (maxDtEnd === '') {
            maxDtEnd = tour.dtEnd;
        } else if (maxDtEnd < tour.dtEnd) {
            maxDtEnd = tour.dtEnd;
        }
    });

    const filteredToursData = useGetFilteredTours(props.logbookId, minDtBegin, maxDtEnd);

    const checkButton = () => {
        if (selectedTourData.length === 1 || filteredToursData === undefined || (filteredToursData !== undefined && filteredToursData.length > selectedTourData.length)) {
            return true;
        } else {
            return false;
        }
    };

    const showMergeHint = () => {
        let result = selectedTourData.find((tour) => tour.type === GetTourTypes().PRIVATE);
        if (result !== undefined) {
            return true;
        } else {
            return false;
        }
    };

    const handleClose = (value) => {
        setOpen(value);
        if (!desktop900) {
            props.closeMobilMenu();
        }
    };

    return (
        <>
            <Tooltip title={checkButton() ? t('invalidSelection') : showMergeHint() ? t('mergedAsPrivate') : ''}>
                <span>
                    <Button variant='text' color='primary' disabled={checkButton()} onClick={() => setOpen(true)}>
                        {t('mergeTours')}
                    </Button>
                </span>
            </Tooltip>
            <Dialog open={open}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <MergeToursDialog mergeTours={props.mergeTours} handleClose={handleClose} minDtBegin={minDtBegin} maxDtEnd={maxDtEnd} />
            </Dialog>
        </>
    );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
    Box,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';
import { getFullname } from '../other/ConfigBasic';
import { useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useGetPersonsApi, useLogbookNotifications, useUpdateLogbookNotifications } from '../../api/LogbookApi';
import CreateDialog from '../createLogbooks/CreateDailog';
import NotificationTopic from './NotificationTopic';



export default function NotificationDialog(props) {
    const { selectedLogbooks, setMessage, setSelected, open, setOpen } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [indeterminate, setIndeterminate] = useState([]);
    const { t } = useTranslation();

    const [checked, setChecked] = useState([]);
    const [secondTopicValue, setSecondTopicValue] = useState(null);
    const notificationEmails = useLogbookNotifications(selectedLogbooks, setMessage);
    const [privateTopicReceived, setPrivateTopicReceived] = useState([]);
    const [openToursReceived, setOpenToursReceived] = useState([]);
    const updateNotificationsForIds = useUpdateLogbookNotifications(setMessage);
    const [openCreate, setOpenCreate] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const persons = useGetPersonsApi();

    const filteredUsers = persons
        ?.filter((user) => {
            if (user.zluser && user.zluser.permissions) {
                return user.zluser.permissions.some((permission) => {
                    return permission.name === '*' || permission.name === 'logbook-cloud';
                });
            }
            return false;
        })
        .map((user) => ({
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            access: [],
        })).filter(user => (
            (user.firstname && user.firstname.toLowerCase().includes(searchValue.toLowerCase())) ||
            (user.lastname && user.lastname.toLowerCase().includes(searchValue.toLowerCase()))
        ))

    useEffect(() => {
        const commonReceiversByTopic = {};
        const uniqueIds = new Set();

        notificationEmails.forEach((entry) => {
            entry.notifications.forEach((notification) => {
                const { topic, receivers } = notification;
                if (!(topic in commonReceiversByTopic)) {
                    commonReceiversByTopic[topic] = new Set(receivers);
                } else {
                    commonReceiversByTopic[topic] = new Set([...commonReceiversByTopic[topic]].filter((receiver) => receivers.includes(receiver)));
                }
                receivers.forEach((receiver) => {
                    uniqueIds.add(receiver);
                });
            });
        });

        const checkedSet = new Set();
        for (const topic in commonReceiversByTopic) {
            commonReceiversByTopic[topic].forEach((receiver) => {
                checkedSet.add(receiver);
            });
        }
        const checkedArray = Array.from(checkedSet);
        const uniqueIdsArray = Array.from(uniqueIds);
        setChecked(checkedArray);
        setIndeterminate(uniqueIdsArray);
    }, [notificationEmails, open]);

    const handleUpdateNotifications = async () => {
        const ids = selectedLogbooks;
        const notifications = [{
            channel: 'email',
            receivers: secondTopicValue === 'all' ? checked : openToursReceived.length > 0 && secondTopicValue !== 'privateNotify' ? openToursReceived : [],
            topic: 'openTours'
        }, {
            channel: 'email',
            receivers: secondTopicValue === 'all' ? checked : privateTopicReceived.length > 0 && secondTopicValue !== 'openTours' ? privateTopicReceived : [],
            topic: 'privateNotify'
        }];
        try {
            await updateNotificationsForIds(ids, notifications);
            setMessage(t('maillingListUpdated'));
        } catch (error) {
            setMessage(error.message);
        }
    };



    const handleToggle = (driverId) => {
        const currentIndex = checked.indexOf(driverId);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(driverId);
        } else {
            newChecked.splice(currentIndex, 1);
            const indeterminateIndex = indeterminate.indexOf(driverId);
            if (indeterminateIndex !== -1) {
                const newIndeterminate = [...indeterminate];
                newIndeterminate.splice(indeterminateIndex, 1);
                setIndeterminate(newIndeterminate);
            }
            setPrivateTopicReceived(prevPrivateTopicReceived => prevPrivateTopicReceived.filter(existingId => existingId !== driverId));

            setOpenToursReceived(prevOpenToursReceived => prevOpenToursReceived.filter(existingId => existingId !== driverId));
        }
        setChecked(newChecked);
    };

    const handleChangeAll = () => {
        if (checked.length < filteredUsers.length) {
            setChecked(filteredUsers.map((person) => person.id));
        } else {
            setChecked([]);
            setSecondTopicValue(null)
            setPrivateTopicReceived([]);
            setOpenToursReceived([]);
            setIndeterminate([]);
        }
    };

    const handleSave = async () => {
        handleUpdateNotifications()
        setOpenCreate(true);
        setOpenCreate(true);
        setOpen(false);
        setSelected([]);
    };

    const handleCancel = () => {
        setOpen(false);
        setSelected([]);
        setSecondTopicValue(null);
        setPrivateTopicReceived([]);
        setOpenToursReceived([]);
    };

    const handleFirstTopicChange = (newValue) => {
        setSecondTopicValue(newValue);
    };

    const handleOnChange = (event) => {
        event.stopPropagation()
        event.preventDefault()
        setSearchValue(event.target.value);
    }

    <CreateDialog open={openCreate} setOpen={setOpenCreate} t={t} totalSteps={selectedLogbooks.length} />

    const disable = () => {
        return !checked.every((id) => {
            return openToursReceived.includes(id) || privateTopicReceived.includes(id)
        })
    }
    return (
        <>
            <Dialog fullScreen={isMobile ? true : false} open={open} onClose={handleCancel} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('notifications')}</DialogTitle>
                {/** individual Access */}
                <DialogContent>
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        padding='0 8px'
                        border={`solid 1px ${theme.palette.grey[600]}`}
                        borderRadius='4px'
                        marginBottom='8px'
                    >
                        <InputBase placeholder={t('search')} onChange={(e) => handleOnChange(e)} value={searchValue} />
                        <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                    </Box>
                    <ListItem key='all' sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '8px' }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={0}>
                            <ListItemIcon>
                                <Checkbox
                                    onClick={() => handleChangeAll()}
                                    checked={checked?.length === filteredUsers?.length}

                                />

                            </ListItemIcon>
                            <ListItemText id='all' primary={t('all')} />
                        </Stack>
                        <NotificationTopic
                            setOpenReceivers={setOpenToursReceived}
                            setPrivateReveivers={setPrivateTopicReceived}
                            extend={true}
                            onChange={handleFirstTopicChange}
                            disabled={checked?.length !== filteredUsers?.length}
                        />
                    </ListItem>
                    <List dense sx={{ height: '300px', overflow: 'scroll', overflowX: 'hidden', minWidth: '400px', paddingInline: '8px' }}>
                        {filteredUsers?.length > 0 ? (
                            filteredUsers?.map((data) => {
                                const labelId = `checkbox-list-secondary-label-${data.id}`;
                                return (
                                    <ListItem key={data.id} disablePadding sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={0}>
                                            <ListItemIcon sx={{ marginRight: '-16px' }}>
                                                <Checkbox
                                                    edge='end'
                                                    onClick={() => handleToggle(data.id)}
                                                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                                                    checked={checked.includes(data.id)}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    indeterminate={indeterminate.includes(data.id) && !checked.includes(data.id)}
                                                />
                                            </ListItemIcon>

                                            <ListItemText id={labelId} primary={getFullname(data)} />
                                        </Stack>
                                        <NotificationTopic
                                            setOpenReceivers={setOpenToursReceived}
                                            setPrivateReveivers={setPrivateTopicReceived}
                                            userTopic={secondTopicValue}
                                            notificationEmails={notificationEmails}
                                            id={data.id}
                                            disabled={!checked.includes(data.id) && !indeterminate.includes(data.id)}
                                            checked={checked}
                                        />
                                    </ListItem>
                                );
                            })
                        ) : filteredUsers?.length === 0 && searchValue.length === 0 ? (
                            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <ListItem>
                                <ListItemText>
                                    <Typography variant='caption' color='gray'>
                                        {t('noResult')}
                                    </Typography>

                                    <Typography variant='h4' color='#00360F' sx={{ mt: '40px' }}>
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            variant='subtitle1'
                                            style={{
                                                color: '#00360F',
                                                textDecoration: 'none',
                                            }}
                                            href={process.env.REACT_APP_ADMIN_CONTACTS}
                                        >
                                            {t('addADriver')}
                                        </a>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        )}
                    </List>
                    <Stack direction={'row'} padding={2}>
                        <InfoIcon sx={{ alignSelf: 'center', marginRight: '8px' }} /> {t('adminEditNotificationWarning')}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='secondary' onClick={() => handleCancel()}>
                        {t('cancel')}
                    </Button>
                    <Button variant='contained' color='success' onClick={() => handleSave()}
                        disabled={disable()}>
                        {t('save')}
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
}

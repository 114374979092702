import React from 'react';
import { Button, InputBase, Stack } from '@mui/material';
import theme from '../../themes/theme';

import AddIcon from '@mui/icons-material/Add';
import CreateLogbookAddDriver from '../Tour/CreateLogbookAddDriver';

const SearchAndAdd = (props) => {
    const { t, driverData, personName = [], setPersonName, initialPersonSet, title, open, setOpen, searchTerm, setSearchTerm } = props;

    return (
        <Stack direction={'row'} justifyContent={personName.length > 0 ? 'space-between' : 'flex-end'}>
            {personName.length > 0 && (
                <Stack
                    direction={'row'}
                    alignItems='center'
                    justifyContent='space-between'
                    padding='6px'
                    sx={{ backgroundColor: theme.palette.grey[100] }}
                    borderRadius='4px'
                    marginBottom='8px'
                    flexBasis='30%'
                >
                    <InputBase placeholder={t('searchInList')} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} sx={{ fontSize: '14px' }} />
                </Stack>
            )}
            <Stack>
                {personName.length > 0 && (
                    <Button startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                        {t('add')}
                    </Button>
                )}
                <CreateLogbookAddDriver
                    showDialog={open}
                    setShowDialog={setOpen}
                    driverData={driverData}
                    personName={personName}
                    setPersonName={setPersonName}
                    initialPersonSet={initialPersonSet}
                    createLogBooks={true}
                    title={title}
                />
            </Stack>
        </Stack>
    );
};

export default SearchAndAdd;

import { useQuery, gql, useMutation, useLazyQuery, useApolloClient } from '@apollo/client';
import Moment from 'moment';
import { compareArrayOfReasons, getCookie, getNumberWithThousandSeparator } from '../components/other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { authClient } from '../components/auth/authClient';
import axios from 'axios';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_CONTACT_PERSONS = gql`
    query getContactPersons($custNr: ObjectScalar!) {
        contactPersons(filter: { val: $custNr, op: EQ, key: "custNr" }, page: null, sort: { op: ASC, key: "name" }) {
            firstname
            lastname
            id
        }
    }
`;

const GET_CONTACT_COMPANIES = gql`
    query getContactCompanies($custNr: ObjectScalar) {
        contactCompanies(filter: { val: $custNr, op: EQ, key: "custNr" }, page: null, sort: { op: ASC, key: "name" }) {
            legalName
            id
        }
    }
`;

export function useGetContactsApi(custNr) {
    const { data: contactPersonsData } = useQuery(GET_CONTACT_PERSONS, {
        variables: {
            custNr,
        },
    });
    const { data: contactCompaniesData } = useQuery(GET_CONTACT_COMPANIES, {
        variables: {
            custNr,
        },
    });

    if (contactPersonsData && contactCompaniesData) {
        const contactsData = {
            contactPersons: contactPersonsData.contactPersons,
            contactCompanies: contactCompaniesData.contactCompanies,
        };
        return contactsData;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_DRIVER = gql`
    query getDrivers {
        contactPersons(filter: { val: "true", op: EQ, key: "isDriver" }, page: null, sort: { op: ASC, key: "name" }) {
            firstname
            lastname
            id
            isDriver
            imageUri
            loginUuid
        }
    }
`;

export function useContactIsDriverApi(setMessage) {
    const { t } = useTranslation();
    const [driverData, setDriverData] = useState(undefined);

    useQuery(GET_DRIVER, {
        onCompleted: (data) => {
            let driverList = [];
            data?.contactPersons?.forEach((driver) => {
                const newDriver = {
                    firstname: driver?.firstname ? driver?.firstname : null,
                    lastname: driver?.lastname ? driver?.lastname : null,
                    id: driver?.id ? driver?.id : null,
                    isDriver: driver?.isDriver ? driver?.isDriver : null,
                    imageUri: driver?.imageUri ? driver?.imageUri : null,
                    loginUuid: driver?.loginUuid ? driver?.loginUuid : null,
                };
                driverList.push(newDriver);
            });
            if (driverList.length === 0) {
                setDriverData([]);
            } else {
                setDriverData(driverList);
            }
        },
        onError: (error) => {
            setMessage(`${t('apiErrorDriverData')} ${error.graphQLErrors[0].message}`);
            setDriverData([]);
        },
    });

    if (driverData !== undefined) {
        return driverData;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ME = gql`
    query getMe {
        me {
            email
            custNr
            adminRoles
            loginUuid
            firstname
            lastname
            id
            zluser {
                imageUri
                org
                locked
                expiration
                permissions {
                    name
                }
            }
        }
    }
`;

const GET_LOGBOOKS = gql`
    query getLogbooks {
        logbooks {
            name
            id
            idAsset
            status
            access {
                holder
                allowed
                dtNotAfter
                dtNotBefore
            }
        }
    }
`;

/**
 * Load the current user data and a list of logbooks.
 * If user is custommer admin, the logbook list get the
 * option to create a new logbook.
 * @returns user data and logbook list
 */
export function useGetInitialData() {
    const logbookList = [];
    const location = useLocation();

    const { loading, error, data: userData } = useQuery(GET_ME);
    const { data: logbooks } = useQuery(GET_LOGBOOKS);
    const { data: teams } = useQuery(GET_TEAM_ACCESS);

    if (error) {
        const token = getCookie(process.env.REACT_APP_COOKIE_NAME);
        if (token.length === 0) {
            let redirectExtension = location.pathname.slice(1).replace('/', '%2F');
            window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL + redirectExtension;
        } else {
            window.location.href = process.env.REACT_APP_ZLOGIN_URL;
        }
    }

    if (!loading) {
        const validation = authClient(userData);

        if (logbooks && userData && teams) {
            logbooks.logbooks.forEach((logbook) => {
                let loginUserAccess = logbook.access.filter((a) => a.holder === userData.me.id || a.holder === teams?.teams[0]?.id);
                let completeAccess = [];
                loginUserAccess?.forEach((access) => {
                    access?.allowed?.forEach((right) => {
                        const dtNotBeforIsValid = access?.dtNotBefore === null || access?.dtNotBefore < Moment().format('YYYY-MM-DD');
                        const dtNotAfterIsValid = access?.dtNotAfter === null || access?.dtNotAfter > Moment().format('YYYY-MM-DD');

                        if (!completeAccess.includes(right) && dtNotBeforIsValid && dtNotAfterIsValid) {
                            completeAccess.push(right);
                        }
                    });
                });

                logbookList.push({
                    label: logbook.name,
                    value: logbook.id,
                    assetId: logbook.idAsset,
                    status: logbook.status,
                    loginUserAccess: loginUserAccess.length > 0 ? completeAccess : null,
                });
            });
            logbookList.sort((a, b) => {
                if (a.label.toLocaleUpperCase() < b.label.toLocaleUpperCase()) {
                    return -1;
                } else if (a.label.toLocaleUpperCase() > b.label.toLocaleUpperCase()) {
                    return 1;
                } else {
                    return 0;
                }
            });
            const initialData = {
                userData: userData,
                logbookList: logbookList,
                validation: validation,
            };
            return initialData;
        }
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_OPEN_TOURS = gql`
    query getOpenTours($idLogbook: ObjectScalar!) {
        logbooktours(filter: { op: AND, filters: [{ val: $idLogbook, op: EQ, key: "idLogbook" }, { val: "OPEN", op: EQ, key: "status" }] }) {
            dtBegin
            dtEnd
            id
            distance
            streetBegin
            postalBegin
            cityBegin
            streetEnd
            postalEnd
            cityEnd
            type
        }
    }
`;

export function useGetOpenTours(idLogbook) {
    const { data: openToursData } = useQuery(GET_OPEN_TOURS, {
        variables: {
            idLogbook,
        },
    });

    if (openToursData) {
        return openToursData.logbooktours;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

/**
 * Starts a request only for 100 entries, because the badge show maximum 99+
 */
const GET_OPEN_TOURS_LENGTH = gql`
    query getOpenToursLength($idLogbook: ObjectScalar!) {
        logbooktours(page: { page: 1, pageSize: 100 }, filter: { op: AND, filters: [{ val: $idLogbook, op: EQ, key: "idLogbook" }, { val: "OPEN", op: EQ, key: "status" }] }) {
            id
        }
    }
`;

export function useGetOpenToursLength(idLogbook) {
    const { t } = useTranslation();
    const [getOpenToursLength, { data: openToursData }] = useLazyQuery(GET_OPEN_TOURS_LENGTH, {
        variables: {
            idLogbook,
        },
    });

    useEffect(() => {
        if (idLogbook !== '' && idLogbook !== t('newLogbook') && idLogbook !== t('overview') && idLogbook !== t('selectLogbook')) {
            getOpenToursLength();
        }
    }, [idLogbook, getOpenToursLength, t]);

    if (idLogbook === '' || idLogbook === t('newLogbook') || idLogbook === t('overview') || idLogbook === t('selectLogbook')) {
        return 0;
    }

    if (openToursData) {
        return openToursData?.logbooktours?.length;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_MODIFIED_TOURS = gql`
    query getModifiedTours($idLogbook: ObjectScalar!, $dtBegin: ObjectScalar!, $dtEnd: ObjectScalar!) {
        logbooktours(
            filter: {
                op: OR
                filters: [
                    {
                        op: AND
                        filters: [
                            { val: $idLogbook, op: EQ, key: "idLogbook" }
                            { val: "EDITED", op: EQ, key: "status" }
                            { val: $dtBegin, op: GTE, key: "dtBegin" }
                            { val: $dtEnd, op: LTE, key: "dtEnd" }
                        ]
                    }
                    {
                        op: AND
                        filters: [
                            { val: $idLogbook, op: EQ, key: "idLogbook" }
                            { val: "OUTDATED", op: EQ, key: "status" }
                            { val: $dtBegin, op: GTE, key: "dtBegin" }
                            { val: $dtEnd, op: LTE, key: "dtEnd" }
                        ]
                    }
                    {
                        op: AND
                        filters: [
                            { val: $idLogbook, op: EQ, key: "idLogbook" }
                            { val: "EXPORTED", op: EQ, key: "status" }
                            { val: $dtBegin, op: GTE, key: "dtBegin" }
                            { val: $dtEnd, op: LTE, key: "dtEnd" }
                        ]
                    }
                ]
            }
        ) {
            dtBegin
            streetBegin
            postalBegin
            cityBegin
            mileageBegin
            dtEnd
            streetEnd
            postalEnd
            cityEnd
            mileageEnd
            type
            contactName
            driver
            distance
            dtEdited
            status
            id
        }
    }
`;

export function useGetFilteredModifidedTours(idLogbook, dateFilter) {
    let dtBegin = Moment(dateFilter.dateFrom).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    let dtEnd = Moment(dateFilter.dateUntil).format('YYYY-MM-DD') + 'T23:59:59.999Z';
    const { data: modifiedToursData } = useQuery(GET_MODIFIED_TOURS, {
        variables: {
            idLogbook,
            dtBegin,
            dtEnd,
        },
    });

    if (modifiedToursData) {
        return modifiedToursData.logbooktours;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

//--------------------------------------------------//
//------check validation for export-----------------//
//--------------------------------------------------//

const CHECK_EXPORT_VALIDATIONS = gql`
    query getOpenToursInTimeRange($idLogbook: ObjectScalar!, $dtBegin: ObjectScalar!, $dtEnd: ObjectScalar!) {
        logbooktours(
            filter: {
                op: AND
                filters: [
                    { val: $idLogbook, op: EQ, key: "idLogbook" }
                    { val: "OPEN", op: EQ, key: "status" }
                    { val: $dtBegin, op: GTE, key: "dtBegin" }
                    { val: $dtEnd, op: LTE, key: "dtEnd" }
                ]
            }
        ) {
            dtBegin
            status
            type
            dtEdited
            dtEnd
            id
        }
    }
`;

export function useCheckExportValidations(idLogbook, dateFilter) {
    let dtBegin = Moment(dateFilter.dateFrom).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    let dtEnd = Moment(dateFilter.dateUntil).format('YYYY-MM-DD') + 'T23:59:59.999Z';
    const { data: timeRangeToursData } = useQuery(CHECK_EXPORT_VALIDATIONS, {
        fetchPolicy: 'no-cache',
        variables: {
            idLogbook,
            dtBegin,
            dtEnd,
        },
    });

    if (timeRangeToursData) {
        if (timeRangeToursData.logbooktours.length > 0) {
            return false;
        } else {
            return true;
        }
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_SELECTED_TOUR = gql`
    query logbooktours($id: ObjectScalar!) {
        logbooktours(filter: { val: $id, op: EQ, key: "id" }) {
            access {
                allowed
                dtNotAfter
                dtNotBefore
                holder
                variant
            }
            cityBegin
            cityEnd
            countryBegin
            countryEnd
            custNr
            dtBegin
            dtCreated
            dtEnd
            id
            idLogbook
            latlonBegin
            latlonEnd
            distance
            mileageBegin
            mileageEnd
            postalBegin
            postalEnd
            stateBegin
            stateEnd
            streetBegin
            streetEnd
            driver
            type
            reason
            contactName
            costCentre
            costObject
            annotation
            status
            calibrationType
        }
    }
`;

export function useGetSelectedTour(id) {
    const { data: selectedTour } = useQuery(GET_SELECTED_TOUR, {
        variables: {
            id,
        },
        onError: (error) => {
            console.log(error.graphQLErrors[0].message);
        },
    });
    if (selectedTour) {
        return selectedTour.logbooktours;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_SELECTED_MIXED_TOUR = gql`
    query getSelectedMixedTour($id: ObjectScalar!) {
        logbooktours(filter: { val: $id, op: EQ, key: "id" }) {
            streetBegin
            postalBegin
            cityBegin
            streetEnd
            postalEnd
            cityEnd
        }
    }
`;

export function useGetSelectedMixedTour(id, setMessage) {
    const [getTourData, { data: selectedTour }] = useLazyQuery(GET_SELECTED_MIXED_TOUR, {
        variables: {
            id,
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    useEffect(() => {
        getTourData();
    }, [id, getTourData]);

    if (selectedTour) {
        return selectedTour.logbooktours;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_SELECTED_TOUR_STATUS = gql`
    query getSelectedTourStatus($id: ObjectScalar!) {
        logbooktours(filter: { val: $id, op: EQ, key: "id" }) {
            status
        }
    }
`;

export function useGetSelectedTourStatus(id) {
    const [getTourStatus, { data: selectedTour }] = useLazyQuery(GET_SELECTED_TOUR_STATUS, {
        variables: {
            id,
        },
    });

    useEffect(() => {
        if (id !== undefined) {
            getTourStatus();
        }
    }, [getTourStatus, id]);

    if (id === undefined) {
        return false;
    }

    if (selectedTour && selectedTour.logbooktours.length > 0) {
        return selectedTour.logbooktours[0].status === 'OPEN' ? false : true;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ASSETS = gql`
    query GetAssets($custNr: ObjectScalar!) {
        assets(sort: null, page: null, filter: { op: AND, filters: [{ val: $custNr, op: EQ, key: "custNr" }, { val: null, op: NE, key: "child" }] }) {
            name
            id
            child {
                __typename
            }
        }
    }
`;

const filterVehicleData = (assetData) => {
    return assetData.child.__typename === 'VehicleChild';
};

export function useGetVehiclesApi(custNr) {
    const { data: assetsData } = useQuery(GET_ASSETS, {
        variables: {
            custNr,
        },
    });

    if (assetsData) {
        let filteredVehiclesData = assetsData.assets.filter(filterVehicleData);
        let sortedVehicleData = filteredVehiclesData.sort((a, b) => {
            if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
                return -1;
            } else if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        return sortedVehicleData;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_LOGBOOK_DRIVERS = gql`
    query getLogbookDrivers($id: ObjectScalar!) {
        logbooks(filter: { val: $id, op: EQ, key: "id" }) {
            idDrivers
        }
    }
`;

export function useGetLogbookDriversApi(logbookId) {
    const { data: driverIds } = useQuery(GET_LOGBOOK_DRIVERS, {
        variables: {
            id: logbookId,
        },
    });
    if (driverIds !== undefined) {
        return driverIds?.logbooks[0]?.idDrivers;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

export const UPDATE_MILEAGE = gql`
    mutation updateMileage($id: ID!, $km: Float!) {
        updateAssetOdom(id: $id, km: $km)
    }
`;

const CREATE_NEW_LOGBOOK = gql`
    mutation createNewLogbook($idAsset: String!, $idDrivers: [String!], $name: String!, $status: EnumLogbookStatus!) {
        createLogbook(input: { idAsset: $idAsset, idDrivers: $idDrivers, name: $name, status: $status }) {
            name
            id
            status
        }
    }
`;

export function CreateNewLogbook(props) {
    const { t } = useTranslation();
    const [updateMileage] = useMutation(UPDATE_MILEAGE, {
        variables: {
            id: props.createLogbook.idAsset,
            km: props.createLogbook.mileage,
        },
        onCompleted: () => {
            createLogbook();
        },
        onError: (error) => {
            props.setMessage(error.graphQLErrors[0].message);
        },
    });

    const [createLogbook] = useMutation(CREATE_NEW_LOGBOOK, {
        variables: {
            idAsset: props.createLogbook.idAsset,
            idDrivers: props.createLogbook.idDrivers,
            name: props.createLogbook.name,
            status: props.createLogbook.mileage === '' ? 'pending' : 'active',
        },
        refetchQueries: [{ query: GET_LOGBOOKS }],
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
        onCompleted: () => {
            props.resetForm();
            props.setMessage(t('logbookSuccessfulCreated'));
        },
        onError: (error) => {
            props.setMessage(error.graphQLErrors[0].message);
        },
    });

    const createNewLogbook = () => {
        const createLogbookFormIsValid = props.formValidation(props.setValidationErrors, props.createLogbook.idAsset, props.createLogbook.idDrivers, props.createLogbook.name);
        if (createLogbookFormIsValid) {
            if (props.createLogbook.mileage !== '') {
                updateMileage();
            } else {
                createLogbook();
            }
        }
    };

    return (
        <>
            <Box textTransform={'uppercase'} sx={{ cursor: 'pointer' }} onClick={() => createNewLogbook()}>
                {t('create')}
            </Box>
        </>
    );
}

//--------------------------------------------------//
//--------------- Create Logbooks-------------------//
//--------------------------------------------------//

const CREATE_LOG_BOOKS = gql`
    mutation CreateLogbook($name: String!, $idAsset: String!, $idDrivers: [String!], $status: EnumLogbookStatus!, $access: [AccessInput]) {
        createLogbook(input: { access: $access, idAsset: $idAsset, idDrivers: $idDrivers, name: $name, status: $status }) {
            id
            name
            status
        }
    }
`;

export const useCreateLogbooks = (setMessage) => {
    const [createLogbook, { loading: createLogbookLoading, error: createLogbookError }] = useMutation(CREATE_LOG_BOOKS, {
        refetchQueries: [{ query: GET_LOGBOOKS }],
        onError: (error) => {
            setMessage(error.message);
        },
    });
    const [updateMileage, { loading: updateMileageLoading, error: updateMileageError }] = useMutation(UPDATE_MILEAGE);

    const createLogbookItem = async (name, idAsset, idDrivers, access, km, id) => {
        try {
            await updateMileage({
                variables: {
                    id: id,
                    km: km,
                },
            });
            createLogbook({
                variables: { name, idAsset, idDrivers, status: 'active', access },
            });

            setMessage('active');
        } catch (error) {
            createLogbook({
                variables: { name, idAsset, idDrivers, status: 'pending', access },
            });
            setMessage('pending');
        }
    };

    return { createLogbookItem, loading: createLogbookLoading || updateMileageLoading, error: createLogbookError || updateMileageError };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ALL_TOUR_VERSIONS = gql`
    query getAllTourVersions($idLogbook: ObjectScalar!, $dtBegin: ObjectScalar!) {
        logbooktours(
            sort: { key: "dtEdited", op: DESC }
            filter: { op: AND, filters: [{ val: $idLogbook, op: EQ, key: "idLogbook" }, { val: $dtBegin, op: EQ, key: "dtBegin" }] }
        ) {
            id
            dtEdited
            idEditor
        }
    }
`;

export function useGetAllTourVersionsApi(idLogbook, dtBegin) {
    const { data: allTourVersions } = useQuery(GET_ALL_TOUR_VERSIONS, {
        variables: {
            idLogbook: idLogbook,
            dtBegin: dtBegin,
        },
    });
    if (allTourVersions !== undefined) {
        return allTourVersions.logbooktours;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_SELECTED_LOGBOOK = gql`
    query getSelectedLogbook($id: ObjectScalar) {
        logbooks(filter: { val: $id, op: EQ, key: "id" }) {
            idCreated
            dtCreated
            id
            idAsset
            idDrivers
            name
            access {
                holder
                allowed
                variant
                dtNotBefore
                dtNotAfter
            }
            notifications {
                topic
                receivers
            }
        }
    }
`;

export function useGetSelectedLogbookApi(idLogbook) {
    const { data: selectedLogbook } = useQuery(GET_SELECTED_LOGBOOK, {
        variables: {
            id: idLogbook,
        },
    });

    if (selectedLogbook !== undefined) {
        return selectedLogbook.logbooks[0];
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ASSET_BASE_DATA = gql`
    query getAssetBaseData($id: ObjectScalar!) {
        assets(filter: { val: $id, op: EQ, key: "id" }) {
            id
            name
            child {
                ... on VehicleChild {
                    imageUri
                    licensePlate
                    vin
                    model
                    make
                }
            }
        }
    }
`;

export function useGetAssetDataApi(idAsset, setMessage = console.log) {
    const [assetData, setAssetData] = useState({
        imageUri: undefined,
        licensePlate: undefined,
        make: undefined,
        model: undefined,
        vin: undefined,
        name: undefined,
    });

    useQuery(GET_ASSET_BASE_DATA, {
        variables: {
            id: idAsset,
        },
        onCompleted: (data) => {
            const asset = data?.assets[0]?.child;
            setAssetData({
                imageUri: asset?.imageUri ? asset?.imageUri : null,
                licensePlate: asset?.licensePlate ? asset?.licensePlate : null,
                make: asset?.make ? asset?.make : null,
                model: asset?.model ? asset?.model : null,
                vin: asset?.vin ? asset?.vin : null,
                name: data?.assets[0]?.name ? data?.assets[0]?.name : null,
            });
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
            setAssetData({
                imageUri: null,
                licensePlate: null,
                make: null,
                model: null,
                vin: null,
                name: null,
            });
        },
    });

    if (assetData.vin !== undefined) {
        return assetData;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ASSET_DATA_FOR_STATUS = gql`
    query getAssetDataForStatus($idAsset: ObjectScalar) {
        assets(filter: { val: $idAsset, op: EQ, key: "id" }) {
            logLast {
                fuelPercent
                latlng
            }
            driverRegistration {
                idContactPerson
                start
                end
            }
        }
    }
`;

export function useGetAssetDataForStatus(idAsset, setMessage) {
    const { data } = useQuery(GET_ASSET_DATA_FOR_STATUS, {
        variables: {
            idAsset,
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    if (data !== undefined) {
        if (data.assets.length === 0) {
            //No Access to asset
            return [];
        } else {
            return data.assets[0];
        }
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_MULTI_ASSET_DAY_LOG = gql`
    query getMultiAssetDayLog($assetIds: [String]!, $startDate: LocalDate!, $endDate: LocalDate!) {
        multiAssetDayLog(assetIds: $assetIds, startDate: $startDate, endDate: $endDate) {
            log {
                speed
                latlng
                date
                why
                heading
            }
        }
    }
`;

export function useGetMultiAssetDayLog(idAsset, tourId, setMessage) {
    let dataMissing = true;
    const originTourData = useGetSelectedTour(tourId);
    const startDate = originTourData ? Moment(originTourData[0]?.dtBegin).format('YYYY-MM-DD') : undefined;
    const endDate = originTourData ? Moment(originTourData[0]?.dtEnd).format('YYYY-MM-DD') : undefined;

    if (startDate && endDate) {
        dataMissing = false;
    }

    const { data: dayLogs } = useQuery(GET_MULTI_ASSET_DAY_LOG, {
        skip: dataMissing,
        variables: {
            assetIds: idAsset,
            startDate: startDate,
            endDate: endDate,
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    if (dayLogs) {
        return {
            dayLogs: dayLogs,
            startDate: Moment(originTourData[0].dtBegin),
            endDate: Moment(originTourData[0].dtEnd),
        };
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_PERSONS = gql`
    query getPersons {
        persons {
            firstname
            lastname
            id
            zluser {
                id
                permissions {
                    name
                }
            }
        }
    }
`;

const GET_PERSONS_ADMIN = gql`
    query getPersons {
        persons {
            firstname
            lastname
            id
            access {
                holder
                allowed
                variant
            }
            zluser {
                id
                permissions {
                    name
                }
            }
        }
    }
`;

export function useGetPersonsApi() {
    const { data: persons } = useQuery(GET_PERSONS);

    if (persons !== undefined) {
        return persons.persons;
    }
}

export function useGetPersonsAdminApi() {
    const { data: persons } = useQuery(GET_PERSONS_ADMIN, {
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
    });
    if (persons !== undefined) {
        return persons.persons;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_FILTERED_TOURS = gql`
    query getFilteredTours($idLogbook: ObjectScalar!, $dtBegin: ObjectScalar!, $dtEnd: ObjectScalar!) {
        logbooktours(
            filter: { op: AND, filters: [{ val: $idLogbook, op: EQ, key: "idLogbook" }, { val: $dtBegin, op: GTE, key: "dtBegin" }, { val: $dtEnd, op: LTE, key: "dtEnd" }] }
        ) {
            id
            dtBegin
            dtEnd
        }
    }
`;

export function useGetFilteredTours(idLogbook, dtBegin, dtEnd) {
    const { data: filteredToursData } = useQuery(GET_FILTERED_TOURS, {
        variables: {
            idLogbook,
            dtBegin,
            dtEnd,
        },
    });

    if (filteredToursData) {
        return filteredToursData.logbooktours;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

export const GET_SELECTED_LOGBOOK_REASONS = gql`
    query getSelectedLogbookReasons($id: ObjectScalar) {
        logbooks(filter: { val: $id, op: EQ, key: "id" }) {
            id
            reasons {
                label
                idPerson
            }
        }
    }
`;

export function useGetSelectedLogbookReasons(idLogbook, setMessage, setReasons, currentUserId) {
    useQuery(GET_SELECTED_LOGBOOK_REASONS, {
        variables: {
            id: idLogbook,
        },
        onCompleted: (data) => {
            let loadedReasons = data.logbooks[0].reasons.map((reason) => {
                return {
                    label: reason.label,
                    idPerson: reason.idPerson,
                };
            });

            const uniqueReasons = loadedReasons.reduce((accumulator, current) => {
                if (!accumulator.find((item) => item.label === current.label && item.idPerson === current.idPerson)) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);

            const filteredReasons = uniqueReasons.filter((reason) => reason.idPerson === null || reason.idPerson === currentUserId);

            setReasons(filteredReasons.sort(compareArrayOfReasons));
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

export const GET_ASSET_REAL_ODOM = gql`
    query getAssetRealOdom($id: ObjectScalar) {
        assets(filter: { val: $id, op: EQ, key: "id" }) {
            logLast {
                realOdom
            }
        }
    }
`;

export function useGetAssetRealOdom(idAsset, callAgain, callback, setMessage) {
    const [getRealOdom] = useLazyQuery(GET_ASSET_REAL_ODOM, {
        variables: {
            id: idAsset === '' ? null : idAsset,
        },
        onCompleted: (data) => {
            const realOdom = data?.assets[0]?.logLast?.realOdom;
            if (realOdom) {
                callback((realOdom / 1000).toFixed(0));
            } else {
                callback('');
            }
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    useEffect(() => {
        if (callAgain) {
            getRealOdom();
        }
    }, [callAgain, getRealOdom]);
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_LOGBOOK_PRIVATE_JOURNEY = gql`
    query getLogbookPrivateJourney($id: ID!) {
        getLogbookPrivateJourney(id: $id) {
            idPrivateJourneyPerson
        }
    }
`;

export function useGetLogbookPrivateJourney(idLogbook, setMessage, callback) {
    useQuery(GET_LOGBOOK_PRIVATE_JOURNEY, {
        variables: {
            id: idLogbook,
        },
        onCompleted: (data) => {
            callback(data?.getLogbookPrivateJourney?.idPrivateJourneyPerson);
        },
        onError: (error) => {
            setMessage(error?.graphQLErrors[0]?.message);
        },
    });
}

/*
 //Eine Query in Abhängigkeit einer anderen Query aufrufen
 export function useContactApiTest() {
     const { data: userData } = useQuery(GET_ME)
     const custNr = userData?.me?.custNr
     const { data: contactsData } = useQuery(GET_CONTACTS, {
         skip: !custNr,
         variables: {
             custNr
         }
     })
 
     if (contactsData !== undefined) {
         return contactsData;
     }
 }
 
 //LazyQuery um eine Query in Abhängigkeit einer Aktion aufzurufen
 export function ContactApi(custNr) {
     const [getContactPersons, { error, loading, data, called }] = useLazyQuery(GET_CONTACTS,  {
         variables: {
             custNr,
         },
     });
 
     useEffect(() => {
         getContactPersons();
     }, [getContactPersons]);
 
     console.log({
         called,
         error,
         loading,
         data
     })
 
     return {
         data
     };
 };
 */

/*
 export function useMeApi() {
     const [getCurrentUser, { error, loading, data, called }] = useLazyQuery(GET_ME);
 
     useEffect(() => {
     getCurrentUser();
     }, [getCurrentUser]);
 
     console.log({
         called,
         error,
         loading,
         data
       })
 
     if (data) {
         return data.me.custNr;
     }
 }
 */

const GET_BATTERY_LEVEL = gql`
    query battery($assetIds: [String]!, $dtBegin: LocalDateTime!, $dtEnd: LocalDateTime!) {
        batteryLevelReportData(startDateTime: $dtBegin, assetIds: $assetIds, endDateTime: $dtEnd) {
            address
            assetName
            isOldReading
            level
            oldReadingTime
            serial
            time
        }
    }
`;

export function useGetBatteryLevel(assetIds, dtBegin, dtEnd) {
    const { data: filterdBatterLevel } = useQuery(GET_BATTERY_LEVEL, {
        variables: {
            assetIds,
            dtBegin,
            dtEnd,
        },
    });

    if (filterdBatterLevel) {
        return filterdBatterLevel;
    }
}

//

const GET_LICENSE = gql`
    query getLicense {
        me {
            zluser {
                permissions {
                    name
                }
            }
        }
    }
`;

export function useGetLicenses() {
    const { loading, data: userData } = useQuery(GET_LICENSE);
    if (!loading) {
        return userData.me.zluser.permissions;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ALL_RECEIPTS = gql`
    query receipts($id: ObjectScalar!, $firstDayOfMonth: ObjectScalar!, $lastDayOfMonth: ObjectScalar!) {
        logbookReceipts(
            filter: {
                op: AND
                filters: [{ key: "idLogbook", val: $id, op: EQ }, { val: $firstDayOfMonth, op: GT, key: "dtBilling" }, { val: $lastDayOfMonth, op: LT, key: "dtBilling" }]
            }
        ) {
            access {
                allowed
                dtNotAfter
                dtNotBefore
                holder
                variant
            }
            address {
                city
                coordinates
                country
                houseNo
                street
                zip
            }
            articles {
                category
                quantity
                total
                type
                unit
                unitPrice
                vat
            }
            currency
            custNr
            dtBilling
            dtCreated
            id
            idCreator
            idLogbook
            imageUrl
            netTotal
            number
            total
            vendor
        }
    }
`;

export function useGetReceipts(id, datum) {
    const firstDayOfMonth = Moment(datum).startOf('month');
    const lastDayOfMonth = Moment(datum).endOf('month');
    const {
        data: receipts,
        loading,
        error,
    } = useQuery(GET_ALL_RECEIPTS, {
        variables: {
            id: id,
            firstDayOfMonth: firstDayOfMonth,
            lastDayOfMonth: lastDayOfMonth,
        },
    });

    return { receipts, loading, error };
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ALL_COST_RECEIPTS = gql`
    query receipts($firstDayOfMonth: ObjectScalar!, $lastDayOfMonth: ObjectScalar!) {
        logbookReceipts(filter: { op: AND, filters: [{ val: $firstDayOfMonth, op: GT, key: "dtBilling" }, { val: $lastDayOfMonth, op: LT, key: "dtBilling" }] }) {
            access {
                allowed
                dtNotAfter
                dtNotBefore
                holder
                variant
            }
            address {
                city
                coordinates
                country
                houseNo
                street
                zip
            }
            articles {
                category
                quantity
                total
                type
                unit
                unitPrice
                vat
                costCentre
                costObject
            }
            currency
            custNr
            dtBilling
            dtCreated
            id
            idCreator
            idLogbook
            imageUrl
            netTotal
            number
            total
            vendor
        }
    }
`;

export function useGetAllReceipts(datum) {
    const firstDayOfMonth = Moment(datum).startOf('month');
    const lastDayOfMonth = Moment(datum).endOf('month');
    const { data: receipts } = useQuery(GET_ALL_COST_RECEIPTS, {
        variables: {
            firstDayOfMonth: firstDayOfMonth,
            lastDayOfMonth: lastDayOfMonth,
        },
    });
    return { receipts };
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ONE_RECEIPT = gql`
    query receipt($id: ObjectScalar!) {
        logbookReceipts(filter: { key: "id", val: $id, op: EQ }) {
            address {
                city
                coordinates
                country
                houseNo
                street
                zip
            }
            articles {
                category
                quantity
                total
                type
                unit
                unitPrice
                vat
                costCentre
                costObject
            }
            dtBilling
            id
            imageUrl
            netTotal
            number
            total
            vendor
        }
    }
`;

export function useGetReceipt(id) {
    const {
        data: receipt,
        loading,
        error,
    } = useQuery(GET_ONE_RECEIPT, {
        variables: {
            id: id,
        },
    });
    return { receipt, loading, error };
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const DELETE_ONE_RECEIPT = gql`
    mutation deleteReceipt($id: ID!) {
        deleteLogbookReceipt(id: $id) {
            affectedRows
        }
    }
`;

export const useDeleteReceipt = (setMessage) => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const location = useLocation();

    const [deleteReceipt] = useMutation(DELETE_ONE_RECEIPT, {
        onCompleted: () => {
            setMessage(t('receiptDeleted'));
            if (location.pathname.includes('/costsOverview')) {
                client.refetchQueries({
                    include: ['receipts'],
                });
            }
        },
        onError: () => {
            setMessage(t('receiptDeletedFehler'));
        },
    });

    return { deleteReceipt };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const CREATE_LOGBOOK_RECEIPT = gql`
    mutation cReceipt(
        $address: LogbookReceiptAddressInput
        $articles: [LogbookReceiptArticleInput]!
        $currency: EnumLogbookReceiptCurrency!
        $dtBilling: DateTime!
        $netTotal: Float!
        $number: String!
        $total: Float!
        $vendor: String
        $idLogbook: String!
    ) {
        createLogbookReceipt(
            input: { address: $address, articles: $articles, currency: $currency, dtBilling: $dtBilling, netTotal: $netTotal, number: $number, total: $total, vendor: $vendor }
            idLogbook: $idLogbook
        ) {
            total
            id
            netTotal
        }
    }
`;

export function useCreateReceipt(setMessage) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [createLogbookReceipt, { loading }] = useMutation(CREATE_LOGBOOK_RECEIPT);

    const createReceipt = async (
        address,
        currency,
        dtBilling,
        netTotal,
        number,
        total,
        vendor,
        idLogbook,
        quantity,
        type,
        unit,
        unitPrice,
        vat,
        file,
        category,
        costCentre,
        costObject
    ) => {
        let errorOccurred = false;
        let idLogbookReceipt = '';
        let logbookReceiptArticle = [];

        if (type === '') {
            logbookReceiptArticle.push({
                category: category,
                quantity: quantity,
                total: total,
                unit: unit,
                unitPrice: unitPrice,
                vat: vat,
                costCentre: costCentre,
                costObject: costObject,
            });
        } else {
            logbookReceiptArticle.push({
                category: category,
                quantity: quantity,
                total: total,
                type: type,
                unit: unit,
                unitPrice: unitPrice,
                vat: vat,
                costCentre: costCentre,
                costObject: costObject,
            });
        }

        try {
            const receiptResponse = await createLogbookReceipt({
                variables: {
                    address: address,
                    articles: logbookReceiptArticle,
                    currency: currency,
                    dtBilling: dtBilling,
                    netTotal: netTotal,
                    number: number,
                    total: total,
                    vendor: vendor,
                    idLogbook: idLogbook,
                },
            });
            idLogbookReceipt = receiptResponse.data.createLogbookReceipt.id;

            if (file) {
                await axios
                    .post(`${process.env.REACT_APP_MINX_API_URL}/${idLogbookReceipt}`, file, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${getCookie(process.env.REACT_APP_COOKIE_NAME)}`,
                        },
                    })
                    .catch((error) => {
                        errorOccurred = true;
                        console.log(error);
                        navigate(`/costs/${idLogbookReceipt}`, {
                            state: {
                                snackbarMessage: t('fehlerImage'),
                            },
                        });
                    });
            }
        } catch (error) {
            errorOccurred = true;
            console.log(error);
            setMessage(t('receiptCreationError'));
        }

        if (!errorOccurred) {
            navigate('/costsOverview', {
                state: {
                    snackbarMessage: t('newReceiptCreated'),
                },
            });
        }
    };

    return { createReceipt, loading };
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const UPDATE_RECEIPT = gql`
    mutation updateReceipt(
        $address: LogbookReceiptAddressInput
        $articles: [LogbookReceiptArticleInput]!
        $currency: EnumLogbookReceiptCurrency!
        $dtBilling: DateTime!
        $netTotal: Float!
        $number: String!
        $total: Float!
        $vendor: String
        $id: ID!
    ) {
        updateLogbookReceipt(
            input: { address: $address, articles: $articles, currency: $currency, dtBilling: $dtBilling, netTotal: $netTotal, number: $number, total: $total, vendor: $vendor }
            id: $id
        ) {
            address {
                city
                coordinates
                country
                houseNo
                street
                zip
            }
            articles {
                category
                quantity
                total
                type
                unit
                unitPrice
                vat
                costCentre
                costObject
            }
            currency
            custNr
            dtBilling
            dtCreated
            id
            idCreator
            idLogbook
            imageUrl
            netTotal
            number
            total
            vendor
        }
    }
`;

export function useUpdateReceipt(setMessage) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [updateLogbookReceipt] = useMutation(UPDATE_RECEIPT);

    const updateReceipt = async (
        address,
        currency,
        dtBilling,
        netTotal,
        number,
        total,
        vendor,
        id,
        quantity,
        type,
        unit,
        unitPrice,
        vat,
        file,
        category,
        costCentre,
        costObject
    ) => {
        let errorOccurred = false;
        let logbookReceiptArticle = [];

        if (type === '') {
            logbookReceiptArticle.push({
                category: category,
                quantity: quantity,
                total: total,
                unit: unit,
                unitPrice: unitPrice,
                vat: vat,
                costCentre: costCentre,
                costObject: costObject,
            });
        } else {
            logbookReceiptArticle.push({
                category: category,
                quantity: quantity,
                total: total,
                type: type,
                unit: unit,
                unitPrice: unitPrice,
                vat: vat,
                costCentre: costCentre,
                costObject: costObject,
            });
        }

        try {
            await updateLogbookReceipt({
                variables: {
                    address: address,
                    articles: logbookReceiptArticle,
                    currency: currency,
                    dtBilling: dtBilling,
                    netTotal: netTotal,
                    number: number,
                    total: total,
                    vendor: vendor,
                    id: id,
                },
            });

            if (file !== null) {
                await axios
                    .post(`${process.env.REACT_APP_MINX_API_URL}/${id}`, file, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${getCookie(process.env.REACT_APP_COOKIE_NAME)}`,
                        },
                    })
                    .catch((error) => {
                        errorOccurred = true;
                        console.log(error);
                        setMessage(t('imageUpdate'));
                    });
            }
        } catch (e) {
            errorOccurred = true;
            console.log(e);
            setMessage(e.graphQLErrors[0].message);
        }

        if (!errorOccurred) {
            navigate('/costsOverview', {
                state: {
                    snackbarMessage: t('receiptUpdated'),
                },
            });
        }
    };
    return { updateReceipt };
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_LOGBOOK_APPSETTINGS = gql`
    query appsettings($idPerson: ObjectScalar!) {
        appsettings(filter: { op: AND, filters: [{ val: $idPerson, op: EQ, key: "idPerson" }, { val: "logbook", op: EQ, key: "app" }] }) {
            setting {
                settingId
                settingValue
            }
            id
        }
    }
`;

const CREATE_LOGBOOK_APPSETTING_ONBOARDING = gql`
    mutation createAppSetting($idPerson: String!) {
        createAppSetting(input: { app: "logbook", idPerson: $idPerson, setting: { settingId: "onboarding", settingValue: "false" } }) {
            setting {
                settingId
                settingValue
            }
            id
        }
    }
`;

export function useGetAppSettings(idPerson, setMessage, checkAppSetting = false) {
    const { data } = useQuery(GET_LOGBOOK_APPSETTINGS, {
        variables: { idPerson },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    const [createAppSetting, { data: appSettingsData, called }] = useMutation(CREATE_LOGBOOK_APPSETTING_ONBOARDING, {
        variables: {
            idPerson: idPerson,
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    useEffect(() => {
        if (checkAppSetting && !called && data && data?.appsettings?.length === 0) {
            createAppSetting();
        }
    }, [data, createAppSetting, called, checkAppSetting]);

    if (data?.appsettings?.length > 0) {
        return data;
    } else if (appSettingsData?.createAppSetting?.setting?.length > 0) {
        const newData = {
            appsettings: [
                {
                    id: appSettingsData?.createAppSetting?.id,
                    setting: [
                        {
                            settingId: appSettingsData?.createAppSetting?.setting[0]?.settingId,
                            settingValue: appSettingsData?.createAppSetting?.setting[0]?.settingValue,
                        },
                    ],
                },
            ],
        };
        return newData;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const UPDATE_LOGBOOK_APPSETTING_ONBOARDING = gql`
    mutation updateOnBoarding($id: ID!, $settingValue: ObjectScalar!) {
        updateAppSetting(input: { setting: { settingId: "onboarding", settingValue: $settingValue } }, id: $id) {
            app
            custNr
            id
            idPerson
            setting {
                settingId
                settingValue
            }
            tags
        }
    }
`;

export const useUpdateOnBoarding = () => {
    const [updateOnBoardingMutation, { loading, error }] = useMutation(UPDATE_LOGBOOK_APPSETTING_ONBOARDING, {
        refetchQueries: [{ query: GET_LOGBOOK_APPSETTINGS }],
    });

    const updateOnBoarding = async (id, settingValue) => {
        try {
            await updateOnBoardingMutation({ variables: { id, settingValue } });
        } catch (err) {
            return err;
        }
    };

    return { updateOnBoarding, loading, error };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const UPDATE_DRIVER_REGISTRATION = gql`
    mutation updateDriverRegistration($id: ID!, $register: Boolean!) {
        updateLogbookDriverRegistration(id: $id, register: $register) {
            idContactPerson
        }
    }
`;

export const useUpdateDriverRegistration = () => {
    const [updateDriverRegistration] = useMutation(UPDATE_DRIVER_REGISTRATION);
    return { updateDriverRegistration };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const UPDATE_LOGBOOK_PRIVATE_JOURNEY = gql`
    mutation updateLogbookPrivateJourney($idLogbook: ID!, $privateJourneyOn: Boolean!) {
        updateLogbookPrivateJourney(id: $idLogbook, privateJourneyOn: $privateJourneyOn) {
            idPrivateJourneyPerson
        }
    }
`;

export const useUpdateLogbookPrivateJourney = () => {
    const [updateLogbookPrivateJourney] = useMutation(UPDATE_LOGBOOK_PRIVATE_JOURNEY, {
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
    });
    return { updateLogbookPrivateJourney };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const UPDATE_LOGBOOK_ARCHIVE_STATUS = gql`
    mutation updateLogbookArchiveStatus($idLogbook: ID!, $status: EnumLogbookStatus!) {
        updateLogbook(input: { status: $status }, id: $idLogbook) {
            id
        }
    }
`;

export const useUpdateLogbookArchiveStatus = () => {
    const [updateLogbookArchiveStatus] = useMutation(UPDATE_LOGBOOK_ARCHIVE_STATUS);
    return { updateLogbookArchiveStatus };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ASSET_CALIBRATION_DATA = gql`
    query getAssetCalibrationData($id: ID!, $date: LocalDate!) {
        assetOdom(id: $id, date: $date) {
            calibration {
                dtCalibration
                calibrationKm
                km
                ymd
            }
            latest {
                km
                ymd
            }
        }
    }
`;

/**
 * Returns the latest user calibration data from mileage
 * @param {String} idAsset, id from the asset
 * @param {Function} setMessage, a function to set an error message
 * @returns latest mileage calibration data
 */
export function useGetLatestUserCalibrationData(idAsset, setMessage) {
    const currentDate = Moment().format('YYYY-MM-DD');
    const [calibrationData, setCalibrationData] = useState(undefined);

    useQuery(GET_ASSET_CALIBRATION_DATA, {
        variables: {
            id: idAsset,
            date: currentDate,
        },
        onCompleted: (data) => {
            setCalibrationData({
                dtCalibration: data?.assetOdom?.calibration ? data?.assetOdom?.calibration?.dtCalibration : null,
                calibrationKm: data?.assetOdom?.calibration ? data?.assetOdom?.calibration?.calibrationKm : null,
                km: data?.assetOdom?.calibration ? data?.assetOdom?.calibration?.km : null,
                ymd: data?.assetOdom?.calibration ? data?.assetOdom?.calibration?.ymd : null,
                latestKm: data?.assetOdom?.latest ? data?.assetOdom?.latest?.km : null,
                latestYmd: data?.assetOdom?.latest ? data?.assetOdom?.latest?.ymd : null,
            });
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
            setCalibrationData({
                dtCalibration: null,
                calibrationKm: null,
                km: null,
                ymd: null,
                latestKm: null,
                latestYmd: null,
            });
        },
    });
    if (calibrationData !== undefined) {
        return calibrationData;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

export function useGetLatestMileage(idAsset, setMessage) {
    const calibrationData = useGetLatestUserCalibrationData(idAsset, setMessage);

    if (calibrationData?.dtCalibration && moment(calibrationData?.dtCalibration).isAfter(calibrationData?.latestYmd)) {
        return {
            latestMileage: getNumberWithThousandSeparator(calibrationData?.calibrationKm, 0),
        };
    } else if (!calibrationData?.dtCalibration && !calibrationData?.latestYmd) {
        return {
            latestMileage: getNumberWithThousandSeparator(0, 0),
        };
    } else {
        return {
            latestMileage: getNumberWithThousandSeparator(calibrationData?.latestKm, 0),
        };
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ALL_LOGBOOKS = gql`
    query getAllLogbooks {
        logbooks {
            name
            id
            status
            idAsset
            idDrivers
            asset {
                privateJourney {
                    end
                    start
                    idPerson
                }
                child {
                    ... on VehicleChild {
                        licensePlate
                        vin
                    }
                }
            }
            access {
                holder
                allowed
                variant
                dtNotAfter
                dtNotBefore
            }
        }
    }
`;

export function useGetAllLogbooksApi(setMessage) {
    let [logbookData, setLogbookData] = useState(undefined);

    useQuery(GET_ALL_LOGBOOKS, {
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
        onCompleted: (data) => {
            let logbookDataTemp = [];
            if (data?.logbooks?.length > 0) {
                data?.logbooks?.forEach((logbook) => {
                    logbookDataTemp.push({
                        id: logbook?.id,
                        name: logbook?.name,
                        status: logbook?.status,
                        idAsset: logbook?.idAsset,
                        access: logbook?.access,
                        asset: logbook?.asset,
                        idDrivers: logbook?.idDrivers,
                    });
                });
                setLogbookData(logbookDataTemp);
            } else {
                setLogbookData([]);
            }
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
            setLogbookData([]);
        },
    });

    if (logbookData !== undefined) {
        return logbookData;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
export function useGetLogbooksDrivers(selectedLogbooks, setMessage) {
    const [selectedLogbooksDrivers, setSelectedLogbooksDrivers] = useState({});

    useQuery(GET_ALL_LOGBOOKS, {
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
        onCompleted: (data) => {
            const filteredLogbooks = data.logbooks.filter((logbook) => selectedLogbooks.includes(logbook.id));
            const idDriversByLogbook = {};
            filteredLogbooks.forEach((logbook) => {
                const drivers = logbook.idDrivers;
                idDriversByLogbook[logbook.id] = drivers;
            });
            setSelectedLogbooksDrivers(idDriversByLogbook);
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    return selectedLogbooksDrivers;
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//
export function useGetLogbooksAccess(selectedLogbooks, setMessage) {
    const [selectedLogbooksAccess, setSelectedLogbooksAccess] = useState([]);

    useQuery(GET_ALL_LOGBOOKS, {
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
        onCompleted: (data) => {
            const filteredLogbooks = data.logbooks.filter((logbook) => selectedLogbooks.includes(logbook.id));
            const logbooksAccess = [];
            filteredLogbooks.forEach((logbook) => {
                logbooksAccess.push({
                    idLogbook: logbook.id,
                    access: logbook.access,
                });
            });
            setSelectedLogbooksAccess(logbooksAccess);
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    return selectedLogbooksAccess;
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

export function useGetSelectedLogbooksReasons(logbookIds, setCurrentReasons) {
    const currentValues = [];

    const client = useApolloClient();
    const promises = logbookIds.map((id) => {
        return client.query({
            query: GET_SELECTED_LOGBOOK_REASONS,
            variables: {
                id: id,
            },
        });
    });

    const getReasons = async () => {
        try {
            const results = await Promise.all(promises);

            results.forEach((result) => {
                const newReasons = result?.data?.logbooks[0]?.reasons.map((reason) => {
                    return {
                        label: reason.label,
                        idPerson: reason.idPerson,
                    };
                });
                currentValues.push({
                    idLogbook: result?.data?.logbooks[0]?.id,
                    reasons: newReasons,
                });
            });

            if (currentValues.length > 0) {
                setCurrentReasons(currentValues);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getReasons();
        // eslint-disable-next-line
    }, []);
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const IMPORT_LOGBOOK_REASON = gql`
    mutation importLogbookReason($id: ID!, $reasons: [LogbookReasonInput]) {
        updateLogbook(input: { reasons: $reasons }, id: $id) {
            id
        }
    }
`;

export function useImportLogbookReasonApi() {
    const { t } = useTranslation();
    const client = useApolloClient();

    const saveReasons = async (newCurrentReasons, setMessage) => {
        const promises = newCurrentReasons.map((cR) => {
            return client.mutate({
                mutation: IMPORT_LOGBOOK_REASON,
                variables: {
                    id: cR.idLogbook,
                    reasons: cR.reasons,
                },
            });
        });

        try {
            await Promise.all(promises);
            setMessage(t('reasonsImported'));
        } catch (error) {
            console.log(error);
            setMessage(t('errorDuringImport'));
        }
    };

    return { saveReasons };
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_ALL_ASSETS = gql`
    query getAllAssets {
        loadAllAssets(filter: { val: null, op: NE, key: "child" }) {
            id
            name
            icon
            child {
                __typename
                ... on VehicleChild {
                    licensePlate
                    vin
                    model
                    imageUri
                    subtype
                }
            }
        }
    }
`;

export function useGetAllAssets(listOfAssetIds, assetsData) {
    const [assetsWithOutLogbook, setAssetsWithOutLogbook] = useState([]);
    const [getAllAssets] = useLazyQuery(GET_ALL_ASSETS, {
        onCompleted: (data) => {
            // Filter assets based on the list of asset IDs
            const filteredAssets = data.loadAllAssets.filter((asset) => listOfAssetIds.includes(asset.id));

            // Get VehicleChilds that are not included in filteredAssets
            const newVehiclesAssets = data.loadAllAssets.filter(
                (asset) => asset.child.__typename === 'VehicleChild' && !filteredAssets.some((filteredAsset) => filteredAsset.id === asset.id)
            );

            // Assign the filtered assets to assetsData.current
            assetsData.current = filteredAssets;

            // Assign the new filtered VehicleChilds to allVehiclesAssets state
            setAssetsWithOutLogbook(newVehiclesAssets);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    return { getAllAssets, assetsWithOutLogbook };
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const SAVE_LOGBOOK_REASONS = gql`
    mutation saveLogbookReasons($id: ID!, $reasons: [LogbookReasonInput]) {
        updateLogbook(input: { reasons: $reasons }, id: $id) {
            id
        }
    }
`;

export const useSaveLogbookReasons = (savingResults) => {
    const [saveReasons] = useMutation(SAVE_LOGBOOK_REASONS, {
        onCompleted: () => {
            const previousResults = savingResults.current;
            previousResults.push('Success');
            savingResults.current = previousResults;
        },
        onError: (error) => {
            console.log(error);
            const previousResults = savingResults.current;
            previousResults.push('Error');
            savingResults.current = previousResults;
        },
    });

    return { saveReasons };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const SAVE_LOGBOOK_DRIVERS = gql`
    mutation saveLogbookDrivers($id: ID!, $idDrivers: [String]!) {
        updateLogbook(input: { idDrivers: $idDrivers }, id: $id) {
            id
        }
    }
`;

export const useSaveLogbookDrivers = () => {
    const { t } = useTranslation();
    const [saveLogbookDrivers, { loading, error }] = useMutation(SAVE_LOGBOOK_DRIVERS);

    const handleSaveLogbookDrivers = async (logbookData, setMessage) => {
        try {
            const updatedLogbooks = await Promise.all(
                Object.entries(logbookData).map(async ([id, idDrivers]) => {
                    const response = await saveLogbookDrivers({
                        variables: { id, idDrivers },
                    });
                    return response.data.updateLogbook;
                })
            );
            setMessage(`${Object.keys(logbookData).length} ${t('logbooksUpdated')}`);
            return updatedLogbooks;
        } catch (errors) {
            setMessage(errors.message);
        }
    };

    return { handleSaveLogbookDrivers, loading, error };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const UPDATE_LOGBOOK_SETTING = gql`
    mutation updateLogbookSetting($id: ID!, $logbookSetting: LogbookSettingInput!) {
        updateCustomerSetting(id: $id, input: { logbookSetting: $logbookSetting }) {
            id
        }
    }
`;

export const useUpdateLogbookSetting = (setMessage) => {
    const { t } = useTranslation();

    const [updateLogbookSetting] = useMutation(UPDATE_LOGBOOK_SETTING, {
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
        refetchQueries: [{ query: GET_LOGBOOK_SETTING }],
        onCompleted: () => {
            setMessage(t('updateSuccessful'));
        },
        onError: (error) => {
            setMessage(t('updateError'));
            console.log(error.graphQLErrors[0].message);
        },
    });

    return { updateLogbookSetting };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_LOGBOOK_SETTING = gql`
    query getLogbookSetting {
        customerSettings {
            logbookSetting {
                key
                value
            }
            id
        }
    }
`;

export function useGetLogbookSetting(setMessage) {
    const { t } = useTranslation();
    const [logbookSetting, setLogbookSetting] = useState({
        id: undefined,
        map: undefined,
    });

    useQuery(GET_LOGBOOK_SETTING, {
        onCompleted: (data) => {
            const newSettingMap = new Map();
            const listOfSetting = data?.customerSettings[0]?.logbookSetting?.value;
            listOfSetting?.forEach((cost) => {
                newSettingMap.set(cost.key, cost.value);
            });
            setLogbookSetting({
                id: data?.customerSettings[0]?.id,
                map: newSettingMap,
            });
        },
        onError: (error) => {
            console.log(error.graphQLErrors[0].message);
            setMessage(t('dataRetrievalFailed'));
            setLogbookSetting({
                id: null,
                map: null,
            });
        },
    });

    if (logbookSetting.id !== undefined) {
        return logbookSetting;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_SELECTED_LOGBOOK_NOTIFICATION = gql`
    query getAllLogbooks {
        logbooks {
            id
            notifications {
                receivers
                topic
            }
        }
    }
`;
//-------------  teams access-----------------------//
//--------------------------------------------------//

const GET_TEAM_ACCESS = gql`
    query getTeam {
        teams(filter: { key: "license", val: "user", op: EQ }) {
            id
        }
    }
`;

/**
 * Returns a list of logbooks with their notifications
 * @param {Array} logbookIds - list of logbook IDs
 * @param {Function} setMessage - function to set an error message
 * @returns {Array} list of logbooks with their notifications
 */
export function useLogbookNotifications(logbookIds, setMessage) {
    const [notificationEmails, setNotificationEmails] = useState([]);

    useQuery(GET_SELECTED_LOGBOOK_NOTIFICATION, {
        context: {
            headers: {
                'x-customer-super': 'on',
            },
        },
        onCompleted: (data) => {
            const filteredLogbooks = data.logbooks.filter((logbook) => logbookIds.includes(logbook.id));

            const notifications = filteredLogbooks.map((logbook) => {
                return {
                    logbookId: logbook.id,
                    notifications: logbook.notifications || [],
                };
            });

            setNotificationEmails(notifications);
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
        },
    });

    return notificationEmails;
}

const UPDATE_LOGBOOK_NOTIFICATIONS = gql`
    mutation updateLogbookNotifications($id: ID!, $notifications: [LogbookNotificationsInput]) {
        updateLogbook(input: { notifications: $notifications }, id: $id) {
            id
        }
    }
`;

/**
 * Returns a function to update logbook notifications
 * @param {Function} setMessage - function to set an error message
 * @returns {Function} to update logbook notifications
 */

export function useUpdateLogbookNotifications(setMessage) {
    const [updateLogbook] = useMutation(UPDATE_LOGBOOK_NOTIFICATIONS, {
        refetchQueries: ['getSelectedLogbook'],
    });

    const updateNotifications = async (id, notifications) => {
        try {
            const { data } = await updateLogbook({
                variables: {
                    id,
                    notifications: notifications,
                },
            });
            return data;
        } catch (error) {
            setMessage(error.graphQLErrors[0].message);
        }
    };

    const updateNotificationsForIds = async (ids, notifications) => {
        const results = [];
        for (const id of ids) {
            try {
                const result = await updateNotifications(id, notifications);
                results.push(result);
            } catch (error) {
                setMessage(error.graphQLErrors[0].message);
            }
        }
        return results;
    };

    return updateNotificationsForIds;
}

export const useGetTeam = () => {
    const { data: teams } = useQuery(GET_TEAM_ACCESS);

    if (teams !== undefined && teams.length !== 0) {
        return teams.teams[0].id;
    }
};

const SAVE_LOGBOOK_ACCESS = gql`
    mutation saveLogbookAccess($id: ID!, $access: [AccessInput]!) {
        updateLogbook(input: { access: $access }, id: $id) {
            id
        }
    }
`;

export const useLogbookAccessUpdater = (setMessage, t) => {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);

    const updateAccessForAllLogbooks = async (logbookAccessObject) => {
        setLoading(true);
        const promises = logbookAccessObject.map((obj) => {
            return client.mutate({
                mutation: SAVE_LOGBOOK_ACCESS,
                variables: {
                    id: obj.idLogbook,
                    access: obj.access,
                },
                context: {
                    headers: {
                        'x-customer-super': 'on',
                    },
                },
            });
        });

        try {
            await Promise.all(promises);
            setMessage(t('accesssChangedSuccessfully'));
            setLoading(false);
            return true;
        } catch (error) {
            console.log(error);
            setMessage('accessChangedError');
            setLoading(false);
            return false;
        }
    };

    return { updateAccessForAllLogbooks, loading };
};

const TEAM_ID = gql`
    query getTeamId {
        teams(filter: { val: "user", op: EQ, key: "name" }) {
            id
        }
    }
`;

export const useGetTeamId = () => {
    const { data, loading, error } = useQuery(TEAM_ID);

    if (loading) {
        return { loading: true };
    }

    if (error) {
        return { error };
    }

    const teamID = data?.teams?.[0]?.id;

    if (teamID) {
        return { teamID };
    }

    return { teamID: null };
};

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

const GET_TOURS_WITH_COST = gql`
    query getToursWithCost($firstDayOfMonth: ObjectScalar!, $lastDayOfMonth: ObjectScalar!) {
        logbooktours(
            filter: {
                op: OR
                filters: [
                    {
                        op: AND
                        filters: [
                            { val: "EDITED", op: EQ, key: "status" }
                            { val: $firstDayOfMonth, op: GTE, key: "dtBegin" }
                            { val: $lastDayOfMonth, op: LTE, key: "dtBegin" }
                            { val: null, op: NE, key: "costCentre" }
                        ]
                    }
                    {
                        op: AND
                        filters: [
                            { val: "EDITED", op: EQ, key: "status" }
                            { val: $firstDayOfMonth, op: GTE, key: "dtBegin" }
                            { val: $lastDayOfMonth, op: LTE, key: "dtBegin" }
                            { val: null, op: NE, key: "costObject" }
                        ]
                    }
                    {
                        op: AND
                        filters: [
                            { val: "EXPORTED", op: EQ, key: "status" }
                            { val: $firstDayOfMonth, op: GTE, key: "dtBegin" }
                            { val: $lastDayOfMonth, op: LTE, key: "dtBegin" }
                            { val: null, op: NE, key: "costCentre" }
                        ]
                    }
                    {
                        op: AND
                        filters: [
                            { val: "EXPORTED", op: EQ, key: "status" }
                            { val: $firstDayOfMonth, op: GTE, key: "dtBegin" }
                            { val: $lastDayOfMonth, op: LTE, key: "dtBegin" }
                            { val: null, op: NE, key: "costObject" }
                        ]
                    }
                ]
            }
        ) {
            dtBegin
            idLogbook
            costCentre
            costObject
            distance
            type
        }
    }
`;

export function useGetToursWithCost(date, setMessage) {
    const firstDayOfMonth = Moment(date).startOf('month');
    const lastDayOfMonth = Moment(date).endOf('month');
    const [toursWithCost, setToursWithCost] = useState(undefined);

    useQuery(GET_TOURS_WITH_COST, {
        variables: {
            firstDayOfMonth,
            lastDayOfMonth,
        },
        onCompleted: (data) => {
            setToursWithCost(data.logbooktours);
        },
        onError: (error) => {
            setMessage(error.graphQLErrors[0].message);
            setToursWithCost([]);
        },
    });

    if (toursWithCost !== undefined) {
        return toursWithCost;
    }
}

//--------------------------------------------------//
//--------------------------------------------------//
//--------------------------------------------------//

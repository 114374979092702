import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SimpleSnackbar from './SimpleSnackbar';
import NotificationDialog from '../admin/NotificationDialog';
import { useOutletContext } from 'react-router-dom';
import { userHasEditAccess } from '../auth/authorization';

export default function DialogSetEMailNotifications(props) {
    const { selected, notifications } = props;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [message, setMessage] = React.useState('');
    const userAccess = useOutletContext().logbookInfo?.loginUserAccess;

    return (
        <>
            <Button variant='outlined' color='primary' onClick={() => setOpen(true)} disabled={!userHasEditAccess(userAccess)}>
                {notifications?.length > 0 ? t('edit') : t('add')}
            </Button>
            <NotificationDialog selectedLogbooks={selected} setMessage={setMessage} setSelected={() => []} open={open} setOpen={setOpen} />

            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

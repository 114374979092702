import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { TextField, useMediaQuery, Typography } from '@mui/material';
import { GetCostCategories, getBreakPoints } from '../../other/ConfigBasic';
import { useTranslation } from 'react-i18next';

function CostsType({ category = [], setCategory, label, fuelState = 'FUEL_DIESEL', setFuelState, validateError }) {
    const { t } = useTranslation();
    const categories = GetCostCategories();
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value !== categories.FUEL) {
            setFuelState('');
        }
        setCategory(typeof value === 'string' ? value.split(',') : value);
    };

    const kraftStoffArten = [
        { fuelName: t('FUEL_DIESEL'), value: 'FUEL_DIESEL' },
        { fuelName: t('FUEL_GAS'), value: 'FUEL_GAS' },
        { fuelName: t('FUEL_ELECTRICITY'), value: 'FUEL_ELECTRICITY' },
        { fuelName: t('FUEL_LPG'), value: 'FUEL_LPG' },
        { fuelName: t('FUEL_CNG'), value: 'FUEL_CNG' },
        { fuelName: t('FUEL_H2'), value: 'FUEL_H2' },
        { fuelName: t('FUEL_ADBLUE'), value: 'FUEL_ADBLUE' },
    ];

    const selectCustomStyle = {
        '& .MuiInputBase-root': {
            height: '48px',
        },

        width: desktop900 ? '100%' : '296px',
        height: '48px',
        fontSize: '14px',
        m: 0,
        p: 0,

        label: {
            fontSize: '14px',
            fontFamily: 'CircularProBook',
            m: 0,
            p: 0,
        },
    };

    return (
        <>
            <FormControl variant='filled'>
                <TextField select label={label} value={category} onChange={handleChange} input={<OutlinedInput label='Name' />} sx={selectCustomStyle}>
                    <MenuItem
                        value=''
                        sx={{
                            display: 'none',
                        }}
                    >
                        None
                    </MenuItem>
                    {Object.values(categories)?.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontFamily: 'CircularProMedium',
                                }}
                            >
                                {/* {' '} */}
                                {t(name.toLowerCase())}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            {category[0] === categories.FUEL && (
                <FormControl>
                    <TextField
                        select
                        label={t(category[0].toLowerCase())}
                        value={fuelState}
                        onChange={(e) => setFuelState(e.target.value)}
                        input={<OutlinedInput label='Name' />}
                        placeholder={category[0]}
                        sx={selectCustomStyle}
                        error={validateError.type}
                        helperText={validateError.type ? t('mandatoryField') : ''}
                    >
                        {kraftStoffArten.map((fuel) => (
                            <MenuItem key={fuel.fuelName} value={fuel.value}>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'CircularProMedium',
                                        label: {
                                            fontSize: '14px',
                                            fontFamily: 'CircularProBook',
                                        },
                                        input: {
                                            fontSize: '14px',
                                        },
                                    }}
                                >
                                    {fuel.fuelName}
                                </Typography>
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
        </>
    );
}

export default CostsType;

import { Alert, Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import theme from '../../../themes/theme';
import { useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';

function FotoUpload({ imageUrlLocal, setImageUrlLocal, id, setFile, isPdf, setIsPdf }) {
    const { t } = useTranslation();
    const [bildError, setBildError] = useState(false);
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [isDragActive, setIsDragActive] = useState(false);
    const dragActiveStyle = {
        backgroundColor: '#e0e0e0',
    };

    //Drag events
    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragActive(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];

            if (allowedImageTypes.includes(file.type)) {
                setBildError('');
                setFile(file);
                setImageUrlLocal(URL.createObjectURL(file));
                setIsPdf(file.type === 'application/pdf');
            } else {
                setBildError(t('validImage'));
            }
        }
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];

    //upload Event
    const handleFotoUpload = (event) => {
        const file = event.target.files[0];

        if (file && allowedImageTypes.includes(file.type)) {
            setBildError('');
            setFile(file);
            setImageUrlLocal(URL.createObjectURL(file));
            setIsPdf(file.type === 'application/pdf');
        } else {
            setBildError(t('validImage'));
        }
    };

    // Label style for the custom Dati AusWählen button
    const labelStyle = {
        textAlign: 'center',
        color: desktop900 ? '#757575' : theme.palette.primary.contrastText,
        border: `1px dashed #E0E0E0`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: desktop900 ? '100%' : '296px',
        height: desktop900 ? '90%' : '288px',
        fontSize: '14px',
        marginInline: 'auto',
        marginTop: '10px',
        borderRadius: '4px',
        fontWeight: 700,
        fontFamily: 'CircularProBook',
    };
    return (
        <Box
            sx={{
                height: desktop900 ? '100%' : '368px',
                width: desktop900 ? '100%' : '328px',
                marginInline: 'auto',
                bgcolor: 'white',
                borderRadius: '10px',
                border: '1px solid #E0E0E0',
                padding: '10px',
            }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack>
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        {id ? t('updateEntry') : t('newReceipt')}
                    </Typography>

                    {desktop900 && (
                        <Typography
                            sx={{
                                color: '#757575',
                                fontSize: '14px',
                                fontFamily: 'CircularProBook',
                                mb: 1,
                            }}
                        >
                            {t('addAPicture')}
                        </Typography>
                    )}
                </Stack>

                {imageUrlLocal && (
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: 700,
                            position: 'relative',
                        }}
                    >
                        <EditIcon sx={{ color: '#757575', cursor: 'pointer' }} onClick={() => document.getElementById('image_upload').click()} />
                    </Typography>
                )}
            </Stack>

            {imageUrlLocal ? (
                <>
                    {isPdf ? (
                        <iframe src={imageUrlLocal} width='100%' height='90%' title='Rechnung Bild'></iframe>
                    ) : (
                        <img src={imageUrlLocal} alt='Rechnung Bild' width='100%' height='90%' />
                    )}
                    <input
                        type='file'
                        name='belegeBild'
                        required
                        accept='image/*, application/pdf'
                        style={{ visibility: 'hidden' }}
                        id='image_upload'
                        onChange={handleFotoUpload}
                    />
                </>
            ) : (
                <label
                    style={isDragActive ? { ...labelStyle, ...dragActiveStyle } : labelStyle}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {desktop900 ? t('dragFileHere') : t('selectFile')}
                    {bildError && (
                        <Alert severity='warning' sx={{ bgcolor: 'red', mt: 2 }}>
                            {bildError}
                        </Alert>
                    )}
                    <input
                        type='file'
                        name='belegeBild'
                        required
                        accept='image/*, application/pdf'
                        style={{ visibility: 'hidden' }}
                        id='image_upload'
                        onChange={handleFotoUpload}
                    />
                    <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            width: '171px',
                            height: '40px',
                            fontFamily: theme.typography.fontFamily,
                            borderRadius: '20px',
                            fontSize: '14px',
                            my: 2,
                            bgcolor: theme.palette.grey[300],
                            fontWeight: 'Bold',
                        }}
                    >
                        {desktop900 ? (
                            ''
                        ) : (
                            <AddAPhotoIcon
                                sx={{
                                    width: '18px',
                                    aspectRatio: 1,
                                }}
                            />
                        )}
                        <span> {desktop900 ? t('selectFile') : t('takeAPic')} </span>
                    </Stack>
                </label>
            )}
        </Box>
    );
}

export default FotoUpload;

import { Card, Fab, LinearProgress, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Moment from 'moment/moment';
import * as React from 'react';
import { useState } from 'react';
import { useOutletContext, useParams } from 'react-router';
import { useGetMultiAssetDayLog } from '../../api/LogbookApi';
import PlaceholderMapSvg from '../../components/other/PlaceholderMapSvg';
import MixedTourList from '../../components/Tour/MixedTourList';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useTranslation } from 'react-i18next';
import MixedTourStopsList from '../../components/Tour/MixedTourStopsList';
import MixedTourActionBar from '../../components/Tour/MixedTourActionBar';
import TourMap from '../../components/Mixed/TourMap';
import { getBreakPoints } from '../../components/other/ConfigBasic';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371000; // Radius of the earth in m
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in m
    return d;
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};

/**
 * Removes every datapoint with an average speed greater than 25 km/h
 * @param {Array} dayLogs, array of day logs
 * @returns an array of day logs with an average speed smaller than 25 km/h
 */
const averageSpeedFilter = (dayLogs) => {
    const filteredLogs = dayLogs.filter((dayLog, index) => {
        if (index + 1 < dayLogs.length) {
            let startLat = dayLog.latlng[0];
            let startLng = dayLog.latlng[1];
            let endLat = dayLogs[index + 1].latlng[0];
            let endLng = dayLogs[index + 1].latlng[1];
            let start = Moment(dayLog.date);
            let end = Moment(dayLogs[index + 1].date);

            const distance = getDistanceFromLatLonInKm(startLat, startLng, endLat, endLng);
            const seconds = Moment.duration(end.diff(start)).asSeconds();
            const speed = (distance / seconds) * 3.6;
            return speed < 25;
        } else {
            return false;
        }
    });
    return filteredLogs;
};

/**
 * Removes every data point that is closer than 500 meter to start and end
 * @param {Array} dayLogsFromTour, array of all data point from the tour
 * @returns an array of data points there are not closer than 500 meter to start and end
 */
const closer500Filter = (dayLogsFromTour) => {
    let tourStartLat = dayLogsFromTour[0]?.latlng[0];
    let tourStartLng = dayLogsFromTour[0]?.latlng[1];

    let tourEndLat = dayLogsFromTour[dayLogsFromTour.length - 1]?.latlng[0];
    let tourEndLng = dayLogsFromTour[dayLogsFromTour.length - 1]?.latlng[1];

    const filteredLogs = dayLogsFromTour.filter((dayLog) => {
        let dayLogLat = dayLog?.latlng[0];
        let dayLogLng = dayLog?.latlng[1];

        const distanceToStartPoint = getDistanceFromLatLonInKm(tourStartLat, tourStartLng, dayLogLat, dayLogLng);
        const distanceToEndPoint = getDistanceFromLatLonInKm(tourEndLat, tourEndLng, dayLogLat, dayLogLng);

        return distanceToStartPoint > 500 && distanceToEndPoint > 500;
    });
    return filteredLogs;
};

/**
 * Removes all data points outside the period of the selected tour
 * @param {Array} dayLogs, for the whole day
 * @param {Moment} startDate, for the selected tour
 * @param {Moment} endDate, for the selected tour
 * @returns all datapoint for the selected tour
 */
const dayLogsFromTourFilter = (dayLogs, startDate, endDate) => {
    const filteredLogs = dayLogs.multiAssetDayLog[0].log.filter((dayLog) => {
        let currentDate = Moment(dayLog.date);
        return Moment.duration(startDate.diff(currentDate)).asSeconds() <= 0 && Moment.duration(endDate.diff(currentDate)).asSeconds() >= 0;
    });
    return filteredLogs;
};

const speedFilter = (dayLogs) => {
    const filteredLogs = dayLogs.filter((dayLog) => dayLog.speed < 10);
    return filteredLogs;
};

const whyFilter = (dayLogs) => {
    const filteredLogs = dayLogs.filter((dayLog) => dayLog.why !== 'tripEnd' || dayLog.why !== 'tipBegin');
    return filteredLogs;
};

/**
 * Removes consecutive points.
 * For consecutive points, the point with the lowest speed is selected
 * @param {Array} dayLogsFromTour, array of all data point from the tour
 * @param {Array} filteredLogs, array of the filtered logs
 * @returns an array of logs with no consecutive points
 */
const neighborFilter = (dayLogsFromTour, filteredLogs) => {
    const datesOfDayLogs = dayLogsFromTour.map((daylog) => daylog.date);
    let indexOfDates = [];
    let suggestionList = [];
    filteredLogs.forEach((log, index) => {
        if (indexOfDates.length === 0) {
            indexOfDates.push(datesOfDayLogs.indexOf(log.date));
        } else if (datesOfDayLogs.indexOf(log.date) - 1 === indexOfDates[indexOfDates.length - 1]) {
            indexOfDates.push(datesOfDayLogs.indexOf(log.date));
        } else {
            if (indexOfDates.length > 0) {
                let checkNeighbors = dayLogsFromTour.filter((daylog, index) => indexOfDates.includes(index));
                let reducedNeighbors = checkNeighbors.reduce(function (prev, curr) {
                    return prev.speed < curr.speed ? prev : curr;
                });
                suggestionList = suggestionList.concat(reducedNeighbors);
            }
            indexOfDates.length = 0;
            indexOfDates.push(datesOfDayLogs.indexOf(log.date));
        }

        if (index === filteredLogs.length - 1) {
            if (indexOfDates.length > 0) {
                let checkNeighbors = dayLogsFromTour.filter((daylog, index) => indexOfDates.includes(index));
                let reducedNeighbors = checkNeighbors.reduce(function (prev, curr) {
                    return prev.speed < curr.speed ? prev : curr;
                });
                suggestionList = suggestionList.concat(reducedNeighbors);
            }
        }
    });

    return suggestionList;
};

const maxFiveFilter = (suggestionList) => {
    if (suggestionList.length <= 5) {
        return suggestionList;
    } else {
        suggestionList.sort((a, b) => a.speed - b.speed);
        suggestionList = suggestionList.slice(0, 5);
        suggestionList.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
        return suggestionList;
    }
};

const filterLogs = (data) => {
    let filteredLogs = dayLogsFromTourFilter(data.dayLogs, data.startDate, data.endDate);
    let allLogsFromTour = [...filteredLogs];
    let dayLogsFromTour = [...filteredLogs];
    filteredLogs = closer500Filter(dayLogsFromTour);
    filteredLogs = averageSpeedFilter(filteredLogs);
    filteredLogs = speedFilter(filteredLogs);
    filteredLogs = whyFilter(filteredLogs);
    let suggestionList = neighborFilter(dayLogsFromTour, filteredLogs);
    suggestionList = maxFiveFilter(suggestionList);
    dayLogsFromTour = averageSpeedFilter(dayLogsFromTour);
    return {
        allLogsFromTour: allLogsFromTour,
        dayLogsFromTour: dayLogsFromTour,
        suggestionList: suggestionList,
    };
};

export default function Mixed(props) {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [selectedStopps, setSelectedStopps] = useState([]);
    const [showSuggestion, setShowSuggestion] = useState(true);
    const [address, setAddress] = useState(null);
    const [altLan, setAltLan] = useState([]);
    const [hoverdPoint, setHoverdPoint] = useState([]);
    const tourId = useParams().id;
    const data = useGetMultiAssetDayLog(useOutletContext().logbookInfo?.assetId, tourId, setMessage);
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [dataPoint, setDataPoint] = useState(null);
    if (!data) {
        return <LinearProgress />;
    }

    const changeSelectedStopps = (address, dataPoint) => {
        const newStopp = {
            address: address,
            dataPoint: dataPoint,
        };
        let newSelectedStopps = [...selectedStopps];
        if (newSelectedStopps.every((stop) => stop.dataPoint.date !== newStopp.dataPoint.date)) {
            newSelectedStopps.push(newStopp);
        } else {
            newSelectedStopps = newSelectedStopps.filter((stop) => stop.dataPoint.date !== newStopp.dataPoint.date);
        }
        setSelectedStopps(newSelectedStopps);
    };

    const removeFirstAndLastDatapoint = (logs) => {
        logs.shift();
        logs.pop();
        return logs;
    };

    if (data?.dayLogs?.multiAssetDayLog?.length === 0) {
        return <div>{t('noAssetAccess')}</div>;
    }

    const filteredLogs = filterLogs(data);

    return (
        <>
            <Grid container xs={12} sx={{ marginBottom: { xs: '56px', md: '0px' } }}>
                <MixedTourActionBar tourId={tourId} timeStamps={selectedStopps.map((stopp) => stopp.dataPoint.date)} noData={!filteredLogs?.dayLogsFromTour.length} />
                <Grid container xs={12} sx={{ overflowY: 'scroll', height: `${window.innerHeight - 168}px`, position: 'relative', top: '-16px' }}>
                    <Grid xs={12} md={8}>
                        <Card
                            variant='outlined'
                            sx={{
                                height: { xs: filteredLogs?.dayLogsFromTour.length === 0 ? '100%' : '400px', md: '83vh' },
                            }}
                        >
                            {filteredLogs?.dayLogsFromTour.length > 0 ? (
                                <TourMap
                                    startPosition={filteredLogs?.dayLogsFromTour[0]}
                                    endPosition={filteredLogs?.dayLogsFromTour[filteredLogs?.dayLogsFromTour.length - 1]}
                                    tourPointList={filteredLogs?.allLogsFromTour}
                                    altLan={altLan}
                                    setPointAltLan={props.setPointAltLan}
                                    setHoverdPoint={setHoverdPoint}
                                    changeSelectedStopps={changeSelectedStopps}
                                    stopPoints={filteredLogs?.suggestionList}
                                    tourRoute={data?.dayLogs?.multiAssetDayLog[0]?.log}
                                    hoverPoint={hoverdPoint}
                                />
                            ) : (
                                <PlaceholderMapSvg text={t('mapError')} />
                            )}
                        </Card>
                    </Grid>
                    {filteredLogs?.dayLogsFromTour.length > 0 && (
                        <Grid xs={12} md={4}>
                            {selectedStopps.length > 0 && (
                                <Card variant='outlined' sx={{ marginBottom: '16px', backgroundColor: '#EEEEEE' }}>
                                    <MixedTourStopsList
                                        selectedStopps={selectedStopps}
                                        start={filteredLogs.dayLogsFromTour[0]}
                                        end={filteredLogs.dayLogsFromTour[filteredLogs.dayLogsFromTour.length - 1]}
                                        changeSelectedStopps={changeSelectedStopps}
                                        setAltLan={setAltLan}
                                        hoverdPoint={hoverdPoint}
                                    />
                                </Card>
                            )}

                            <Card variant='outlined' sx={{ p: '10px' }}>
                                <MixedTourList
                                    dayLogsFromTour={removeFirstAndLastDatapoint(filteredLogs.dayLogsFromTour)}
                                    suggestionList={filteredLogs.suggestionList}
                                    changeSelectedStopps={changeSelectedStopps}
                                    selectedStopps={selectedStopps}
                                    showSuggestion={showSuggestion}
                                    setShowSuggestion={setShowSuggestion}
                                    setAltLan={setAltLan}
                                    hoverdPoint={hoverdPoint}
                                    mobile={desktop900}
                                    setAddress={setAddress}
                                    setData={setDataPoint}
                                />
                                {altLan.length > 0 && !desktop900 && (
                                    <Fab
                                        color='success'
                                        variant='extended'
                                        sx={{ position: 'fixed', bottom: '10%', left: '38%', borderRadius: '14px', p: '16px' }}
                                        onTouchEnd={() => changeSelectedStopps(address, dataPoint)}
                                    >
                                        <LocationOnIcon /> {t('select')}
                                    </Fab>
                                )}
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

import * as React from 'react';
import { useState } from 'react';
import { LinearProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { GetAccessStrings } from '../other/ConfigBasic';
import { useEffect } from 'react';
import SelectionDialogTemplate from '../dialog/SelectionDialogTemplate';

const getAccessPersonsId = (accessArray) => {
    const accessPersonsId = accessArray.map((person) => person.holder);
    return accessPersonsId;
};

export default function DialogAddLogbookUser(props) {
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState([]);
    const [persons, setPersons] = useState('');
    const [allPersonsData, setAllPersonsData] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const allFilteredPersonsData = props.personsData.filter((person) => !getAccessPersonsId(props.access).includes(person.id));
        setPersons(allFilteredPersonsData);
        setAllPersonsData(allFilteredPersonsData);
    }, [props]);

    if (!persons) {
        return <LinearProgress />;
    }

    const handleToggle = (personId) => () => {
        const currentIndex = checked.indexOf(personId);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(personId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAdd = () => {
        setOpen(false);
        let newLogbookAccess = props.access.map((access) => {
            return {
                holder: access.holder,
                allowed: access.allowed,
                variant: access.variant,
                dtNotAfter: access.dtNotAfter,
                dtNotBefore: access.dtNotBefore,
            };
        });
        newLogbookAccess.push(...createNewLogbookAccess());
        props.changeLogbookAccess(newLogbookAccess);
    };

    const createNewLogbookAccess = () => {
        const accessObject = GetAccessStrings();
        return checked.map((personId) => {
            const newUserAccess = {
                holder: personId,
                allowed: accessObject.allowed.view,
                variant: accessObject.variant.person,
                dtNotAfter: null,
                dtNotBefore: null,
            };
            return newUserAccess;
        });
    };

    const handleCancel = () => {
        setOpen(false);
        setPersons(allPersonsData);
        setChecked([]);
    };

    const handleChangeAll = () => {
        if (checked.length < allPersonsData.length) {
            setChecked(allPersonsData.map((person) => person.id));
        } else {
            setChecked([]);
        }
    };

    const filterPersons = (event) => {
        const searchValue = event.target.value;
        if (searchValue.length === 0) {
            setPersons(allPersonsData);
        } else {
            setPersons(
                allPersonsData.filter(
                    (person) => person?.firstname?.toLowerCase().includes(searchValue.toLowerCase()) || person?.lastname?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    };

    return (
        <>
            <AddIcon color='primary' cursor='pointer' onClick={() => setOpen(true)} />
            <SelectionDialogTemplate
                open={open}
                title={t('addUser')}
                filterListFunction={filterPersons}
                checked={checked}
                dataAll={allPersonsData}
                handleChangeAll={handleChangeAll}
                dataList={persons}
                handleToggle={handleToggle}
                showAvatar={false}
                emptyListMessage={t('noResult')}
                showEmptyListAction={false}
                emptyListActionMessage={''}
                handleCancel={handleCancel}
                handleAction={handleAdd}
                handleActionMessage={t('add')}
            />
        </>
    );
}

const TourPoint = (fill, deg) => `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 24 24" style="enable-background:new 0 0 88 88;" xml:space="preserve" transform="rotate(${deg})">
    <style type="text/css">
      .st0{fill:none;}
      .st1{fill:#FFFFFF;}
      .st2{fill:${fill};}
    </style>
    <g>
      <rect class="st0" width="24" height="24"/>
    </g>
    <g>
      <path class="st2" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,16v-3H8v-2h4V8l4,4L12,16z"/>
      <polygon class="st1" points="12,16 12,13 8,13 8,11 12,11 12,8 16,12"/>
    </g>
  </svg>`;

export default TourPoint;

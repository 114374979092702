import * as React from 'react';
import axios from 'axios';
import { Divider, ListItem, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import Moment from 'moment';
import theme from '../../themes/theme';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { getBreakPoints } from '../other/ConfigBasic';

const getTourInfos = (lat, long, callback, setMessage) => {
    const baseUrl = process.env.REACT_APP_GEOCODER_API;
    axios({
        method: 'get',
        url: `${baseUrl}${lat},${long}`,
    })
        .then((response) => callback(response.data))
        .catch((error) => setMessage(error.message));
};

/** @setAltLan is a Prop will be passed to the ToutSplitMap to check which point is being hoverd over */

export default function MixedTourListItem(props) {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [message, setMessage] = useState('');
    const [address, setAddress] = useState('');
    const [start, setStart] = useState(props.index < props.numberOfEntries ? true : false);

    const setGeoCoderOutput = (output) => {
        setAddress(output);
    };

    const handleClick = () => {
        props.changeSelectedStopps(address, props.dataPoint);
    };

    useEffect(() => {
        setStart(props.index < props.numberOfEntries ? true : false);
        if (address === '' && start) {
            getTourInfos(props.dataPoint.latlng[0], props.dataPoint.latlng[1], setGeoCoderOutput, setMessage);
        }
    }, [props.dataPoint.latlng, address, start, props.index, props.numberOfEntries]);

    if (!address) {
        if (props.index < props.numberOfEntries) {
            return (
                <>
                    <ListItem
                        key={props.dataPoint?.date}
                        sx={{
                            backgroundColor: 'transparent',
                            height: '64px',
                            lineHeight: '1',
                        }}
                    >
                        <ListItemText
                            secondary={
                                <Typography
                                    sx={{
                                        display: 'block',
                                        fontSize: '14px',
                                        color: theme.palette.grey[600],
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                    component='span'
                                >
                                    ...Loading
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider variant='fullWidth' component='li' />
                    {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
                </>
            );
        }
    }

    const checkSearchValue = () => {
        if (props.searchValue === undefined || props.searchValue.length === 0) {
            return true;
        } else {
            return address.line1.trim().toLowerCase().includes(props.searchValue.toLowerCase());
        }
    };

    const isListItemUnSelected = (date) => {
        return Array.isArray(props.selectedStopps) && props.selectedStopps.every((stop) => stop.dataPoint.date !== date);
    };

    if (props.index < props.numberOfEntries) {
        return (
            <>
                {checkSearchValue() && (
                    <>
                        <ListItem
                            key={props.dataPoint?.date}
                            sx={{
                                backgroundColor:
                                    props.dataPoint?.date === props.hoverdPoint?.date // Check if this is the hovered item
                                        ? theme.palette.success.light
                                        : isListItemUnSelected(props.dataPoint?.date)
                                        ? 'transparent'
                                        : theme.palette.success.main,
                                cursor: 'pointer',
                                height: '64px',
                                lineHeight: '1',
                                ':hover': {
                                    backgroundColor: theme.palette.success.main,
                                    'svg:first-of-type': { opacity: '1' },
                                },
                            }}
                            onClick={() => (desktop900 ? handleClick() : '')}
                            onMouseEnter={() => props.setAltLan(props.dataPoint.latlng)}
                            onMouseLeave={() => props.setAltLan([])}
                            onTouchEnd={() => {
                                props.setAddress(address);
                                props.setData(props.dataPoint);
                            }}
                        >
                            <ListItemText
                                primary={Moment(props?.dataPoint?.date).format('HH:mm:ss')}
                                primaryTypographyProps={{ fontSize: '16px' }}
                                secondary={
                                    <Typography
                                        sx={{
                                            display: 'block',
                                            fontSize: '14px',
                                            color: theme.palette.grey[600],
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        component='span'
                                    >
                                        {address.line1.trim()}, {address.line2}
                                    </Typography>
                                }
                            />
                            {desktop900 && (
                                <LocationOnIcon
                                    sx={{
                                        position: 'absolute',
                                        right: '16px',
                                        top: '8px',
                                        opacity: '0',
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            )}
                        </ListItem>

                        <Divider variant='fullWidth' component='li' />
                    </>
                )}
            </>
        );
    }
}

import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import Moment from 'moment';
import { GetTourTypes } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 52;

export default function OpenToursCheckboxFilter(props) {
    const { openTourList, setSelected, daysUntilHint } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectAll = () => {
        const newSelected = openTourList.map((n) => n.id);
        setSelected(newSelected);
        handleClose();
    };

    const handleSelectSevenDayWarning = () => {
        const dateHint = new Date(Moment().subtract(daysUntilHint, 'days').format('MM/DD/YYYY')).getTime();
        const newSelected = openTourList
            .filter((tour) => {
                let dateTour = new Date(Moment(tour.dtBegin).format('MM/DD/YYYY')).getTime();
                return tour.type !== GetTourTypes().PRIVATE && dateTour < dateHint;
            })
            .map((tour) => tour.id);
        setSelected(newSelected);
        handleClose();
    };

    const handleSelectToday = () => {
        const dateToday = new Date(Moment().format('MM/DD/YYYY')).getTime();
        const newSelected = openTourList
            .filter((tour) => {
                let dateTour = new Date(Moment(tour.dtBegin).format('MM/DD/YYYY')).getTime();
                return dateTour === dateToday;
            })
            .map((tour) => tour.id);
        setSelected(newSelected);
        handleClose();
    };

    return (
        <>
            <ArrowDropDownIcon onClick={(event) => handleClick(event)} sx={{ verticalAlign: 'middle', position: 'relative', left: '-8px', cursor: 'pointer' }} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                <MenuItem onClick={() => handleSelectAll()}>{t('all')}</MenuItem>
                <MenuItem onClick={() => handleSelectSevenDayWarning()}>{t('OnlySevenDayWarning')}</MenuItem>
                <MenuItem onClick={() => handleSelectToday()}>{t('OnlyToursFromToday')}</MenuItem>
            </Menu>
        </>
    );
}

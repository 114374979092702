import { getCookie } from '../other/ConfigBasic';

export function Logout() {
    const cookieValue = getCookie(process.env.REACT_APP_COOKIE_NAME);

    const setCookie = (cname, cvalue, exdays) => {
        const domain = '.bornemann.net';
        const d = new Date();
        d.setTime(d.getTime() - exdays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires + '; Domain=' + domain + '; SameSite=None; Secure; path=/';
    };

    if (cookieValue.length > 0) {
        setCookie('access_token', cookieValue, 400);
        window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL;
    }
}

import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';


const DriverListItem = ({ row, t, onDelete }) => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsButtonVisible(true);
    };

    const handleMouseLeave = () => {
        setIsButtonVisible(false);
    };

    const handleButtonClick = (id) => {
        onDelete(id);

    };

    return (
        <TableRow hover onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} sx={{
            position: 'relative',
            cursor: 'pointer',
        }}>
            <TableCell>
                {row.firstname} {row.lastname}
            </TableCell>
            {isButtonVisible && (
                <Box
                    component='td'
                    sx={{
                        position: 'absolute',
                        paddingRight: '16px',
                        right: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: '#f5f5f5',
                    }}
                >
                    <Button
                        variant='outlined'
                        color='error'
                        sx={{ marginLeft: '8px' }}
                        onClick={() => {
                            handleButtonClick(row.id);
                        }}
                    >
                        {t('delete')}
                    </Button>
                </Box>
            )}
        </TableRow>
    );
};

export default DriverListItem;

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getCookie } from '../components/other/ConfigBasic';
import { onError } from '@apollo/client/link/error';
import { errorHandling } from './ApolloErrorHandling';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPH_URI,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from cookie
    const token = getCookie(process.env.REACT_APP_COOKIE_NAME);

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            'X-Requested-From': process.env.REACT_APP_APOLLO_REQUESTED_HEADER,
        },
    };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors && graphQLErrors[0]?.extensions?.errorcode) {
        errorHandling(graphQLErrors[0]?.extensions?.errorcode);
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
});

import * as React from 'react';
import CreateCostDesktop from '../../components/Cost/CreateCostDesktop';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../../components/other/ConfigBasic';
import CreateCostForm from '../../components/Cost/CreateCostForm';
import EditReceptDesktop from '../../components/Cost/EditReceptDesktop';
import { useEffect } from 'react';
import { useState } from 'react';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useGetLogbookSetting } from '../../api/LogbookApi';

export default function Costs() {
    const { id } = useParams();
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState('');
    const logbookSetting = useGetLogbookSetting(setMessage);
    const [costCentreList, setCostCentreList] = useState([]);
    const [costObjectList, setCostObjectList] = useState([]);

    useEffect(() => {
        if (logbookSetting !== undefined) {
            const allCostCentre = logbookSetting?.map?.has('costCentre') ? logbookSetting?.map.get('costCentre') : [];
            const allCostObject = logbookSetting?.map?.has('costObject') ? logbookSetting?.map.get('costObject') : [];
            setCostCentreList(allCostCentre.filter((costCentre) => costCentre.active === true));
            setCostObjectList(allCostObject.filter((costObject) => costObject.active === true));
        }
    }, [logbookSetting]);

    useEffect(() => {
        if (location.state !== null && location.state.snackbarMessage !== null) {
            setMessage(location.state.snackbarMessage);
            navigate(location.pathname);
        }
    }, [navigate, location]);

    return (
        <>
            <Container maxWidth='md' sx={{ m: 0, p: 0, mx: `${desktop900 ? 'auto' : '0'}` }}>
                {!id &&
                    (desktop900 ? (
                        <CreateCostDesktop costCentreList={costCentreList} costObjectList={costObjectList} />
                    ) : (
                        <CreateCostForm costCentreList={costCentreList} costObjectList={costObjectList} />
                    ))}
                {id &&
                    (desktop900 ? (
                        <EditReceptDesktop id={id} costCentreList={costCentreList} costObjectList={costObjectList} />
                    ) : (
                        <CreateCostForm id={id} costCentreList={costCentreList} costObjectList={costObjectList} />
                    ))}
            </Container>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

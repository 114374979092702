
import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import useAutoComplete from '../other/useAutoComplete';
import AutocompleteInput from './AutocompleteInput';
import TimerPicker from './TimerPicker';
import InfoIcon from '@mui/icons-material/Info';
import EditedToutHistory from './EditedToutHistory';
import { GetEditAddressReasons, } from '../other/ConfigBasic';
import useDistance from '../other/useDistance';

import moment from 'moment';


const formatAddress = (info) => {
    return `${info.street} ${info.houseNo ? info.houseNo : ''}, ${info.postal ? info.postal : info.zip}, ${info.city}`;
};

const EditAddress = ({ mobile, open, setOpen, startInfos, endInfos, setMessage, tour, setAnnotation }) => {
    const { t } = useTranslation();
    // States for start address
    const [startAddress, setStartAddress] = useState('');

    const [startAddressChange, setStartAddressChange] = useState(formatAddress(startInfos()));
    const { options: startOptions } = useAutoComplete(startAddressChange, setStartAddress, setMessage);
    const [startDate, setStartDate] = useState(tour.dtBegin);
    const [startChanged, setStartChanged] = useState(false)



    // States for end address
    const [endAddress, setEndAddress] = useState({});
    const [endAddressChange, setEndAddressChange] = useState(formatAddress(endInfos()));
    const { options: endOptions } = useAutoComplete(endAddressChange, setEndAddress, setMessage);
    const [endDate, setEndDate] = useState(tour.dtEnd)
    const [endChanged, setEndChanged] = useState(false)
    const [selectedStartOption, setSelectedStartOption] = useState(null);
    const [selectedEndOption, setSelectedEndOption] = useState(null);


    //Reason for change
    const [reason, setReason] = useState('')

    const [validateError, setValidateError] = useState({
        startDistanceError: false,
        startError: false,
        startDate: false,
        startMilage: false,
        endDistanceError: false,
        endAddress: false,
        endDate: false,
        endMilage: false,
        reason: false,
    })

    const distance = useDistance(startInfos(), startAddress, setMessage);
    const endDistace = useDistance(endInfos(), endAddress, setMessage);

    useEffect(() => {
        if (distance > 5 && startChanged) {
            setValidateError(prevState => ({
                ...prevState,
                startDistanceError: true
            }));
        } else {
            setValidateError(prevState => ({
                ...prevState,
                startDistanceError: false
            }));
        }
    }, [distance, startAddressChange, startChanged]);

    //useEffect to control the end address distance
    useEffect(() => {
        if (endDistace > 5 && endChanged) {
            setValidateError(prevState => ({
                ...prevState,
                endDistanceError: true
            }));
        } else {
            setValidateError(prevState => ({
                ...prevState,
                endDistanceError: false
            }));
        }

    }, [endDistace, endAddressChange, endChanged]);
    useEffect(() => {
        const tenMinutesInMilliseconds = 10 * 60 * 1000;
        const oldStartDate = new Date(tour.dtBegin);
        const newStartDate = new Date(startDate);
        const startDateError = Math.abs(newStartDate - oldStartDate) > tenMinutesInMilliseconds;

        setValidateError(prevState => ({
            ...prevState,
            startDate: startDateError
        }));
    }, [startDate, tour.dtBegin]);

    // Add useEffect for endDate validation
    useEffect(() => {
        const tenMinutesInMilliseconds = 10 * 60 * 1000;
        const oldEndDate = new Date(tour.dtEnd);
        const newEndDate = new Date(endDate);
        const endDateError = Math.abs(newEndDate - oldEndDate) > tenMinutesInMilliseconds;
        setValidateError(prevState => ({
            ...prevState,
            endDate: endDateError
        }));
    }, [endDate, tour.dtEnd]);

    const formValidation = () => {
        const errors = {
            startDistanceError: false,
            startError: false,
            startDate: false,
            startMilage: false,
            endDistanceError: false,
            endAddress: false,
            endDate: false,
            endMilage: false,
            reason: false,

        };

        // Validating that none of the inputs are empty
        if (!startAddressChange.trim()) {
            errors.startError = true;
        }
        if (!endAddressChange.trim()) {
            errors.endAddress = true;
        }
        if (reason === '') {
            errors.reason = true;
        }

        setValidateError(errors);

        return !Object.values(errors).some(error => error);
    };




    const handleCancel = () => {
        setOpen(false);

    }

    const handleUpdateTour = (e) => {
        e.preventDefault();
        const annotationFormat = () => {
            return `${t('tourCorrection')} ${reason}:\n${startChanged ? t('start') + ': ' + moment(startDate).format('DD.MM.YYYY HH:mm:ss') + ' ' + startAddressChange : ''}\n${endChanged ? t('end') + ': ' + moment(endDate).format('DD.MM.YYYY HH:mm:ss') + ' ' + endAddressChange : ''}`
        }
        formValidation();
        if (!formValidation()) {
            return;
        } else {
            setAnnotation(annotationFormat())
            setOpen(false)
        }

    };


    const handleChangeReason = (event) => {
        event.preventDefault()
        setReason(event.target.value)
    }
    useEffect(() => {
        if (reason !== '') {
            setValidateError(prevState => ({
                ...prevState,
                reason: false
            }));
        }
    }, [reason])
    return (

        <Dialog fullScreen={!mobile} open={open} PaperProps={{
            style: {
                borderRadius: '4px',
                height: mobile ? 'atuo' : '',
                margin: 0
            },
        }}  >
            <DialogTitle >
                {t("addressEdit")}
            </DialogTitle>
            <DialogContent>
                <Stack sx={{ my: 3 }}>
                    <TextField
                        select
                        value={reason}
                        label={t('reasonsCorrection')}
                        onChange={handleChangeReason}
                        error={validateError.reason}
                    >
                        {GetEditAddressReasons().map((reason, idx) => (
                            <MenuItem value={reason} key={idx}>
                                {reason}
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>
                <Divider />
                <Stack flexBasis={"82%"} gap={4} mt={'24px'}  >
                    <Stack gap={"35px"} >
                        {/**Start*/}
                        <TimerPicker
                            mobile={mobile}
                            value={startDate}
                            onChange={(e) => setStartDate(e.$d)}
                            label={t("startTime")}
                            setShowHistory={setStartChanged}
                            error={validateError.startDate}
                        />
                        <AutocompleteInput
                            id='start-address-autocomplete'
                            label='startAddress'
                            options={startOptions}
                            selectOption={selectedStartOption}
                            setSelectOption={setSelectedStartOption}
                            addressChange={startAddressChange}
                            setAddressChange={setStartAddressChange}
                            error={validateError.startError}
                            distanceError={validateError.startDistanceError}
                            setShowHistory={setStartChanged}
                        />
                    </Stack>
                    <Divider />
                    <Stack gap={"26px"}>
                        {/**End**/}
                        <TimerPicker
                            mobile={mobile}
                            value={endDate}
                            onChange={(e) => setEndDate(e.$d)}
                            label={t("arrivalTime")}
                            setShowHistory={setEndChanged}
                            error={validateError.endDate}
                        />
                        <AutocompleteInput
                            id='end-address-autocomplete'
                            label='endAddress'
                            options={endOptions}
                            selectOption={selectedEndOption}
                            setSelectOption={setSelectedEndOption}
                            addressChange={endAddressChange}
                            setAddressChange={setEndAddressChange}
                            error={validateError.endAddress}
                            distanceError={validateError.endDistanceError}
                            setShowHistory={setEndChanged}
                        />

                    </Stack>

                    <Divider />
                </Stack>
                {tour && (endChanged || startChanged) &&
                    <EditedToutHistory
                        address={formatAddress(startInfos())}
                        date={startInfos().dt}
                        newAddress={formatAddress(startAddress)}
                        newDate={moment(startDate).format("DD.MM.YYYY HH:mm:ss")}
                        endAddress={formatAddress(endInfos())}
                        endDate={endInfos().dt}
                        changedEndDate={moment(endDate).format("DD.MM.YYYY HH:mm:ss")}
                        changedEndAddress={formatAddress(endAddress)}
                    />}
                <Stack direction="row" alignItems="center" gap={1}>
                    <InfoIcon color='info' />
                    <Typography sx={{ fontSize: "14px", color: "#757575", fontFamily: "CircularProBook" }}>{t("infoChangeAddress")}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions  >
                <Button variant='text' color='secondary' onClick={handleCancel}>
                    {t('cancel')}
                </Button>
                <Button variant='contained' color='success' onClick={(e) => handleUpdateTour(e)} disabled={Object.values(validateError).some(error => error)} >
                    ok
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default EditAddress
import { useEffect } from 'react';

/** This function embeds the Matomo Tag Manager and it is called in App.js
 * MatomoURL and ContainerId differ depending on the environments and are stored in respective env files.
 */
const MatomoTagManager = ({ matomoUrl, containerId }) => {
    useEffect(() => {
        var _mtm = (window._mtm = window._mtm || []);
        _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
        var d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = `${matomoUrl}/js/container_${containerId}.js`;
        s.parentNode.insertBefore(g, s);
    }, [matomoUrl, containerId]);

    return null;
};

export default MatomoTagManager;

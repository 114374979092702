import { Button, LinearProgress } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import SelectionDialogTemplate from '../dialog/SelectionDialogTemplate';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function CreateLogbookAddDriver(props) {
    const { driverData, personName, setPersonName, initialPersonSet, createLogBooks, title, showDialog, setShowDialog } = props;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [checked, setChecked] = useState([]);
    const [driverList, setDriverList] = useState(undefined);

    useEffect(() => {
        setChecked(
            personName.map((person) => {
                return person.id;
            })
        );
    }, [personName]);

    useEffect(() => {
        if ((showDialog || open) && driverList === undefined) {
            setDriverList(driverData);
        }
    }, [open, driverList, driverData, showDialog]);

    if (!initialPersonSet) {
        return <LinearProgress />;
    }

    const handleToggle = (driverId) => () => {
        const currentIndex = checked.indexOf(driverId);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(driverId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleChangeAll = () => {
        if (checked.length < driverData.length) {
            setChecked(driverData.map((driver) => driver.id));
        } else {
            setChecked([]);
        }
    };

    const handleSave = () => {
        const newPersonList = driverData.filter((driver) => checked.includes(driver.id));
        setOpen(false);
        if (setShowDialog !== undefined) {
            setShowDialog(false);
        }
        setPersonName(newPersonList);
        setDriverList(undefined);
    };

    const handleCancel = () => {
        setOpen(false);
        if (setShowDialog !== undefined) {
            setShowDialog(false);
        }
        setChecked(
            personName.map((person) => {
                return person.id;
            })
        );
        setDriverList(undefined);
    };

    const filterDrivers = (event) => {
        const searchValue = event.target.value;
        if (searchValue.length === 0) {
            setDriverList(driverData);
        } else {
            setDriverList(
                driverData.filter(
                    (driver) => driver?.firstname?.toLowerCase().includes(searchValue.toLowerCase()) || driver?.lastname?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    };

    return (
        <>
            {!createLogBooks && (
                <Button
                    sx={{ fontSize: '14px', height: '32px', marginBottom: '8px' }}
                    variant={personName.length === 0 ? 'contained' : 'outlined'}
                    color={personName.length === 0 ? 'error' : 'info'}
                    onClick={() => setOpen(true)}
                >
                    {t('add')}
                </Button>
            )}
            <SelectionDialogTemplate
                open={showDialog ? showDialog : open}
                title={createLogBooks ? title : t('selectDriver')}
                filterListFunction={filterDrivers}
                checked={checked}
                dataAll={driverData}
                handleChangeAll={handleChangeAll}
                dataList={driverList}
                handleToggle={handleToggle}
                showAvatar={true}
                emptyListMessage={t('noResult')}
                showEmptyListAction={true}
                emptyListActionMessage={t('addADriver')}
                handleCancel={handleCancel}
                handleAction={handleSave}
                handleActionMessage={t('confirm')}
            />
        </>
    );
}

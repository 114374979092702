import { Stack, Typography } from '@mui/material';

function SummContainer({ label, summ }) {
    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
                flexWrap: 'wrap',
            }}
        >
            <Typography
                sx={{
                    color: '#757575',
                    fontSize: '14px',
                    fontFamily: 'CircularProBook',
                }}
            >
                {label}
            </Typography>
            <Typography
                sx={{
                    color: '#212121',
                    fontFamily: 'CircularProMedium',
                    fontSize: '14px',
                }}
            >
                {summ > 0 ? summ?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} €
            </Typography>
        </Stack>
    );
}

export default SummContainer;

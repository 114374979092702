import { Box } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useGetAssetDataForStatus } from '../../api/LogbookApi';
import StatusFuelLevel from '../../components/status/StatusFuelLevel';
import StatusLastPosition from '../../components/status/StatusLastPosition';
import StatusMileage from '../../components/status/StatusMileage';
import StatusPrivateModus from '../../components/status/StatusPrivateModus';
// import StatusStopover from '../../components/status/StatusStopover';
import StatusVehicle from '../../components/status/StatusVehicle';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useTranslation } from 'react-i18next';

export default function Status() {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [connectionStatus, setConnectionStatus] = useState(0);
    const idAsset = useOutletContext().logbookInfo?.assetId;
    const [mileage, setMileage] = useState(null);
    const vehicleData = useGetAssetDataForStatus(idAsset, setMessage);
    const [isPrivatModusActive, setIsPrivateModusActive] = useState(false);

    if (vehicleData === undefined) {
        return <>{message && <SimpleSnackbar message={message} setMessage={setMessage} />}</>;
    } else if (vehicleData === null || vehicleData?.length === 0) {
        return (
            <Box sx={{ maxWidth: '538px', margin: 'auto' }}>
                <div>{t('noAssetAccess')}</div>
            </Box>
        );
    } else {
        return (
            <Box sx={{ maxWidth: '538px', margin: 'auto' }}>
                <StatusVehicle
                    idAsset={idAsset}
                    driverRegistration={vehicleData?.driverRegistration}
                    connectionStatus={connectionStatus}
                    setConnectionStatus={setConnectionStatus}
                    setMessage={setMessage}
                    isPrivatModusActive={isPrivatModusActive}
                />
                <StatusLastPosition latlng={vehicleData?.logLast?.latlng} />
                <Box sx={{ display: 'flex' }}>
                    <StatusFuelLevel fuelPercent={vehicleData?.logLast?.fuelPercent} mileage={mileage} />
                    <StatusMileage idAsset={idAsset} fuelPercent={vehicleData?.logLast?.fuelPercent} mileage={mileage} setMileage={setMileage} />
                </Box>
                {connectionStatus >= 2 && (
                    <>
                        <StatusPrivateModus isPrivatModusActive={isPrivatModusActive} setIsPrivateModusActive={setIsPrivateModusActive} />
                        {/* <StatusStopover /> */}
                    </>
                )}
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </Box>
        );
    }
}

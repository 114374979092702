import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, Checkbox, Chip, Fab, FormControl, FormControlLabel, MenuItem, OutlinedInput, Select, Snackbar, TextField } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useCheckExportValidations, useGetAssetDataApi } from '../../api/LogbookApi';
import axios from 'axios';
import Moment from 'moment';
import 'moment-timezone';
import { getCookie } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';
import i18n from 'i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

export default function ExportComp(props) {
    const [noValidTourOpen, setNoValidTourOpen] = React.useState(false);
    const [exportTourOpen, setExportTourOpen] = React.useState(false);
    const [format, setFormat] = React.useState([]);
    const [email, setEmail] = React.useState([]);
    const [editContant, setEditContant] = React.useState(false);
    const dateFilter = props.datesFilter;
    const logBookId = props.logbookId;

    const [driverOverview, setDriverOverview] = React.useState(true);
    const [singleTours, setSingleTours] = React.useState(true);
    const [annualOverview, setAnnualOverview] = React.useState(true);
    const [costs, setCosts] = React.useState(true);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('No Message');

    const [emailMessage, setEmailMessage] = React.useState('');
    const [formatMessage, setFormatMessage] = React.useState('');

    const { t } = useTranslation();
    //todo import account contacts for autocomplete
    const contactEmails = [];

    const validation = useCheckExportValidations(logBookId, dateFilter);

    let navigate = useNavigate();
    const loginUser = useOutletContext().loginUser;
    const assetInfo = useOutletContext().logbookInfo;
    const vehicleInfo = useGetAssetDataApi(assetInfo?.assetId);
    const formatsData = [
        {
            id: 1,
            name: 'PDF',
        },
        {
            id: 2,
            name: 'CSV',
        },
        {
            id: 3,
            name: 'XLSX',
        },
    ];

    const handleNoValidClickOpen = () => {
        setNoValidTourOpen(true);
    };
    const handleNoValidClose = () => {
        setNoValidTourOpen(false);
    };
    const handleOpenTours = () => {
        navigate('/open');
    };

    const handleExportClickOpen = () => {
        setExportTourOpen(true);
    };
    const handleExportClose = () => {
        setExportTourOpen(false);
    };
    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    const validateEmails = (emails) => {
        let valid = true;
        emails.forEach(function (value) {
            if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(value)) {
                valid = false;
            }
        });
        return valid;
    };
    const validValues = () => {
        return email.length > 0 && format.length > 0 && (singleTours || costs || driverOverview || annualOverview);
    };
    const handleExportTours = () => {
        if (email.length <= 0) {
            setEmailMessage(t('pleaseEnterEmail'));
            // setSnackOpen(true);
        } else if (!validateEmails(email)) {
            setEmailMessage(t('pleaseEnterValidEmail'));
            // setSnackOpen(true);
        } else if (format.length <= 0) {
            setEmailMessage('');
            setFormatMessage(t('pleaseSelectDataFromat'));
        } else {
            const userTimezone = 'Europe/Berlin';
            let data = JSON.stringify({
                type: 'logbook_tour',
                idLogbook: logBookId,
                dtFrom: Moment.tz(dateFilter.dateFrom, 'YYYY-MM-DD', userTimezone).startOf('day').utc().format('YYYY-MM-DDTHH:mm'),
                dtTo: Moment.tz(dateFilter.dateUntil, 'YYYY-MM-DD', userTimezone).endOf('day').utc().format('YYYY-MM-DDTHH:mm'),
                docType: format,
                emails: email,
                lang: i18n.language.includes('en') ? 'en' : 'de',
                tz: userTimezone,
                by: loginUser.firstname + ' ' + loginUser.lastname,
                metaData: {
                    report_title: 'Logbook Report',
                    vehicle_name: vehicleInfo?.name,
                    license_plate: vehicleInfo?.licensePlate,
                    driverOverview,
                    singleTours,
                    annualOverview,
                    costs,
                },
            });
            const token = getCookie(process.env.REACT_APP_COOKIE_NAME);
            if (token.length === 0) {
                window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL;
            }

            let config = {
                method: 'post',
                url: process.env.REACT_APP_EXPORT_API + '/api/report/publish',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(config)
                .then((response) => {
                    if (response.data.status === 'FAIL') {
                        setExportTourOpen(false);
                        handleNoValidClickOpen();
                    } else {
                        setSnackMessage(t('successfulExport'));
                        setSnackOpen(true);
                        setExportTourOpen(false);
                    }
                })
                .catch((error) => {
                    setSnackMessage(t('unsuccessfulExport'));
                    setSnackOpen(true);
                });
        }
    };

    const handleFormatChange = (event) => {
        setFormat(event.target.value);
    };
    const handleFormatDelete = (event, item) => {
        event.stopPropagation();
        event.preventDefault();
        setFormat(format.filter((element) => element !== item));
    };

    const useCheckValidation = () => {
        validation ? handleExportClickOpen() : handleNoValidClickOpen();
    };

    const customCss = {
        position: 'fixed',
        bottom: props.mobile ? '72px' : '16px',
        right: '16px',
        backgroundColor: theme.palette.success.main,
        height: '56px',
        borderRadius: '16px',
    };

    return (
        <div>
            <Fab onClick={useCheckValidation} variant='extended' color='lightgreen' aria-label='Export' sx={customCss} disabled={validation === undefined}>
                {props.scrollDown && <SaveAltIcon />}
                {!props.scrollDown && (
                    <>
                        <SaveAltIcon sx={{ marginRight: '8px' }} />
                        Export
                    </>
                )}
            </Fab>

            {/* not valid dialog  */}
            <Dialog
                onClose={handleNoValidClose}
                open={noValidTourOpen}
                maxWidth='xs'
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <DialogTitle sx={{ padding: '24px 24px 16px 24px' }}>{t('exportNotPossible')}</DialogTitle>
                <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                    <Box display='flex' alignItems='center' justifyContent='center'>
                        <img src='./imgs/dialog.svg' alt='exporting' loading='lazy' />
                    </Box>
                    <Typography gutterBottom>{t('exportErrorMessage')}</Typography>
                </DialogContent>
                <DialogActions sx={{ height: '88px', padding: '24px' }}>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important' }} color='primary' autoFocus onClick={handleNoValidClose}>
                        {t('cancel')}
                    </Button>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important' }} color='primary' autoFocus onClick={handleOpenTours}>
                        {t('edit')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* export dialog  */}
            <Dialog
                onClose={handleExportClose}
                open={exportTourOpen}
                maxWidth='xs'
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <DialogTitle sx={{ padding: '24px 24px 16px 24px' }}>{t('logbookExport')}</DialogTitle>
                <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                    <Box display='flex' alignItems='center' justifyContent='center'></Box>
                    <FormControl fullWidth>
                        <Typography variant='caption' display='block' gutterBottom>
                            {t('selectRecipients')}
                        </Typography>
                        <Autocomplete
                            value={email}
                            onChange={(event, newValue) => {
                                if (event.target.value === undefined) {
                                    setEmail(newValue);
                                }
                                if (event.target.value !== undefined) {
                                    let recipients = [].concat(email);
                                    if (event.target.value.trim().length > 0) {
                                        let split = event.target.value.split(' ');
                                        recipients = recipients.concat(split);
                                    }
                                    setEmail(recipients);
                                }
                            }}
                            onBlur={(event) => {
                                let newValue = [].concat(email);
                                if (event.target.value.trim().length > 0) {
                                    let split = event.target.value.split(' ');
                                    newValue = newValue.concat(split);
                                }
                                setEmail(newValue);
                            }}
                            clearOnBlur
                            multiple
                            id='tags-outlined'
                            options={contactEmails.map((option) => option)}
                            getOptionLabel={(option) => option}
                            freeSolo
                            renderInput={(params) => <TextField error={emailMessage.length !== 0} {...params} placeholder={t('recipient')} />}
                        />
                        <Typography sx={{ color: 'error.main' }} variant='caption' display='block' gutterBottom>
                            {emailMessage}
                        </Typography>
                    </FormControl>

                    <FormControl fullWidth>
                        <Typography variant='caption' display='block' gutterBottom>
                            {t('selectDFormat')}
                        </Typography>
                        <Select
                            error={formatMessage.length !== 0}
                            labelId='demo-multiple-chip-label'
                            id='demo-multiple-chip'
                            multiple={true}
                            value={format}
                            onChange={handleFormatChange}
                            input={<OutlinedInput id='select-multiple-chip' />}
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 0.5,
                                    }}
                                >
                                    {selected.map((item) => (
                                        <Chip clickable onMouseDown={(e) => handleFormatDelete(e, item)} onDelete={(e) => handleFormatDelete(e, item)} key={item} label={item} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {formatsData.map((formatData) => (
                                <MenuItem key={formatData.id} value={formatData.name}>
                                    {formatData.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography sx={{ color: 'error.main' }} variant='caption' display='block' gutterBottom>
                            {formatMessage}
                        </Typography>
                    </FormControl>
                    <FormControl fullWidth sx={{ my: '16px' }}>
                        <Typography sx={{ fontFamily: 'CircularProMedium', cursor: 'pointer' }} display='block' gutterBottom onClick={() => setEditContant(true)}>
                            {editContant ? t('selectedContent') : t('editExportContant')}
                        </Typography>
                        {editContant && (
                            <>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked sx={{ mr: '12px' }} onChange={(e) => setSingleTours(e.target.checked)} value={singleTours} />}
                                    label={t('singleTours')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontFamily: 'CircularProBook',
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox defaultChecked sx={{ mr: '12px' }} onChange={(e) => setAnnualOverview(e.target.checked)} value={annualOverview} />}
                                    label={t('annualOvervirw')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontFamily: 'CircularProBook',
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox defaultChecked sx={{ mr: '12px' }} onChange={(e) => setDriverOverview(e.target.checked)} value={driverOverview} />}
                                    label={t('driverOverview')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontFamily: 'CircularProBook',
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox defaultChecked sx={{ mr: '12px' }} onChange={(e) => setCosts(e.target.checked)} value={costs} />}
                                    label={t('costs')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontFamily: 'CircularProBook',
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                            </>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ height: '88px', padding: '24px' }}>
                    <Button variant='text' color='secondary' autoFocus onClick={handleExportClose} sx={{ fontSize: '14px', height: '40px', marginBottom: '0px !important' }}>
                        {t('cancel')}
                    </Button>
                    <Button
                        variant='contained'
                        color='success'
                        autoFocus
                        onClick={handleExportTours}
                        sx={{ bgcolor: theme.palette.primary.light, fontSize: '14px', height: '40px', marginBottom: '0px !important' }}
                        disabled={!validValues()}
                    >
                        {t('send')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackOpen} autoHideDuration={6000} message={snackMessage} onClose={handleSnackClose} />
        </div>
    );
}

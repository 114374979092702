import { Button, InputBase, Stack } from '@mui/material';
import React from 'react';
import theme from '../../themes/theme';

import AddIcon from '@mui/icons-material/Add';
import AssetsDialog from './AssetsDialog';


const SearchAndAddAssets = (props) => {
    const { t, selectedAssets = [], setSelectedAssets, initialPersonSet, title, open, setOpen, assetsWithOutLogbook, setSearchTerm, searchTerm } = props


    return (
        <Stack direction={'row'} justifyContent={selectedAssets.length > 0 ? 'space-between' : 'flex-end'}>
            {selectedAssets.length > 0 && (
                <Stack
                    direction={'row'}
                    alignItems='center'
                    justifyContent='space-between'
                    padding='6px'
                    sx={{ backgroundColor: theme.palette.grey[100] }}
                    borderRadius='4px'
                    marginBottom='8px'
                    flexBasis='30%'
                >
                    <InputBase placeholder={t('searchInList')} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} sx={{ fontSize: '14px' }} />
                </Stack>
            )}
            <Stack>
                {selectedAssets.length > 0 && <Button startIcon={<AddIcon />} onClick={() => setOpen(true)} >
                    {t('add')}
                </Button>}
                <AssetsDialog
                    showDialog={open}
                    setShowDialog={setOpen}
                    data={assetsWithOutLogbook}
                    selectedItems={selectedAssets}
                    setSelectedItems={setSelectedAssets}
                    initialPersonSet={initialPersonSet}
                    createLogBooks={true}
                    title={title}
                />
            </Stack>
        </Stack>
    );
}

export default SearchAndAddAssets
import * as React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getFullname, GetTourTypes } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';

export default function OneClickTourUpdate(props) {
    const loginUuid = useOutletContext().loginUser.loginUuid;
    const { t } = useTranslation();
    let driverName = '';
    let navigate = useNavigate();

    const uniqueDriverFound = () => {
        let userDriverData = props.allDriverData.find((driver) => driver.loginUuid === loginUuid && props.driverIds.includes(driver.id));

        if (userDriverData === undefined && props.driverIds.length === 1) {
            userDriverData = props.allDriverData.find((driver) => props.driverIds[0] === driver.id);
        }

        if (userDriverData !== undefined) {
            driverName = getFullname(userDriverData);
            return true;
        } else {
            return false;
        }
    };

    if (uniqueDriverFound()) {
        return (
            <>
                {props.tourType !== GetTourTypes().PRIVATE && (
                    <>
                        <Button
                            variant='outlined'
                            sx={{
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                    borderColor: theme.palette.primary.contrastText,
                                },
                            }}
                            onClick={() =>
                                navigate('/edit/' + props.tourId, {
                                    state: {
                                        type: GetTourTypes().BUSINESS,
                                    },
                                })
                            }
                        >
                            {t('business')}
                        </Button>
                        <Tooltip title={`${driverName} ${t('tooltipSetDriver')}!`}>
                            <span>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    sx={{ marginLeft: '8px' }}
                                    onClick={() => props.saveTourOneClick(props.tourId, driverName, GetTourTypes().COMMUTE, t('tourMarkedAsCommute'))}
                                >
                                    {t('commute')}
                                </Button>
                            </span>
                        </Tooltip>
                    </>
                )}
                <Tooltip title={`${driverName} ${t('tooltipSetDriver')}!`}>
                    <span>
                        <Button
                            variant='outlined'
                            color='error'
                            sx={{ marginLeft: '8px' }}
                            onClick={() => props.saveTourOneClick(props.tourId, driverName, GetTourTypes().PRIVATE, t('tourMarkedAsPrivate'))}
                        >
                            {t('privateTour')}
                        </Button>
                    </span>
                </Tooltip>
            </>
        );
    } else {
        return <></>;
    }
}

import React, { useCallback, useEffect } from 'react';
import { Stack, Typography, Button, Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import theme from '../../themes/theme';
import FilterMenu from './components/FilterMenu';
import { useDeleteReceipt, useGetReceipts } from '../../api/LogbookApi';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import DeleteDialog from './components/DeleteDialog';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import DatePickerCosts from '../ui/DatePickerCosts';
import SvgNoCosts from '../other/SvgNoCosts';
import Badge from '@mui/material/Badge';
import { useLongPress } from 'use-long-press';
import InvoiceReceipt from '../other/InvoiceReceipt';
import LinearProgress from '@mui/material/LinearProgress';

function CostView() {
    /** State Valiables */
    const [datum, setDatum] = useState(moment());
    const [receiptsArray, setReceiptsArray] = useState([]);
    const [showFilterList, setShowFilterList] = useState(false);
    const [costFilter, setCostFilter] = useState([]);
    const [providerFilter, setProviderFilter] = useState([]);
    const [fee, setFee] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [receiptID, setReceiptID] = useState('');
    const [actionMessage, setActionMessage] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loginUserAccess = useOutletContext().logbookInfo?.loginUserAccess;
    const idlogbook = useOutletContext().logbookId;
    const [isScrolling, setIsScrolling] = useState(false);

    /** All receipts from backend */
    const { receipts, loading } = useGetReceipts(idlogbook, datum);
    const { deleteReceipt } = useDeleteReceipt(setActionMessage);

    /** Check which rights the User have */
    const superAdmin = loginUserAccess?.filter((right) => right === 'grant');
    const editAccess = loginUserAccess?.filter((right) => right === 'edit');
    const deleteAccess = loginUserAccess?.filter((right) => right === 'delete');

    /** A Function to change the value to two decimal number  */
    const showTwoAfterComma = (params) => {
        let number = params;
        if (typeof number === 'number') {
            number = number.toFixed(2).replace('.', ',');
        }
        return number;
    };

    const filterAndSortReceipts = useCallback(() => {
        const filteredRows = receipts?.logbookReceipts?.filter((row) => {
            let hasMatchingCategory = true;
            let hasMatchingProvider = true;
            if (costFilter.length > 0) {
                hasMatchingCategory = row.articles?.some((article) => costFilter.some((category) => category?.toLowerCase() === article?.category?.toLowerCase()));
            }
            if (providerFilter.length > 0) {
                hasMatchingProvider = providerFilter.some((provider) => provider.toLowerCase() === row.vendor.toLowerCase());
            }
            return hasMatchingCategory && hasMatchingProvider;
        });

        return filteredRows.sort((a, b) => new Date(b.dtBilling) - new Date(a.dtBilling));
    }, [costFilter, providerFilter, receipts?.logbookReceipts]);

    useEffect(() => {
        if (receipts) {
            setReceiptsArray(filterAndSortReceipts());
            const totalFeeSum = filterAndSortReceipts()
                ?.reduce((sum, row) => {
                    const fee = parseFloat(row?.total?.toLocaleString().replace(',', '.'));
                    if (!isNaN(fee)) {
                        return sum + fee;
                    }
                    return sum;
                }, 0)
                ?.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });

            setFee(totalFeeSum);
        }
    }, [costFilter, providerFilter, datum, idlogbook, receipts, t, filterAndSortReceipts]);

    /** A Fucntion to get change the value of the select to a date */
    const handleChange = (event) => {
        const selectedMonth = moment(event);
        setDatum(selectedMonth);
        setCostFilter([]);
        setProviderFilter([]);

        if (selectedMonth === '') {
            setReceiptsArray(receipts);
        } else {
            setReceiptsArray(filterAndSortReceipts());
        }
    };

    /** A Function to check what rights have the use */
    const navigateToCostDetails = useCallback(
        (itemId) => {
            if (editAccess || superAdmin) {
                navigate(`/costs/${itemId}`);
            }
        },
        [navigate, editAccess, superAdmin]
    );

    /** A Fucntion to determin it is a Long press */
    const bind = useLongPress((event, itemId) => {
        if (!isScrolling && (superAdmin || deleteAccess)) {
            setReceiptID(itemId.context);
            setOpenDelete(true);
        }
    });

    const handleTouchMove = () => {
        setIsScrolling(true);
    };

    const handleTouchEnd = () => {
        setIsScrolling(false);
    };

    /** A Delete function */
    const handleDelete = async () => {
        await deleteReceipt({ variables: { id: receiptID } });
    };

    const changeCostFilter = (newCategory) => {
        let newCostFilter = [...costFilter];
        if (newCostFilter.every((cost) => cost.toLowerCase() !== newCategory.toLowerCase())) {
            newCostFilter.push(newCategory);
        } else {
            newCostFilter = newCostFilter.filter((cost) => cost.toLowerCase() !== newCategory.toLowerCase());
        }
        setCostFilter(newCostFilter);
    };

    const changeProviderFilter = (newProvider) => {
        let newProviderFilter = [...providerFilter];
        if (newProviderFilter.every((provider) => provider.toLowerCase() !== newProvider.toLowerCase())) {
            newProviderFilter.push(newProvider);
        } else {
            newProviderFilter = newProviderFilter.filter((provider) => provider.toLowerCase() !== newProvider.toLowerCase());
        }
        setProviderFilter(newProviderFilter);
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            <Box>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography
                        variant='h3'
                        sx={{
                            color: '#212121',
                            fontFamily: 'CircularProMedium',
                        }}
                    >
                        {t('costs')}
                    </Typography>

                    <Stack
                        sx={{
                            position: 'relative',
                        }}
                        direction='row'
                        spacing={2}
                    >
                        {/** The filter chosse */}
                        {costFilter.length > 0 || providerFilter.length > 0 ? (
                            <Badge componentsProps={{ badge: { style: { backgroundColor: '#16A100' } } }} variant='dot'>
                                <FilterListIcon
                                    onClick={() => setShowFilterList(!showFilterList)}
                                    sx={{
                                        width: '24px',
                                        aspectRatio: 1,
                                    }}
                                />
                            </Badge>
                        ) : (
                            <FilterListIcon
                                onClick={() => setShowFilterList(!showFilterList)}
                                sx={{
                                    width: '24px',
                                    aspectRatio: 1,
                                }}
                            />
                        )}
                        {/** Filter List */}
                        {showFilterList && receiptsArray.length > 0 && (
                            <FilterMenu
                                items={receipts?.logbookReceipts}
                                costFilter={costFilter}
                                changeCostFilter={changeCostFilter}
                                providerFilter={providerFilter}
                                changeProviderFilter={changeProviderFilter}
                            />
                        )}
                    </Stack>
                </Stack>
                <Stack direction='row' justifyContent='space-between' sx={{ fontFamily: theme.typography.fontFamily }} alignItems='center'>
                    {/** Select to chosse which month to show and By Deafult is the current month */}
                    <DatePickerCosts date={datum} handleChange={handleChange} />
                    <Stack>
                        {/** cost summ for the Month */}
                        <Typography sx={{ fontSize: '14px', color: theme.palette.grey[600] }}>{t('thisMonth')}</Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontFamily: 'CircularProMedium',
                                textAlign: 'right',
                            }}
                        >
                            {fee?.toLocaleString('de-DE', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                            &nbsp;€
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
            <Stack sx={{ my: '10px', p: 0, overflow: 'scroll', height: '75%', px: '10px', overflowX: 'hidden', pb: '40px' }}>
                {loading ? (
                    <LinearProgress />
                ) : !loading && receiptsArray.length > 0 ? (
                    receiptsArray?.map((item) => (
                        <Button
                            {...bind(item.id)}
                            key={uuidv4()}
                            to={`/costs/${item.id}`}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                p: 0,
                                m: 0,
                                borderRadius: '0',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                                '&:active': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            onClick={() => navigateToCostDetails(item.id)}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                        >
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                key={item.id}
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'CircularProMedium',
                                        fontSize: '16px',
                                        color: '#212121',
                                    }}
                                    key={uuidv4()}
                                >
                                    {item.imageUrl === null && <InvoiceReceipt size={10} fill={'#ff3d00'} />} {moment(item.dtBilling).format('DD.MM.YYYY HH:mm')}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'RobotoMonoRegular',
                                        fontSize: '12px',
                                        color: '#757575',
                                    }}
                                    key={uuidv4()}
                                >
                                    {showTwoAfterComma(item.total)}&nbsp;€
                                </Typography>
                            </Stack>
                            <Typography
                                key={uuidv4()}
                                variant='h4'
                                sx={{
                                    fontFamily: 'CircularProBook',
                                    fontSize: '14px',
                                    textAlign: 'left',
                                    width: '100%',
                                    textDecoration: 'none',
                                }}
                            >
                                {t(item.articles[0]?.category.toLowerCase())}
                            </Typography>
                            <Typography
                                key={uuidv4()}
                                variant='h4'
                                sx={{
                                    mb: '15px',
                                    paddingBottom: '10px',
                                    borderBottom: '1px solid #E0E0E0',
                                    fontFamily: 'CircularProBook',
                                    fontSize: '14px',
                                    textAlign: 'left',
                                    width: '100%',
                                    textDecoration: 'none',
                                }}
                            >
                                {` ${item.vendor}, ${`${item.address.street} ${item.address.houseNo}, ${item.address.zip} ${item.address.city}`}`}
                            </Typography>
                        </Button>
                    ))
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            padding: '32px 48px',
                        }}
                    >
                        <SvgNoCosts />
                        <Typography
                            sx={{
                                color: theme.palette.grey[600],
                                paddingTop: '16px',
                            }}
                        >
                            {t('noReceipt')}
                        </Typography>
                    </Box>
                )}
                {/** Delete Dialog for Mobile */}
                <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />
            </Stack>
            {actionMessage && <SimpleSnackbar message={actionMessage} setMessage={setActionMessage} />}
        </>
    );
}

export default CostView;

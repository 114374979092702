import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@mui/material';
import { getNumberWithThousandSeparator } from '../other/ConfigBasic';

export default function AdditionalTourInfos(props) {
    const selectedTour = props.selectedTour;
    const { t } = useTranslation();

    const getTourStatus = () => {
        let status = '';
        if (selectedTour.status === 'EDITED') {
            status = t('statusValid');
        } else if (selectedTour.status === 'EXPORTED') {
            status = t('exported');
        } else {
            status = t('statusInvalid');
        }
        return status;
    };

    return (
        <Card variant='outlined'>
            <CardContent>
                <Grid container xs={12} md={12}>
                    <Grid xs={12} md={12}>
                        <h4>{t('furtherInformation')}</h4>
                    </Grid>
                    <Grid xs={6} md={6} textAlign='left'>
                        {t('status')}
                    </Grid>
                    <Grid xs={6} md={6} textAlign='right'>
                        {getTourStatus()}
                    </Grid>
                    <Grid xs={6} md={6} textAlign='left'>
                        {t('mileageCalibrated')}
                    </Grid>
                    <Grid xs={6} md={6} textAlign='right'>
                        {/* byNone, bySystem, byUser */}
                        {t(`by${selectedTour.calibrationType[0].toUpperCase() + selectedTour.calibrationType.slice(1).toLowerCase()}`)}
                    </Grid>
                    <Grid xs={6} md={6} textAlign='left'>
                        {t('mileageBegin')}
                    </Grid>
                    <Grid xs={6} md={6} textAlign='right'>
                        {selectedTour.mileageBegin === null ? 'n.a.' : getNumberWithThousandSeparator(selectedTour.mileageBegin / 1000, 0)} km
                    </Grid>
                    <Grid xs={6} md={6} textAlign='left'>
                        {t('mileageEnd')}
                    </Grid>
                    <Grid xs={6} md={6} textAlign='right'>
                        {selectedTour.mileageEnd === null ? 'n.a.' : getNumberWithThousandSeparator(selectedTour.mileageEnd / 1000, 0)} km
                    </Grid>
                    <Grid xs={6} md={6} textAlign='left'>
                        {t('distance')}
                    </Grid>
                    <Grid xs={6} md={6} textAlign='right'>
                        {selectedTour.distance === null ? 'n.a.' : (selectedTour.distance / 1000).toFixed(2).replace('.', ',')} km
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

import * as React from 'react';
import { LinearProgress, List, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import axios from 'axios';
import { useEffect } from 'react';
import MixedTourStopsListItem from './MixedTourStopsListItem';
import SimpleSnackbar from '../ui/SimpleSnackbar';

const getTourInfos = (lat, long, callback, setMessage) => {
    const baseUrl = process.env.REACT_APP_GEOCODER_API;
    axios({
        method: 'get',
        url: `${baseUrl}${lat},${long}`,
    })
        .then((response) => callback(response.data))
        .catch((error) => setMessage(error.message));
};

const buildNewTours = (startAddress, startDataPoint, endAddress, endDataPoint, stops) => {
    const newTours = stops.map((stop, index) => {
        const pointAddress = stop?.address !== null;

        if (stops.length === 1) {
            return [
                {
                    date: stop.dataPoint.date,
                    dtBegin: startDataPoint.date,
                    streetBegin: `${startAddress.street}${startAddress.house !== null ? ' ' + startAddress.house : ''}`,
                    postalBegin: startAddress.postal,
                    cityBegin: startAddress.city,
                    latlngBegin: startDataPoint.latlng,
                    dtEnd: stop.dataPoint.date,
                    streetEnd: pointAddress ? `${stop?.address.street}${stop?.address.house !== null ? ' ' + stop?.address.house : ''}` : '',
                    postalEnd: pointAddress ? stop.address.postal : '',
                    cityEnd: pointAddress ? stop.address.city : '',
                    latlngEnd: stop.dataPoint.latlng,
                },
                {
                    date: stop.dataPoint.date,
                    dtBegin: stop.dataPoint.date,
                    streetBegin: pointAddress ? `${stop.address.street}${stop.address.house !== null ? ' ' + stop.address.house : ''}` : '',
                    postalBegin: pointAddress ? stop.address.postal : '',
                    cityBegin: pointAddress ? stop.address.city : '',
                    latlngBegin: stop.dataPoint.latlng,
                    dtEnd: endDataPoint.date,
                    streetEnd: `${endAddress.street}${endAddress.house !== null ? ' ' + endAddress.house : ''}`,
                    postalEnd: endAddress.postal,
                    cityEnd: endAddress.city,
                    latlngEnd: endDataPoint.latlng,
                },
            ];
        } else if (index === 0) {
            return {
                date: stop.dataPoint.date,
                dtBegin: startDataPoint.date,
                streetBegin: `${startAddress.street}${startAddress.house !== null ? ' ' + startAddress.house : ''}`,
                postalBegin: startAddress.postal,
                cityBegin: startAddress.city,
                latlngBegin: startDataPoint.latlng,
                dtEnd: pointAddress ? stop.dataPoint.date : '',
                streetEnd: pointAddress ? `${stop.address.street}${stop.address.house !== null ? ' ' + stop.address.house : ''}` : '',
                postalEnd: pointAddress ? stop.address.postal : '',
                cityEnd: pointAddress ? stop.address.city : '',
                latlngEnd: stop.dataPoint.latlng,
            };
        } else if (index === stops.length - 1) {
            return [
                {
                    date: stop.dataPoint.date,
                    dtBegin: stops[index - 1].dataPoint.date,
                    streetBegin: `${stops[index - 1].address.street}${stops[index - 1].address.house !== null ? ' ' + stops[index - 1].address.house : ''}`,
                    postalBegin: stops[index - 1].address.postal,
                    cityBegin: stops[index - 1].address.city,
                    latlngBegin: stops[index - 1].dataPoint.latlng,
                    dtEnd: stop.dataPoint.date,
                    streetEnd: pointAddress ? `${stop.address.street}${stop.address.house !== null ? ' ' + stop.address.house : ''}` : '',
                    postalEnd: pointAddress ? stop.address.postal : '',
                    cityEnd: pointAddress ? stop.address.city : '',
                    latlngEnd: stop.dataPoint.latlng,
                },
                {
                    date: stop.dataPoint.date,
                    dtBegin: stop.dataPoint.date,
                    streetBegin: pointAddress ? `${stop.address.street}${stop.address.house !== null ? ' ' + stop.address.house : ''}` : '',
                    postalBegin: pointAddress ? stop.address.postal : '',
                    cityBegin: pointAddress ? stop.address.city : '',
                    latlngBegin: stop.dataPoint.latlng,
                    dtEnd: endDataPoint.date,
                    streetEnd: `${endAddress.street}${endAddress.house !== null ? ' ' + endAddress.house : ''}`,
                    postalEnd: endAddress.postal,
                    cityEnd: endAddress.city,
                    latlngEnd: endDataPoint.latlng,
                },
            ];
        } else {
            return {
                date: stop.dataPoint.date,
                dtBegin: stops[index - 1].dataPoint.date,
                streetBegin: `${stops[index - 1].address.street}${stops[index - 1].address.house !== null ? ' ' + stops[index - 1].address.house : ''}`,
                postalBegin: stops[index - 1].address.postal,
                cityBegin: stops[index - 1].address.city,
                latlngBegin: stops[index - 1].dataPoint.latlng,
                dtEnd: stop.dataPoint.date,
                streetEnd: pointAddress ? `${stop.address.street}${stop.address.house !== null ? ' ' + stop.address.house : ''}` : '',
                postalEnd: pointAddress ? stop.address.postal : '',
                cityEnd: pointAddress ? stop.address.city : '',
                latlngEnd: stop.dataPoint.latlng,
            };
        }
    });

    return newTours.flatMap((obj) => obj);
};

export default function MixedTourStopsList(props) {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [startAddress, setStartAddress] = useState('');
    const [endAddress, setEndAddress] = useState('');
    const setGeoCoderStartOutput = (output) => {
        setStartAddress(output);
    };
    const setGeoCoderEndOutput = (output) => {
        setEndAddress(output);
    };

    useEffect(() => {
        getTourInfos(props.start.latlng[0], props.start.latlng[1], setGeoCoderStartOutput, setMessage);
        getTourInfos(props.end.latlng[0], props.end.latlng[1], setGeoCoderEndOutput, setMessage);
    }, [props.start.latlng, props.end.latlng]);

    if (!startAddress || !endAddress) {
        return (
            <>
                <LinearProgress />
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }

    const newTours = buildNewTours(startAddress, props.start, endAddress, props.end, props.selectedStopps);
    if (!newTours) {
        return <LinearProgress />;
    }

    return (
        <>
            <Grid container xs={12}>
                <Grid xs={12} textAlign='left'>
                    <Typography variant='h6' component='div' sx={{ px: '16px' }}>
                        {t('previewTourSplitting')}
                    </Typography>
                </Grid>
            </Grid>
            <List
                sx={{
                    width: '100%',
                    height: newTours.length === 0 ? '0px' : '186px',
                    overflowY: 'auto',
                }}
            >
                {newTours !== undefined &&
                    newTours.map((newTour) => <MixedTourStopsListItem key={newTour.dtBegin + 'X'} newTour={newTour} changeSelectedStopps={props.changeSelectedStopps} />)}
            </List>
        </>
    );
}

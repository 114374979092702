import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useAutoComplete = (input, setAddress, setMessage) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let isMounted = true;

        if (!input || input.length < 6) return;

        const fetchAddress = async () => {
            setLoading(true);
            const sessionId = uuidv4();

            try {
                const autocompleteResp = await fetch(`${process.env.REACT_APP_AUTOCOMLETE}input=${input}&session=${sessionId}&provider=google`);
                const autocompleteData = await autocompleteResp.json();

                if (autocompleteData.length !== 0) {
                    setOptions(autocompleteData);
                }

                if (autocompleteData.length > 0) {
                    const placeId = autocompleteData[0].placeId;
                    const detailsResp = await fetch(`${process.env.REACT_APP_PLACES_DETAILS}session=${sessionId}&provider=google&placeid=${placeId}`);
                    const detailsData = await detailsResp.json();

                    if (isMounted)
                        setAddress({
                            street: detailsData.street,
                            houseNo: detailsData.house ? detailsData.house : '',
                            zip: detailsData.postal ? detailsData.postal : '',
                            city: detailsData.city,
                            country: detailsData.country,
                            coordinates: [detailsData.location.lat, detailsData.location.long],
                        });
                }
            } catch (err) {
                if (isMounted) setMessage(err.message);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchAddress();

        return () => {
            isMounted = false;
        };
    }, [input, setAddress, setMessage]);

    return { options, loading };
};

export default useAutoComplete;

import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Card, CardContent, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import theme from '../../themes/theme';
import { getBreakPoints } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'dayjs/locale/de';
import i18n from 'i18next';

export default function DatePickerModifiedTours(props) {
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = useState(props.dateFilter.dateFrom);
    const [dateUntil, setDateUntil] = useState(props.dateFilter.dateUntil);
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const DATE_FORMAT = 'DD.MM.YYYY';

    const dateValidation = (date) => {
        return moment(date, DATE_FORMAT).isValid();
    };

    const saveDateFrom = (date) => {
        if (dateValidation(date)) {
            localStorage.setItem('editedDateFrom', date);
            setDateFrom(date);
            setNewDateFilter(date, dateUntil);
        }
    };

    const saveDateUntil = (date) => {
        if (dateValidation(date)) {
            localStorage.setItem('editedDateUntil', date);
            setDateUntil(date);
            setNewDateFilter(dateFrom, date);
        }
    };

    const setNewDateFilter = (dateFrom, dateUntil) => {
        props.setDateFilter({
            dateFrom: dateFrom,
            dateUntil: dateUntil,
        });
    };

    return (
        <>
            {desktop900 && (
                <Card variant='outlined'>
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t('from')}
                                value={dateFrom}
                                inputFormat={DATE_FORMAT}
                                onChange={(newValue) => {
                                    saveDateFrom(newValue.$d);
                                }}
                                renderInput={(params) => <TextField {...params} sx={[theme.components.MuiDatePicker, { marginBottom: '0' }]} />}
                            />
                            <DatePicker
                                label={t('till')}
                                value={dateUntil}
                                inputFormat={DATE_FORMAT}
                                onChange={(newValue) => {
                                    saveDateUntil(newValue.$d);
                                }}
                                renderInput={(params) => <TextField {...params} sx={[theme.components.MuiDatePicker, { marginBottom: '0', paddingRight: '0' }]} />}
                            />
                        </LocalizationProvider>
                    </CardContent>
                </Card>
            )}
            {!desktop900 && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: '0px',
                        top: '80px',
                        width: '100%',
                        padding: '0 16px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LocalizationProvider adapterLocale={i18n.language.includes('de') ? 'de' : ''} dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t('from')}
                            value={dateFrom}
                            inputFormat={DATE_FORMAT}
                            onChange={(newValue) => {
                                saveDateFrom(newValue.$d);
                            }}
                            renderInput={(params) => <TextField {...params} sx={theme.components.MuiDatePicker} />}
                        />
                        <DatePicker
                            label={t('till')}
                            value={dateUntil}
                            inputFormat={DATE_FORMAT}
                            onChange={(newValue) => {
                                saveDateUntil(newValue.$d);
                            }}
                            renderInput={(params) => <TextField {...params} sx={[theme.components.MuiDatePicker, { paddingRight: '0' }]} />}
                        />
                    </LocalizationProvider>
                </Box>
            )}
        </>
    );
}

import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ListItemIcon, List, ListItem, ListItemButton, ListItemText, Checkbox, Button, Box, InputBase, ListItemAvatar, Avatar, Typography } from '@mui/material';
import theme from '../../themes/theme';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { getFullname, getLetterAvatar } from '../other/ConfigBasic';

/**
 *
 * @param {*} props
 * @returns
 */
export default function SelectionDialogTemplate(props) {
    const {
        open,
        title,
        filterListFunction,
        checked,
        dataAll,
        handleChangeAll,
        dataList,
        handleToggle,
        showAvatar,
        emptyListMessage,
        showEmptyListAction,
        emptyListActionMessage,
        handleCancel,
        handleAction,
        handleActionMessage,
    } = props;
    const { t } = useTranslation();

    return (
        <Dialog open={open}
            PaperProps={{
                style: {
                    borderRadius: '28px',
                },
            }}
        >
            <DialogTitle sx={{padding: '24px 24px 16px 24px'}}>{title}</DialogTitle>
            <DialogContent sx={{padding:'0px 24px 0px 24px'}}>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    padding='0 8px'
                    border={`solid 1px ${theme.palette.grey[600]}`}
                    borderRadius='4px'
                    marginBottom='8px'
                >
                    <InputBase placeholder={t('search')} onChange={(value) => filterListFunction(value)} />
                    <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                </Box>
                <ListItem key='all' disablePadding>
                    <ListItemText id='all' primary={`${t('all')} (${checked.length} ${t('of')} ${dataAll.length})`} sx={{ textAlign: 'left', paddingRight: '16px' }} />
                    <ListItemIcon sx={{ marginRight: '-7px' }}>
                        <Checkbox
                            edge='start'
                            checked={checked.length === dataAll.length}
                            indeterminate={checked.length > 0 && checked.length < dataAll.length}
                            onClick={() => handleChangeAll()}
                        />
                    </ListItemIcon>
                </ListItem>
                <List dense sx={{ height: '300px', overflow: 'scroll', overflowX: 'hidden' }}>
                    {dataList?.length > 0 &&
                        dataList?.map((data) => {
                            const labelId = `checkbox-list-secondary-label-${data.id}`;
                            return (
                                <ListItem key={data.id} disablePadding>
                                    <ListItemButton onClick={handleToggle(data.id)} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                        {showAvatar && (
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={getFullname(data)}
                                                    src={data.imageUri ? `${process.env.REACT_APP_AWS_CONTACT_PATH}${data.imageUri}` : ''}
                                                    sx={{ color: '#212121', fontSize: '14px', backgroundColor: '#E0E0E0' }}
                                                >
                                                    {getLetterAvatar(data)}
                                                </Avatar>
                                            </ListItemAvatar>
                                        )}
                                        <ListItemText id={labelId} primary={getFullname(data)} />
                                        <ListItemIcon sx={{ marginRight: '-16px' }}>
                                            <Checkbox edge='end' checked={checked.indexOf(data.id) !== -1} inputProps={{ 'aria-labelledby': labelId }} />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    {dataList?.length === 0 && (
                        <ListItem>
                            <ListItemText>
                                <Typography variant='caption' color='gray'>
                                    {emptyListMessage}
                                </Typography>
                                {showEmptyListAction && (
                                    <Typography variant='h4' color='#00360F' sx={{ mt: '40px' }}>
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            variant='subtitle1'
                                            style={{
                                                color: '#00360F',
                                                textDecoration: 'none',
                                            }}
                                            href={process.env.REACT_APP_ADMIN_CONTACTS}
                                        >
                                            {emptyListActionMessage}
                                        </a>
                                    </Typography>
                                )}
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions sx={{ height: '88px',padding: '24px'}}>
                <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important'}} color='secondary' onClick={() => handleCancel()}>
                    {t('cancel')}
                </Button>
                {checked.length !== 0 && (
                    <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important'}} color='success' onClick={() => handleAction()}>
                        {handleActionMessage}
                    </Button>
                )}
                {checked.length === 0 && (
                    <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important'}} color='success' disabled>
                        {handleActionMessage}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NoPage() {
    const { t } = useTranslation();
    return (
        <main style={{ padding: '1rem 0' }}>
            <Box className='content' sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} xl={12} textAlign='center' m={5}>
                        <img src='./imgs/404.svg' alt='404 Page Not Found' loading='lazy' />
                        <h2 className='errorMessage'>{t('p404')} </h2>
                    </Grid>
                </Grid>
            </Box>
        </main>
    );
}

import React from 'react';
import theme from '../../themes/theme';

export default function SaveSvg(props) {
    return (
        <svg id='save-grey' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path id='Pfad_3015' data-name='Pfad 3015' d='M0,0H24V24H0Z' fill='none' />
            <path
                id='Pfad_3016'
                data-name='Pfad 3016'
                d='M17,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7ZM12,19a3,3,0,1,1,3-3A3,3,0,0,1,12,19ZM15,9H5V5H15Z'
                fill={props.disabled ? theme.palette.primary.dark : theme.palette.secondary.main}
            />
        </svg>
    );
}

import React from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlaceIcon from '@mui/icons-material/Place';
import theme from '../../themes/theme';
const AutocompleteInput = ({
    id,
    label,
    options,
    selectOption,
    setSelectOption,
    addressChange,
    setAddressChange,
    error,
    distanceError,
    setShowHistory
}) => {

    const { t } = useTranslation();
    const handleOptionChange = (event, newValue) => {
        setSelectOption(newValue);
        if (newValue) {
            setAddressChange(newValue.label);
        } else {
            setAddressChange('');
        }
    };

    return (
        <Autocomplete
            id={id}
            options={distanceError ? [] : options}
            isOptionEqualToValue={(option, value) => option.postal === value.postal}
            getOptionLabel={(option) => (option.label ? option.label : addressChange)}
            value={selectOption === null ? addressChange : selectOption}
            onChange={handleOptionChange}
            onInputChange={(event, newInputValue) => {
                setAddressChange(newInputValue);
            }}
            noOptionsText={distanceError ? t("moreThen5Km") : t('enterAddress')}
            onBlur={() => {
                if (!selectOption && options.length > 0) {
                    setSelectOption(options[0]);
                    setAddressChange(options[0].label);
                }
            }}
            autoSelect={true}
            renderInput={(params) => (
                <TextField
                    sx={{
                        width: "100%",
                        fontFamily: 'CircularProMedium',
                        color: theme.palette.grey[900]
                    }}
                    {...params}
                    label={t(label)}
                    onChange={(e) => {
                        setAddressChange(e.target.value);
                        setShowHistory(true)
                        if (!e.target.value) {
                            setSelectOption(null);
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end" >
                                <PlaceIcon sx={{ position: "absolute", right: "12px" }} />
                            </InputAdornment>
                        ),
                    }}
                    required
                    error={error || distanceError}
                    helperText={distanceError ? t("moreThan5KmInputError") : error ? t('mandatoryField') : ''}
                />
            )}
        />
    );
};

export default AutocompleteInput;
import * as React from 'react';
import { Avatar, Box, Button, Divider, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import { Logout } from '../auth/Logout';
import { useTranslation } from 'react-i18next';
import { getFullname, getLetterAvatar } from '../other/ConfigBasic';
import LogoutSvg from '../../img/LogoutSvg';

export default function AccountMenu(props) {
    const { anchorEl, isMenuOpen, handleProfileMenuOpen, handleMenuClose, loginUser } = props;
    const { t } = useTranslation();
    const menuId = 'primary-search-account-menu';

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            MenuListProps={{ sx: { padding: '0' } }}
            PaperProps={{ sx: { borderRadius: '28px', maxWidth: '328px' } }}
        >
            <Box sx={{ padding: '0 8px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        whiteSpace: 'pre',
                        height: '56px',
                        padding: '0 16px',
                        fontSize: '12px',
                        color: '#757575',
                    }}
                >
                    <Box>{`${loginUser?.zluser?.org} – `}</Box>
                    <Box>{`${t('custNo')} ${loginUser?.custNr}`}</Box>
                </Box>
                <ListItem disablePadding sx={{ height: '72px' }}>
                    <ListItemButton href={process.env.REACT_APP_ACCOUNT_URL} target='_blank' rel='noreferrer' sx={{ borderRadius: '36px' }}>
                        <ListItemAvatar>
                            <Avatar
                                alt={getLetterAvatar(loginUser)}
                                src={loginUser?.zluser?.imageUri ? `${process.env.REACT_APP_AWS_ZLOGIN_PATH}${loginUser?.zluser?.imageUri}` : ''}
                                sx={{ color: '#212121', fontSize: '14px', backgroundColor: '#E0E0E0' }}
                            >
                                {getLetterAvatar(loginUser)}
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primary={getFullname(loginUser)}
                            primaryTypographyProps={{ fontSize: '16px !important', fontFamily: 'CircularProMedium' }}
                            secondary={loginUser?.email}
                            secondaryTypographyProps={{ fontSize: '12px !important', color: '#757575 !important' }}
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{ margin: '8px 0', height: '48px' }}>
                    <ListItemButton onClick={Logout}>
                        <ListItemIcon>
                            <LogoutSvg />
                        </ListItemIcon>
                        <ListItemText primary={t('logout')} primaryTypographyProps={{ fontSize: '14px !important', color: '#757575 !important' }} />
                    </ListItemButton>
                </ListItem>
                <Divider sx={{ margin: '0 16px' }}></Divider>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '8px 0' }}>
                    <Button
                        href={process.env.REACT_APP_DOCUMENT_URL}
                        target='_blank'
                        rel='noreferrer'
                        sx={{ fontSize: '12px', color: '#757575', height: '32px', textTransform: 'none' }}
                    >
                        {t('legalDocuments')}
                    </Button>
                </Box>
            </Box>
        </Menu>
    );

    return (
        <>
            <IconButton size='large' edge='end' aria-label='account of current user' aria-controls={menuId} aria-haspopup='true' onClick={handleProfileMenuOpen} color='inherit'>
                <Avatar
                    alt={getLetterAvatar(loginUser)}
                    src={loginUser?.zluser?.imageUri ? `${process.env.REACT_APP_AWS_ZLOGIN_PATH}${loginUser?.zluser?.imageUri}` : ''}
                    sx={
                        loginUser?.zluser?.imageUri
                            ? { padding: '0px', width: '32px', height: '32px', fontSize: '14px', color: '#212121', backgroundColor: '#E0E0E0' }
                            : { padding: '2px', width: '32px', height: '32px', fontSize: '14px', color: '#212121', backgroundColor: '#E0E0E0' }
                    }
                >
                    {getLetterAvatar(loginUser)}
                </Avatar>
            </IconButton>
            {renderMenu}
        </>
    );
}

import * as React from 'react';
import { Divider, ListItem, ListItemText, Typography } from '@mui/material';
import Moment from 'moment';
import theme from '../../themes/theme';
import DeleteIcon from '@mui/icons-material/Delete';

export default function MixedTourStopsListItem(props) {
    const tour = props.newTour;

    return (
        <>
            <ListItem
                key={tour.dtBegin}
                sx={{
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: '#FBE9E7',
                        'svg:first-of-type': { opacity: 1 },
                    },
                    height: '88px',
                    padding: '12px 16px',
                }}
                onClick={() => props.changeSelectedStopps({}, { date: tour.date })}
            >
                <ListItemText
                    primary={Moment(tour.dtBegin).format('HH:mm:ss') + ' - ' + Moment(tour.dtEnd).format('HH:mm:ss')}
                    primaryTypographyProps={{ fontSize: '16px' }}
                    secondary={
                        <>
                            <Typography
                                sx={{
                                    display: 'block',
                                    fontSize: '14px',
                                    color: theme.palette.grey[600],
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                component='span'
                            >
                                {tour.streetBegin}, {tour.postalBegin} {tour.cityBegin}
                            </Typography>
                            <Typography
                                sx={{
                                    display: 'block',
                                    fontSize: '14px',
                                    color: theme.palette.grey[600],
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                component='span'
                            >
                                {tour.streetEnd}, {tour.postalEnd} {tour.cityEnd}
                            </Typography>
                        </>
                    }
                />
                <DeleteIcon
                    sx={{
                        position: 'absolute',
                        right: '16px',
                        top: '12px',
                        opacity: '0',
                        color: theme.palette.error.main,
                    }}
                />
            </ListItem>
            <Divider variant='fullWidth' component='li' />
        </>
    );
}

import React from 'react';

export default function OverviewSvg() {
    return (
        <svg id='admin_panel_settings_black_24dp' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'>
            <g id='Gruppe_3427' data-name='Gruppe 3427'>
                <rect id='Rechteck_1801' data-name='Rechteck 1801' width='18' height='18' fill='none' />
            </g>
            <g id='Gruppe_3429' data-name='Gruppe 3429' transform='translate(2.25 2.25)'>
                <g id='Gruppe_3428' data-name='Gruppe 3428'>
                    <path
                        id='Pfad_3685'
                        data-name='Pfad 3685'
                        d='M13.5,9a4.978,4.978,0,0,1,.75.068V5.452L8.625,3,3,5.452V9.135A7.66,7.66,0,0,0,8.625,16.5a6.875,6.875,0,0,0,1.2-.412A4.493,4.493,0,0,1,13.5,9Z'
                        transform='translate(-3 -3)'
                        fill='#00360f'
                    />
                    <path
                        id='Pfad_3686'
                        data-name='Pfad 3686'
                        d='M16,13a3,3,0,1,0,3,3A3,3,0,0,0,16,13Zm0,1.035a.84.84,0,1,1-.84.84A.843.843,0,0,1,16,14.035Zm0,4.028a2.043,2.043,0,0,1-1.68-.878c.038-.54,1.133-.81,1.68-.81s1.642.27,1.68.81A2.043,2.043,0,0,1,16,18.063Z'
                        transform='translate(-5.5 -5.5)'
                        fill='#00360f'
                    />
                </g>
            </g>
        </svg>
    );
}

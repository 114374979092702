import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import DialogSetEMailNotifications from '../../components/ui/DialogSetEMailNotifications';
import { useState, useEffect } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, Stack, Typography } from '@mui/material';
import theme from '../../themes/theme';
import { useOutletContext } from 'react-router-dom';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';

export default function EMailNotification(props) {
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState({});

    const logbookId = useOutletContext().logbookId;
    const [message, setMessage] = React.useState('');
    useEffect(() => {
        const createMap = (notifications) => {
            if (notifications?.length === 0) {
                return {};
            }

            const map = {};

            notifications?.forEach((notification) => {
                const { topic, receivers } = notification;
                if (!map[topic]) {
                    map[topic] = [];
                }
                map[topic].push(...receivers);
            });

            // Find common receivers across all topics
            const allReceivers = Object.values(map).flat();
            const receiverCount = allReceivers.reduce((acc, receiver) => {
                acc[receiver] = (acc[receiver] || 0) + 1;
                return acc;
            }, {});

            const commonReceivers = Object.keys(receiverCount).filter((receiver) => receiverCount[receiver] > 1);
            if (commonReceivers.length > 0) {
                map['all'] = commonReceivers;
            }

            Object.keys(map).forEach((topic) => {
                if (topic !== 'all') {
                    map[topic] = map[topic].filter((receiver) => !commonReceivers.includes(receiver));
                }
            });

            return map;
        };

        const result = createMap(props?.notifications);
        setSelectedUser(result);
    }, [props.notifications]);

    return (
        <>
            <Grid container xs={12} alignItems='center'>
                <Grid xs={10} textAlign='left'>
                    <h4 style={{ padding: 0, marginBlock: 0 }}>{t('emailNotifications')}</h4>
                </Grid>
                <Grid xs={8}>{t('emailNotificationsInfo')}</Grid>
                <Grid xs={4} textAlign='right'>
                    <DialogSetEMailNotifications selected={[logbookId]} notifications={props.notifications} />
                </Grid>

                {Object.entries(selectedUser).length > 0 ? (
                    <Grid xs={12} textAlign='left'>
                        {Object.entries(selectedUser).map(([topic, receivers]) =>
                            receivers.map((receiver) => (
                                <Stack
                                    key={receiver}
                                    direction={'row'}
                                    sx={{
                                        height: '56px',
                                        alignItems: 'center',
                                        overflowX: 'hidden',
                                        overflowY: 'scroll',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <Grid xs={4} md={7} textAlign='left'>
                                        {props.getFullnameById(receiver)}
                                    </Grid>
                                    <Grid xs={8} md={5} textAlign='right'>
                                        <Button
                                            sx={{ fontSize: '14px', maxWidth: '100%', p: 0.5, marginBlock: 1 }}
                                            variant='outlined'
                                            color='primary'
                                            id='lock-button'
                                            disabled={true}
                                        >
                                            {t(topic)}
                                        </Button>
                                    </Grid>
                                </Stack>
                            ))
                        )}
                    </Grid>
                ) : (
                    <Grid xs={12} textAlign='left'>
                        <Typography color={theme.palette.error.main} alignItems='center' display='flex'>
                            <ErrorIcon sx={{ marginRight: '16px' }} /> {t('noReceiversSelected')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}

import React from 'react';

function DriverSvg() {
    return (
        <svg id='driver_black_24dp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <g id='Gruppe_3346' data-name='Gruppe 3346'>
                <path id='Pfad_3596' data-name='Pfad 3596' d='M0,0H24V24H0Z' fill='none' />
            </g>
            <g id='Gruppe_3348' data-name='Gruppe 3348' transform='translate(2 4)'>
                <g id='Gruppe_3347' data-name='Gruppe 3347' transform='translate(10.4 7.4)'>
                    <path
                        id='Pfad_3597'
                        data-name='Pfad 3597'
                        d='M17,11.4A4.6,4.6,0,1,0,21.6,16,4.653,4.653,0,0,0,17,11.4ZM13.5,16v-.3l2.6,1,.3,2.7A3.449,3.449,0,0,1,13.5,16Zm.3-1.4A3.386,3.386,0,0,1,17,12.5a3.542,3.542,0,0,1,3.2,2.1l-3.2.9Zm3.8,4.8.3-2.7,2.6-1v.2A3.476,3.476,0,0,1,17.6,19.4Z'
                        transform='translate(-12.4 -11.4)'
                    />
                </g>
                <path
                    id='Pfad_3598'
                    data-name='Pfad 3598'
                    d='M4,18v-.6a1.039,1.039,0,0,1,.4-.8A11.026,11.026,0,0,1,10,15h.1a6.637,6.637,0,0,1,.6-2H10a13.3,13.3,0,0,0-6.6,1.8A3.016,3.016,0,0,0,2,17.4V20h9.3a7.5,7.5,0,0,1-1-2Z'
                    transform='translate(-2 -4)'
                />
                <path
                    id='Pfad_3599'
                    data-name='Pfad 3599'
                    d='M10,12A4,4,0,1,0,6,8,4.012,4.012,0,0,0,10,12Zm0-6A2,2,0,1,1,8,8,2.006,2.006,0,0,1,10,6Z'
                    transform='translate(-2 -4)'
                />
            </g>
        </svg>
    );
}

export default DriverSvg;

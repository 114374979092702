import * as React from 'react';
import { useState } from 'react';
import { useGetLatestUserCalibrationData, useGetLogbookPrivateJourney } from '../../api/LogbookApi';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import Moment from 'moment';
import { useEffect } from 'react';

export default function LogbookHelper(props) {
    const { assetId, setShowHint, logbookId } = props;
    const [message, setMessage] = useState('');
    const calibrationData = useGetLatestUserCalibrationData(assetId, setMessage);
    const [privateJourneyIsActiv, setPrivateJourneyIsActiv] = useState(undefined);
    useGetLogbookPrivateJourney(logbookId, setMessage, setPrivateJourneyIsActiv);

    useEffect(() => {
        if (calibrationData !== undefined && privateJourneyIsActiv !== undefined) {
            let hint = {
                status: privateJourneyIsActiv === null ? false : true,
                settings: false,
                settingsVehicle: false,
            };
            if (calibrationData.ymd === null) {
                hint.settings = true;
                hint.settingsVehicle = true;
            } else {
                const date1 = Moment(calibrationData.ymd);
                const date2 = Moment(new Date());
                if (date2.diff(date1, 'M') >= 6) {
                    hint.settings = true;
                    hint.settingsVehicle = true;
                }
            }
            setShowHint(hint);
        }
    }, [assetId, calibrationData, setShowHint, privateJourneyIsActiv]);

    return <>{message && <SimpleSnackbar message={message} setMessage={setMessage} />}</>;
}


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InfoIcon from '@mui/icons-material/Info';
import { FormControl, Stack, TextField, Typography } from '@mui/material';
import theme from '../../themes/theme';
import { useState } from 'react';

const RenameLogbook = (props) => {
    const { open, setOpen, t, setLogBookName, setMileage, mileage } = props
    const [name, setName] = useState('')

    const handleClose = () => {
        setOpen(false);
        setName('')
        setMileage('')
    };

    const handleChangeName = () => {
        setOpen(false);
        setLogBookName(name.trim())
        setMileage(mileage)
    };


    return (
        <Dialog open={open}
            PaperProps={{
                style: {
                    borderRadius: '28px',
                },
            }}
        >
            <DialogTitle id="customized-dialog-title" sx={{padding: '24px 24px 16px 24px'}}>
                {t('completeLBInfo')}
            </DialogTitle>
            <DialogContent sx={{padding:'0px 24px 0px 24px'}}>
                <Stack gap={4}>
                    <FormControl>
                        <Stack gap={'13px'}>
                            <Typography variant="text" sx={{ color: theme.palette.grey[400], fontSize: '14px', fontFamily: 'CircularProBook' }}>
                                {t('enterCurrentMileage')}
                            </Typography>
                            <TextField
                                required={true}
                                label={t('currentMileageInKm')}
                                variant='outlined'
                                value={mileage}
                                onChange={(e) => setMileage(e.target.value)}

                            />
                            <Stack direction='row' alignItems='center' gap={1} >
                                <InfoIcon color='info' />
                                <Typography sx={{ fontSize: '14px', color: '#757575', fontFamily: 'CircularProBook' }}>{t('warningVehical')}</Typography>
                            </Stack>
                        </Stack>
                    </FormControl>
                    <FormControl>
                        <Stack gap={'13px'}>
                            <Typography variant="text" sx={{ color: theme.palette.grey[400], fontSize: '14px', fontFamily: 'CircularProBook' }}>
                                {t('renameLogbook')}
                            </Typography>
                            <TextField
                                required={true}
                                label='Name'
                                variant='outlined'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                error={!name.length < 0}
                            />

                        </Stack>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ height: '88px',padding: '24px'}}>
                <Button onClick={handleClose} sx={{ height: '40px', marginBottom: '0px !important'}}>
                    {t('cancel')}
                </Button>
                <Button sx={{ height: '40px', marginBottom: '0px !important'}} 
                    variant='contained' color='success' onClick={handleChangeName}>
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default RenameLogbook